<template>
  <div id="trailSystemDetails">
    <b-card
      class="blockDrawer large fullMobile"
      :class="$store.state.drawer.trailSystemDetails.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="closeTrailSystemDetails()"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title">{{ language(1) }}</h4>
        <b-btn
          @click="editTrailSystem()"
          class="mb-2 mr-1"
          v-if="$store.state.currentRole === 'Administrator' && !$store.state.embedMode"
        >
          <i class="fas fa-cog"></i> {{ language(2) }}
        </b-btn>
        <b-btn
          @click="showTrustees()"
          class="mb-2 mr-1"
          v-if="$store.state.currentRole === 'Administrator' && !$store.state.embedMode"
        >
          <i class="fas fa-users"></i> {{ language(3) }}
        </b-btn>
        <b-btn
          @click="showCalendarsSettings()"
          class="mb-2"
          v-if="$store.state.currentRole === 'Administrator' && !$store.state.embedMode && ($store.state.trailSystem.subscription === 'doubleDiamond' || $store.state.trailSystem.subscription === 'star')"
        >
          <i class="fas fa-calendar"></i> {{ language(4) }}
        </b-btn>
      </template>
      <b-btn
        @click="subscribe()"
        class="mb-2"
        v-if="!$store.state.publicUserProfile.subscriptions && $store.state.trailSystemMode !== 'native'"
        variant="success"
      >
        <i class="fas fa-bell-on"></i> {{ language(5) }}
      </b-btn>
      <b-btn
        @click="subscribe()"
        class="mb-2"
        v-if="$store.state.publicUserProfile.subscriptions && !$store.state.publicUserProfile.subscriptions.includes($store.state.trailSystem.id) && $store.state.trailSystemMode !== 'native'"
        variant="success"
      >
        <i class="fas fa-bell-on"></i> {{ language(5) }}
      </b-btn>
      <b-btn
        @click="unsubscribe()"
        class="mb-2"
        v-if="$store.state.publicUserProfile.subscriptions && $store.state.publicUserProfile.subscriptions.includes($store.state.trailSystem.id) && $store.state.trailSystemMode !== 'native'"
      >
        <i class="fas fa-bell-slash"></i> {{ language(6) }}
      </b-btn>
      <p class="lead" v-if="trailSystem.totalTrails">{{ trailSystem.totalTrails }} {{ language(7) }} {{ formatLength(trailSystem.totalDistance) }}</p>
      <b-progress v-if="trailSystem.totalTrails" height="24px" class="mb-3" :max="$store.state.trailSystem.totalTrails" show-value>
        <b-progress-bar :value="$store.state.trailSystem.openTrails" variant="success"><span><i class="fas fa-check-circle"></i> {{ $store.state.trailSystem.openTrails }}</span></b-progress-bar>
        <b-progress-bar :value="$store.state.trailSystem.cautionTrails" variant="warning"><span><i class="fas fa-exclamation-circle"></i> {{ $store.state.trailSystem.cautionTrails }}</span></b-progress-bar>
        <b-progress-bar :value="$store.state.trailSystem.closedTrails" variant="danger"><span><i class="fas fa-times-circle"></i> {{ $store.state.trailSystem.closedTrails }}</span></b-progress-bar>
      </b-progress>
      <p v-if="trailSystem.activities" class="lead">
        <b-badge
          v-for="(activity, i) in trailSystem.activities"
          v-bind:key="i"
          class="mr-1"
          pill
          variant="dark"
        >
          <i :class="activity.iconClass"></i> {{ activity[$store.state.trailSystem.language] }}
        </b-badge>
      </p>
      <b-btn
        v-if="trailSystem.phoneNumber"
        class="mr-1"
        :href="'tel:' + trailSystem.phoneNumber"
        size="sm"
      ><i class="fas fa-phone"></i> {{ trailSystem.phoneNumber }}</b-btn>
      <b-btn
        v-if="trailSystem.website"
        class="mr-1"
        target="_blank"
        :href="trailSystem.website"
        size="sm"
      ><i class="fas fa-external-link-square"></i> {{ language(8) }}</b-btn>
      <p class="mt-2" v-if="trailSystem.description !== 'NULL'">{{ trailSystem.description }}</p>
      <!-- RELATED TRAIL SYSTEMS -->
      <p v-if="trailSystem.relatedTrailSystems && trailSystem.relatedTrailSystems.length > 0" class="text-center"><strong>Related Trail Systems</strong></p>
      <b-list-group v-if="trailSystem.relatedTrailSystems">
        <b-list-group-item
          v-for="(relatedTrailSystem, i) in trailSystem.relatedTrailSystems"
          v-bind:key="i"
          :href="'/ts/' + relatedTrailSystem.tsId"
        ><i class="fas fa-sign"></i> {{ relatedTrailSystem.name }}</b-list-group-item>
      </b-list-group>
    </b-card>
  </div><!-- /#trail -->
</template>

<script>
const pfb = require('../../publicFirebaseConfig.js')

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Trail System Details',
          fr: 'Détails du réseau de sentiers'
        },
        2: {
          en: 'Trail System Settings',
          fr: 'Paramètres du réseau de sentiers'
        },
        3: {
          en: 'Trustees',
          fr: 'Administrateurs'
        },
        4: {
          en: 'Calendars Settings',
          fr: 'Paramètres des calendriers'
        },
        5: {
          en: 'Subscribe to Trail Updates',
          fr: 'S’abonner aux mises à jour des sentiers'
        },
        6: {
          en: 'Turn off subscription',
          fr: 'Désactiver l\'abonnement'
        },
        7: {
          en: 'trails spanning',
          fr: 'sentiers s’étendant sur'
        },
        8: {
          en: 'Website',
          fr: 'Site Web'
        }
      }
    }
  },
  computed: {
    trailSystem: {
      get () {
        return this.$store.state.trailSystem
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trailSystem', value: value })
      }
    }
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    launchCalendars () {
      const self = this
      this.$store.commit('hideDrawer', 'trailSystemDetails')
      setTimeout(function () {
        self.$store.commit('showDrawer', 'calendars')
      }, 300)
    },
    subscribe () {
      if (!this.$store.state.currentPublicUser) {
        this.$router.push('/public-login/login/' + this.$store.state.trailSystem.id)
      } else {
        pfb.usersCollection.doc(this.$store.state.currentPublicUser.uid).get().then(publicUserDoc => {
          const subscriptions = publicUserDoc.data().subscriptions
          subscriptions.push(this.$store.state.trailSystem.id)
          return pfb.usersCollection.doc(this.$store.state.currentPublicUser.uid).update({
            subscriptions: subscriptions
          })
        }).catch(err => {
          console.log(err.message)
        })
      }
    },
    unsubscribe () {
      pfb.usersCollection.doc(this.$store.state.currentPublicUser.uid).get().then(publicUserDoc => {
        const subscriptions = publicUserDoc.data().subscriptions
        const index = subscriptions.indexOf(this.$store.state.trailSystem.id)
        if (index > -1) {
          subscriptions.splice(index, 1)
        }
        return pfb.usersCollection.doc(this.$store.state.currentPublicUser.uid).update({
          subscriptions: subscriptions
        })
      }).catch(err => {
        console.log(err.message)
      })
    },
    showTrustees () {
      this.$store.commit('hideDrawer', 'trailSystemDetails')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'trustees')
      }, 300)
    },
    showCalendarsSettings () {
      this.$store.commit('hideDrawer', 'trailSystemDetails')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'calendarsSettings')
      }, 300)
    },
    editTrailSystem () {
      this.$store.commit('setStateProperty', { property: 'editMode', value: true })
      this.$store.commit('hideDrawer', 'trailSystemDetails')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'createTrailSystem')
      }, 300)
    },
    closeTrailSystemDetails () {
      this.$store.commit('hideDrawer', 'trailSystemDetails')
    },
    formatLength (length) {
      const lang = this.$store.state.trailSystem.language || 'en'
      const units = {
        miles: {
          en: 'miles',
          fr: 'milles'
        },
        kilometers: {
          en: 'kilometers',
          fr: 'kilomètres'
        }
      }
      if (!length) {
        return '-'
      } else {
        if (this.$store.state.trailSystem.lengthMeasurement === 'miles') {
          let miles = length * 0.621371
          miles = miles.toFixed(0)
          return miles + ' ' + units.miles[lang]
        } else {
          return length.toFixed(0) + ' ' + units.kilometers[lang]
        }
      }
    }
  }
}
</script>

<style scoped>
#tsIcon {
  height: 50px;
  border-radius: 50px;
}
</style>

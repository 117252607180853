import firebase from 'firebase'
import 'firebase/firestore'

// firebase init goes here
const config = {
  apiKey: 'AIzaSyD-xbs3FCBxG5WNtggzEYX0jG0UIPZjq24',
  authDomain: 'trail-hub.firebaseapp.com',
  databaseURL: 'https://trail-hub.firebaseio.com',
  projectId: 'trail-hub',
  storageBucket: 'trail-hub.appspot.com',
  messagingSenderId: '801587461961',
  appId: '1:801587461961:web:713c5888cfd279dd79e498',
  measurementId: 'G-M85WGMJVQN'
}
firebase.initializeApp(config)

// firebase utils
const db = firebase.firestore()
const storage = firebase.storage()
const auth = firebase.auth()
const currentUser = auth.currentUser

// date issue fix according to firebase
const settings = {
}
db.settings(settings)

// firebase collections
const auditRequestsCollection = db.collection('auditRequests')
const compileRequestsCollection = db.collection('compileRequests')
const customersCollection = db.collection('customers')
const gatherElevationsRequestsCollection = db.collection('gatherElevationsRequests')
const migrationRequestsCollection = db.collection('migrationRequests')
const notificationsCollection = db.collection('notifications')
const pointsCollection = db.collection('points')
const plansCollection = db.collection('plans')
const trailSystemsCollection = db.collection('trailSystems')
const trailsCollection = db.collection('trails')
const trashedTrailSystemsCollection = db.collection('trashedTrailSystems')
const trashedTrailsCollection = db.collection('trashedTrails')
const trashedPointsCollection = db.collection('trashedPoints')
const usersCollection = db.collection('users')
const updatesCollection = db.collection('updates')

export {
  db,
  storage,
  auth,
  currentUser,
  auditRequestsCollection,
  compileRequestsCollection,
  customersCollection,
  gatherElevationsRequestsCollection,
  migrationRequestsCollection,
  notificationsCollection,
  pointsCollection,
  plansCollection,
  trailSystemsCollection,
  trailsCollection,
  trashedTrailSystemsCollection,
  trashedTrailsCollection,
  trashedPointsCollection,
  usersCollection,
  updatesCollection
}

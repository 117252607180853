<template>
  <div id="mapMenu">
    <!-- TrailSystems Button -->
    <!-- <b-btn
      id="trailSystemDetailsToggle"
      class="map-toggle-long"
      @click="toggleTrailSystemDetails()"
    ><span v-if="!$store.state.trailSystem.name"><i class="fas fa-sync fa-spin"></i></span><img v-if="$store.state.mapLoader === false" :src="$store.state.trailSystem.iconUrl" id="tsIcon"/>{{ $store.state.trailSystem.name }}</b-btn> -->
    <!-- Trails -->
    <b-btn
      id="trailsToggle"
      class="map-toggle-long"
      @click="$store.commit('showDrawer', 'trails')"
      v-if="$store.state.embedMode"
    >
       <i class="fas fa-map-signs"></i> {{ language(1) }} <i class="fas fa-caret-right"></i>
    </b-btn>
    <b-btn
      id="trailsToggleMobile"
      class="map-toggle-long"
      @click="$store.commit('showDrawer', 'trails')"
      :class="$route.params.embedMode"
    >
      <i class="fas fa-map-signs"></i> {{ language(1) }} <i class="fas fa-caret-up"></i>
    </b-btn>
    <!-- Points -->
    <!-- <b-btn
      id="pointsToggle"
      class="map-toggle"
      @click="showPoints()"
      v-b-tooltip.hover.right="'View Points'"
      size="sm"
      v-if="$store.state.currentRole === 'Administrator' && !$store.state.embedMode || $store.state.currentRole === 'Trustee' && !$store.state.embedMode"
    >
      <i class="fas fa-map-marker-alt"></i>
    </b-btn> -->
    <!-- Updates -->
    <!-- <b-btn
      id="updatesToggle"
      class="map-toggle"
      @click="toggleUpdates()"
      v-b-tooltip.hover.right="'Manage Updates'"
      size="sm"
      v-if="$store.state.currentRole === 'Administrator' && !$store.state.embedMode || $store.state.currentRole === 'Trustee' && !$store.state.embedMode"
    >
      <i class="fas fa-comment-alt-plus"></i>
    </b-btn> -->
    <!-- User Settings -->
    <!-- <b-btn
      id="settingsToggle"
      class="map-toggle"
      @click="$store.commit('showDrawer', 'userSettings')"
      v-b-tooltip.hover.right="'User Settings'"
      size="sm"
      v-if="!$store.state.embedMode"
    >
      <i class="fas fa-user"></i>
    </b-btn> -->
    <!-- Reset Zoom Button -->
    <b-btn
      v-if="!$store.state.embedMode"
      id="resetZoom"
      class="map-toggle"
      @click="resetZoom()"
      v-b-tooltip.hover.right="language(2)"
      size="sm"
    ><i class="fas fa-sync-alt"></i></b-btn>
    <!-- Toggle Satellite -->
    <b-btn
      v-if="!$store.state.embedMode"
      id="satelliteToggle"
      class="map-toggle"
      @click="toggleSatellite()"
      v-b-tooltip.hover.right="language(4)"
      size="sm"
    >
      <i v-if="!satelliteVisible" class="fas fa-satellite"></i><i v-if="satelliteVisible" class="fas fa-road"></i>
    </b-btn>
    <!-- Toggle Terrain -->
    <!-- <b-btn
      v-if="!$store.state.embedMode"
      id="terrainToggle"
      class="map-toggle"
      @click="toggleTerrain()"
      v-b-tooltip.hover.right="language(3)"
      size="sm"
    >
      <span v-if="!terrainVisible">3D</span><span v-if="terrainVisible">2D</span>
    </b-btn> -->
    <!-- Share Button -->
    <!-- <b-btn
      v-if="!$store.state.embedMode"
      id="shareToggle"
      class="map-toggle"
      @click="toggleShare()"
      v-b-tooltip.hover.right="'Share'"
      size="sm"
    >
      <i class="fas fa-share"></i>
    </b-btn> -->
    <!-- Fullscreen Button -->
    <b-btn
      v-if="$store.state.embedMode"
      id="fullscreenToggle"
      class="map-toggle"
      :class="$route.params.embedMode"
      :href="'https://trailhub.org/ts/' + $store.state.trailSystem.id"
      target="_blank"
      v-b-tooltip.hover.right="language(5)"
      size="sm"
    >
      <i class="fas fa-external-link-alt"></i>
    </b-btn>
  </div><!-- /#mapMenu -->
</template>

<script>
export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Trails',
          fr: 'Sentiers'
        },
        2: {
          en: 'Refresh Zoom',
          fr: 'Actualiser le zoom'
        },
        3: {
          en: 'Toggle Terrain Mode',
          fr: 'Basculer le mode de terrain'
        },
        4: {
          en: 'Toggle Satellite/Road',
          fr: 'Basculer Satellite/Route'
        },
        5: {
          en: 'Launch Trail System',
          fr: 'Plein écran'
        }
      },
      satelliteVisible: false,
      terrainVisible: false
    }
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    showPoints () {
      this.$store.commit('hideAllDrawers')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'points')
      }, 300)
    },
    resetZoom () {
      if (this.terrainVisible) {
        this.removeTerrain()
      }
      this.$store.dispatch('zoomToOrg')
    },
    toggleUpdates () {
      this.$store.commit('hideAllDrawers')
      setTimeout(() => {
        this.$store.dispatch('fetchUpdates').then(() => {
          this.$store.commit('showDrawer', 'updates')
        })
      }, 300)
    },
    toggleShare () {
      if (this.$store.state.drawer.share.position === 'out') {
        this.$store.commit('hideAllDrawers')
        setTimeout(() => {
          this.$store.commit('showDrawer', 'share')
        }, 300)
      } else {
        this.$store.commit('hideDrawer', 'share')
      }
    },
    toggleTrailSystemDetails () {
      this.$store.commit('hideAllDrawers')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'trailSystemDetails')
      }, 300)
    },
    loadTerrain () {
      this.terrainVisible = true
      const map = this.$store.state.mapObject
      map.setTerrain(null)
      if (map.getLayer('sky')) {
        map.removeLayer('sky')
      }
      if (map.getSource('mapbox-dem')) {
        map.removeSource('mapbox-dem')
      }
      map.addSource('mapbox-dem', {
        type: 'raster-dem',
        url: 'mapbox://mapbox.mapbox-terrain-dem-v1'
      })
      map.addLayer({
        'id': 'sky', // eslint-disable-line
        'type': 'sky', // eslint-disable-line
        'paint': { // eslint-disable-line
          'sky-type': 'atmosphere', // eslint-disable-line
          'sky-atmosphere-sun': [0.0, 0.0], // eslint-disable-line
          'sky-atmosphere-sun-intensity': 15 // eslint-disable-line
        }
      })
      map.setTerrain({ source: 'mapbox-dem' })
      map.flyTo({
        pitch: 65
      })
    },
    removeTerrain () {
      this.terrainVisible = false
      const map = this.$store.state.mapObject
      map.setTerrain(null)
      if (map.getLayer('sky')) {
        map.removeLayer('sky')
      }
      if (map.getSource('mapbox-dem')) {
        map.removeSource('mapbox-dem')
      }
      map.flyTo({
        pitch: 0,
        bearing: 0
      })
    },
    toggleTerrain () {
      if (this.terrainVisible) {
        this.removeTerrain()
      } else {
        if (!this.satelliteVisible) {
          this.toggleSatellite()
        }
        this.loadTerrain()
      }
    },
    toggleSatellite () {
      const map = this.$store.state.mapObject
      const self = this
      if (self.satelliteVisible) {
        map.setStyle('https://orbit.ist/trailhub.json')
        map.on('style.load', function () {
          self.satelliteVisible = false
          self.$store.dispatch('mapTrails').then(() => {
            if (self.terrainVisible) {
              self.loadTerrain()
            }
          })
        })
      } else {
        map.setStyle('https://api.maptiler.com/maps/da520b6d-739a-4646-888f-1d3f2b664f78/style.json?key=IuJ0D0R9S0Cjl4krhvr1')
        map.on('style.load', function () {
          self.satelliteVisible = true
          self.$store.dispatch('mapTrails').then(() => {
            if (self.terrainVisible) {
              self.loadTerrain()
            }
          })
        })
      }
    },
    editTrailSystem () {
      this.$store.commit('setStateProperty', { property: 'editMode', value: true })
      this.$store.commit('showDrawer', 'createTrailSystem')
    }
  }
}
</script>

<style scoped>
.embed_noui {
  display: none !important;
}

.map-toggle, .map-toggle-long {
  position: fixed;
  z-index: 3;
}

.map-toggle-long {
  max-width: 80vw;
}

#settingsToggle {
  bottom: 34px;
  left: 10px;
}

#trailSystemDetailsToggle {
  top: 10px;
  left: 10px;
}

#tsIcon {
  height: 36px;
  border-radius: 50px;
  margin-left: -12px;
  margin-top: -15px;
  margin-right: 5px;
  margin-bottom: -11px;
}

#trailsToggle {
  top: 10px;
  left: 10px;
}

#pointsToggle {
  top: 116px;
  left: 10px;
}

#updatesToggle {
  top: 172px;
  left: 10px;
}

#trailsToggleMobile {
  display: none;
}

#satelliteToggle {
  bottom: 34px;
  right: 10px;
}

#terrainToggle {
  bottom: 86px;
  right: 10px;
}

#resetZoom {
  bottom: 90px;
  right: 10px;
}

#shareToggle {
  bottom: 34px;
  right: 10px;
}

#fullscreenToggle {
  padding-top: 7px;
  font-size: 18px;
  bottom: 34px;
  right: 10px;
}

@media (max-width: 800px) {
  #trailsToggle {
    display: none;
  }
  #trailsToggleMobile {
    display: block;
    bottom: 34px;
    left: calc(50% - 50px);
  }

  #pointsToggle {
    top: 60px;
    left: 10px;
  }

  #updatesToggle {
    top: 116px;
    left: 10px;
  }
}
</style>

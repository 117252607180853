import firebase from 'firebase'
import 'firebase/firestore'

// firebase init goes here
const config = {
  apiKey: 'AIzaSyCHwUZqHiMnLWNa4a4OgqFSXf24OMTYYzE',
  authDomain: 'trail-hub-public.firebaseapp.com',
  projectId: 'trail-hub-public',
  storageBucket: 'trail-hub-public.appspot.com',
  messagingSenderId: '126231489428',
  appId: '1:126231489428:web:d5e521c1aee9d7a0cbfd81',
  measurementId: 'G-Z0YD48JKW4'
}
const publicApp = firebase.initializeApp(config, 'secondary')

// firebase utils
const db = publicApp.firestore()
const storage = publicApp.storage()
const auth = publicApp.auth()
const currentUser = auth.currentUser
const emailAuthProvider = firebase.auth.EmailAuthProvider
// firebase collections
const usersCollection = db.collection('users')

export {
  db,
  storage,
  auth,
  publicApp,
  currentUser,
  usersCollection,
  emailAuthProvider
}

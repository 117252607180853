<template>
  <div id="trailSystems">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.trailSystems.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="closeTrailSystems()"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title">{{ language(1) }}</h4>
        <b-btn
          @click="createTrailSystem()"
          class="mb-2"
        >
          <i class="far fa-plus"></i> {{ language(2) }}
        </b-btn>
      </template>

      <div class="list-group">
        <div
          v-for="(trailSystem, i) in $store.state.trailSystems"
          v-bind:key="i"
          class="list-group-item list-group-item-action"
        >
          <div class="d-flex w-100 justify-content-between">
            <small
              @click="launchTrailSystem(trailSystem.id)"
              class="clickable"
            >
              <strong>{{ language(trailSystem.role) }}</strong>
            </small>
            <div class="card-right-group">
              <small
                v-if="trailSystem.id == $store.state.userProfile.defaultTrailSystem"
                v-b-tooltip.hover
                :title="language(4)"
                class="clickable"
              >
                <i class="fas fa-heart"></i>
              </small>
              <small
                v-else
                v-b-tooltip.hover
                :title="language(3)"
                @click="setDefaultTrailSystem(trailSystem.id)"
                class="clickable"
              >
                <i class="far fa-heart"></i>
              </small>
            </div><!-- /.card-right-group -->
          </div>
          <p @click="launchTrailSystem(trailSystem.id)" class="lead mb-1 clickable">{{ trailSystem.name }}</p>
        </div>
      </div><!-- /.list-group -->
    </b-card>
  </div><!-- /#blockMaps -->
</template>

<script>
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'My Trail Systems',
          fr: 'Mes réseaux de sentiers'
        },
        2: {
          en: 'New Trail System',
          fr: 'Nouveau réseau de sentiers'
        },
        3: {
          en: 'Set Default Trail System',
          fr: 'Définir le système de suivi par défaut'
        },
        4: {
          en: 'Default Trail System',
          fr: 'Système de sentiers par défaut'
        },
        Owner: {
          en: 'Owner',
          fr: 'Propriétaire'
        },
        Trustee: {
          en: 'Trustee',
          fr: 'Trustee'
        },
        Administrator: {
          en: 'Administrator',
          fr: 'Administrateur'
        }
      }
    }
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    closeTrailSystems () {
      this.$store.commit('hideDrawer', 'trailSystems')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'userSettings')
      }, 300)
    },
    setDefaultTrailSystem (id) {
      fb.usersCollection.doc(this.$store.state.currentUser.uid).update({
        defaultTrailSystem: id
      }).then(() => {
        this.$store.dispatch('fetchUserProfile')
      })
    },
    launchTrailSystem (id) {
      window.location.href = '/ts/' + id
    },
    createTrailSystem () {
      this.$store.commit('hideDrawer', 'trailSystems')
      setTimeout(() => {
        this.$store.commit('setStateProperty', { property: 'editMode', value: false })
        this.$store.commit('showDrawer', 'createTrailSystem')
      }, 300)
    }
  }
}
</script>

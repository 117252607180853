<template>
  <div id="calendarsSettings">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.calendarsSettings.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="$store.commit('hideDrawer', 'calendarsSettings')"
        >
          <i class="far fa-times"></i>
        </a>
        <h4>{{ language(1) }}</h4>
      </template>
      <p>{{ language(2) }}</p>
      <form @submit.prevent class="mt-4">
        <h2>{{ language(3) }}</h2>
        <p>{{ language(4) }}</p>
        <input
          v-model="calendarApiKey"
          type="text"
          class="form-control"
          :placeholder="language(4)"
        >
        <h2 class="mt-4">{{ language(5) }}</h2>
        <div class="form-group">
          <div
            class="row mb-1"
            v-for="(calendar, index) in calendars"
            v-bind:key="index"
          >
            <div class="col-4">
              <input
                v-model="calendar.title"
                type="text"
                class="form-control"
                :placeholder="language(7)"
              >
              <input
                v-model="calendar.id"
                type="text"
                class="form-control mt-2"
                :placeholder="language(8)"
              >
            </div><!-- /.col -->
            <div class="col-4">
              <chrome-picker
                v-model="calendar.color"
                @input="checkColor()"
              />
            </div><!-- /.col -->
            <div class="col-4 text-right">
              <b-btn
                class="btn-danger"
                @click="removeCalendar(index)"
              >
                <i class="fas fa-minus"></i>
              </b-btn>
            </div><!-- /.col-1 -->
          </div><!-- /.row -->
        </div><!-- /.form-group -->
        <b-btn
          @click="addCalendar()"
        >{{ language(6) }}</b-btn>
      </form>
      <button
        class="btn btn-primary mb-4 mt-4"
        @click="saveSettings()"
      >
        {{ language(9) }}
      </button>
    </b-card>
  </div><!-- /#googleCalendar -->
</template>

<script>
import { Chrome } from 'vue-color'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Calendar Settings',
          fr: 'Paramètres des calendriers'
        },
        2: {
          en: 'This feature uses Google Calendar\'s API. Please contact support if you need help integrating another events management system.',
          fr: 'Cette fonction utilise l’API de Google Agenda. Veuillez contacter le service d’assistance si vous avez besoin d’aide pour intégrer un autre système de gestion d’événements.'
        },
        3: {
          en: 'API Key',
          fr: 'Clé API'
        },
        4: {
          en: 'IMPORTANT: Make sure you lock this key down to https://trailhub.org to prevent abuse.',
          fr: 'IMPORTANT : Veillez à verrouiller cette clé sur https://trailhub.org pour éviter tout abus.'
        },
        5: {
          en: 'Calendars',
          fr: 'Calendriers'
        },
        6: {
          en: 'Add Calendar',
          fr: 'Ajouter un calendrier'
        },
        7: {
          en: 'Calendar Title',
          fr: 'Titre du calendrier'
        },
        8: {
          en: 'Calendar ID',
          fr: 'ID du calendrier'
        },
        9: {
          en: 'Save Settings',
          fr: 'Enregistrer les paramètres'
        }
      },
      calendarApiKey: '',
      calendars: []
    }
  },
  created () {
    if (this.$store.state.trailSystem.calendars) {
      this.calendars = this.$store.state.trailSystem.calendars
      this.calendarApiKey = this.$store.state.trailSystem.calendarApiKey
    }
  },
  components: {
    'chrome-picker': Chrome
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    saveSettings () {
      fb.trailSystemsCollection.doc(this.$store.state.trailSystem.id).update({
        calendars: this.calendars,
        calendarApiKey: this.calendarApiKey,
        updatedOn: new Date(),
        updatedBy: this.$store.state.currentUser.uid
      }).then(e => {
        this.$store.commit('hideDrawer', 'calendarsSettings')
        setTimeout(() => {
          this.$store.commit('showDrawer', 'trailSystemDetails')
        }, 300)
      }).catch(err => {
        console.log(err.message)
      })
    },
    checkColor () {
      console.log(this.calendars)
    },
    removeCalendar (index) {
      this.calendars.splice(index, 1)
    },
    addCalendar () {
      this.calendars.push({
        title: '',
        color: ''
      })
    }
  }
}
</script>

<template>
  <div id="signageTrails">
    <div class="row" v-if="showTrails">
      <div
        v-for="(column, i) in columns"
        v-bind:key="i"
        class="col-4 activity-column"
      >
        <div
          class="activity-container"
          v-if="!column.hide"
        >
          <p class="lead text-center signage-header mb-1"><i :class="column.activity.iconClass"></i> {{ column.activity[$store.state.trailSystem.language] || column.activity.label }}</p>
          <p class="text-center mb-2">{{ column.trails.length }} {{ language(1) }}</p>
          <div
            v-for="(trail, i) in column.displayedTrails"
            v-bind:key="i"
            class="trail-container"
          >
            <div
              class="list-group-item list-group-item-action flex-column align-items-start clickable p-0 trail-item handle"
            >
              <!-- STATUS LEFT BAR -->
              <!-- <div class="status-bar h-100" :class="trail.properties.status">
                <i v-if="trail.properties.status == 'Open'" class="fas fa-check-circle"></i>
                <i v-if="trail.properties.status == 'Closed'" class="fas fa-times-circle"></i>
                <i v-if="trail.properties.status == 'Caution'" class="fas fa-exclamation-circle"></i>
              </div> -->
              <div class="status-bar h-100" :class="trail.properties.activityStatus[column.activity.value]">
                <i v-if="trail.properties.activityStatus[column.activity.value] == 'Open'" class="fas fa-check-circle"></i>
                <i v-if="trail.properties.activityStatus[column.activity.value] == 'Closed'" class="fas fa-times-circle"></i>
                <i v-if="trail.properties.activityStatus[column.activity.value] == 'Caution'" class="fas fa-exclamation-circle"></i>
              </div>

              <div class="trail-card-body">
                <!-- TITLE LINE -->
                <div class="d-flex w-100 justify-content-between">
                  <p class="mb-1">
                      <span v-if="trail.properties.difficulty != 'doubleDiamond'" :class="trail.properties.difficulty" class="difficulty-marker mr-1"></span>
                      <span v-if="trail.properties.difficulty == 'doubleDiamond'" class="difficulty-marker diamond mr-1"></span><span v-if="trail.properties.difficulty == 'doubleDiamond'" class="difficulty-marker diamond mr-1"></span>
                      <strong>{{ trail.properties.name }}</strong>
                  </p>
                  <small v-if="trail.properties.distance" class="pt-1"><strong>{{ formatLength(trail.properties.distance) }}</strong></small>
                </div><!-- /.d-flex -->
                <!-- <div v-if="trail.properties.updates">
                  <div v-if="trail.properties.updates.notice">
                    <p
                      v-for="(notice, i) in trail.properties.updates.notice"
                      v-bind:key="i"
                      class="mb-0"
                    >
                      <i v-if="notice.severity === 'info'" class="fas fa-info-circle"></i><i v-if="notice.severity === 'warning'" class="fas fa-exclamation-circle text-warning"></i><i v-if="notice.severity === 'danger'" class="fas fa-exclamation-circle text-danger"></i> {{ notice.description }}
                    </p>
                  </div>
                  <p class="mb-0">
                    <span
                      v-if="trail.properties.updates.snowReport"
                      class="mr-2"
                    >
                      <i class="fas fa-snowflake"></i> {{ trail.properties.updates.snowReport[0].baseDepth }} <span v-if="$store.state.trailSystem.depthMeasurement == 'inches'">in</span><span v-if="$store.state.trailSystem.depthMeasurement == 'centimeters'">cm</span>
                    </span>
                    <span
                      v-if="trail.properties.updates.surfaceConditions"
                    >
                      <span
                        v-for="(condition, index) in trail.properties.updates.surfaceConditions[0].conditions"
                        v-bind:key="index"
                        class="mr-2"
                      ><i :class="condition.iconClass"></i></span>
                    </span>
                  </p>
                </div> -->
                <!-- If Updates -->
              </div><!-- /.trail-card-body -->
            </div><!-- /.list-group -->
          </div><!-- render logic -->
        </div><!-- /.activity-container -->
      </div>
    </div><!-- /.row -->
  </div><!-- /#signageTrails -->
</template>

<script>
export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'trails',
          fr: 'sentiers'
        }
      },
      activitiesIndex: -3,
      columns: [
        {
          activity: {},
          cycling: false,
          trailIndex: 0,
          displayedTrails: [],
          trails: [],
          hide: false
        },
        {
          activity: {},
          cycling: false,
          trailIndex: 0,
          displayedTrails: [],
          trails: [],
          hide: false
        },
        {
          activity: {},
          cycling: false,
          trailIndex: 0,
          displayedTrails: [],
          trails: [],
          hide: false
        }
      ],
      cycleLength: 10000,
      showTrails: false,
      trailsByActivity: {},
      trailsLimit: 17
    }
  },
  mounted () {
    this.$store.state.trailSystem.activities.forEach((activity, i) => {
      if (!this.$store.state.hiddenActivities[activity.value]) {
        this.trailsByActivity[activity.value] = []
      }
    })
    console.log(this.$store.state.trails)
    this.$store.state.trails.forEach((trail, i) => {
      if (!trail.properties.hidden && trail.properties.showInLists) {
        trail.properties.activities.forEach((trailActivity, i) => {
          // trail.properties.status = trailActivity.status
          if (!trail.properties.activityStatus) {
            trail.properties.activityStatus = {}
          }
          trail.properties.activityStatus[trailActivity.value] = trailActivity.status
          if (this.trailsByActivity[trailActivity.value]) {
            this.trailsByActivity[trailActivity.value].push(trail)
          }
        })
        console.log(trail)
      } else {
        console.log('Trail Hidden or not shown in lists')
      }
    })
    this.showTrails = true
    const self = this
    setTimeout(function () {
      self.cycleActivities()
    }, 10)
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    cycleTrails () {
      let cycleActivities = true
      this.columns.forEach(column => {
        if (column.cycling) {
          column.displayedTrails = []
          for (let i = column.trailIndex; i < (column.trailIndex + this.trailsLimit); i++) {
            if (column.trails[i]) {
              column.displayedTrails.push(column.trails[i])
            }
          }
          column.trailIndex = column.trailIndex + this.trailsLimit
          if (!column.trails[column.trailIndex]) {
            column.cycling = false
          } else {
            cycleActivities = false
          }
        }
      })
      const self = this
      if (cycleActivities) {
        setTimeout(function () {
          self.cycleActivities()
        }, self.cycleLength)
      } else {
        setTimeout(function () {
          self.cycleTrails()
        }, self.cycleLength)
      }
    },
    cycleActivities () {
      this.activitiesIndex = this.activitiesIndex + 3
      if (this.activitiesIndex >= this.$store.state.trailSystem.activities.length) {
        this.activitiesIndex = 0
      }
      for (let i = this.activitiesIndex; i < (this.activitiesIndex + 3); i++) {
        const columnIndex = i - this.activitiesIndex
        if (this.$store.state.trailSystem.activities[i]) {
          this.columns[columnIndex].hide = false
          this.columns[columnIndex].activity = this.$store.state.trailSystem.activities[i]
          this.columns[columnIndex].trailIndex = 0
          this.columns[columnIndex].trails = this.trailsByActivity[this.$store.state.trailSystem.activities[i].value]
          if (this.columns[columnIndex].trails.length > this.trailsLimit) {
            this.columns[columnIndex].cycling = true
          } else {
            this.columns[columnIndex].cycling = false
            this.columns[columnIndex].displayedTrails = this.columns[columnIndex].trails
          }
        } else {
          // For situations where there is less than 3 activities left
          this.columns[columnIndex].hide = true
        }
      }
      this.cycleTrails()
    },
    formatLength (length) {
      if (length) {
        if (this.$store.state.trailSystem.lengthMeasurement === 'miles') {
          let miles = length * 0.621371
          miles = miles.toFixed(2)
          return miles + ' mi'
        } else {
          return length.toFixed(2) + ' km'
        }
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
#signageTrails {
  position: absolute;
  /* background-color: #F5F5F5; */
  top: 20px;
  left: 20px;
  height: calc(100vh - 120px);
  width: calc(100% - 40px);
  overflow: hidden;
  padding: 0 20px;
  border-radius: 20px;
}

.activity-container {
  background-color: #F5F5F5;
  border-radius: 20px;
  height: calc(100vh - 140px);
  overflow: hidden;
  padding: 10px;
  font-weight: bold;
}
</style>

<template>
  <div id="trailMap">
    <div id="map" :class="$store.state.trailSystemMode + ' ' + $route.params.embedMode"></div>
    <div
      id="map-spinner"
      v-if="$store.state.mapLoader"
    >
      <RingLoader
        :color="'#333'"
        :size="'100px'"
      ></RingLoader>
      <img src="/static/th-globe.jpg" class="th-globe" />
    </div><!-- #/map-spinner -->
  </div><!-- ./ trailMap -->
</template>

<script>
import RingLoader from 'vue-spinner/src/MoonLoader'

export default {
  components: {
    RingLoader
  },
  mounted () {
    if (this.$route.params.embedMode) {
      this.$store.commit('setStateProperty', { property: 'embedMode', value: this.$route.params.embedMode })
    }
    this.$store.dispatch('initTrailSystemMap')
  }
}
</script>

<style scoped>
.th-globe {
  width: 80px;
  border-radius: 100px;
  position: absolute;
  left: calc(50% - 40px);
  top: calc(50% - 45px);
}

#map {
  position: fixed;
  top: 56px;
  width: 100%;
  height: calc(100% - 56px);
}

#map.embed, #map.embed_noui {
  top: 0px;
  height: 100%;
}

#map.native {
  top: 40px;
  height: calc(100% - 40px);
}

#map-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  /* background-color: #4cb0dc; */
  background-color: white;
}

.v-spinner {
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 55px);
}

@media(max-width: 800px) {
  #map {
    top: 0px;
    height: 100%;
  }
}
</style>

<template>
  <div id="adminTable">
    <b-table
      hover
      :items="adminData.filteredResults"
      :fields="fields"
      v-if="adminData.displayList && $store.state.userProfile.superAdmin"
      :tbody-tr-class="rowClass"
    >
      <template #cell(name)="data">
        <span class="clickable text-secondary" style="text-decoration:underline;" @click="click(data.item)">{{ data.item.name }}</span>
        <span v-if="data.item.blocked"><i class="fas fa-ban ml-1 text-danger" v-b-tooltip.hover.bottom="'Blocked'"></i></span>
        <span v-if="data.item.override"><i class="fas fa-lock-open ml-1 text-success" v-b-tooltip.hover.bottom="'Overridden'"></i></span>
      </template>
      <template #cell(subscription)="data">
        <span v-if="data.item.subscription === 'circle'">Circle (NFP)</span>
        <span v-if="data.item.subscription === 'triangle'">Triangle (NFP)</span>
        <span v-if="data.item.subscription === 'star'">Star (NFP)</span>
        <span v-if="data.item.subscription === 'square'">Square</span>
        <span v-if="data.item.subscription === 'diamond'">Diamond</span>
        <span v-if="data.item.subscription === 'doubleDiamond'">Double Diamond</span>
      </template>
      <template #cell(ownerProfile)="data">
        {{ data.item.ownerProfile.email }}
      </template>
      <template #cell(activeSub.created._seconds)="data">
        <span v-if="data.item.activeSub">
          {{ data.item.activeSub.created._seconds | formatDate }}
        </span>
      </template>
      <template #cell(activeSub.current_period_start._seconds)="data">
        <span v-if="data.item.activeSub">
          {{ data.item.activeSub.current_period_start._seconds | formatDate }}
        </span>
      </template>
      <template #cell(activeSub.current_period_end._seconds)="data">
        <span v-if="data.item.activeSub">
          {{ data.item.activeSub.current_period_end._seconds | formatDate }}
        </span>
      </template>
      <template #cell(periodLength)="data">
        <span v-if="data.item.activeSub">
          {{ data.item.periodLength }}
        </span>
      </template>
    </b-table>
  </div><!-- /#adminTable -->
</template>

<script>
import axios from 'axios'
import firebase from 'firebase'
import moment from 'moment'

export default {
  data () {
    return {
      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'subscription',
          sortable: true,
          label: 'Subscription'
        },
        {
          key: 'ownerProfile.email',
          sortable: true,
          label: 'Owner Email'
        },
        {
          key: 'activeSub.created._seconds',
          sortable: true,
          label: 'Sub. Started'
        },
        {
          key: 'activeSub.current_period_start._seconds',
          sortable: true,
          label: 'Period Start'
        },
        {
          key: 'activeSub.current_period_end._seconds',
          sortable: true,
          label: 'Period End'
        },
        {
          key: 'periodLength',
          sortable: true,
          label: 'Period Length'
        }
      ],
      plans: {
        circle: {
          systems: 2,
          trustees: 3
        },
        triangle: {
          systems: 4,
          trustees: 5
        },
        star: {
          systems: 8,
          trustees: 10
        },
        square: {
          systems: 2,
          trustees: 3
        },
        diamond: {
          systems: 4,
          trustees: 5
        },
        doubleDiamond: {
          systems: 8,
          trustees: 10
        }
      }
    }
  },
  computed: {
    adminData: {
      get () {
        return this.$store.state.adminData
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'adminData', value: value })
      }
    }
  },
  created () {
    const storageRef = firebase.storage().ref('admin/customerAudit.json')
    storageRef.getDownloadURL().then(url => {
      axios.get(url).then(response => {
        this.adminData.trailSystems = response.data
        this.runAudit()
      })
    })
  },
  methods: {
    click (item) {
      this.adminData.trailSystem = item
      this.adminData.showTrailSystem = true
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.passAudit) return 'table-success'
      if (item.trialPeriod) return 'table-warning'
      if (item.blocked) return 'table-danger'
    },
    runAudit () {
      const nowSeconds = Date.now() / 1000
      this.adminData.trailSystems.forEach((ts, i) => {
        const ownerId = ts.ownerId
        this.adminData.trailSystems[i].passAudit = true
        // Look for subscriptions
        if (ts.ownerSubscriptions.length === 0) {
          this.adminData.trailSystems[i].passAudit = false
          this.adminData.trailSystems[i].failComment = 'No subscription'
        } else {
          let activeSub = false
          ts.ownerSubscriptions.forEach(sub => {
            // Subscription status
            if (sub.status === 'active') {
              activeSub = true
              this.adminData.trailSystems[i].activeSub = sub
            }
          })
          if (!activeSub) {
            this.adminData.trailSystems[i].activeSub = false
            this.adminData.trailSystems[i].passAudit = false
            this.adminData.trailSystems[i].failComment = 'No paid/active subscription'
          }
        }
        // Check Owner Number of Trail Systems
        let ownerCount = 0
        const ownerSystems = []
        this.adminData.trailSystems.forEach(ownerCheckSystem => {
          if (ownerCheckSystem.ownerId === ownerId) {
            ownerCount++
            const systemParse = JSON.parse(JSON.stringify(ownerCheckSystem))
            ownerSystems.push(systemParse)
          }
        })

        this.adminData.trailSystems[i].ownerTSCount = ownerCount
        this.adminData.trailSystems[i].ownerTSs = ownerSystems

        // Establish period length variable
        this.adminData.trailSystems[i].periodLength = false

        // Check Number of Trustees & Systems
        if (this.adminData.trailSystems[i].activeSub) {
          let role = this.adminData.trailSystems[i].activeSub.role
          if (!this.plans[role]) {
            console.log('No role')
            console.log(this.adminData.trailSystems[i])
            role = 'circle'
          }
          if (this.plans[role].systems < this.adminData.trailSystems[i].ownerTSCount || this.plans[role].trustees < this.adminData.trailSystems[i].trusteeCount) {
            this.adminData.trailSystems[i].passAudit = false
            this.adminData.trailSystems[i].failComment = 'Too many trail systems or trustees'
          }

          // Check for Monthly or Annual Payment
          const periodStart = this.adminData.trailSystems[i].activeSub.current_period_start._seconds
          const periodEnd = this.adminData.trailSystems[i].activeSub.current_period_end._seconds
          const periodSeconds = periodEnd - periodStart
          if (periodSeconds < 2851200) {
            this.adminData.trailSystems[i].periodLength = 'Monthly'
          } else {
            this.adminData.trailSystems[i].periodLength = 'Annual'
          }
        }
        // Check for 31 day trial
        const startedAccount = ts.ownerProfile.createdOn._seconds
        if (nowSeconds - startedAccount > 2678400) {
          this.adminData.trailSystems[i].trialPeriod = false
        } else {
          this.adminData.trailSystems[i].trialPeriod = true
        }
        if (ts.override) {
          this.adminData.trailSystems[i].passAudit = true
        }
        this.adminData.filteredResults.push(ts)
      })
      this.adminData.displayList = true
    }
  },
  filters: {
    formatDate (val) {
      if (!val) { return '-' }
      var t = new Date(1970, 0, 1)
      t.setSeconds(val)
      return moment(t).format('MM/DD/YY')
    }
  }
}
</script>

<style scoped>
#adminTable {
  margin-top: 60px;
}
</style>

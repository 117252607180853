<template>
  <div id="share">
    <b-card
      id="shareCard"
      class="blockDrawer large fullMobile"
      :class="$store.state.drawer.share.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="hideShare()"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 id="blockTitle">{{ language(1) }}</h4>
      </template>
      <div class="container mb-5">
        <h2>{{ language(2) }}</h2>
        <b-input-group>
          <b-form-input v-model="shareLink"></b-form-input>
          <b-input-group-append>
            <b-button @click="copy(shareLink)"><i class="fas fa-copy"></i> {{ language(10) }}</b-button>
          </b-input-group-append>
        </b-input-group>
        <h2 class="mt-5" v-if="$store.state.trailSystemMode !== 'native'">{{ language(3) }}</h2>
        <div class="mb-1" v-if="$store.state.trailSystemMode !== 'native'">
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="embedMode !== 'hybrid'"
            @click="embedMode = 'hybrid'"
          >{{ language(4) }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="embedMode === 'hybrid'"
            @click="embedMode = 'hybrid'"
          >{{ language(4) }}</b-button>
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="embedMode !== 'map'"
            @click="embedMode = 'map'"
          >{{ language(5) }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="embedMode === 'map'"
            @click="embedMode = 'map'"
          >{{ language(5) }}</b-button>
          <b-button
            variant="outline-secondary"
            v-if="embedMode !== 'list'"
            @click="embedMode = 'list'"
          >{{ language(6) }}</b-button>
          <b-button
            variant="secondary"
            v-if="embedMode === 'list'"
            @click="embedMode = 'list'"
          >{{ language(6) }}</b-button>
        </div>
        <b-input-group class="mb-3" v-if="embedMode === 'list' && $store.state.trailSystemMode !== 'native'">
          <b-form-textarea rows="7" v-model="listEmbed"></b-form-textarea>
          <b-input-group-append>
            <b-button @click="copy(listEmbed)"><i class="fas fa-copy"></i> {{ language(10) }}</b-button>
          </b-input-group-append>
        </b-input-group>
        <b-input-group class="mb-3" v-if="embedMode === 'map' && $store.state.trailSystemMode !== 'native'">
          <b-form-textarea rows="7" v-model="mapEmbed"></b-form-textarea>
          <b-input-group-append>
            <b-button @click="copy(mapEmbed)"><i class="fas fa-copy"></i> {{ language(10) }}</b-button>
          </b-input-group-append>
        </b-input-group>
        <b-input-group class="mb-3" v-if="embedMode === 'hybrid' && $store.state.trailSystemMode !== 'native'">
          <b-form-textarea rows="7" v-model="hybridEmbed"></b-form-textarea>
          <b-input-group-append>
            <b-button @click="copy(hybridEmbed)"><i class="fas fa-copy"></i> {{ language(10) }}</b-button>
          </b-input-group-append>
        </b-input-group>
        <h2 class="mt-5" v-if="$store.state.trailSystemMode !== 'native'">{{ language(7) }}</h2>
        <VueQrcode v-if="$store.state.trailSystemMode !== 'native'" :value="shareLink" :scale="20" :margin="0" class="w-100 m-2" />
        <div
          v-if="($store.state.trailSystem.subscription === 'doubleDiamond' || $store.state.trailSystem.subscription === 'star') && $store.state.currentRole === 'Administrator' || ($store.state.trailSystem.subscription === 'doubleDiamond' || $store.state.trailSystem.subscription === 'star') && $store.state.currentRole === 'Trustee'"
        >
          <h2 class="mt-5">{{ language(8) }}</h2>
          <b-btn
            :href="'/signage/' + $store.state.trailSystem.id"
            target="_blank"
            class="mt-2"
          ><i class="fas fa-tv"></i> {{ language(9) }}</b-btn>
        </div><!-- /if admin and doubleDiamond -->
      </div><!-- /.container -->
    </b-card>
  </div><!-- /#share -->
</template>

<script>
import VueQrcode from 'vue-qrcode'

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Share',
          fr: 'Partager'
        },
        2: {
          en: 'Direct Link',
          fr: 'Lien direct'
        },
        3: {
          en: 'Embed Code',
          fr: 'Intégrer le code'
        },
        4: {
          en: 'Hybrid',
          fr: 'Hybride'
        },
        5: {
          en: 'Map',
          fr: 'Carte géographique'
        },
        6: {
          en: 'List',
          fr: 'Liste'
        },
        7: {
          en: 'QR Code',
          fr: 'Code QR'
        },
        8: {
          en: 'Signage Mode',
          fr: 'Mode de signalisation'
        },
        9: {
          en: 'Launch Signage',
          fr: 'Signalisation de lancement'
        },
        10: {
          en: 'Copy',
          fr: 'Copie'
        }
      },
      shareLink: '',
      listEmbed: '',
      mapEmbed: '',
      hybridEmbed: '',
      embedMode: 'hybrid'
    }
  },
  created () {
    this.generateLinks()
  },
  components: {
    VueQrcode
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    copy (text) {
      this.$copyText(text).then((e) => {
        this.$store.commit('setAlert', { active: true, message: 'Copied!', level: 'alert-success', timeout: 2000 })
      })
    },
    generateLinks () {
      this.shareLink = 'https://trailhub.org/ts/' + this.$store.state.trailSystem.id
      this.listEmbed = '<iframe width="100%" height="550" src="' + this.shareLink + '/list/embed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      this.mapEmbed = '<iframe width="100%" height="550" src="' + this.shareLink + '/map/embed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      this.hybridEmbed = '<iframe width="100%" height="550" src="' + this.shareLink + '/hybrid/embed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
    },
    hideShare () {
      this.$store.commit('hideDrawer', 'share')
    }
  }
}
</script>

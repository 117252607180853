<template>
  <div id="publicUserSettings">
    <b-nav pills class="mb-3">
      <b-nav-item @click="detailsMode = 'subscriptions'" :active="(detailsMode === 'subscriptions') ? true : false">{{ language(1) }}</b-nav-item>
      <b-nav-item @click="detailsMode = 'details'" :active="(detailsMode === 'details') ? true : false">{{ language(2) }}</b-nav-item>
      <b-nav-item @click="detailsMode = 'delete'" :active="(detailsMode === 'delete') ? true : false">{{ language(3) }}</b-nav-item>
      <b-nav-item @click="logout()"><i class="far fa-sign-out-alt"></i> {{ language(4) }}</b-nav-item>
    </b-nav>
    <div v-if="detailsMode === 'details' && currentPublicUser">
      <h5>{{ language(9) }}</h5>
      <b-form>
        <label for="inline-form-input-name-first">{{ language(10) }}</label>
        <b-form-input
          id="inline-form-input-name-first"
          :placeholder="publicUserProfile.nameFirst"
          v-model="newNameFirst"
          class="mb-2"
        ></b-form-input>
        <label for="inline-form-input-name-last">{{ language(11) }}</label>
        <b-form-input
          id="inline-form-input-name-last"
          :placeholder="publicUserProfile.nameLast"
          v-model="newNameLast"
          class="mb-2"
        ></b-form-input>
        <label for="inline-form-input-phone-number">{{ language(12) }}</label>
        <b-form-input
          id="inline-form-input-phone-number"
          :placeholder="publicUserProfile.phoneNumber"
          v-model="newPhoneNumber"
          class="mb-2"
        ></b-form-input>
        <label for="inline-form-input-email">{{ language(13) }}</label>
        <b-form-input
          id="inline-form-input-email"
          :placeholder="currentPublicUser.email"
          v-model="newEmail"
          class="mb-2"
        ></b-form-input>
        <b-form-checkbox
          id="smsNotifications"
          v-model="newSmsNotifications"
          name="smsNotifications"
          :value="true"
          :unchecked-value="false"
        >
          {{ language(6) }}
        </b-form-checkbox>
        <b-form-checkbox
          id="emailNotifications"
          v-model="newEmailNotifications"
          name="emailNotifications"
          :value="true"
          :unchecked-value="false"
        >
          {{ language(7) }}
        </b-form-checkbox>
        <label class="mt-2" for="inline-form-input-password">{{ language(14) }}</label>
        <b-form-input
          id="inline-form-input-password"
          class="mb-2 mr-sm-2 mb-sm-0"
          placeholder="******"
          v-model="currentPassword"
          type="password"
        ></b-form-input>
        <label class="mt-2" for="inline-form-input-password">{{ language(15) }}</label>
        <b-form-input
          id="inline-form-input-password"
          class="mb-2 mr-sm-2 mb-sm-0"
          placeholder="******"
          v-model="newPassword"
          type="password"
        ></b-form-input>

        <b-button variant="primary" class="mt-3" @click="saveDetails()">{{ language(16) }}</b-button>
      </b-form>
    </div><!-- /DETAILS -->
    <div v-if="detailsMode === 'subscriptions'">
      <h5>{{ language(17) }}</h5>
      <b-form-checkbox
        v-model="newSmsNotifications"
        switch
        size="lg"
        :value="true"
        :unchecked-value="false"
      >{{ language(18) }}</b-form-checkbox>
      <b-form-checkbox
        v-model="newEmailNotifications"
        switch
        size="lg"
        :value="true"
        :unchecked-value="false"
      >{{ language(19) }}</b-form-checkbox>
      <b-button variant="primary" class="mt-3 mb-5" @click="saveNotificationPreferences()">{{ language(20) }}</b-button>
      <h5>{{ language(1) }}</h5>
      <ul class="list-group">
        <li
          class="list-group-item d-flex justify-content-between align-items-center"
          v-for="(subscription, i) in subscriptions"
          v-bind:key="i"
        ><a :href="'/ts/' + subscription.id">{{ subscription.name }}</a> <i @click="removeSubscription(subscription)" class="fas fa-minus-circle clickable"></i></li>
      </ul>
    </div><!-- /SUBSCRIPTIONS -->

    <div v-if="detailsMode === 'delete'">
      <div class="alert alert-danger">
        <h5>{{ language(21) }}</h5>
        <b-button variant="danger" @click="confirmDelete = true">{{ language(22) }}</b-button>
      </div>
    </div><!-- /BILLING -->

    <Confirm
      v-if="confirmDelete"
      :text="language(23)"
      v-on:confirmed="deleteAccount($event)"
      class="mt-5"
    />

  </div><!-- /#publicUserSetting -->
</template>

<script>
import Confirm from '../Confirm'
const pfb = require('../../publicFirebaseConfig.js')
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Subscriptions',
          fr: 'Abonnements'
        },
        2: {
          en: 'Details',
          fr: 'Détails'
        },
        3: {
          en: 'Delete',
          fr: 'Supprimer'
        },
        4: {
          en: 'Logout',
          fr: 'Déconnexion'
        },
        5: {
          en: 'Notification Preferences',
          fr: 'Préférences de notification'
        },
        6: {
          en: 'SMS Text Alerts',
          fr: 'Alertes par texto'
        },
        7: {
          en: 'Email Alerts',
          fr: 'Alertes par courriel'
        },
        8: {
          en: 'Save Notification Preferences',
          fr: 'Enregistrer les préférences de notification'
        },
        9: {
          en: 'Update Account Details',
          fr: 'Mettre à jour les détails du compte'
        },
        10: {
          en: 'First Name',
          fr: 'Prénom'
        },
        11: {
          en: 'Last Name',
          fr: 'Nom de famille'
        },
        12: {
          en: 'Phone Number',
          fr: 'Numéro de téléphone'
        },
        13: {
          en: 'Email',
          fr: 'Adresse courriel'
        },
        14: {
          en: 'Current Password',
          fr: 'Mot de passe actuel'
        },
        15: {
          en: 'New Password',
          fr: 'Nouveau mot de passe'
        },
        16: {
          en: 'Save Details',
          fr: 'Enregistrer les détails'
        },
        17: {
          en: 'Notification Preferences',
          fr: 'Préférences de notification'
        },
        18: {
          en: 'SMS Text Alerts',
          fr: 'Alertes par texto'
        },
        19: {
          en: 'Email Alerts',
          fr: 'Alertes par courriel'
        },
        20: {
          en: 'Save Notification Preferences',
          fr: 'Enregistrer les préférences de notification'
        },
        21: {
          en: 'Danger Zone',
          fr: 'Zone de danger'
        },
        22: {
          en: 'Delete Account',
          fr: 'Supprimer le compte'
        },
        23: {
          en: 'DANGER! Are you sure you want to delete your account? This cannot be undone! Be sure you delete any trail systems, trails, and/or points you\'d like deleted before deleting your account. You will not be able to delete these after your account is deleted!',
          fr: 'DANGER! Êtes-vous sûr de vouloir supprimer votre compte? Cette opération est irréversible! Assurez-vous d’avoir supprimé tous les sentiers, réseaux de sentiers ou points que vous souhaitez supprimer avant de supprimer votre compte. Vous ne pourrez pas les supprimer après la suppression de votre compte!'
        }
      },
      detailsMode: 'subscriptions',
      confirmDelete: false,
      newEmail: '',
      currentPassword: '',
      newPassword: '',
      newNameFirst: '',
      newNameLast: '',
      newPhoneNumber: '',
      newSmsNotifications: true,
      newEmailNotifications: true,
      loading: false,
      subscriptions: []
    }
  },
  components: {
    Confirm
  },
  computed: {
    currentPublicUser: {
      get () {
        return this.$store.state.currentPublicUser
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'currentPublicUser', value: value })
      }
    },
    publicUserProfile: {
      get () {
        return this.$store.state.publicUserProfile
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'publicUserProfile', value: value })
      }
    }
  },
  created () {
    if (this.publicUserProfile) {
      this.newNameFirst = this.publicUserProfile.nameFirst
      this.newNameLast = this.publicUserProfile.nameLast
      this.newPhoneNumber = this.publicUserProfile.phoneNumber
      this.newSmsNotifications = this.publicUserProfile.smsNotifications
      this.newEmailNotifications = this.publicUserProfile.emailNotifications
      this.fetchSubscriptions()
    }
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    fetchSubscriptions (notThisOne) {
      this.subscriptions = []
      this.publicUserProfile.subscriptions.forEach(subscription => {
        fb.trailSystemsCollection.doc(subscription).get().then(subscriptionDoc => {
          const subscriptionData = subscriptionDoc.data()
          subscriptionData.id = subscriptionDoc.id
          if (subscriptionData.id !== notThisOne) {
            this.subscriptions.push(subscriptionData)
          }
        })
      })
    },
    removeSubscription (subscription) {
      for (let i = this.publicUserProfile.subscriptions.length - 1; i >= 0; i--) {
        if (this.publicUserProfile.subscriptions[i] === subscription.id) {
          this.publicUserProfile.subscriptions.splice(i, 1)
        }
      }
      pfb.usersCollection.doc(this.currentPublicUser.uid).update({
        updatedOn: new Date(),
        subscriptions: this.publicUserProfile.subscriptions
      }).then(() => {
        this.$store.commit('setAlert', { active: true, message: 'Subscription removed.', level: 'alert-success', timeout: 4000 })
        this.fetchSubscriptions(subscription.id)
      }).catch(err => {
        console.log(err.message)
      })
    },
    saveNotificationPreferences () {
      pfb.usersCollection.doc(this.currentPublicUser.uid).update({
        updatedOn: new Date(),
        emailNotifications: this.newEmailNotifications,
        smsNotifications: this.newSmsNotifications
      }).then(() => {
        this.$store.commit('setAlert', { active: true, message: 'Preferences updated!', level: 'alert-success', timeout: 4000 })
      }).catch(err => {
        console.log(err.message)
      })
    },
    deleteAccount (confirmed) {
      if (confirmed) {
        const self = this
        pfb.currentUser.delete().then(() => {
          return pfb.usersCollection.doc(self.currentPublicUser.uid).delete()
        }).then(() => {
          self.$router.push('/')
        }).catch(err => {
          console.log(err.message)
          self.$store.commit('setAlert', { active: true, message: 'This is a sensative operation. In order to delete your account, you must log out and then log back in first.', level: 'alert-warning', timeout: 8000 })
        })
      } else {
        this.confirmDelete = false
        return false
      }
    },
    saveDetails () {
      if (!this.currentPassword) {
        return this.$store.commit('setAlert', { active: true, message: 'Must enter current password', level: 'alert-warning', timeout: 3000 })
      }
      const user = pfb.auth.currentUser
      const cred = pfb.emailAuthProvider.credential(this.currentPublicUser.email, this.currentPassword)
      user.reauthenticateWithCredential(cred).then(response => {
        console.log('Re-authenticated')
        console.log(response)
        if (this.newEmail) {
          console.log('Updating email')
          pfb.usersCollection.doc(this.currentPublicUser.uid).update({
            email: this.newEmail,
            updatedOn: new Date(),
            nameFirst: this.newNameFirst,
            nameLast: this.newNameLast,
            phoneNumber: this.newPhoneNumber,
            smsNotifications: this.newSmsNotifications,
            emailNotifications: this.newEmailNotifications
          })
          return user.updateEmail(this.newEmail)
        } else {
          console.log('No email update')
          return false
        }
      }).then(() => {
        if (this.newPassword) {
          console.log('Updating password')
          user.updatePassword(this.newPassword)
        } else {
          console.log('No password update')
        }
        return this.$store.commit('setAlert', { active: true, message: 'Settings updated!', level: 'alert-success', timeout: 3000 })
      }).catch(err => {
        this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 3000 })
      })
    },
    logout () {
      pfb.auth.signOut().then(() => {
        this.$router.push('/')
        location.reload()
      }).catch(err => {
        this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 3000 })
      })
    }
  }
}
</script>

<template>
  <div id="userSettings">
    <!-- User Details -->
    <b-card
      id="settingsCard"
      class="blockDrawer large"
      :class="$store.state.drawer.userSettings.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="hideSettings()"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 id="blockTitle">{{ language(1) }}</h4>
        <b-btn
          @click="showAllTrailSystems()"
          class="mb-2"
          v-if="$store.state.currentUser"
        >
          <i class="fas fa-caret-left"></i> {{ language(2) }}
        </b-btn>
        <b-btn
          href="https://support.trailhub.org"
          target="_blank"
          class="mb-2 ml-1"
          variant="secondary"
          v-if="currentUser"
        >
          <i class="fas fa-question-circle"></i> {{ language(3) }}
        </b-btn>
        <b-btn
          @click="logout()"
          class="mb-2 ml-1"
          variant="success"
          v-if="currentUser"
        >
          <i class="far fa-sign-out-alt"></i> {{ language(4) }}
        </b-btn>
      </template>
      <h4 v-if="currentUser">{{ language(5) }}</h4>
      <b-nav v-if="currentUser" pills class="mb-3">
        <b-nav-item @click="detailsMode = 'details'" :active="(detailsMode === 'details') ? true : false">{{ language(6) }}</b-nav-item>
        <b-nav-item @click="detailsMode = 'billing'" :active="(detailsMode === 'billing') ? true : false">{{ language(7) }}</b-nav-item>
        <b-nav-item @click="detailsMode = 'trash'" :active="(detailsMode === 'trash') ? true : false">{{ language(8) }}</b-nav-item>
        <b-nav-item @click="detailsMode = 'delete'" :active="(detailsMode === 'delete') ? true : false">{{ language(9) }}</b-nav-item>
      </b-nav>
      <div v-if="detailsMode === 'details' && currentUser">
        <h5>{{ language(10) }}</h5>
        <b-form>
          <label for="inline-form-input-name-first">{{ language(11) }}</label>
          <b-form-input
            id="inline-form-input-name-first"
            :placeholder="userProfile.nameFirst"
            v-model="newNameFirst"
            class="mb-2"
          ></b-form-input>
          <label for="inline-form-input-name-last">{{ language(12) }}</label>
          <b-form-input
            id="inline-form-input-name-last"
            :placeholder="userProfile.nameLast"
            v-model="newNameLast"
            class="mb-2"
          ></b-form-input>
          <label for="inline-form-input-phone-number">{{ language(13) }}</label>
          <b-form-input
            id="inline-form-input-phone-number"
            :placeholder="userProfile.phoneNumber"
            v-model="newPhoneNumber"
            class="mb-2"
          ></b-form-input>
          <label for="inline-form-input-organization">{{ language(14) }}</label>
          <b-form-input
            id="inline-form-input-organization"
            :placeholder="userProfile.organization"
            v-model="newOrganization"
            class="mb-2"
          ></b-form-input>
          <label for="inline-form-input-email">{{ language(15) }}</label>
          <b-form-input
            id="inline-form-input-email"
            :placeholder="currentUser.email"
            v-model="newEmail"
            class="mb-2"
          ></b-form-input>
          <label class="mt-2" for="inline-form-input-password">{{ language(16) }}</label>
          <b-form-input
            id="inline-form-input-password"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="******"
            v-model="currentPassword"
            type="password"
          ></b-form-input>
          <label class="mt-2" for="inline-form-input-password">{{ language(17) }}</label>
          <b-form-input
            id="inline-form-input-password"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="******"
            v-model="newPassword"
            type="password"
          ></b-form-input>

          <b-button variant="primary" class="mt-3 mb-5" @click="saveDetails()">{{ language(18) }}</b-button>
        </b-form>
      </div><!-- /DETAILS -->
      <div v-if="detailsMode === 'trash'">
        <h5>{{ language(8) }}</h5>
        <!-- TRAIL SYSTEMS TRASH ITEMS -->
        <b-btn
          @click="fetchTrashedTrailSystems()"
        >{{ language(25) }}</b-btn>
        <ul class="mt-2">
          <li
            v-for="(trailSystem, i) in trashedTrailSystems"
            v-bind:key="i"
          >{{ trailSystem.name }} <span class="text-success clickable" @click="restoreTrailSystem(trailSystem)">{{ language(28) }}</span></li>
        </ul>
        <!-- TRAILS TRASH ITEMS -->
        <b-btn
          @click="fetchTrashedTrails()"
        >{{ language(26) }}</b-btn>
        <ul class="mt-2">
          <li
            v-for="(trail, i) in trashedTrails"
            v-bind:key="i"
          >{{ trail.name }} <span class="text-success clickable" @click="restoreTrail(trail)">{{ language(28) }}</span></li>
        </ul>
        <!-- POINTS TRASH ITEMS -->
        <b-btn
          @click="fetchTrashedPoints()"
        >{{ language(27) }}</b-btn>
        <ul class="mt-2">
          <li
            v-for="(point, i) in trashedPoints"
            v-bind:key="i"
          >{{ point.name }} <span class="text-success clickable" @click="restorePoint(point)">{{ language(28) }}</span></li>
        </ul>
      </div><!-- /TRASH -->
      <div v-if="detailsMode === 'billing'">
        <h5>{{ language(22) }}</h5>
        <p v-if="$store.state.plan"><strong>{{ language(23) }}</strong> {{ $store.state.plan.name }}</p>
        <b-btn
          v-if="$store.state.plan && !loading"
          @click="goToCustomerPortal()"
          class="mb-4"
        >
          {{ language(24) }}
        </b-btn>
        <b-btn
          v-if="loading"
          class="mb-4"
          variant="default"
        >
          <i class="fas fa-sync fa-spin"></i> Please wait...
        </b-btn>
        <div v-if="!$store.state.planRole">
          <div class="alert alert-danger text-center">
            <h5>No Plan Selected</h5>
            <b-btn
              @click="showBilling()"
              variant="danger"
            >Please Select a Plan</b-btn>
          </div><!-- /.alert -->
        </div>
      </div><!-- BILLING -->
      <div v-if="detailsMode === 'delete'">
        <div class="alert alert-danger">
          <h5>{{ language(29) }}</h5>
          <b-button variant="danger" @click="confirmDelete = true">{{ language(30) }}</b-button>
        </div>
      </div><!-- /BILLING -->
      <hr v-if="currentUser">
      <h4 v-if="currentUser">{{ language(19) }}</h4>
      <div v-if="!currentPublicUser">
        <div class="alert alert-success text-center">
          <h5>{{ language(21) }}</h5>
          <b-btn
            :to="{ name: 'PublicLogin', params: { mode: 'default', redirect: $store.state.trailSystem.id } }"
            variant="success"
          >{{ language(20) }}</b-btn>
        </div><!-- /.alert -->
      </div>
      <PublicUserSettings v-if="currentPublicUser" />
      <div v-if="!currentUser">
        <p class="text-center mt-5"><router-link :to="{ name: 'Login', params: { mode: 'default', redirect: $store.state.trailSystem.id } }">Log in as a Trail Manager</router-link></p>
      </div>
    </b-card>

    <Confirm
      v-if="confirmDelete"
      :text="language(31)"
      v-on:confirmed="deleteAccount($event)"
    />

  </div><!-- /#settings -->
</template>

<script>
import PublicUserSettings from './PublicUserSettings'
import Confirm from '../Confirm'
import firebase from 'firebase'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'User Settings',
          fr: 'Paramètres de l’utilisateur'
        },
        2: {
          en: 'My Trail Systems',
          fr: 'Mes réseaux de sentiers'
        },
        3: {
          en: 'Help',
          fr: 'Aide'
        },
        4: {
          en: 'Logout',
          fr: 'Déconnexion'
        },
        5: {
          en: 'Trail Manager Account',
          fr: 'Compte du gestionnaire de sentiers'
        },
        6: {
          en: 'Details',
          fr: 'Détails'
        },
        7: {
          en: 'Billing',
          fr: 'Facturation'
        },
        8: {
          en: 'Trash',
          fr: 'Supprimés'
        },
        9: {
          en: 'Delete',
          fr: 'Supprimer'
        },
        10: {
          en: 'Update Account Details',
          fr: 'Mettre à jour les détails du compte'
        },
        11: {
          en: 'First Name',
          fr: 'Prénom'
        },
        12: {
          en: 'Last Name',
          fr: 'Nom de famille'
        },
        13: {
          en: 'Phone Number',
          fr: 'Numéro de téléphone'
        },
        14: {
          en: 'Organization',
          fr: 'Organisation'
        },
        15: {
          en: 'Email',
          fr: 'Adresse courriel'
        },
        16: {
          en: 'Current Password',
          fr: 'Mot de passe actuel'
        },
        17: {
          en: 'New Password',
          fr: 'Nouveau mot de passe'
        },
        18: {
          en: 'Save Details',
          fr: 'Enregistrer les détails'
        },
        19: {
          en: 'Public Account',
          fr: 'Compte public'
        },
        20: {
          en: 'Log In or Sign Up',
          fr: 'Identifiez-vous ou inscrivez-vous'
        },
        21: {
          en: 'Subscribe to Trail Updates',
          fr: 'S’abonner aux mises à jour des sentiers'
        },
        22: {
          en: 'Plan',
          fr: 'Forfait'
        },
        23: {
          en: 'Current Plan:',
          fr: 'Forfait actuel : '
        },
        24: {
          en: 'Manage Plan and Billing',
          fr: 'Gérer le forfait et la facturation'
        },
        25: {
          en: 'View Trail Systems Trash',
          fr: 'Afficher les réseaux de sentiers supprimés'
        },
        26: {
          en: 'View Trails Trash',
          fr: 'Afficher les sentiers supprimés'
        },
        27: {
          en: 'View Points Trash',
          fr: 'Afficher les points supprimés'
        },
        28: {
          en: 'Restore',
          fr: 'Restaurer'
        },
        29: {
          en: 'Danger Zone',
          fr: 'Zone de danger'
        },
        30: {
          en: 'Delete Account',
          fr: 'Supprimer le compte'
        },
        31: {
          en: 'DANGER! Are you sure you want to delete your account? This cannot be undone! Be sure you delete any trail systems, trails, and/or points you\'d like deleted before deleting your account. You will not be able to delete these after your account is deleted!',
          fr: 'DANGER! Êtes-vous sûr de vouloir supprimer votre compte? Cette opération est irréversible! Assurez-vous d’avoir supprimé tous les sentiers, réseaux de sentiers ou points que vous souhaitez supprimer avant de supprimer votre compte. Vous ne pourrez pas les supprimer après la suppression de votre compte!'
        }
      },
      detailsMode: 'details',
      confirmDelete: false,
      newEmail: '',
      currentPassword: '',
      newPassword: '',
      newNameFirst: '',
      newNameLast: '',
      newPhoneNumber: '',
      newOrganization: '',
      loading: false,
      trashedTrailSystems: [],
      trashedTrails: [],
      trashedPoints: []
    }
  },
  components: {
    Confirm,
    PublicUserSettings
  },
  computed: {
    currentPublicUser: {
      get () {
        return this.$store.state.currentPublicUser
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'currentPublicUser', value: value })
      }
    },
    publicUserProfile: {
      get () {
        return this.$store.state.publicUserProfile
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'publicUserProfile', value: value })
      }
    },
    currentUser: {
      get () {
        return this.$store.state.currentUser
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'currentUser', value: value })
      }
    },
    userProfile: {
      get () {
        return this.$store.state.userProfile
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'userProfile', value: value })
      }
    }
  },
  created () {
    if (this.userProfile) {
      this.newNameFirst = this.userProfile.nameFirst
      this.newNameLast = this.userProfile.nameLast
      this.newPhoneNumber = this.userProfile.phoneNumber
      this.newOrganization = this.userProfile.organization
      this.getBillingData()
    }
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    showAllTrailSystems () {
      this.$store.commit('hideDrawer', 'userSettings')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'trailSystems')
      }, 300)
    },
    restoreTrailSystem (trailSystem) {
      fb.trashedTrailSystemsCollection.doc(trailSystem.id).get().then(doc => {
        const item = doc.data()
        return fb.trailSystemsCollection.doc(item.id).set(item)
      }).then(() => {
        return fb.trashedTrailSystemsCollection.doc(trailSystem.id).delete()
      }).then(() => {
        this.fetchTrashedTrailSystems()
        fb.compileRequestsCollection.add({
          createdOn: new Date(),
          createdBy: this.$store.state.currentUser.uid,
          trailSystemId: trailSystem.id,
          oldGeoJsonPath: trailSystem.geoJsonPath
        })
        this.$store.dispatch('fetchTrailSystems')
      })
    },
    fetchTrashedTrailSystems () {
      fb.trashedTrailSystemsCollection.where('trashedBy', '==', this.$store.state.currentUser.uid).limit(300).onSnapshot(snapshots => {
        this.trashedTrailSystems = []
        snapshots.forEach(doc => {
          const item = doc.data()
          item.id = doc.id
          this.trashedTrailSystems.push(item)
        })
      })
    },
    restoreTrail (trail) {
      let trailSystem = {}
      fb.trailSystemsCollection.doc(trail.trailSystemId).get().then(doc => {
        trailSystem = doc.data()
        trailSystem.id = doc.id
        return fb.trashedTrailsCollection.doc(trail.id).get()
      }).then(doc => {
        const item = doc.data()
        item.id = doc.id
        console.log(item)
        return fb.trailsCollection.doc(item.id).set(item)
      }).then(() => {
        return fb.trashedTrailsCollection.doc(trail.id).delete()
      }).then(() => {
        this.fetchTrashedTrails()
        fb.compileRequestsCollection.add({
          createdOn: new Date(),
          createdBy: this.$store.state.currentUser.uid,
          trailSystemId: trailSystem.id,
          oldGeoJsonPath: trailSystem.geoJsonPath
        })
      }).catch(err => {
        console.log(err.message)
        this.$store.commit('setAlert', { active: true, message: 'This trail belongs to a trashed trail system. You must restore that trail system before restoring this trail!', level: 'alert-warning', timeout: 3000 })
      })
    },
    fetchTrashedTrails () {
      fb.trashedTrailsCollection.where('trashedBy', '==', this.$store.state.currentUser.uid).limit(300).onSnapshot(snapshots => {
        this.trashedTrails = []
        snapshots.forEach(doc => {
          const item = doc.data()
          item.id = doc.id
          this.trashedTrails.push(item)
        })
      })
    },
    restorePoint (point) {
      let trailSystem = {}
      fb.trailSystemsCollection.doc(point.trailSystemId).get().then(doc => {
        trailSystem = doc.data()
        trailSystem.id = doc.id
        return fb.trashedPointsCollection.doc(point.id).get()
      }).then(doc => {
        const item = doc.data()
        item.id = doc.id
        return fb.pointsCollection.doc(item.id).set(item)
      }).then(() => {
        return fb.trashedPointsCollection.doc(point.id).delete()
      }).then(() => {
        this.fetchTrashedPoints()
        fb.compileRequestsCollection.add({
          createdOn: new Date(),
          createdBy: this.$store.state.currentUser.uid,
          trailSystemId: trailSystem.id,
          oldGeoJsonPath: trailSystem.geoJsonPath
        })
      }).catch(err => {
        console.log(err.message)
        this.$store.commit('setAlert', { active: true, message: 'This point belongs to a trashed trail system. You must restore that trail system before restoring this point!', level: 'alert-warning', timeout: 3000 })
      })
    },
    fetchTrashedPoints () {
      fb.trashedPointsCollection.where('trashedBy', '==', this.$store.state.currentUser.uid).limit(300).onSnapshot(snapshots => {
        this.trashedPoints = []
        snapshots.forEach(doc => {
          const item = doc.data()
          item.id = doc.id
          this.trashedPoints.push(item)
        })
      })
    },
    deleteAccount (confirmed) {
      if (confirmed) {
        const self = this
        firebase.auth().currentUser.delete().then(() => {
          return fb.usersCollection.doc(self.currentUser.uid).delete()
        }).then(() => {
          self.$router.push('/login')
        }).catch(err => {
          console.log(err.message)
          self.$store.commit('setAlert', { active: true, message: 'This is a sensative operation. In order to delete your account, you must log out and then log back in first.', level: 'alert-warning', timeout: 8000 })
        })
      } else {
        this.confirmDelete = false
        return false
      }
    },
    async goToCustomerPortal () {
      this.loading = true
      const functionRef = firebase.app().functions('us-central1').httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')
      const { data } = await functionRef({ returnUrl: window.location.href })
      window.location.assign(data.url)
    },
    getBillingData () {
      const plansArray = []
      const self = this
      fb.plansCollection.where('active', '==', true).get().then(querySnapshot => {
        querySnapshot.forEach(async function (doc) {
          const plan = doc.data()
          plan.id = doc.id
          plan.prices = []
          const priceSnap = await doc.ref.collection('prices').get()
          priceSnap.docs.forEach((doc) => {
            const price = doc.data()
            price.id = doc.id
            if (price.active) {
              plan.prices.push(price)
            }
          })
          if (plan.role === self.$store.state.planRole) {
            self.$store.commit('setStateProperty', { property: 'plan', value: plan })
          }
          plansArray.push(plan)
        })
        self.$store.commit('setStateProperty', { property: 'plans', value: plansArray })
      })
    },
    showBilling () {
      this.$store.commit('hideDrawer', 'userSettings')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'billing')
      }, 300)
    },
    saveDetails () {
      if (!this.currentPassword) {
        return this.$store.commit('setAlert', { active: true, message: 'Must enter current password', level: 'alert-warning', timeout: 3000 })
      }
      const user = firebase.auth().currentUser
      const cred = firebase.auth.EmailAuthProvider.credential(user.email, this.currentPassword)
      user.reauthenticateWithCredential(cred).then(response => {
        console.log('Re-authenticated')
        console.log(response)
        if (this.newEmail) {
          console.log('Updating email')
          fb.usersCollection.doc(this.currentUser.uid).update({
            email: this.newEmail,
            updatedOn: new Date(),
            nameFirst: this.newNameFirst,
            nameLast: this.newNameLast,
            organization: this.newOrganization,
            phoneNumber: this.newPhoneNumber
          })
          return user.updateEmail(this.newEmail)
        } else {
          console.log('No email update')
          return false
        }
      }).then(() => {
        if (this.newPassword) {
          console.log('Updating password')
          user.updatePassword(this.newPassword)
        } else {
          console.log('No password update')
        }
        return this.$store.commit('setAlert', { active: true, message: 'Settings updated!', level: 'alert-success', timeout: 3000 })
      }).catch(err => {
        this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 3000 })
      })
    },
    logout () {
      fb.auth.signOut().then(() => {
        this.$router.push('/login')
        location.reload()
      }).catch(err => {
        this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 3000 })
      })
    },
    hideSettings () {
      this.$store.commit('hideDrawer', 'userSettings')
    }
  }
}
</script>

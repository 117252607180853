<template>
  <div id="adminNav">
    <b-navbar fixed="top">
      <b-navbar-brand>Customer Portal</b-navbar-brand>
      <b-navbar-nav>
        <span>
          <b-badge
            pill
            class="clickable"
            @click="refresh()"
            variant="warning"
          >
            <i class="fas fa-sync fa-spin mr-1" v-if="gettingData"></i>Run Audit
          </b-badge>
        </span>
        <b-nav-form id="activeCheck" class="ml-3">
          <b-form-checkbox
            v-model="adminData.active"
            switch
            @change="filter()"
          >Active</b-form-checkbox>
        </b-nav-form>
        <b-nav-form id="trialCheck" class="ml-3">
          <b-form-checkbox
            v-model="adminData.trial"
            switch
            @change="filter()"
          >Trial</b-form-checkbox>
        </b-nav-form>
        <b-nav-form id="inactiveCheck" class="ml-3">
          <b-form-checkbox
            v-model="adminData.inactive"
            switch
            @change="filter()"
          >Inactive</b-form-checkbox>
        </b-nav-form>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <VueFuse
            v-if="adminData.displayList"
            :fuseOpts="fuseOptions"
            :list="adminData.trailSystems"
            placeholder="Search"
            class="text-center border rounded-lg mr-2"
            @fuse-results="handleSearch"
          />
        </b-nav-form>
      </b-navbar-nav>
    </b-navbar>
  </div><!-- /#adminNav -->
</template>

<script>
import VueFuse from 'vue-fuse'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      fuseOptions: {
        keys: [
          'name',
          'ownerProfile.nameFirst',
          'ownerProfile.nameLast',
          'ownerProfile.email'
        ]
      },
      gettingData: false
    }
  },
  computed: {
    adminData: {
      get () {
        return this.$store.state.adminData
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'adminData', value: value })
      }
    }
  },
  components: {
    VueFuse
  },
  methods: {
    filter () {
      setTimeout(() => {
        this.adminData.filteredResults = []
        this.adminData.trailSystems.forEach(system => {
          if (system.passAudit && this.adminData.active) {
            this.adminData.filteredResults.push(system)
          }
          if (system.trialPeriod && this.adminData.trial) {
            this.adminData.filteredResults.push(system)
          }
          if (!system.passAudit && this.adminData.inactive) {
            this.adminData.filteredResults.push(system)
          }
        })
      }, 100)
    },
    handleSearch (r) {
      if (!this.adminData.initialLoad) {
        this.adminData.active = true
        this.adminData.trial = true
        this.adminData.inactive = true
        this.adminData.filteredResults = []
        r.forEach(result => {
          this.adminData.filteredResults.push(result.item)
        })
      } else {
        this.adminData.initialLoad = false
        this.filter()
      }
    },
    refresh () {
      this.gettingData = true
      fb.auditRequestsCollection.add({
        createdOn: new Date()
      }).then(() => {
        console.log('Created')
        setTimeout(() => {
          window.location.href = '/admin'
        }, 8000)
      })
    }
  }
}
</script>

<style scopred>
#activeCheck .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #8fd19e;
  background-color: #8fd19e;
}

#trialCheck .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #ffdf7e;
  background-color: #ffdf7e;
}

#inactiveCheck .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #ed969e;
  background-color: #ed969e;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions.js'
import mutations from './mutations.js'
import adminData from './adminData.js'
const fb = require('../firebaseConfig.js')
const pfb = require('../publicFirebaseConfig.js')

Vue.use(Vuex)

// handle page reload
fb.auth.onAuthStateChanged(user => {
  if (user) {
    store.commit('setStateProperty', { property: 'currentUser', value: user })
    store.dispatch('fetchUserProfile')
    // fb.usersCollection.doc(user.uid).onSnapshot(doc => {
    //   store.commit('setStateProperty', { property: 'userProfile', value: doc.data() })
    // })
  }
})
pfb.auth.onAuthStateChanged(user => {
  if (user) {
    store.commit('setStateProperty', { property: 'currentPublicUser', value: user })
    store.dispatch('fetchPublicUserProfile')
    // pfb.usersCollection.doc(user.uid).onSnapshot(doc => {
    //   store.commit('setStateProperty', { property: 'publicUserProfile', value: doc.data() })
    // })
  }
})

export const store = new Vuex.Store({
  state: {
    adminMode: false,
    adminData: adminData,
    alert: {
      active: false,
      message: 'This is a basic alert',
      level: 'alert-info'
    },
    allSystemsGeoJson: false,
    allActivities: false,
    currentUser: false,
    currentPublicUser: false,
    drawer: {
      allSystems: {
        show: false,
        position: 'out'
      },
      billing: {
        show: false,
        position: 'out'
      },
      bulkEdit: {
        show: false,
        position: 'out'
      },
      calendars: {
        show: false,
        position: 'out'
      },
      calendarsSettings: {
        show: false,
        position: 'out'
      },
      createPoint: {
        show: false,
        position: 'out'
      },
      createUpdate: {
        show: false,
        position: 'out'
      },
      createTrail: {
        show: false,
        position: 'out'
      },
      createTrailSystem: {
        show: false,
        position: 'out'
      },
      points: {
        show: false,
        position: 'out'
      },
      pointDetails: {
        show: false,
        position: 'out'
      },
      share: {
        show: false,
        position: 'out'
      },
      trailSystems: {
        show: false,
        position: 'out'
      },
      trailSystemDetails: {
        show: false,
        position: 'out'
      },
      trails: {
        show: false,
        position: 'out'
      },
      trail: {
        show: false,
        position: 'out'
      },
      trustees: {
        show: false,
        position: 'out'
      },
      userSettings: {
        show: false,
        position: 'out'
      },
      update: {
        show: false,
        position: 'out'
      },
      updates: {
        show: false,
        position: 'out'
      },
      weather: {
        show: false,
        position: 'out'
      }
    },
    embedMode: false,
    filteredActivity: false,
    hiddenActivities: {},
    iosInstallPrompt: false,
    plans: [],
    plan: false,
    planRole: false,
    drawControlsObjects: [],
    editMode: false,
    firstLoad: true,
    locateControl: {},
    mapLoader: false,
    mapObject: {},
    trailSystems: [],
    trailSystem: {
      language: 'en'
    },
    trusteeData: false,
    ownerData: false,
    currentRole: false,
    trailSystemMode: 'hybrid',
    geocoderControl: {},
    selectMarker: false,
    mapMarkers: [],
    tempPoint: {},
    trails: [],
    trailsGeoJson: {},
    trailsGeoJsonDefault: {},
    trail: {},
    points: [],
    point: {},
    userProfile: {},
    publicUserProfile: {},
    updates: [],
    updatesGathered: false,
    expiredUpdates: [],
    showTSIcon: false,
    subscriptionsGeoJson: false,
    update: {},
    zoomControl: {}
  },
  mutations: mutations,
  actions: actions
})

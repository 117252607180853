<template>
  <div id="offerings">
    <h2 class="text-center">Plans for Trail Managers</h2>
    <p class="text-center mt-5 mb-5"><a @click="$router.push('/login/create')" class="outlined clickable">Get Started! Free for 30 days!</a></p>
    <div class="text-center mb-3">
      <b-form-checkbox
        v-model="pricingInterval"
        switch
        size="lg"
        value="year"
        unchecked-value="month"
      >Annual Pricing</b-form-checkbox>
      <b-form-checkbox
        v-model="priceMode"
        switch
        size="lg"
        value="nonProfit"
        unchecked-value="forProfit"
      >Nonprofit Prices</b-form-checkbox>
    </div><!-- /.col-md-4 -->
    <div class="container-fluid">
      <div class="row" v-if="priceMode === 'forProfit'">
        <div
          v-for="(offering, i) in offerings"
          v-bind:key="i"
          class="offering"
        >
          <a>
            <div class="offering-content">
              <span v-if="offering.title == 'Square'" class="difficulty-marker square mb-1"></span>
              <span v-if="offering.title == 'Diamond'" class="difficulty-marker diamond mb-1"></span>
              <span v-if="offering.title == 'Double Diamond'" class="difficulty-marker diamond ml-1 mb-1"></span><span v-if="offering.title == 'Double Diamond'" class="difficulty-marker diamond mb-1"></span>
              <!-- <img :src="offering.image" /> -->
              <h2>{{ offering.title }}<br>
              <small v-if="pricingInterval === 'month'">{{ offering.price }}</small><small v-if="pricingInterval === 'year'">{{ offering.annual }}</small></h2>
              <!-- <p>{{ offering.description }}</p> -->
              <div v-if="offering.features">
                <p class="m-0 pb-1" v-for="(feature, index) in offering.features" v-bind:key="index"><i v-if="feature.value" class="fas fa-check-circle text-success"></i><i v-if="!feature.value" class="fas fa-times-circle text-danger"></i> {{ feature.title }}</p>
              </div>
            </div><!-- /.offering-content -->
          </a>
        </div><!-- /.offering -->
      </div><!-- /.row -->
      <div class="row" v-if="priceMode === 'nonProfit'">
        <div
          v-for="(offering, i) in nonProfitOfferings"
          v-bind:key="i"
          class="offering"
        >
          <a>
            <div class="offering-content">
              <span v-if="offering.title == 'Circle'" class="difficulty-marker circle mb-1"></span>
              <span v-if="offering.title == 'Triangle'" class="difficulty-marker triangle mb-1"></span>
              <span v-if="offering.title == 'Star'" class="difficulty-marker star"></span>
              <!-- <img :src="offering.image" /> -->
              <h2>{{ offering.title }}<br>
              <small v-if="pricingInterval === 'month'">{{ offering.price }}</small><small v-if="pricingInterval === 'year'">{{ offering.annual }}</small></h2>
              <!-- <p>{{ offering.description }}</p> -->
              <div v-if="offering.features">
                <p class="m-0 pb-1" v-for="(feature, index) in offering.features" v-bind:key="index"><i v-if="feature.value" class="fas fa-check-circle text-success"></i><i v-if="!feature.value" class="fas fa-times-circle text-danger"></i> {{ feature.title }}</p>
              </div>
            </div><!-- /.offering-content -->
          </a>
        </div><!-- /.offering -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
    <p class="text-center lead mt-5">Are you a parks system or manage many trail systems?</p>
    <p class="text-center mb-5"><a href="https://www.support.trailhub.org/contact" target="_blank" class="outlined clickable">Contact Us About Custom Packages</a></p>
  </div><!-- /#offerings -->
</template>

<script>
export default {
  data () {
    return {
      pricingInterval: 'month',
      priceMode: 'forProfit',
      offerings: [
        {
          title: 'Square',
          description: 'Small outdoor center, for-profit, up to 2 trail systems, <100K avg. views per month.',
          features: [
            {
              title: 'Small Outdoor Center',
              value: true
            },
            {
              title: 'For-profit',
              value: true
            },
            {
              title: '2 Trail Systems',
              value: true
            },
            // {
            //   title: '3 Trustees',
            //   value: true
            // },
            {
              title: 'Embed Codes',
              value: true
            },
            {
              title: 'QR Code',
              value: true
            },
            {
              title: 'Custom Branding',
              value: true
            },
            {
              title: 'Snow Reports',
              value: true
            },
            {
              title: 'Surface Conditions',
              value: true
            },
            {
              title: 'Notices',
              value: true
            },
            {
              title: 'Audience can Subscribe',
              value: true
            },
            {
              title: 'SMS and Email Notifications',
              value: true
            },
            {
              title: 'Points of Interest',
              value: true
            },
            {
              title: 'Map Webcams',
              value: true
            },
            {
              title: 'Bulk Update Trails',
              value: true
            },
            {
              title: 'Import GIS Data',
              value: true
            },
            {
              title: 'Elevation Profiles',
              value: true
            },
            {
              title: 'Trails Weather',
              value: false
            },
            {
              title: 'Ski Lifts',
              value: false
            },
            {
              title: 'Wait Times',
              value: false
            },
            {
              title: 'Events Calendars',
              value: false
            },
            {
              title: 'Dining Menus',
              value: false
            },
            {
              title: 'Digital Signage',
              value: false
            },
            {
              title: 'API Access',
              value: false
            }
          ],
          price: '$32/month',
          annual: '$325/year'
        },
        {
          title: 'Diamond',
          description: 'Medium outdoor center, for-profit, up to 4 trail systems, <200K avg. views per month, API Access.',
          features: [
            {
              title: 'Medium Outdoor Center',
              value: true
            },
            {
              title: 'For-profit',
              value: true
            },
            {
              title: '4 Trail Systems',
              value: true
            },
            // {
            //   title: '5 Trustees',
            //   value: true
            // },
            {
              title: 'Embed Codes',
              value: true
            },
            {
              title: 'QR Code',
              value: true
            },
            {
              title: 'Custom Branding',
              value: true
            },
            {
              title: 'Snow Reports',
              value: true
            },
            {
              title: 'Surface Conditions',
              value: true
            },
            {
              title: 'Notices',
              value: true
            },
            {
              title: 'Audience can Subscribe',
              value: true
            },
            {
              title: 'SMS and Email Notifications',
              value: true
            },
            {
              title: 'Points of Interest',
              value: true
            },
            {
              title: 'Map Webcams',
              value: true
            },
            {
              title: 'Bulk Update Trails',
              value: true
            },
            {
              title: 'Import GIS Data',
              value: true
            },
            {
              title: 'Elevation Profiles',
              value: true
            },
            {
              title: 'Trails Weather',
              value: true
            },
            {
              title: 'Ski Lifts',
              value: false
            },
            {
              title: 'Wait Times',
              value: false
            },
            {
              title: 'Events Calendars',
              value: false
            },
            {
              title: 'Dining Menus',
              value: false
            },
            {
              title: 'Digital Signage',
              value: false
            },
            {
              title: 'API Access',
              value: false
            }
          ],
          price: '$60/month',
          annual: '$618/year'
        },
        {
          title: 'Double Diamond',
          description: 'Large outdoor center/resort, for-profit, up to 8 trail systems, <500K avg. views per month, API access.',
          features: [
            {
              title: 'Large Outdoor Center',
              value: true
            },
            {
              title: 'For-profit',
              value: true
            },
            {
              title: '8 Trail Systems',
              value: true
            },
            // {
            //   title: '10 Trustees',
            //   value: true
            // },
            {
              title: 'Embed Codes',
              value: true
            },
            {
              title: 'QR Code',
              value: true
            },
            {
              title: 'Custom Branding',
              value: true
            },
            {
              title: 'Snow Reports',
              value: true
            },
            {
              title: 'Surface Conditions',
              value: true
            },
            {
              title: 'Notices',
              value: true
            },
            {
              title: 'Audience can Subscribe',
              value: true
            },
            {
              title: 'SMS and Email Notifications',
              value: true
            },
            {
              title: 'Points of Interest',
              value: true
            },
            {
              title: 'Map Webcams',
              value: true
            },
            {
              title: 'Bulk Update Trails',
              value: true
            },
            {
              title: 'Import GIS Data',
              value: true
            },
            {
              title: 'Elevation Profiles',
              value: true
            },
            {
              title: 'Trails Weather',
              value: true
            },
            {
              title: 'Ski Lifts',
              value: true
            },
            {
              title: 'Wait Times',
              value: true
            },
            {
              title: 'Events Calendars',
              value: true
            },
            {
              title: 'Dining Menus',
              value: true
            },
            {
              title: 'Digital Signage',
              value: true
            },
            {
              title: 'API Access',
              value: true
            }
          ],
          price: '$116/month',
          annual: '$1274/year'
        }
      ],
      nonProfitOfferings: [
        {
          title: 'Circle',
          description: 'Small Club, non-profit, up to 2 trail systems, <100K avg. views per month.',
          features: [
            {
              title: 'Small Outdoor Center',
              value: true
            },
            {
              title: 'For-profit',
              value: false
            },
            {
              title: '2 Trail Systems',
              value: true
            },
            // {
            //   title: '3 Trustees',
            //   value: true
            // },
            {
              title: 'Embed Codes',
              value: true
            },
            {
              title: 'QR Code',
              value: true
            },
            {
              title: 'Custom Branding',
              value: true
            },
            {
              title: 'Snow Reports',
              value: true
            },
            {
              title: 'Surface Conditions',
              value: true
            },
            {
              title: 'Notices',
              value: true
            },
            {
              title: 'Audience can Subscribe',
              value: true
            },
            {
              title: 'SMS and Email Notifications',
              value: true
            },
            {
              title: 'Points of Interest',
              value: true
            },
            {
              title: 'Map Webcams',
              value: true
            },
            {
              title: 'Bulk Update Trails',
              value: true
            },
            {
              title: 'Import GIS Data',
              value: true
            },
            {
              title: 'Elevation Profiles',
              value: true
            },
            {
              title: 'Trails Weather',
              value: false
            },
            {
              title: 'Ski Lifts',
              value: false
            },
            {
              title: 'Wait Times',
              value: false
            },
            {
              title: 'Events Calendars',
              value: false
            },
            {
              title: 'Dining Menus',
              value: false
            },
            {
              title: 'Digital Signage',
              value: false
            },
            {
              title: 'API Access',
              value: false
            }
          ],
          price: '$16/month',
          annual: '$169/year'
        },
        {
          title: 'Triangle',
          description: 'Medium club, non-profit, up to 4 trail systems, <200K avg. views per month, API Access.',
          features: [
            {
              title: 'Medium Outdoor Center',
              value: true
            },
            {
              title: 'For-profit',
              value: false
            },
            {
              title: '4 Trail Systems',
              value: true
            },
            // {
            //   title: '5 Trustees',
            //   value: true
            // },
            {
              title: 'Embed Codes',
              value: true
            },
            {
              title: 'QR Code',
              value: true
            },
            {
              title: 'Custom Branding',
              value: true
            },
            {
              title: 'Snow Reports',
              value: true
            },
            {
              title: 'Surface Conditions',
              value: true
            },
            {
              title: 'Notices',
              value: true
            },
            {
              title: 'Audience can Subscribe',
              value: true
            },
            {
              title: 'SMS and Email Notifications',
              value: true
            },
            {
              title: 'Points of Interest',
              value: true
            },
            {
              title: 'Map Webcams',
              value: true
            },
            {
              title: 'Bulk Update Trails',
              value: true
            },
            {
              title: 'Import GIS Data',
              value: true
            },
            {
              title: 'Elevation Profiles',
              value: true
            },
            {
              title: 'Trails Weather',
              value: true
            },
            {
              title: 'Ski Lifts',
              value: false
            },
            {
              title: 'Wait Times',
              value: false
            },
            {
              title: 'Events Calendars',
              value: false
            },
            {
              title: 'Dining Menus',
              value: false
            },
            {
              title: 'Digital Signage',
              value: false
            },
            {
              title: 'API Access',
              value: false
            }
          ],
          price: '$42/month',
          annual: '$455/year'
        },
        {
          title: 'Star',
          description: 'Large club, non-profit, up to 8 trail systems, <500K avg. views per month, API access.',
          features: [
            {
              title: 'Large Outdoor Center',
              value: true
            },
            {
              title: 'For-profit',
              value: false
            },
            {
              title: '8 Trail Systems',
              value: true
            },
            // {
            //   title: '10 Trustees',
            //   value: true
            // },
            {
              title: 'Embed Codes',
              value: true
            },
            {
              title: 'QR Code',
              value: true
            },
            {
              title: 'Custom Branding',
              value: true
            },
            {
              title: 'Snow Reports',
              value: true
            },
            {
              title: 'Surface Conditions',
              value: true
            },
            {
              title: 'Notices',
              value: true
            },
            {
              title: 'Audience can Subscribe',
              value: true
            },
            {
              title: 'SMS and Email Notifications',
              value: true
            },
            {
              title: 'Points of Interest',
              value: true
            },
            {
              title: 'Map Webcams',
              value: true
            },
            {
              title: 'Bulk Update Trails',
              value: true
            },
            {
              title: 'Import GIS Data',
              value: true
            },
            {
              title: 'Elevation Profiles',
              value: true
            },
            {
              title: 'Trails Weather',
              value: true
            },
            {
              title: 'Ski Lifts',
              value: true
            },
            {
              title: 'Wait Times',
              value: true
            },
            {
              title: 'Events Calendars',
              value: true
            },
            {
              title: 'Dining Menus',
              value: true
            },
            {
              title: 'Digital Signage',
              value: true
            },
            {
              title: 'API Access',
              value: true
            }
          ],
          price: '$98/month',
          annual: '$1072/year'
        }
      ]
    }
  }
}
</script>

<style scoped>
#offerings {
  position: relative;
  z-index: 4;
}

.offering {
  position: relative;
  display: inline-grid;
  width: 33%;
  padding: 10px;
  text-align: center;
  height: 100%;
}

.offering-content {
  background-color: white;
  border: 1px solid #eee;
  padding: 40px 25px;
  height: 100%;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.offering-content:hover {
  box-shadow: 1px 2px 5px rgba(0,0,0,0.2);
  transform: scale(1.03);
  border: solid 1px #eee;
}

.offering a {
  color: #333
}

.offering a:hover {
  text-decoration: none;
}

a.outlined:hover {
  background-color: #333;
  color: white;
  border: solid 1px #333;
  text-decoration: none;
}
a.outlined {
  margin: 0 10px;
  color: #333;
  transition: all 0.2s ease-in-out;
  padding: 10px 14px;
  border: solid 1px;
  border-radius: 50px;
}

.offering img {
  width: 150px;
  max-width: 100%;
  border-radius: 100px;
  margin-bottom: 30px;
}

.offering h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.offering p {
  color: #333;
  font-weight: 300;
}

@media (max-width: 767px) {
  .offering {
    display: block;
    width: 100%;
    padding: 10px;
  }
}

</style>

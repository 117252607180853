<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="3000"
      filename="trail-hub-report"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="letter"
      pdf-orientation="portrait"
      pdf-content-width="720px"
      ref="html2Pdf"
      :html-to-pdf-options="{margin: 10}"
    >
      <section slot="pdf-content" class="container">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="card-title">{{ trailSystem.name }} <small>Trail Report</small></h5>
          <h6><small>{{ new Date() | formatDate }}</small></h6>
        </div><!-- /.d-flex -->
        <p
          v-for="(notice, i) in $store.state.trailSystem.updates.notice"
          v-bind:key="i"
          class="m-0"
        >
          <small><i v-if="notice.severity === 'info'" class="fas fa-info-circle"></i><i v-if="notice.severity === 'warning'" class="fas fa-exclamation-circle text-warning"></i><i v-if="notice.severity === 'danger'" class="fas fa-exclamation-circle text-danger"></i> {{ notice.description }}</small>
        </p>

        <SnowReport
          v-if="$store.state.trailSystem.updates.snowReport"
          :update="$store.state.trailSystem.updates.snowReport[0]"
          class=" mt-3 mb-3 pdf-item"
        />

        <SurfaceConditions
          v-if="$store.state.trailSystem.updates.surfaceConditions"
          :update="$store.state.trailSystem.updates.surfaceConditions[0]"
          class="mb-3 pdf-item"
        />

        <!-- NEW TABLE -->
        <table class="table" id="print-report">
          <thead>
            <tr>
              <th scope="col">Trail</th>
              <th scope="col">Rating</th>
              <th scope="col">Distance</th>
              <th scope="col">Activities</th>
              <th scope="col">Surface</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(trail, i) in $store.state.trails"
              v-bind:key="i"
            >
              <td class="w-50">
                <p class="mb-0"><span v-if="trail.properties.status == 'Open'" class="text-success"><i class="fas fa-check-circle"></i> {{ trail.properties.name }}</span>
                <span v-if="trail.properties.status == 'Closed'" class="text-danger"><i class="fas fa-times-circle text-danger"></i> {{ trail.properties.name }}</span>
                <span v-if="trail.properties.status == 'Caution'" class="text-warning"><i class="fas fa-exclamation-circle text-warning"></i> {{ trail.properties.name }}</span>
                <span v-if="trail.properties.status == 'None'">{{ trail.properties.name }}</span></p>
                <div v-if="trail.properties.updates.notice">
                  <p
                    v-for="(notice, i) in trail.properties.updates.notice"
                    v-bind:key="i"
                    class="m-0"
                  >
                    <i v-if="notice.severity === 'info'" class="fas fa-info-circle"></i><i v-if="notice.severity === 'warning'" class="fas fa-exclamation-circle text-warning"></i><i v-if="notice.severity === 'danger'" class="fas fa-exclamation-circle text-danger"></i> {{ notice.description }}
                  </p>
                </div>
              </td>
              <td>
                <span v-if="trail.properties.difficulty != 'doubleDiamond'" :class="trail.properties.difficulty" class="difficulty-marker mr-1"></span>
                <span v-if="trail.properties.difficulty == 'doubleDiamond'" class="difficulty-marker diamond mr-1"></span><span v-if="trail.properties.difficulty == 'doubleDiamond'" class="difficulty-marker diamond mr-1"></span>
              </td>
              <td>{{ formatLength(trail.properties.distance) }}</td>
              <td v-if="trail.properties.activities.length > 0">
                <b-badge
                  v-for="(activity, i) in trail.properties.activities"
                  v-bind:key="i"
                  class="mr-1"
                  pill
                  :variant="setActivityVariant(activity.status)"
                >
                  <i v-if="activity.status == 'Open'" class="fas fa-check-circle mr-1"></i>
                  <i v-if="activity.status == 'Closed'" class="fas fa-times-circle mr-1"></i>
                  <i v-if="activity.status == 'Caution'" class="fas fa-exclamation-circle mr-1"></i>
                  <i :class="activity.iconClass" class="activity-icon"></i> {{ activity.label }}
                  <span v-if="activity.difficulty && activity.difficulty === 'circle'" class="difficulty-marker circle ml-1"></span>
                  <span v-if="activity.difficulty && activity.difficulty === 'square'" class="difficulty-marker square ml-1"></span>
                  <span v-if="activity.difficulty && activity.difficulty === 'diamond'" class="difficulty-marker diamond ml-1"></span>
                  <span v-if="activity.difficulty && activity.difficulty === 'doubleDiamond'" class="difficulty-marker diamond ml-1"></span><span v-if="activity.difficulty && activity.difficulty === 'doubleDiamond'" class="difficulty-marker diamond"></span>
                  <span v-if="activity.difficulty && activity.difficulty === 'terrainPark'" class="difficulty-marker terrainPark ml-1"></span>
                </b-badge>
              </td>
              <td>
                <span
                  v-if="trail.properties.updates.surfaceConditions"
                >
                  <b-badge
                    v-for="(condition, index) in trail.properties.updates.surfaceConditions[0].conditions"
                    v-bind:key="index"
                    class="mr-1"
                    pill
                  ><i :class="condition.iconClass"></i> {{ condition.en }}</b-badge>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- END NEW TABLE -->
        <p class="text-right"><small>Powered by trailhub.org</small></p>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import SnowReport from './update/templates/SnowReport'
import SurfaceConditions from './update/templates/SurfaceConditions'
import moment from 'moment'
import VueHtml2pdf from 'vue-html2pdf' // eslint-disable-line
const fb = require('../firebaseConfig.js')

export default {
  data () {
    return {
      filtered: false
    }
  },
  components: {
    VueHtml2pdf, // eslint-disable-line
    SnowReport,
    SurfaceConditions
  },
  created () {
    this.init()
  },
  computed: {
    trailSystem: {
      get () {
        return this.$store.state.trailSystem
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trailSystem', value: value })
      }
    },
    trails: {
      get () {
        return this.$store.state.trails
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trails', value: value })
      }
    }
  },
  methods: {
    setActivityVariant (status) {
      if (status === 'Open') {
        return 'success'
      } else if (status === 'Caution') {
        return 'warning'
      } else if (status === 'Closed') {
        return 'danger'
      }
    },
    init () {
      const tsId = this.$route.params.id
      fb.trailSystemsCollection.doc(tsId).get().then(ts => {
        this.trailSystem = ts.data()
        this.trailSystem.id = ts.id
        return this.$store.dispatch('fetchTrails')
      }).then(() => {
        console.log(this.$store.state.trails)
        // Run filters from URL
        const urlParams = new URLSearchParams(window.location.search)
        const statusFilter = urlParams.get('status')
        const activityFilter = urlParams.get('activity')
        // Remove any hidden trails
        for (let i = this.trails.length - 1; i >= 0; i--) {
          if (this.trails[i].properties.hidden) {
            this.trails.splice(i, 1)
          }
        }
        if (statusFilter !== 'all') {
          this.filtered = true
          for (let i = this.trails.length - 1; i >= 0; i--) {
            if (this.trails[i].properties.status !== statusFilter) {
              this.trails.splice(i, 1)
            }
          }
        }
        if (activityFilter !== 'all') {
          this.filtered = true
          // Remove Trails that don't match the filter
          for (let i = this.trails.length - 1; i >= 0; i--) {
            let passTrail = false
            this.trails[i].properties.activities.forEach((activity, i) => {
              if (activity.value === activityFilter) {
                passTrail = true
              }
            })
            if (!passTrail) {
              this.trails.splice(i, 1)
            }
          }
          // Remove activities that don't match the filter
          for (let i = this.trailSystem.activities.length - 1; i >= 0; i--) {
            if (activityFilter !== this.trailSystem.activities[i].value) {
              this.trailSystem.activities.splice(i, 1)
            }
          }
        }
        console.log(this.trails)
        const self = this
        setTimeout(function () {
          self.generateReport()
        }, 1000)
      })
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    formatLength (length) {
      if (length) {
        if (this.$store.state.trailSystem.lengthMeasurement === 'miles') {
          let miles = length * 0.621371
          miles = miles.toFixed(2)
          return miles + ' mi'
        } else {
          return length.toFixed(2) + ' km'
        }
      } else {
        return false
      }
    }
  },
  filters: {
    formatDate (date) {
      return moment(date).format('MM-DD-YYYY')
    }
  }
}
</script>

<style scoped>
.trail-item {
  margin-left: 38px;
}
#print-report {
  font-size: 11px;
}

.difficulty-marker.circle {
  width: 8px;
  height: 8px;
  border-radius: 20px;
  background-color: #28a745;
}
.difficulty-marker.square {
  width: 8px;
  height: 8px;
  background-color: #007bff;
}
.difficulty-marker.diamond {
  width: 6px;
  height: 6px;
  background-color: #333;
  transform: rotate(45deg);
  margin-right: 3px;
}
.difficulty-marker.terrainPark {
  width: 11px;
  height: 8px;
  border-radius: 20px;
  background-color: #ffc107;
}
</style>

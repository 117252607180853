<template>
  <div id="adminChangeOwner">
    <div class="o-modal mt-5">
      <div class="o-modal-content mt-4">
        <div class="o-modal-header">
          <p class="lead">New Owner <i class="fas fa-times float-right clickable mt-1" @click="adminData.changeOwner = false"></i></p>
        </div><!-- /.o-modal-header -->
        <div class="o-modal-body">
          <div
            v-if="warningMessage"
            class="alert alert-danger"
          >
            {{ warningMessage }}
          </div>
          <b-form inline>
            <label class="sr-only" for="inline-form-input-email">Lookup User by Email</label>
            <b-form-input
              id="inline-form-input-email"
              class="mb-2 mr-sm-2 mb-sm-0"
              placeholder="email@email.com"
              v-model="email"
            ></b-form-input>
            <b-button
              @click="lookup()"
              variant="primary"
            >Search</b-button>
          </b-form>
          <b-card
            v-if="userDetails"
            :title="userDetails.nameFirst + ' ' + userDetails.nameLast"
            :sub-title="userDetails.email"
            class="mt-2"
          >
            <b-btn
              v-if="billingLink"
              :href="billingLink"
              target="_blank"
              class="mr-1"
            >
              Billing Details
            </b-btn>
            <b-btn
              @click="confirmAssign = true"
              variant="warning"
            >
              Make Owner
            </b-btn>
          </b-card>
        </div><!-- /.o-modal-body -->
      </div><!-- /.o-modal-content -->
    </div><!-- /.o-modal -->
    <Confirm
      class="mt-5"
      v-if="confirmAssign"
      :text="'CAREFUL! Are you sure you want to assign this new owner to ' + $store.state.adminData.trailSystem.name + '?'"
      v-on:confirmed="makeOwner($event)"
    />
  </div><!-- /#adminChangeOwner -->
</template>

<script>
import Confirm from '../Confirm'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      email: '',
      userDetails: false,
      warningMessage: false,
      billingLink: false,
      confirmAssign: false
    }
  },
  components: {
    Confirm
  },
  computed: {
    adminData: {
      get () {
        return this.$store.state.adminData
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'adminData', value: value })
      }
    }
  },
  methods: {
    lookup () {
      this.warningMessage = false
      this.userDetails = false
      fb.usersCollection.where('email', '==', this.email).limit(10).get().then(users => {
        if (users.docs.length === 0) {
          this.warningMessage = 'No user found'
          return false
        } else {
          this.userDetails = users.docs[0].data()
          this.userDetails.id = users.docs[0].id
          console.log(this.userDetails)
          return fb.customersCollection.doc(this.userDetails.id).get().then(customer => {
            this.billingLink = customer.data().stripeLink
          })
        }
      }).catch(err => {
        this.warningMessage = err.message
        this.userDetails = false
        console.log(err.message)
      })
    },
    makeOwner (confirm) {
      if (confirm) {
        console.log('Update Owner')
        fb.trailSystemsCollection.doc(this.adminData.trailSystem.id).update({
          ownerId: this.userDetails.id,
          updatedOn: new Date()
        }).then(() => {
          this.warningMessage = 'Owner Updated. Run Audit to update admin records.'
          this.confirmAssign = false
        }).catch(err => {
          this.warningMessage = err.message
          this.confirmAssign = false
        })
      } else {
        this.confirmAssign = false
      }
    }
  }
}
</script>

<style scoped>
.o-modal {
  z-index: 4;
}
</style>

<template>
  <div id="signageWebCams">
    <p
      class="lead text-center signage-header"
      v-if="webCamPoint.name"
    >{{ webCamPoint.name }}</p>
    <div
      v-if="embedCode"
      v-html="embedCode"
    ></div><!-- /embedCode -->
  </div><!-- /#signageWebCams -->
</template>

<script>
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      webCams: [],
      webCamPoint: {},
      embedCode: false
    }
  },
  created () {
    // Get all web cams
    this.$store.state.points.forEach(pointFeature => {
      if (pointFeature.properties.markerClass === 'web-cam') {
        this.webCams.push(pointFeature.properties.pointId)
      }
    })
    // Select random web cam id
    const randomWebCam = this.webCams[Math.floor(Math.random() * this.webCams.length)]
    // Gather webcam details
    fb.pointsCollection.doc(randomWebCam).get().then(pointDoc => {
      this.webCamPoint = pointDoc.data()
      this.webCamPoint.id = pointDoc.id
      this.generateEmbedCode(this.webCamPoint.webCamUrl)
    })
  },
  methods: {
    generateEmbedCode (url) {
      function getId (link) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
        const match = link.match(regExp)
        return (match && match[2].length === 11)
          ? match[2]
          : null
      }
      const videoId = getId(url)
      this.embedCode = '<iframe width="100%" height="350" src="//www.youtube.com/embed/' + videoId + '?autoplay=1" frameborder="0" allowfullscreen></iframe>'
    }
  }
}
</script>

<template>
  <div id="navbar">
    <div class="orbitist-navbar" :class="navbarClass">
      <div class="container">
        <div class="orbitist-navbar-title" :class="navbarClass">
          <img class="logo" :class="navbarClass" src="https://firebasestorage.googleapis.com/v0/b/trail-hub.appspot.com/o/assets%2Fimages%2Flogos%2Fth-globe.jpg?alt=media&token=6872818c-5f01-405f-aab8-11fda7a2a41d" />
        </div><!-- /.orbitist-navbar-title -->
        <div class="orbitist-navbar-links" :class="navbarClass">
          <a @click="$router.push('/login')" class="clickable">{{ language(1) }}</a>
          <a v-if="!$store.state.currentPublicUser" @click="$router.push('/public-login')" class="clickable outlined">{{ language(2) }}</a>
          <a v-if="$store.state.currentPublicUser" @click="$router.push('/all')" class="clickable outlined">{{ language(2) }}</a>
        </div><!-- /.orbitist-navbar-links -->
      </div><!-- /.container -->
    </div><!-- /.orbitist-navbar -->
  </div><!-- /#navbar -->
</template>

<script>
export default {
  data () {
    return {
      scrollPosition: 0,
      navbarClass: '',
      phrases: {
        1: {
          en: 'Trail Managers',
          fr: 'Gestionnaires de sentiers'
        },
        2: {
          en: 'Public Users',
          fr: 'Utilisateurs publics'
        }
      }
    }
  },
  mounted () {
    var self = this
    window.addEventListener('scroll', function (e) {
      self.scrollPosition = window.scrollY
      if (self.scrollPosition > 70) {
        self.navbarClass = 'scrolled'
      } else {
        self.navbarClass = ''
      }
    })
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    }
  }
}
</script>

<style scoped>
.orbitist-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 130px;
  background-color: rgba(0,0,0,0);
  z-index: 5;
  transition: all 0.25s ease-in-out;
}

.orbitist-navbar.scrolled {
  background-color: rgba(255,255,255,1);
  height: 75px;
}

.logo {
  height: 50px;
  border-radius: 25px;
  margin-bottom: 8px;
}
/* .logo.scrolled {
  height: 50px;
  border-radius: 25px;
  margin-bottom: 5px;
} */

.orbitist-navbar-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  color: #eee;
  font-size: 35px;
  padding: 30px 30px;
  transition: all 0.2s ease-in-out;
}

.orbitist-navbar-title.scrolled {
  padding: 12px 30px;
  color: #333;
}

.orbitist-navbar-links {
  position: absolute;
  right: 0px;
  top: 0;
  text-align: right;
  padding: 40px 10px;
  transition: all 0.2s ease-in-out;
  font-size: 18px;
}

.orbitist-navbar-links.scrolled {
  padding: 25px 30px;
}

.orbitist-navbar-links a {
  margin: 0 10px;
  color: #eee;
  transition: all 0.2s ease-in-out;
  padding: 10px 14px;
  border-bottom: solid 0px #eee;
}

.orbitist-navbar-links a:hover {
  text-decoration: none;
  border-bottom: solid 5px #eee;
  color: white;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.2);
}
.orbitist-navbar-links.scrolled a:hover {
  border-bottom: solid 5px #333;
}

.orbitist-navbar-links a.outlined:hover {
  background-color: #eee;
  color: white;
  border: solid 1px #eee;
}

.orbitist-navbar-links.scrolled a {
  color: #333;
}

.orbitist-navbar-links.scrolled a:hover {
  color: #333;
  text-shadow: 1px 1px 3px rgba(0,0,0,0)
}

.orbitist-navbar-links a.outlined {
  border: solid 1px;
  border-radius: 50px;
}

.orbitist-navbar-links a.outlined:hover {
  background-color: #333;
  color: white;
  border: solid 1px #333;
}

@media (max-width:800px) {
  .orbitist-navbar-links a {
    margin: 0 2px;
  }
}
</style>

<template>
  <div id="pointDetails">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.pointDetails.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="closePointDetails()"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title" v-if="point.name">
          <span class="marker" :class="point.markerClass"></span>{{ point.name }}
        </h4>
        <b-btn
          @click="editPoint()"
          class="mb-2"
          v-if="$store.state.currentUser && $store.state.currentRole"
        >
          Edit Point
        </b-btn>
      </template>

      <p class="text-center mb-0"><em><small>Last Updated: {{ point.updatedOn | formatDate }}</small></em></p>

      <h5 v-if="point.status">
        <i style="color:#28a745;" v-if="point.status == 'Open'" class="fas fa-check-circle"></i>
        <i style="color:#dc3545;" v-if="point.status == 'Closed'" class="fas fa-times-circle"></i>
        <i style="color:#ffc107;" v-if="point.status == 'Caution'" class="fas fa-exclamation-circle"></i>
        {{ this.statuses[point.status][$store.state.trailSystem.language] }}
      </h5>

      <div
        v-if="embedCode"
        v-html="embedCode"
      ></div><!-- /embedCode -->

      <p v-if="point.description">{{ point.description }}</p>

      <!-- IF DINING, RENDER MENUS -->
      <div
        v-if="point.markerClass === 'dining'"
      >
        <ul class="nav nav-pills mb-4">
          <li class="nav-item">
            <button
              class="nav-link btn mr-2"
              :class="{ active: currentDay === 0 }"
              @click="currentDay = 0"
            >Su</button>
          </li>
          <li class="nav-item">
            <button
              class="nav-link btn mr-2"
              :class="{ active: currentDay === 1 }"
              @click="currentDay = 1"
            >Mo</button>
          </li>
          <li class="nav-item">
            <button
              class="nav-link btn mr-2"
              :class="{ active: currentDay === 2 }"
              @click="currentDay = 2"
            >Tu</button>
          </li>
          <li class="nav-item">
            <button
              class="nav-link btn mr-2"
              :class="{ active: currentDay === 3 }"
              @click="currentDay = 3"
            >We</button>
          </li>
          <li class="nav-item">
            <button
              class="nav-link btn mr-2"
              :class="{ active: currentDay === 4 }"
              @click="currentDay = 4"
            >Th</button>
          </li>
          <li class="nav-item">
            <button
              class="nav-link btn mr-2"
              :class="{ active: currentDay === 5 }"
              @click="currentDay = 5"
            >Fr</button>
          </li>
          <li class="nav-item">
            <button
              class="nav-link btn mr-2"
              :class="{ active: currentDay === 6 }"
              @click="currentDay = 6"
            >Sa</button>
          </li>
        </ul>

        <!-- DISPLAY MEMUS -->
        <div class="menus">
          <b-card
            v-for="(menu, index) in point.diningOptions.days[currentDay].menus"
            v-bind:key="index"
            no-body
            class="mb-2"
          >
            <b-card-header
              header-tag="header"
              class="pt-2 pb-2 pl-3 pr-3 menu-header"
              role="tab"
            >
              <div
                class='d-flex justify-content-between align-items-center'
                v-b-toggle="'accordion-' + index"
              >
                <span>{{ menu.title }} | {{ menu.startTime | toTime }}</span>
                <span><i class="far fa-chevron-down"></i></span>
              </div>
            </b-card-header>
            <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <h3><span v-if="menu.startTime">{{ menu.startTime | toTime }}</span><span v-if="menu.endTime"> - {{ menu.endTime | toTime }}</span></h3>
                <div v-html="menu.body"></div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div><!-- /.menus -->
      </div><!-- /v-if view === 'menus' -->

    </b-card>
  </div><!-- /#point -->
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      embedCode: false,
      currentDay: 0,
      statuses: {
        Open: {
          en: 'Open',
          fr: 'Ouvert'
        },
        Caution: {
          en: 'Caution',
          fr: 'Attention'
        },
        Closed: {
          en: 'Closed',
          fr: 'Fermé'
        }
      }
    }
  },
  computed: {
    point: {
      get () {
        return this.$store.state.point
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'point', value: value })
      }
    }
  },
  created () {
    if (this.point.webCamUrl) {
      this.generateEmbedCode()
    }
    if (this.point.markerClass === 'dining') {
      this.currentDay = new Date().getDay()
    }
  },
  methods: {
    generateEmbedCode () {
      function getId (url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
        const match = url.match(regExp)
        return (match && match[2].length === 11)
          ? match[2]
          : null
      }
      const videoId = getId(this.point.webCamUrl)
      this.embedCode = '<iframe width="100%" height="350" src="//www.youtube.com/embed/' + videoId + '" frameborder="0" allowfullscreen></iframe>'
    },
    editPoint () {
      this.$store.commit('setStateProperty', { property: 'editMode', value: true })
      this.$store.commit('hideDrawer', 'pointDetails')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'createPoint')
      }, 300)
    },
    closePointDetails () {
      this.$store.dispatch('removePointHighlight')
      this.$store.commit('hideDrawer', 'pointDetails')
    }
  },
  filters: {
    formatDate (val) {
      if (!val) { return '-' }
      const date = val.toDate()
      return moment(date).format('MM/DD/YY - h:mm a')
    },
    toTime (value) {
      let hoursFormatted
      let amPm
      const hours = parseInt(value.substr(0, 2), 10)
      const minutes = value.substr(3, 2)
      if (hours < 13) {
        hoursFormatted = hours
        amPm = 'a.m.'
      } else {
        hoursFormatted = hours - 12
        amPm = 'p.m.'
      }
      return hoursFormatted + ':' + minutes + ' ' + amPm
    }
  }
}
</script>

<style scoped>
.menu-header {
  border-radius: 30px;
}

#pointDetails .nav-link {
  background-color: #8E7D69;
  border-color: #8E7D69;
  color: white;
}

#pointDetails .nav-link:hover {
  background-color: #0A5C72;
  border-color: #0A5C72;
}

#pointDetails .nav-link.active {
  background-color: #4BB0DC;
  border-color: #4BB0DC;
}
</style>

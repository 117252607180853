<template>
  <div id="confirm" class="o-modal">
    <div
      class="o-modal-content"
    >
      <p>{{ text }}</p>
      <button
        class="btn btn-danger mr-1"
        @click="$emit('confirmed', true)"
      >
        {{ language(1) }}
      </button>
      <button
        class="btn btn-secondary"
        @click="$emit('confirmed', false)"
      >
        {{ language(2) }}
      </button>
    </div><!-- /.o-modal-content -->
  </div><!-- /#confirm -->
</template>

<script>
export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Yes',
          fr: 'Oui'
        },
        2: {
          en: 'No',
          fr: 'Non'
        }
      }
    }
  },
  props: ['text'],
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    }
  }
}
</script>

<style scoped>
#confirm {
  text-align: center;
  z-index: 99;
}
</style>

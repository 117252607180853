<template>
  <div id="createUpdate">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.createUpdate.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="closeCreateUpdate()"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 v-if="$store.state.editMode">{{ language(1) }}</h4>
        <h4 v-if="!$store.state.editMode">{{ language(2) }}</h4>
      </template>
      <div
        v-if="mode === 'choose'"
      >
        <b-card
          v-for="(type, i) in updateTypes"
          v-bind:key="i"
          class="mb-3 standard-card"
        >
          <b-card-title>
            <i :class="type.iconClass"></i> {{ type[$store.state.trailSystem.language].label }}
          </b-card-title>
          <b-card-text>
            {{ type[$store.state.trailSystem.language].typeDescription }}
          </b-card-text>
          <b-button
            @click="chooseUpdateType(type)"
            variant="primary"
            class="w-100"
          >{{ language(4) }}</b-button>
        </b-card>
      </div>
      <b-form
        v-if="mode === 'configure'"
      >
        <b-btn
          @click="mode = 'choose'"
          class="mb-2 mr-1"
          size="sm"
        >
          <i class="fas fa-caret-left"></i> {{ language(3) }}
        </b-btn>
        <h4 class="mt-2 mb-0"><i :class="tempUpdate.iconClass"></i> {{ tempUpdate[$store.state.trailSystem.language].label }}</h4>
        <b-form>
          <div
            v-for="(field, i) in tempUpdate.fields"
            v-bind:key="i"
          >
            <label class="mt-3">{{ field[$store.state.trailSystem.language].label }} <small class="text-danger" v-if="field.required">*</small><br v-if="field[$store.state.trailSystem.language].help"><small v-if="field[$store.state.trailSystem.language].help">{{ field[$store.state.trailSystem.language].help }}</small></label>
            <b-form-select
              v-if="field.element === 'select'"
              v-model="tempUpdate[field.property]"
              :options="field.options"
            ></b-form-select>
            <b-form-textarea
              v-if="field.element === 'textArea'"
              v-model="tempUpdate[field.property]"
            ></b-form-textarea>
            <b-form-input
              v-if="field.element === 'text'"
              v-model="tempUpdate[field.property]"
            ></b-form-input>
            <b-form-input
              v-if="field.element === 'number'"
              v-model="tempUpdate[field.property]"
              type="number"
            ></b-form-input>
            <b-form-datepicker
              v-if="field.element === 'date'"
              v-model="tempUpdate[field.property]"
              class="mb-2"
              value-as-date
              reset-button
              :locale="$store.state.trailSystem.language"
              :placeholder="language(18)"
            ></b-form-datepicker>
            <b-form-timepicker
              v-if="field.element === 'time'"
              v-model="tempUpdate[field.property]"
              :locale="$store.state.trailSystem.language"
              class="mb-2"
              reset-button
              :placeholder="language(17)"
            ></b-form-timepicker>
            <multiselect
              v-if="field.element === 'multiSelect'"
              v-model="tempUpdate[field.property]"
              :options="field.options"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="true"
              :preserve-search="false"
              :placeholder="'Select ' + field.label"
              :label="$store.state.trailSystem.language || 'text'"
              track-by="value"
            >
            </multiselect>
          </div>

          <!-- UNIVERSAL FIELDS -->
          <label class="mt-3">{{ language(5) }} <small class="text-danger">*</small><br><small>{{ language(6) }}</small></label>
          <b-form-datepicker
            v-model="tempUpdate.expirationDate"
            class="mb-2"
            value-as-date
            reset-button
            :locale="$store.state.trailSystem.language"
            :placeholder="language(18)"
          ></b-form-datepicker>
          <b-form-timepicker
            v-model="tempUpdate.expirationTime"
            :locale="$store.state.trailSystem.language"
            class="mb-2"
            reset-button
            :placeholder="language(17)"
          ></b-form-timepicker>
          <b-form-checkbox class="mt-4" v-model="tempUpdate.attachToTrails" size="lg" switch>
            {{ language(7) }}
          </b-form-checkbox>
          <div v-if="tempUpdate.attachToTrails">
            <label class="mt-2">{{ language(8) }}</label>
            <multiselect
              v-model="tempUpdate.relevantTrails"
              :options="allTrails"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="true"
              :preserve-search="false"
              :placeholder="language(9)"
              label="name"
              track-by="trailId"
            >
            </multiselect>
            <b-btn
              v-if="tempUpdate.relevantTrails.length > 0"
              @click="clearTrails()"
              class="mt-2"
              size="sm"
              variant="warning"
            ><i class="fas fa-times-circle"></i> {{ language(11) }}</b-btn>
            <b-btn
              v-if="!allTrailsSelected"
              @click="selectAllTrails()"
              class="mt-2 mr-1"
              size="sm"
              variant="outline-secondary"
            ><i class="fas fa-check-double"></i> {{ language(10) }}</b-btn>
            <b-button
              v-for="(activity, i) in $store.state.trailSystem.activities"
              v-bind:key="i"
              class="mt-2 mr-1"
              variant="outline-secondary"
              @click="selectBy('activity', activity)"
              size="sm"
            ><i :class="activity.iconClass" class="activity-icon"></i> {{ language(4) }} {{ activity[$store.state.trailSystem.language] || activity.label }}</b-button>
            <b-button
              class="mt-2 mr-1"
              variant="outline-secondary"
              @click="selectBy('status', 'Open')"
              size="sm"
            ><i class="fas fa-check-circle text-success"></i> {{ language(19) }}</b-button>
            <b-button
              class="mt-2 mr-1"
              variant="outline-secondary"
              @click="selectBy('status', 'Caution')"
              size="sm"
            ><i class="fas fa-exclamation-circle text-warning"></i> {{ language(20) }}</b-button>
            <b-button
              class="mt-2 mr-1"
              variant="outline-secondary"
              @click="selectBy('status', 'Closed')"
              size="sm"
            ><i class="fas fa-times-circle text-danger"></i> {{ language(21) }}</b-button>
            <b-button
              class="mt-2 mr-1"
              variant="outline-secondary"
              @click="selectBy('difficulty', 'circle')"
              size="sm"
            ><span class="difficulty-marker circle"></span> {{ language(22) }}</b-button>
            <b-button
              class="mt-2 mr-1"
              variant="outline-secondary"
              @click="selectBy('difficulty', 'square')"
              size="sm"
            ><span class="difficulty-marker square"></span> {{ language(23) }}</b-button>
            <b-button
              class="mt-2 mr-1"
              variant="outline-secondary"
              @click="selectBy('difficulty', 'diamond')"
              size="sm"
            ><span class="difficulty-marker diamond"></span> {{ language(24) }}</b-button>
            <b-button
              class="mt-2 mr-1"
              variant="outline-secondary"
              @click="selectBy('difficulty', 'doubleDiamond')"
              size="sm"
            ><span class="difficulty-marker diamond"></span><span class="difficulty-marker diamond"></span> {{ language(25) }}</b-button>
            <b-button
              v-for="(category, i) in $store.state.trailSystem.categories"
              v-bind:key="category + i"
              class="mt-2 mr-1"
              variant="outline-secondary"
              @click="selectBy('category', category)"
              size="sm"
            >{{ category }}</b-button>

          </div>
          <b-form-checkbox class="mt-4" v-model="tempUpdate.notify" size="lg" switch v-if="!$store.state.editMode">
            <small>
              <i v-if="tempUpdate.notify" class="fas fa-exclamation-circle text-danger"></i> {{ language(12) }}<br>
              <span class="text-danger" v-if="tempUpdate.notify"> <i class="fas fa-exclamation-circle"></i> {{ language(28) }}<br></span>
              {{ language(13) }}
            </small>
          </b-form-checkbox>
        </b-form>
        <b-button variant="primary" class="mt-5 mr-1 w-100" @click="saveUpdate()">{{ language(14) }}</b-button>
        <b-button
          variant="danger"
          class="mt-3"
          v-if="$store.state.editMode"
          @click="confirmDelete = true"
        >{{ language(15) }}</b-button>
      </b-form>
    </b-card>

    <Confirm
      v-if="confirmDelete"
      :text="language(16)"
      v-on:confirmed="deleteUpdate($event)"
    />
  </div><!-- /#createUpdate -->
</template>

<script>
import Confirm from '../Confirm'
import Multiselect from 'vue-multiselect'

const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Edit Update',
          fr: 'Modifier la mise à jour'
        },
        2: {
          en: 'Create Update',
          fr: 'Créer une mise à jour'
        },
        3: {
          en: 'Back',
          fr: 'Précédent'
        },
        4: {
          en: 'Select',
          fr: 'Sélectionner'
        },
        5: {
          en: 'Expiration Date',
          fr: 'Date d’expiration'
        },
        6: {
          en: 'This update will disappear on the selected date. This can be changed later.',
          fr: 'Cette mise à jour disparaîtra à la date choisie. Ce paramètre peut être modifié ultérieurement.'
        },
        7: {
          en: 'Attach update to specific trails',
          fr: 'Associer la mise à jour à des sentiers particuliers.'
        },
        8: {
          en: 'Relevant Trails',
          fr: 'Sentiers pertinents'
        },
        9: {
          en: 'Select Trails',
          fr: 'Sélectionner les sentiers'
        },
        10: {
          en: 'Select All Trails',
          fr: 'Sélectionner tous les sentiers'
        },
        11: {
          en: 'Clear Selected Trails',
          fr: 'Effacer les sentiers sélectionnés'
        },
        12: {
          en: 'Notify Subscribers of this Update',
          fr: 'Notifier les abonnés de cette mise à jour'
        },
        13: {
          en: 'Sends an email and/or SMS message to anyone subscribed to this Trail System.',
          fr: 'Envoie un courriel et/ou un texto à toute personne abonnée à ce réseau de sentiers.'
        },
        14: {
          en: 'Save',
          fr: 'Enregistrer'
        },
        15: {
          en: 'Delete Update',
          fr: 'Supprimer la mise à jour'
        },
        16: {
          en: 'DANGER! Are you sure you want to delete this update? This cannot be undone!',
          fr: 'DANGER! Êtes-vous sûr de vouloir supprimer cette mise à jour ? Ça ne peut pas être annulé!'
        },
        17: {
          en: 'Choose a time',
          fr: 'Choisissez une heure'
        },
        18: {
          en: 'Choose a date',
          fr: 'Choisissez une date'
        },
        19: {
          en: 'Open',
          fr: 'Ouvert'
        },
        20: {
          en: 'Caution',
          fr: 'Attention'
        },
        21: {
          en: 'Closed',
          fr: 'Fermé'
        },
        22: {
          en: 'Beginner',
          fr: 'Débutant'
        },
        23: {
          en: 'Intermediate',
          fr: 'Intermédiaire'
        },
        24: {
          en: 'Expert',
          fr: 'Expert'
        },
        25: {
          en: 'Expert Only',
          fr: 'Expert seulement'
        },
        26: {
          en: 'Terrain Park',
          fr: 'Parc à neige'
        },
        27: {
          en: 'Not Rated',
          fr: 'Sans classement'
        },
        28: {
          en: 'Use this option sparingly! It is best to limit notifications to one or two per day as to not overwhelm trail users.',
          fr: 'Utilisez cette option avec parcimonie ! Il est préférable de limiter les notifications à une ou deux par jour pour ne pas submerger les utilisateurs du sentier.'
        }
      },
      allTrailsSelected: false,
      allTrails: [],
      mode: 'choose',
      confirmDelete: false,
      tempUpdate: {},
      updateTypes: [
        {
          type: 'notice', /* Universal */
          label: 'Notice', /* Universal */
          typeDescription: 'Used to create general notices and alerts.', /* Universal */
          en: {
            label: 'Notice',
            typeDescription: 'Used to create general notices and alerts.'
          },
          fr: {
            label: 'Avis',
            typeDescription: 'Utilisé pour créer des alertes et avis généraux.'
          },
          iconClass: 'fas fa-info-circle', /* Universal */
          attachToTrails: false, /* Universal */
          expirationDate: '', /* Universal */
          relevantTrails: [], /* Universal */
          description: '',
          severity: 'info',
          linkUrl: '',
          fields: [
            {
              element: 'textArea',
              property: 'description',
              label: 'Description',
              help: 'Content of the notice goes here.',
              en: {
                label: 'Description',
                help: 'Content of the notice goes here.'
              },
              fr: {
                label: 'La description',
                help: 'Le contenu de l’avis doit être placé ici.'
              },
              required: true
            },
            {
              element: 'select',
              property: 'severity',
              label: 'Severity',
              help: 'Choose the severity of this notice.',
              en: {
                label: 'Severity',
                help: 'Choose the severity of this notice.'
              },
              fr: {
                label: 'Gravité',
                help: 'Choisir le niveau de gravité de cet avis.'
              },
              required: true,
              options: [
                {
                  value: 'info',
                  text: 'Info',
                  en: 'Info',
                  fr: 'Info'
                },
                {
                  value: 'warning',
                  text: 'Warning',
                  en: 'Warning',
                  fr: 'Avertissement'
                },
                {
                  value: 'danger',
                  text: 'Danger',
                  en: 'Danger',
                  fr: 'Danger'
                }
              ]
            },
            {
              element: 'text',
              property: 'linkUrl',
              label: 'More Info Link',
              help: 'Example: https://google.com',
              en: {
                label: 'More Info Link',
                help: 'Example: https://google.com'
              },
              fr: {
                label: 'Lien vers plus d’information',
                help: 'Exemple : '
              },
              required: false
            }
          ]
        },
        {
          type: 'surfaceConditions', /* Universal */
          label: 'Surface Conditions', /* Universal */
          typeDescription: 'Post conditions of trail surfaces.', /* Universal */
          en: {
            label: 'Surface Conditions',
            typeDescription: 'Post conditions of trail surfaces.'
          },
          fr: {
            label: 'État de la surface',
            typeDescription: 'Publier l’état de la surface des sentiers.'
          },
          iconClass: 'fas fa-sign', /* Universal */
          attachToTrails: true, /* Universal */
          expirationDate: '', /* Universal */
          relevantTrails: [], /* Universal */
          conditions: [],
          fields: [
            {
              element: 'multiSelect',
              property: 'conditions',
              label: 'Conditions',
              help: 'Choose one or more conditions to post.',
              en: {
                label: 'Conditions',
                help: 'Choose one or more conditions to post.'
              },
              fr: {
                label: 'État',
                help: 'Choisissez une ou plusieurs conditions à publier.'
              },
              required: true,
              options: [
                {
                  value: 'compacted',
                  text: 'Compacted',
                  en: 'Compacted',
                  fr: 'Compacté',
                  iconClass: 'fas fa-snowflake'
                },
                {
                  value: 'very-compacted',
                  text: 'Very compacted',
                  en: 'Very compacted',
                  fr: 'Très compacté',
                  iconClass: 'fas fa-snowflake'
                },
                {
                  value: 'dry',
                  text: 'Dry',
                  en: 'Dry',
                  fr: 'Sèche',
                  iconClass: 'fas fa-sun-dust'
                },
                {
                  value: 'frozen-granular',
                  text: 'Frozen granular',
                  en: 'Frozen granular',
                  fr: 'Granuleuse gelée',
                  iconClass: 'fas fa-exclamation-circle'
                },
                {
                  value: 'groomed',
                  text: 'Groomed',
                  en: 'Groomed',
                  fr: 'Damée',
                  iconClass: 'fas fa-snowplow'
                },
                {
                  value: 'skateGroomed',
                  text: 'Skate Groomed',
                  en: 'Skate Groomed',
                  fr: 'Damée',
                  iconClass: 'fas fa-snowplow'
                },
                {
                  value: 'classicGroomed',
                  text: 'Classic Groomed',
                  en: 'Classic Groomed',
                  fr: 'Damée',
                  iconClass: 'fas fa-snowplow'
                },
                {
                  value: 'hard-packed',
                  text: 'Hard packed',
                  en: 'Hard Packed',
                  fr: 'Compactée',
                  iconClass: 'fas fa-exclamation-circle'
                },
                {
                  value: 'icy',
                  text: 'Icy',
                  en: 'Icy',
                  fr: 'Verglacée',
                  iconClass: 'fas fa-icicles'
                },
                {
                  value: 'leaf-covered',
                  text: 'Leaf Covered',
                  en: 'Leaf Covered',
                  fr: 'Feuilles au sol',
                  iconClass: 'fas fa-leaf-maple'
                },
                {
                  value: 'light-snow',
                  text: 'Light Snow',
                  en: 'Light Snow',
                  fr: 'Neige légère',
                  iconClass: 'fal fa-snowflake'
                },
                {
                  value: 'loose-granular',
                  text: 'Loose granular',
                  en: 'Loose Granular',
                  fr: 'Granuleuse légère',
                  iconClass: 'fas fa-exclamation-circle'
                },
                {
                  value: 'maintenance-in-progress',
                  text: 'Maintenance in Progress',
                  en: 'Maintenance in Progress',
                  fr: 'Entretien en cours',
                  iconClass: 'fas fa-snowplow'
                },
                {
                  value: 'muddy',
                  text: 'Muddy',
                  en: 'Muddy',
                  fr: 'Boueux',
                  iconClass: 'fas fa-exclamation-circle'
                },
                {
                  value: 'no-snow',
                  text: 'No snow',
                  en: 'No snow',
                  fr: 'Pas de neige',
                  iconClass: 'fas fa-exclamation-circle'
                },
                {
                  value: 'packed-powder',
                  text: 'Packed powder',
                  en: 'Packed powder',
                  fr: 'Poudreuse tassée',
                  iconClass: 'fas fa-exclamation-circle'
                },
                {
                  value: 'powder',
                  text: 'Powder',
                  en: 'Powder',
                  fr: 'Poudreuse',
                  iconClass: 'fas fa-snowflakes'
                },
                {
                  value: 'raked-leaves',
                  text: 'Raked Leaves',
                  en: 'Raked Leaves',
                  fr: 'Feuilles soufflées',
                  iconClass: 'fas fa-leaf-maple'
                },
                {
                  value: 'snow-machine-packed',
                  text: 'Snow Machine Packed',
                  en: 'Snow Machine Packed',
                  fr: 'Machine à neige emballée',
                  iconClass: 'fas fa-snowflake'
                },
                {
                  value: 'soft',
                  text: 'Soft',
                  en: 'Soft',
                  fr: 'Doux',
                  iconClass: 'fas fa-snowflake'
                },
                {
                  value: 'very-soft',
                  text: 'Very soft',
                  en: 'Very soft',
                  fr: 'Très doux',
                  iconClass: 'fas fa-snowflake'
                },
                {
                  value: 'track-set',
                  text: 'Track Set',
                  en: 'Track Set',
                  fr: 'Piste lissée',
                  iconClass: 'fas fa-skiing-nordic'
                },
                {
                  value: 'variable-conditions',
                  text: 'Variable conditions',
                  en: 'Variable conditions',
                  fr: 'Conditions variables',
                  iconClass: 'fas fa-exclamation-circle'
                },
                {
                  value: 'wet',
                  text: 'Wet',
                  en: 'Wet',
                  fr: 'Mouillée',
                  iconClass: 'fas fa-water'
                },
                {
                  value: 'wet-granular',
                  text: 'Wet granular',
                  en: 'Wet granular',
                  fr: 'Granuleuse mouillée',
                  iconClass: 'fas fa-exclamation-circle'
                },
                {
                  value: 'wet-packed',
                  text: 'Wet packed',
                  en: 'Wet packed',
                  fr: 'Mouillée compactée',
                  iconClass: 'fas fa-exclamation-circle'
                },
                {
                  value: 'wet-snow',
                  text: 'Wet snow',
                  en: 'Wet snow',
                  fr: 'Neige mouillée',
                  iconClass: 'fas fa-exclamation-circle'
                },
                {
                  value: 'windblown',
                  text: 'Windblown',
                  en: 'Windblown',
                  fr: 'Poudrerie',
                  iconClass: 'far fa-wind'
                }
              ]
            }
          ]
        },
        {
          type: 'snowReport', /* Universal */
          label: 'Snow Report', /* Universal */
          typeDescription: 'Update snow totals and conditions. Enter as much or little about recent snow totals as you like.', /* Universal */
          en: {
            label: 'Snow Report',
            typeDescription: 'Update snow totals and conditions. Enter as much or little about recent snow totals as you like.'
          },
          fr: {
            label: 'Bulletin de neige',
            typeDescription: 'Mettre à jour les totaux et conditions d’enneigement. Entrez autant de données que vous le souhaitez sur les récentes chutes de neige.'
          },
          iconClass: 'fas fa-snowflakes', /* Universal */
          attachToTrails: false, /* Universal */
          expirationDate: '', /* Universal */
          relevantTrails: [], /* Universal */
          lastSnowAmount: 0,
          lastSnowDate: '',
          lastSnowTime: '',
          // today: 0,
          twentyFourHours: 0,
          fourtyEightHours: 0,
          sevenDays: 0,
          seasonTotal: 0,
          baseDepth: 0,
          upperElevationDepth: 0,
          lastSnowMakingDate: '',
          fields: [
            {
              element: 'number',
              property: 'baseDepth',
              label: 'Base Depth',
              help: 'In inches/centimeters',
              en: {
                label: 'Base Depth',
                help: 'In inches/centimeters'
              },
              fr: {
                label: 'Profondeur de la base',
                help: 'En pouces/centimètres'
              },
              required: false
            },
            {
              element: 'number',
              property: 'twentyFourHours',
              label: '24 Hours',
              help: 'Amount of new snow in inches/centimeters in the last 24 hours.',
              en: {
                label: '24 Hours',
                help: 'Amount of new snow in inches/centimeters in the last 24 hours.'
              },
              fr: {
                label: '24 heures',
                help: 'Quantité de nouvelle neige en pouces/centimètres au cours des 24 dernières heures.'
              },
              required: false
            },
            {
              element: 'number',
              property: 'fourtyEightHours',
              label: '48 Hours',
              help: 'Amount of new snow in inches/centimeters in the last 48 hours.',
              en: {
                label: '48 Hours',
                help: 'Amount of new snow in inches/centimeters in the last 48 hours.'
              },
              fr: {
                label: '48 heures',
                help: 'Quantité de nouvelle neige en pouces/centimètres au cours des 48 dernières heures.'
              },
              required: false
            },
            {
              element: 'number',
              property: 'sevenDays',
              label: '7 Days',
              help: 'Amount of new snow in inches/centimeters in the last 7 days.',
              en: {
                label: '7 Days',
                help: 'Amount of new snow in inches/centimeters in the last 7 days.'
              },
              fr: {
                label: '7 jours',
                help: 'Quantité de nouvelle neige en pouces/centimètres au cours des 7 jours.'
              },
              required: false
            },
            {
              element: 'number',
              property: 'seasonTotal',
              label: 'Season Total',
              help: 'Amount of snow in inches/centimeters that has fallen this season.',
              en: {
                label: 'Season Total',
                help: 'Amount of snow in inches/centimeters that has fallen this season.'
              },
              fr: {
                label: 'Total de la saison',
                help: 'Quantité de neige en pouces/centimètres tombée cette saison.'
              },
              required: false
            },
            {
              element: 'number',
              property: 'lastSnowAmount',
              label: 'Last Snow Amount',
              help: 'Amount of snow in inches/centimeters that last fell.',
              en: {
                label: 'Last Snow Amount',
                help: 'Amount of snow in inches/centimeters that last fell.'
              },
              fr: {
                label: 'Dernière quantité de neige',
                help: 'Quantité de neige en pouces/centimètres tombée en dernier.'
              },
              required: false
            },
            {
              element: 'date',
              property: 'lastSnowDate',
              label: 'Last Snow Date',
              help: 'Date of the last snow fall.',
              en: {
                label: 'Last Snow Date',
                help: 'Date of the last snow fall.'
              },
              fr: {
                label: 'Date de la dernière neige',
                help: 'Date de la dernière chute de neige.'
              },
              required: false
            },
            {
              element: 'time',
              property: 'lastSnowTime',
              label: 'Last Snow Time',
              help: 'Time of the last snow fall.',
              en: {
                label: 'Last Snow Time',
                help: 'Time of the last snow fall.'
              },
              fr: {
                label: 'Jour/heure de la dernière neige',
                help: 'Jour/heure de la dernière chute de neige.'
              },
              required: false
            },
            {
              element: 'number',
              property: 'upperElevationDepth',
              label: 'Upper Elevation Depth',
              help: 'In inches/centimeters',
              en: {
                label: 'Upper Elevation Depth',
                help: 'In inches/centimeters'
              },
              fr: {
                label: 'Profondeur en haute altitude',
                help: 'En pouces/centimètres'
              },
              required: false
            },
            {
              element: 'date',
              property: 'lastSnowMakingDate',
              label: 'Last Snowmaking Date',
              help: 'Last date snow was made.',
              en: {
                label: 'Last Snowmaking Date',
                help: 'Last date snow was made.'
              },
              fr: {
                label: 'Date de la dernière fabrication de neige',
                help: 'Dernière date à laquelle de la neige a été fabriquée.'
              },
              required: false
            }
          ]
        }
      ]
    }
  },
  components: {
    Confirm,
    Multiselect
  },
  computed: {
    update: {
      get () {
        return this.$store.state.update
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'update', value: value })
      }
    }
  },
  mounted () {
    // Deal with proper language of labels
    if (this.$store.state.trailSystem.language !== 'en') {
      this.updateTypes.forEach(updateType => {
        updateType.fields.forEach(field => {
          if (field.options) {
            field.options.forEach(option => {
              option.text = option[this.$store.state.trailSystem.language]
            })
          }
        })
      })
    }
    this.init()
  },
  destroyed () {
    this.$store.commit('setStateProperty', { property: 'editMode', value: false })
  },
  methods: {
    selectBy (mode, option) {
      // this.tempUpdate.relevantTrails = []
      this.allTrails.forEach(trailItem => {
        let alreadySelected = false
        this.tempUpdate.relevantTrails.forEach(relevantTrail => {
          if (trailItem.name === relevantTrail.name) {
            alreadySelected = true
          }
        })
        if (!alreadySelected) {
          if (mode === 'status' && trailItem.status === option) {
            this.tempUpdate.relevantTrails.push(trailItem)
          } else if (mode === 'difficulty' && trailItem.difficulty === option) {
            this.tempUpdate.relevantTrails.push(trailItem)
          } else if (mode === 'activity') {
            trailItem.activities.forEach(trailActivity => {
              if (trailActivity.value === option.value) {
                this.tempUpdate.relevantTrails.push(trailItem)
              }
            })
          } else if (mode === 'category' && trailItem.categories) {
            trailItem.categories.forEach(trailCategory => {
              if (trailCategory === option) {
                this.tempUpdate.relevantTrails.push(trailItem)
              }
            })
          }
        }
      })
    },
    selectAllTrails () {
      this.tempUpdate.relevantTrails = this.allTrails
      this.allTrailsSelected = true
    },
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    clearTrails () {
      this.tempUpdate.relevantTrails = []
      this.allTrailsSelected = false
    },
    init () {
      if (this.$store.state.editMode) {
        this.tempUpdate = this.update
        this.tempUpdate.expirationDate = this.tempUpdate.expirationDate.toDate()
        this.tempUpdate.fields.forEach(field => {
          if (field.element === 'date' && this.tempUpdate[field.property]) {
            this.tempUpdate[field.property] = this.tempUpdate[field.property].toDate()
          }
        })
        this.mode = 'configure'
      }
      this.$store.state.trails.forEach(trail => {
        const trailObject = {
          activities: trail.properties.activities,
          categories: trail.properties.categories || [],
          difficulty: trail.properties.difficulty,
          name: trail.properties.name,
          status: trail.properties.status,
          trailId: trail.properties.trailId
        }
        this.allTrails.push(trailObject)
      })
    },
    chooseUpdateType (type) {
      const updateCreatedOn = this.tempUpdate.createdOn
      const updateCreatedBy = this.tempUpdate.createdBy
      const updateTrailSystemId = this.tempUpdate.trailSystemId
      this.tempUpdate = type
      if (updateCreatedOn) {
        this.tempUpdate.createdOn = updateCreatedOn
      }
      if (updateCreatedBy) {
        this.tempUpdate.createdBy = updateCreatedBy
      }
      if (updateTrailSystemId) {
        this.tempUpdate.trailSystemId = updateTrailSystemId
      }
      this.mode = 'configure'
    },
    closeCreateUpdate () {
      this.$store.commit('hideDrawer', 'createUpdate')
      setTimeout(() => {
        // Fetch Updates and show updates drawer
        this.$store.dispatch('fetchUpdates').then(() => {
          this.$store.commit('showDrawer', 'updates')
        })
      }, 300)
    },
    deleteUpdate (confirmed) {
      const updateId = this.$store.state.update.id
      if (confirmed) {
        this.confirmDelete = false
        fb.updatesCollection.doc(updateId).delete().then(() => {
          this.$store.commit('setStateProperty', { property: 'updates', value: [] })
          return fb.compileRequestsCollection.add({
            createdOn: new Date(),
            createdBy: this.$store.state.currentUser.uid,
            trailSystemId: this.$store.state.trailSystem.id,
            oldGeoJsonPath: this.$store.state.trailSystem.geoJsonPath
          })
          // return this.$store.dispatch('fetchUpdates')
        }).then(() => {
          this.closeCreateUpdate()
          this.$store.commit('setAlert', { active: true, message: 'Deleting Update!', level: 'alert-success', timeout: 4000 })
        }).catch(err => {
          console.log(err.message)
        })
      } else {
        this.confirmDelete = false
        return false
      }
    },
    saveUpdate () {
      let holdSave = false
      if (this.tempUpdate.expirationTime) {
        const hours = this.tempUpdate.expirationTime.slice(0, 2)
        const minutes = this.tempUpdate.expirationTime.slice(3, 5)
        this.tempUpdate.expirationDate.setHours(hours)
        this.tempUpdate.expirationDate.setMinutes(minutes)
      }
      this.tempUpdate.fields.forEach(field => {
        if (!this.tempUpdate[field.property] && field.required) {
          holdSave = true
        }
        // HANDLE NUMBERS... CONVERT FROM STRINGS TO NUMBERS
        if (this.tempUpdate[field.property] && field.element === 'number') {
          this.tempUpdate[field.property] = Number(this.tempUpdate[field.property])
        }
      })
      if (!this.tempUpdate.expirationDate) {
        this.$store.commit('setAlert', { active: true, message: 'Please enter an expiration date for this update.', level: 'alert-warning', timeout: 6000 })
      }
      if (holdSave) {
        this.$store.commit('setAlert', { active: true, message: 'Please fill in all required fields.', level: 'alert-warning', timeout: 6000 })
      } else if (this.tempUpdate.expirationDate.getTime() <= new Date().getTime() && !this.$store.state.editMode) {
        this.$store.commit('setAlert', { active: true, message: 'You must pick an expiration date in the future!', level: 'alert-warning', timeout: 6000 })
      } else {
        this.tempUpdate.updatedOn = new Date()
        this.tempUpdate.updatedBy = this.$store.state.currentUser.uid
        console.log(this.tempUpdate)
        if (this.$store.state.editMode) {
          fb.updatesCollection.doc(this.update.id).update(this.tempUpdate).then(ref => {
            return fb.compileRequestsCollection.add({
              createdOn: new Date(),
              createdBy: this.$store.state.currentUser.uid,
              trailSystemId: this.$store.state.trailSystem.id,
              oldGeoJsonPath: this.$store.state.trailSystem.geoJsonPath
            })
          }).then(() => {
            this.closeCreateUpdate()
            this.$store.commit('setAlert', { active: true, message: 'Updating update!', level: 'alert-success', timeout: 4000 })
          }).catch(err => {
            this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 6000 })
            console.log(err)
          })
        } else {
          this.tempUpdate.createdBy = this.$store.state.currentUser.uid
          this.tempUpdate.createdOn = new Date()
          this.tempUpdate.trailSystemId = this.$store.state.trailSystem.id
          fb.updatesCollection.add(this.tempUpdate).then(ref => {
            return fb.compileRequestsCollection.add({
              createdOn: new Date(),
              createdBy: this.$store.state.currentUser.uid,
              trailSystemId: this.$store.state.trailSystem.id,
              oldGeoJsonPath: this.$store.state.trailSystem.geoJsonPath
            })
          }).then(() => {
            this.closeCreateUpdate()
            this.$store.commit('setAlert', { active: true, message: 'Posting update!', level: 'alert-success', timeout: 4000 })
          }).catch(err => {
            console.log(err)
          })
        }
        // Update all relevant trails updatedOn and updatedBy
        this.tempUpdate.relevantTrails.forEach(trail => {
          fb.trailsCollection.doc(trail.trailId).update({
            updatedOn: new Date(),
            updatedBy: this.$store.state.currentUser.uid
          })
        })
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<template>
  <div id="trails">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.bulkEdit.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="closeBulkEdit()"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title">{{ language(1) }}</h4>
      </template>
      <label>{{ language(2) }}</label>
      <multiselect
        v-model="trailsToEdit"
        :options="allTrails"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="true"
        :preserve-search="false"
        :placeholder="language(3)"
        label="name"
        track-by="trailId"
      >
      </multiselect>
      <b-btn
        v-if="trailsToEdit.length > 0"
        @click="clearTrails()"
        class="mt-2 mr-1"
        size="sm"
        variant="warning"
      ><i class="fas fa-times-circle"></i> {{ language(33) }}</b-btn>
      <b-btn
        v-if="!allTrailsSelected"
        @click="selectAllTrails()"
        class="mt-2 mr-1"
        variant="outline-secondary"
        size="sm"
      ><i class="fas fa-check-double"></i> {{ language(4) }}</b-btn>
      <b-button
        v-for="(activity, i) in $store.state.trailSystem.activities"
        v-bind:key="i"
        class="mt-2 mr-1"
        variant="outline-secondary"
        @click="selectBy('activity', activity)"
        size="sm"
      ><i :class="activity.iconClass" class="activity-icon"></i> {{ language(14) }} {{ activity[$store.state.trailSystem.language] || activity.label }}</b-button>
      <b-button
        class="mt-2 mr-1"
        variant="outline-secondary"
        @click="selectBy('status', 'Open')"
        size="sm"
      ><i class="fas fa-check-circle text-success"></i> {{ language(7) }}</b-button>
      <b-button
        class="mt-2 mr-1"
        variant="outline-secondary"
        @click="selectBy('status', 'Caution')"
        size="sm"
      ><i class="fas fa-exclamation-circle text-warning"></i> {{ language(8) }}</b-button>
      <b-button
        class="mt-2 mr-1"
        variant="outline-secondary"
        @click="selectBy('status', 'Closed')"
        size="sm"
      ><i class="fas fa-times-circle text-danger"></i> {{ language(9) }}</b-button>
      <b-button
        class="mt-2 mr-1"
        variant="outline-secondary"
        @click="selectBy('difficulty', 'circle')"
        size="sm"
      ><span class="difficulty-marker circle"></span> {{ language(10) }}</b-button>
      <b-button
        class="mt-2 mr-1"
        variant="outline-secondary"
        @click="selectBy('difficulty', 'square')"
        size="sm"
      ><span class="difficulty-marker square"></span> {{ language(11) }}</b-button>
      <b-button
        class="mt-2 mr-1"
        variant="outline-secondary"
        @click="selectBy('difficulty', 'diamond')"
        size="sm"
      ><span class="difficulty-marker diamond"></span> {{ language(12) }}</b-button>
      <b-button
        class="mt-2 mr-1"
        variant="outline-secondary"
        @click="selectBy('difficulty', 'doubleDiamond')"
        size="sm"
      ><span class="difficulty-marker diamond"></span><span class="difficulty-marker diamond"></span> {{ language(13) }}</b-button>
      <b-button
        v-for="(category, i) in $store.state.trailSystem.categories"
        v-bind:key="category + i"
        class="mt-2 mr-1"
        variant="outline-secondary"
        @click="selectBy('category', category)"
        size="sm"
      >{{ category }}</b-button>
      <div class="mt-4">
        <label>{{ language(5) }}</label>
        <b-form-select v-model="valueToUpdate" :options="valueOptions"></b-form-select>
      </div>
      <!-- STATUS -->
      <label class="mt-4">{{ language(6) }}</label>
      <div v-if="valueToUpdate === 'status'">
        <b-button
          class="mr-1"
          variant="outline-secondary"
          v-if="status !== 'Open'"
          @click="status = 'Open'"
          size="sm"
        ><i class="fas fa-check-circle text-success"></i> {{ language(15) }}</b-button>
        <b-button
          class="mr-1"
          variant="secondary"
          v-if="status === 'Open'"
          @click="status = 'Open'"
          size="sm"
        ><i class="fas fa-check-circle text-success"></i> {{ language(15) }}</b-button>
        <b-button
          class="mr-1"
          variant="outline-secondary"
          v-if="status !== 'Caution'"
          @click="status = 'Caution'"
          size="sm"
        ><i class="fas fa-exclamation-circle text-warning"></i> {{ language(16) }}</b-button>
        <b-button
          class="mr-1"
          variant="secondary"
          v-if="status === 'Caution'"
          @click="status = 'Caution'"
          size="sm"
        ><i class="fas fa-exclamation-circle text-warning"></i> {{ language(16) }}</b-button>
        <b-button
          class="mr-1"
          variant="outline-secondary"
          v-if="status !== 'Closed'"
          @click="status = 'Closed'"
          size="sm"
        ><i class="fas fa-times-circle text-danger"></i> {{ language(17) }}</b-button>
        <b-button
          class="mr-1"
          variant="secondary"
          v-if="status === 'Closed'"
          @click="status = 'Closed'"
          size="sm"
        ><i class="fas fa-times-circle text-danger"></i> {{ language(17) }}</b-button>
        <b-button
          variant="outline-secondary"
          v-if="status !== 'None'"
          @click="status = 'None'"
          size="sm"
        >{{ language(38) }}</b-button>
        <b-button
          variant="secondary"
          v-if="status === 'None'"
          @click="status = 'None'"
          size="sm"
        >{{ language(38) }}</b-button>
      </div>
      <div v-if="valueToUpdate === 'difficulty'">
        <b-button
          class="mr-1 mb-1"
          variant="outline-secondary"
          v-if="difficulty !== 'circle'"
          @click="difficulty = 'circle'"
          size="sm"
        ><span class="difficulty-marker circle"></span> {{ language(18) }}</b-button>
        <b-button
          class="mr-1 mb-1"
          variant="secondary"
          v-if="difficulty === 'circle'"
          @click="difficulty = 'circle'"
          size="sm"
        ><span class="difficulty-marker circle"></span> {{ language(18) }}</b-button>
        <b-button
          class="mr-1 mb-1"
          variant="outline-secondary"
          v-if="difficulty !== 'square'"
          @click="difficulty = 'square'"
          size="sm"
        ><span class="difficulty-marker square"></span> {{ language(19) }}</b-button>
        <b-button
          class="mr-1 mb-1"
          variant="secondary"
          v-if="difficulty === 'square'"
          @click="difficulty = 'square'"
          size="sm"
        ><span class="difficulty-marker square"></span> {{ language(19) }}</b-button>
        <b-button
          class="mr-1 mb-1"
          variant="outline-secondary"
          v-if="difficulty !== 'diamond'"
          @click="difficulty = 'diamond'"
          size="sm"
        ><span class="difficulty-marker diamond"></span> {{ language(20) }}</b-button>
        <b-button
          class="mr-1 mb-1"
          variant="secondary"
          v-if="difficulty === 'diamond'"
          @click="difficulty = 'diamond'"
          size="sm"
        ><span class="difficulty-marker diamond"></span> {{ language(20) }}</b-button>
        <b-button
          class="mr-1 mb-1"
          variant="outline-secondary"
          v-if="difficulty !== 'doubleDiamond'"
          @click="difficulty = 'doubleDiamond'"
          size="sm"
        ><span class="difficulty-marker diamond"></span><span class="difficulty-marker diamond"></span> {{ language(21) }}</b-button>
        <b-button
          class="mr-1 mb-1"
          variant="secondary"
          v-if="difficulty === 'doubleDiamond'"
          @click="difficulty = 'doubleDiamond'"
          size="sm"
        ><span class="difficulty-marker diamond"></span><span class="difficulty-marker diamond"></span> {{ language(21) }}</b-button>
        <b-button
          class="mr-1 mb-1"
          variant="outline-secondary"
          v-if="difficulty !== 'terrainPark'"
          @click="difficulty = 'terrainPark'"
          size="sm"
        ><span class="difficulty-marker terrainPark"></span> {{ language(22) }}</b-button>
        <b-button
          class="mr-1 mb-1"
          variant="secondary"
          v-if="difficulty === 'terrainPark'"
          @click="difficulty = 'terrainPark'"
          size="sm"
        ><span class="difficulty-marker terrainPark"></span> {{ language(22) }}</b-button>
        <b-button
          class="mr-1 mb-1"
          variant="outline-secondary"
          v-if="difficulty !== 'notRated'"
          @click="difficulty = 'notRated'"
          size="sm"
        >{{ language(23) }}</b-button>
        <b-button
          class="mr-1 mb-1"
          variant="secondary"
          v-if="difficulty === 'notRated'"
          @click="difficulty = 'notRated'"
          size="sm"
        >{{ language(23) }}</b-button>
      </div>
      <!-- ACTIVITIES -->
      <div v-if="valueToUpdate === 'activities'">
        <!-- ACTIVITY UPDATE MODE -->
        <div>
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="activityUpdateMode !== 'add'"
            @click="activityUpdateMode = 'add'"
            size="sm"
          ><i class="fas fa-plus-circle text-success"></i> {{ language(29) }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="activityUpdateMode === 'add'"
            @click="activityUpdateMode = 'add'"
            size="sm"
          ><i class="fas fa-plus-circle text-success"></i> {{ language(29) }}</b-button>
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="activityUpdateMode !== 'remove'"
            @click="activityUpdateMode = 'remove'"
            size="sm"
          ><i class="fas fa-minus-circle text-danger"></i> {{ language(30) }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="activityUpdateMode === 'remove'"
            @click="activityUpdateMode = 'remove'"
            size="sm"
          ><i class="fas fa-minus-circle text-danger"></i> {{ language(30) }}</b-button>
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="activityUpdateMode !== 'update'"
            @click="activityUpdateMode = 'update'"
            size="sm"
          ><i class="fas fa-pencil-alt"></i> {{ language(31) }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="activityUpdateMode === 'update'"
            @click="activityUpdateMode = 'update'"
            size="sm"
          ><i class="fas fa-pencil-alt"></i> {{ language(31) }}</b-button>
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="activityUpdateMode !== 'override'"
            @click="activityUpdateMode = 'override'"
            size="sm"
          ><i class="fas fa-exclamation-circle text-danger"></i> {{ language(32) }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="activityUpdateMode === 'override'"
            @click="activityUpdateMode = 'override'"
            size="sm"
          ><i class="fas fa-exclamation-circle text-danger"></i> {{ language(32) }}</b-button>
        </div>
        <!-- OPTION TO ONLY OVERRIDE SELECTED ACTIVITIES -->
        <div>
          <!-- <b-form-checkbox
            v-model="overrideSelectedActivitiesOnly"
            name="override-checkbox"
            :value="true"
            :unchecked-value="false"
          >
            Only update selected activities<br><small>(BE CAREFUL: If unchecked, this will override all activities for the selected trails)</small>
          </b-form-checkbox> -->
        </div><!-- OVERRIDE OPTION -->
        <div v-if="activities" class="form-group mt-2">
          <div class="container">
            <div class="row" v-if="activities.length > 0">
              <div class="col-6">
                <label>{{ language(24) }}</label>
              </div><!-- /.col-md-8 -->
              <div class="col-4 text-center">
                <label>{{ language(25) }}</label>
              </div><!-- /.col-md-2 -->
              <div class="col-2">
                <label>{{ language(26) }}</label>
              </div><!-- /.col-md-2 -->
            </div><!-- /.row -->
            <div
              v-for="(activity, i) in activities"
              v-bind:key="i"
              class="row mb-2"
            >
              <div class="col-6">
                <i :class="activity.iconClass"></i> {{ activity[$store.state.trailSystem.language] || activity.label }}
              </div><!-- /.col-md-8 -->
              <div class="col-4 text-center">
                <div>
                  <b-button
                    class="mr-1"
                    variant="outline-secondary"
                    v-if="activities[i].status !== 'Open'"
                    @click="activities[i].status = 'Open'"
                    size="sm"
                  ><i class="fas fa-check-circle text-success"></i></b-button>
                  <b-button
                    class="mr-1"
                    variant="secondary"
                    v-if="activities[i].status === 'Open'"
                    @click="activities[i].status = 'Open'"
                    size="sm"
                  ><i class="fas fa-check-circle text-success"></i></b-button>
                  <b-button
                    class="mr-1"
                    variant="outline-secondary"
                    v-if="activities[i].status !== 'Caution'"
                    @click="activities[i].status = 'Caution'"
                    size="sm"
                  ><i class="fas fa-exclamation-circle text-warning"></i></b-button>
                  <b-button
                    class="mr-1"
                    variant="secondary"
                    v-if="activities[i].status === 'Caution'"
                    @click="activities[i].status = 'Caution'"
                    size="sm"
                  ><i class="fas fa-exclamation-circle text-warning"></i></b-button>
                  <b-button
                    variant="outline-secondary"
                    v-if="activities[i].status !== 'Closed'"
                    @click="activities[i].status = 'Closed'"
                    size="sm"
                  ><i class="fas fa-times-circle text-danger"></i></b-button>
                  <b-button
                    variant="secondary"
                    v-if="activities[i].status === 'Closed'"
                    @click="activities[i].status = 'Closed'"
                    size="sm"
                  ><i class="fas fa-times-circle text-danger"></i></b-button>
                </div>
              </div><!-- /.col-md-4 -->
              <div class="col-2 text-center">
                <i @click="activities.splice(i, 1)" class="fas fa-minus-circle text-danger clickable"></i>
              </div><!-- /.col-md-2 -->
            </div><!-- /.row -->
          </div><!-- /.container -->
        </div><!-- ACTIVITIES TABLE -->
        <b-input-group
          :prepend="language(27)"
          class="mt-2"
        >
          <b-form-select
            v-model="newActivity"
            :options="activityOptions"
          ></b-form-select>
          <b-input-group-append>
            <b-button
              @click="addActivity()"
              variant="success"
            >{{ language(28) }}</b-button>
          </b-input-group-append>
        </b-input-group>
      </div><!-- ACTIVITIES -->

      <!-- CATEGORIES -->
      <div v-if="valueToUpdate === 'categories'">
        <!-- CATEGORY UPDATE MODE -->
        <div>
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="categoryUpdateMode !== 'add'"
            @click="categoryUpdateMode = 'add'"
            size="sm"
          ><i class="fas fa-plus-circle text-success"></i> {{ language(29) }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="categoryUpdateMode === 'add'"
            @click="categoryUpdateMode = 'add'"
            size="sm"
          ><i class="fas fa-plus-circle text-success"></i> {{ language(29) }}</b-button>
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="categoryUpdateMode !== 'remove'"
            @click="categoryUpdateMode = 'remove'"
            size="sm"
          ><i class="fas fa-minus-circle text-danger"></i> {{ language(30) }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="categoryUpdateMode === 'remove'"
            @click="categoryUpdateMode = 'remove'"
            size="sm"
          ><i class="fas fa-minus-circle text-danger"></i> {{ language(30) }}</b-button>
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="categoryUpdateMode !== 'override'"
            @click="categoryUpdateMode = 'override'"
            size="sm"
          ><i class="fas fa-exclamation-circle text-danger"></i> {{ language(32) }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="categoryUpdateMode === 'override'"
            @click="categoryUpdateMode = 'override'"
            size="sm"
          ><i class="fas fa-exclamation-circle text-danger"></i> {{ language(32) }}</b-button>
        </div>
        <label class="mt-2">{{ language(36) }}</label>
        <Multiselect
          v-model="categories"
          :options="trailSystem.categories"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="true"
          :preserve-search="false"
          :placeholder="language(37)"
        />
      </div><!-- CATEGORIES -->

      <!-- TRAIL HIDDEN -->
      <div v-if="valueToUpdate === 'hidden'">
        <!-- HIDE FROM PUBLIC VIEW -->
        <b-form-checkbox v-model="hidden" name="check-button" switch class="mt-3">
          {{ language(34) }}<br><small>{{ language(35) }}</small>
        </b-form-checkbox>
      </div><!-- TRAIL HIDDEN -->

      <b-button
        variant="primary"
        class="mt-4 w-100"
        v-if="trailsToEdit.length > 0"
        @click="confirmEdit = true"
      >{{ language(1) }}</b-button>
    </b-card>
    <Confirm
      v-if="confirmEdit"
      text="Are you sure you want to edit all of these Trails?"
      v-on:confirmed="editTrails($event)"
    />
  </div><!-- /#bulk-edit -->
</template>

<script>
import Multiselect from 'vue-multiselect'
import Confirm from '../Confirm'
import activityOptions from '../../assets/lib/activityOptions.js'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Bulk Edit Trails',
          fr: 'Modifier des sentiers en bloc'
        },
        2: {
          en: 'Select Trails to Edit',
          fr: 'Sélectionner les sentiers à modifier'
        },
        3: {
          en: 'Select trails',
          fr: 'Sélectionner les sentiers'
        },
        4: {
          en: 'Select All',
          fr: 'Sélectionner tout'
        },
        5: {
          en: 'Select Value to Update',
          fr: 'Sélectionner la valeur à mettre à jour'
        },
        6: {
          en: 'New Value',
          fr: 'Nouvelle valeur'
        },
        7: {
          en: 'Select Open',
          fr: 'Sélectionner Ouvert'
        },
        8: {
          en: 'Select Caution',
          fr: 'Sélectionner Attention'
        },
        9: {
          en: 'Select Closed',
          fr: 'Sélectionner Fermé'
        },
        10: {
          en: 'Select Beginner',
          fr: 'Sélectionner Débutant'
        },
        11: {
          en: 'Select Intermediate',
          fr: 'Sélectionner Intermédiaire'
        },
        12: {
          en: 'Select Expert',
          fr: 'Sélectionner Expert'
        },
        13: {
          en: 'Select Expert Only',
          fr: 'Sélectionner Expert seulement'
        },
        14: {
          en: 'Select',
          fr: 'Sélectionner'
        },
        15: {
          en: 'Open',
          fr: 'Ouvert'
        },
        16: {
          en: 'Caution',
          fr: 'Attention'
        },
        17: {
          en: 'Closed',
          fr: 'Fermé'
        },
        18: {
          en: 'Beginner',
          fr: 'Débutant'
        },
        19: {
          en: 'Intermediate',
          fr: 'Intermédiaire'
        },
        20: {
          en: 'Expert',
          fr: 'Expert'
        },
        21: {
          en: 'Expert Only',
          fr: 'Expert seulement'
        },
        22: {
          en: 'Terrain Park',
          fr: 'Parc à neige'
        },
        23: {
          en: 'Not Rated',
          fr: 'Sans classement'
        },
        24: {
          en: 'Activity',
          fr: 'Activité'
        },
        25: {
          en: 'Status',
          fr: 'État'
        },
        26: {
          en: 'Remove',
          fr: 'Retirer'
        },
        27: {
          en: 'Choose',
          fr: 'Choisir'
        },
        28: {
          en: 'Add Activity',
          fr: 'Ajouter une activité'
        },
        29: {
          en: 'Add',
          fr: 'Ajouter'
        },
        30: {
          en: 'Remove',
          fr: 'Retirer'
        },
        31: {
          en: 'Update',
          fr: 'Mettre à jour'
        },
        32: {
          en: 'Override',
          fr: 'Passer outre'
        },
        33: {
          en: 'Clear Selected Trails',
          fr: 'Effacer les sentiers sélectionnés'
        },
        34: {
          en: 'Trail Hidden',
          fr: 'Sentier caché'
        },
        35: {
          en: 'This will hide this trail from public view.',
          fr: 'Cela cachera ce sentier à la vue du public.'
        },
        36: {
          en: 'Categories',
          fr: 'Catégories'
        },
        37: {
          en: 'Select Categories',
          fr: 'Sélectionnez les catégories'
        },
        38: {
          en: 'None',
          fr: 'Aucune'
        }
      },
      activityUpdateMode: 'update',
      categoryUpdateMode: 'add',
      categories: [],
      // overrideSelectedActivitiesOnly: true,
      confirmEdit: false,
      trailsToEdit: [],
      allTrails: [],
      allTrailsSelected: false,
      valueToUpdate: 'status',
      valueOptions: [
        {
          value: 'status',
          text: 'Status',
          en: 'Status',
          fr: 'État'
        },
        {
          value: 'difficulty',
          text: 'Difficulty',
          en: 'Difficulty',
          fr: 'Difficulté'
        },
        {
          value: 'activities',
          text: 'Activities',
          en: 'Activities',
          fr: 'Activités'
        },
        {
          value: 'categories',
          text: 'Categories',
          en: 'Categories',
          fr: 'Catégories'
        },
        {
          value: 'hidden',
          text: 'Trail Hidden',
          en: 'Trail Hidden',
          fr: 'Sentier caché'
        }
      ],
      status: 'Open',
      difficulty: 'circle',
      hidden: false,
      activities: [],
      newActivity: {},
      activityOptions: activityOptions
    }
  },
  components: {
    Multiselect,
    Confirm
  },
  computed: {
    trailSystem: {
      get () {
        return this.$store.state.trailSystem
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trailSystem', value: value })
      }
    }
  },
  created () {
    // Deal with proper language of labels
    if (this.$store.state.trailSystem.language !== 'en') {
      this.activityOptions.forEach(option => {
        option.text = option.value[this.$store.state.trailSystem.language]
      })
      this.valueOptions.forEach(option => {
        option.text = option[this.$store.state.trailSystem.language]
      })
    }
    this.init()
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    closeBulkEdit () {
      this.$store.commit('hideDrawer', 'bulkEdit')
      const self = this
      setTimeout(function () {
        self.$store.commit('showDrawer', 'trails')
      }, 300)
    },
    editTrails (confirmed) {
      this.confirmEdit = false
      const self = this
      async function updateTrail (trail) {
        console.log(trail)
        let activitiesUpdate = trail.activities
        let categoriesUpdate = trail.categories
        let difficultyUpdate = trail.difficulty
        let hiddenUpdate = trail.hidden || false
        let statusUpdate = trail.status
        if (self.valueToUpdate === 'activities') {
          // ADD
          if (self.activityUpdateMode === 'add') {
            console.log('Add')
            self.activities.forEach(activityItem => {
              let activityExists = false
              activitiesUpdate.forEach((trailActivityItem, i) => {
                if (activitiesUpdate[i].value === activityItem.value) {
                  activityExists = true
                }
              })
              if (!activityExists) {
                activitiesUpdate.push(activityItem)
              }
            })
          }
          // REMOVE
          if (self.activityUpdateMode === 'remove') {
            console.log('Remove')
            self.activities.forEach(activityItem => {
              for (let i = activitiesUpdate.length - 1; i >= 0; i--) {
                if (activitiesUpdate[i].value === activityItem.value) {
                  activitiesUpdate.splice(i, 1)
                }
              }
            })
          }
          // UPDATE
          if (self.activityUpdateMode === 'update') {
            console.log('Update')
            self.activities.forEach(activityItem => {
              activitiesUpdate.forEach((trailActivityItem, i) => {
                if (activitiesUpdate[i].value === activityItem.value) {
                  activitiesUpdate[i] = activityItem
                }
              })
            })
          }
          // OVERRIDE
          if (self.activityUpdateMode === 'override') {
            console.log('Override')
            activitiesUpdate = self.activities
          }
        } else if (self.valueToUpdate === 'difficulty') {
          difficultyUpdate = self.difficulty
        } else if (self.valueToUpdate === 'hidden') {
          hiddenUpdate = self.hidden
        } else if (self.valueToUpdate === 'status') {
          if (self.status === 'Closed') {
            activitiesUpdate.forEach((activity, i) => {
              activity.status = 'Closed'
            })
          }
          statusUpdate = self.status
        } else if (self.valueToUpdate === 'categories') {
          // ADD
          if (self.categoryUpdateMode === 'add') {
            console.log('Add')
            self.categories.forEach(categoryItem => {
              let categoryExists = false
              categoriesUpdate.forEach((trailCategoryItem, i) => {
                if (categoriesUpdate[i] === categoryItem) {
                  categoryExists = true
                }
              })
              if (!categoryExists) {
                categoriesUpdate.push(categoryItem)
              }
            })
          }
          // REMOVE
          if (self.categoryUpdateMode === 'remove') {
            console.log('Remove')
            self.categories.forEach(categoryItem => {
              for (let i = categoriesUpdate.length - 1; i >= 0; i--) {
                if (categoriesUpdate[i] === categoryItem) {
                  categoriesUpdate.splice(i, 1)
                }
              }
            })
          }
          // OVERRIDE
          if (self.categoryUpdateMode === 'override') {
            console.log('Override')
            categoriesUpdate = self.categories
          }
        }
        // CHECK TO MAKE SURE THAT THE TRAIL STATUS MAKES SENSE
        // console.log(statusUpdate)
        // console.log(activitiesUpdate)
        // let toggleStatusUpdate
        // if (statusUpdate === 'Open' || statusUpdate === 'Caution') {
        //   toggleStatusUpdate = true
        //   activitiesUpdate.forEach(activityUpdate => {
        //     if (activityUpdate.status === 'Open' || activityUpdate.status === 'Caution') {
        //       toggleStatusUpdate = false
        //     }
        //   })
        // } else if (statusUpdate === 'Closed') {
        //   toggleStatusUpdate = false
        //   activitiesUpdate.forEach(activityUpdate => {
        //     if (activityUpdate.status === 'Open' || activityUpdate.status === 'Caution') {
        //       toggleStatusUpdate = true
        //     }
        //   })
        // }
        // if ((toggleStatusUpdate && statusUpdate === 'Open') || (toggleStatusUpdate && statusUpdate === 'Caution')) {
        //   statusUpdate = 'Closed'
        // } else if (toggleStatusUpdate && statusUpdate === 'Closed') {
        //   statusUpdate = 'Open'
        // }

        await fb.trailsCollection.doc(trail.trailId).update({
          updatedOn: new Date(),
          updatedBy: self.$store.state.currentUser.uid,
          activities: activitiesUpdate,
          difficulty: difficultyUpdate,
          categories: categoriesUpdate,
          hidden: hiddenUpdate,
          status: statusUpdate
        }).then(() => {
          return false
        })
      }
      if (confirmed) {
        this.$store.commit('setAlert', { active: true, message: 'Updating trails...', level: 'alert-info', timeout: 6000 })
        const promises = []
        this.trailsToEdit.forEach(trail => {
          const prom = updateTrail(trail)
          promises.push(prom)
        })
        Promise.all(promises).then(() => {
          console.log('Compiling...')
          return fb.compileRequestsCollection.add({
            createdOn: new Date(),
            createdBy: this.$store.state.currentUser.uid,
            trailSystemId: this.$store.state.trailSystem.id,
            oldGeoJsonPath: this.$store.state.trailSystem.geoJsonPath
          })
        }).then(() => {
          this.$store.commit('setAlert', { active: true, message: 'Trails Updated!', level: 'alert-success', timeout: 6000 })
          this.closeBulkEdit()
        })
      }
    },
    addActivity () {
      let addThis = true
      if (!this.activities) {
        this.activities = []
      }
      if (!this.newActivity.value) {
        this.$store.commit('setAlert', { active: true, message: 'You must first select an activity from the dropdown!', level: 'alert-warning', timeout: 6000 })
        addThis = false
      }
      this.activities.forEach(activity => {
        if (activity.value === this.newActivity.value) {
          this.$store.commit('setAlert', { active: true, message: 'You already added that activity to this trail!', level: 'alert-warning', timeout: 6000 })
          addThis = false
        }
      })
      if (addThis) {
        this.activities.push(this.newActivity)
      }
      this.newActivity = {}
    },
    selectBy (mode, option) {
      // this.trailsToEdit = []
      this.allTrails.forEach(trailItem => {
        let alreadySelected = false
        this.trailsToEdit.forEach(relevantTrail => {
          if (trailItem.name === relevantTrail.name) {
            alreadySelected = true
          }
        })
        if (!alreadySelected) {
          if (mode === 'status' && trailItem.status === option) {
            this.trailsToEdit.push(trailItem)
          } else if (mode === 'difficulty' && trailItem.difficulty === option) {
            this.trailsToEdit.push(trailItem)
          } else if (mode === 'activity') {
            trailItem.activities.forEach(trailActivity => {
              if (trailActivity.value === option.value) {
                this.trailsToEdit.push(trailItem)
              }
            })
            // ALSO PRELOAD THAT ACTIVITY
            let activityAlreadySelected = false
            this.activities.forEach(selectedActivity => {
              if (selectedActivity.value === option.value) {
                activityAlreadySelected = true
              }
            })
            if (!activityAlreadySelected) {
              console.log('Not already selected')
              this.activityOptions.forEach(activityOption => {
                if (activityOption.value.value === option.value) {
                  this.activities.push(activityOption.value)
                  console.log(this.activities)
                }
              })
            }
          } else if (mode === 'category' && trailItem.categories) {
            trailItem.categories.forEach(trailCategory => {
              if (trailCategory === option) {
                this.trailsToEdit.push(trailItem)
              }
            })
          }
        }
      })
    },
    clearTrails () {
      this.trailsToEdit = []
      this.allTrailsSelected = false
      this.activities = []
    },
    selectAllTrails () {
      this.trailsToEdit = this.allTrails
      this.allTrailsSelected = true
    },
    init () {
      this.$store.state.trails.forEach(trail => {
        const trailObject = {
          activities: trail.properties.activities,
          categories: trail.properties.categories || [],
          difficulty: trail.properties.difficulty,
          name: trail.properties.name,
          status: trail.properties.status,
          trailId: trail.properties.trailId,
          hidden: trail.properties.hidden || false
        }
        this.allTrails.push(trailObject)
      })
    }
  }
}
</script>

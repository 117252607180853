<template>
  <div id="trailSystemNavbar">
    <b-navbar id="desktopNav" toggleable="lg">
      <b-navbar-brand>
        <span v-if="!$store.state.trailSystem.name">
          <i class="fas fa-sync fa-spin"></i>
        </span>
        <img
          @click="toggleDrawer('trailSystemDetails')"
          v-if="$store.state.showTSIcon"
          :src="$store.state.trailSystem.iconUrl"
          id="tsIcon"
          class="clickable"
        />
          <span
            @click="toggleDrawer('trailSystemDetails')"
            v-if="!$store.state.trailSystem.hideName"
            class="clickable"
            id="trailSystemNavbarTitle"
            :class="{ active: $store.state.drawer.trailSystemDetails.show }"
          >
            {{ $store.state.trailSystem.name }}
          </span>
        <span
          v-if="$store.state.trailSystem.weather && ($store.state.trailSystem.subscription === 'doubleDiamond' || $store.state.trailSystem.subscription === 'diamond' || $store.state.trailSystem.subscription === 'star' || $store.state.trailSystem.subscription === 'triangle')"
          class="ml-2 mr-2"
        > | </span>
        <span
          @click="toggleDrawer('weather')"
          v-if="$store.state.trailSystem.weather  && ($store.state.trailSystem.subscription === 'doubleDiamond' || $store.state.trailSystem.subscription === 'diamond' || $store.state.trailSystem.subscription === 'star' || $store.state.trailSystem.subscription === 'triangle')"
          id="currentConditions"
          class="clickable"
          :class="{ active: $store.state.drawer.weather.show }"
        ><i :class="getClass()"></i> {{ $store.state.trailSystem.weather.main.temp | formatTemp($store.state.trailSystem.tempMeasurement) }} <i class="fal fa-angle-right"></i></span>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav v-if="$store.state.trailSystem.name">
        <b-navbar-nav class="ml-auto">
          <b-nav-item
            @click="toggleDrawer('trails')"
            :class="{ active: $store.state.drawer.trails.show }"
          >{{ language(1) }}</b-nav-item>
          <!-- <b-nav-item
            v-if="$store.state.currentUser && $store.state.currentRole"
            @click="toggleDrawer('points')"
            :class="{ active: $store.state.drawer.points.show }"
          >{{ language(2) }}</b-nav-item> -->
          <b-nav-item
            @click="toggleDrawer('points')"
            :class="{ active: $store.state.drawer.points.show }"
          >{{ language(2) }}</b-nav-item>
          <b-nav-item
            v-if="$store.state.currentUser && $store.state.currentRole"
            @click="toggleUpdates()"
            :class="{ active: $store.state.drawer.updates.show }"
          >{{ language(3) }}</b-nav-item>
          <b-nav-item
            v-if="$store.state.trailSystem.calendars && $store.state.trailSystem.calendars.length > 0 && ($store.state.trailSystem.subscription === 'doubleDiamond' || $store.state.trailSystem.subscription === 'star')"
            @click="toggleDrawer('calendars')"
            :class="{ active: $store.state.drawer.calendars.show }"
          >{{ language(4) }}</b-nav-item>
          <b-nav-item-dropdown
            :text="language(12)"
            v-if="$store.state.trailSystem.relatedTrailSystems && $store.state.trailSystem.relatedTrailSystems.length > 0"
            right
          >
            <b-dropdown-item
              v-for="(relatedTrailSystem, i) in $store.state.trailSystem.relatedTrailSystems"
              v-bind:key="i"
              :href="'/ts/' + relatedTrailSystem.tsId"
              target="_blank"
            >
              <i class="fas fa-sign"></i> {{ relatedTrailSystem.name }}
            </b-dropdown-item>
            <b-dropdown-item href="/all" target="_blank" v-if="$store.state.trailSystem.promoteTrailHUB">{{ language(10) }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item href="/all" target="_blank" v-if="$store.state.trailSystem.promoteTrailHUB && $store.state.trailSystem.relatedTrailSystems && $store.state.trailSystem.relatedTrailSystems.length === 0">{{ language(11) }}</b-nav-item>
          <b-nav-item
            @click="toggleDrawer('share')"
            v-b-tooltip.hover.bottom="language(5)"
            :class="{ active: $store.state.drawer.share.show }"
          ><span class="hide-mobile"><i class="fas fa-share-square"></i></span><span class="show-mobile"> {{ language(5) }}</span></b-nav-item>
          <b-nav-item
            v-if="$store.state.trailSystemMode !== 'native'"
            @click="toggleDrawer('userSettings')"
            v-b-tooltip.hover.bottom="language(6)"
            :class="{ active: $store.state.drawer.userSettings.show }"
          ><span class="hide-mobile"><i class="fas fa-user-circle"></i></span><span class="show-mobile"> {{ language(6) }}</span></b-nav-item>
          <b-nav-item
            @click="toggleDrawer('trailSystemDetails')"
            v-b-tooltip.hover.bottom="language(8)"
            :class="{ active: $store.state.drawer.trailSystemDetails.show }"
          ><span class="hide-mobile"><i class="fas fa-info-circle"></i></span><span class="show-mobile"> {{ language(8) }}</span></b-nav-item>
          <b-nav-item
            href="https://support.trailhub.org"
            target="_blank"
            v-b-tooltip.hover.bottom="language(7)"
          ><span class="hide-mobile"><i class="fas fa-question-circle"></i></span><span class="show-mobile"> {{ language(7) }}</span></b-nav-item>

          <!-- <b-nav-item-dropdown text="Manage" right>
            <b-dropdown-item
              @click="toggleDrawer('share')"
              v-b-tooltip.hover.bottom="language(5)"
              :class="{ active: $store.state.drawer.share.show }"
            ><i class="fas fa-share-square"></i> Share</b-dropdown-item>
            <b-dropdown-item href="#">ES</b-dropdown-item>
          </b-nav-item-dropdown> -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <nav id="mobileNav">
      <div
        v-if="$store.state.trailSystemMode && $store.state.trailSystemMode !== 'native'"
        class="mobilenav-title"
      >
        <span v-if="!$store.state.trailSystem.name">
          <i class="fas fa-sync fa-spin"></i>
        </span>
        <img
          v-if="$store.state.showTSIcon"
          :src="$store.state.trailSystem.iconUrl"
        />
        <span
          v-if="!$store.state.trailSystem.hideName"
        >
          {{ $store.state.trailSystem.name }}
        </span>
      </div><!-- ./mobilenav-title -->
      <div class="mobilenav-wrapper">
        <ul class="mobilenav-items">
          <li
            @click="toggleDrawer('trails')"
            :class="{ active: $store.state.drawer.trails.show }"
          >{{ language(1) }}</li>
          <li
            @click="toggleDrawer('points')"
            :class="{ active: $store.state.drawer.points.show }"
          >{{ language(2) }}</li>
          <li
            v-if="$store.state.trailSystem.weather && ($store.state.trailSystem.subscription === 'doubleDiamond' || $store.state.trailSystem.subscription === 'diamond' || $store.state.trailSystem.subscription === 'star' || $store.state.trailSystem.subscription === 'triangle')"
            @click="toggleDrawer('weather')"
            :class="{ active: $store.state.drawer.weather.show }"
          >{{ language(9) }}</li>
          <li
            @click="toggleDrawer('trailSystemDetails')"
            :class="{ active: $store.state.drawer.trailSystemDetails.show }"
          >{{ language(8) }}</li>
          <li
            v-if="$store.state.currentUser && $store.state.currentRole"
            @click="toggleUpdates()"
            :class="{ active: $store.state.drawer.updates.show }"
          >{{ language(3) }}</li>
          <li
            v-if="$store.state.trailSystem.calendars && $store.state.trailSystem.calendars.length > 0 && ($store.state.trailSystem.subscription === 'doubleDiamond' || $store.state.trailSystem.subscription === 'star')"
            @click="toggleDrawer('calendars')"
            :class="{ active: $store.state.drawer.calendars.show }"
          >{{ language(4) }}</li>
          <li
            @click="toggleDrawer('share')"
            :class="{ active: $store.state.drawer.share.show }"
          >{{ language(5) }}</li>
          <li
            v-if="$store.state.trailSystemMode !== 'native'"
            @click="toggleDrawer('userSettings')"
            :class="{ active: $store.state.drawer.userSettings.show }"
          >{{ language(6) }}</li>
          <li
            v-if="$store.state.trailSystemMode !== 'native'"
          >
            <a
              href="https://support.trailhub.org"
              target="_blank"
            >{{ language(7) }}</a>
          </li>
          <li
            v-for="(relatedTrailSystem, i) in $store.state.trailSystem.relatedTrailSystems"
            v-bind:key="i"
          >
            <a
              :href="'/ts/' + relatedTrailSystem.tsId"
              target="_blank"
            ><i class="fas fa-sign"></i> {{ relatedTrailSystem.name }}</a>
          </li>
          <li
            v-if="$store.state.trailSystem.promoteTrailHUB"
          >
            <a
              href="/all"
              target="_blank"
            >{{ language(11) }}</a>
          </li>
        </ul>
        <div id="rightIndicator"><i class="fas fa-chevron-right"></i></div>
      </div><!-- /.mobilenav-wrapper -->
    </nav><!-- /#mobileNav -->
  </div><!-- /#trailSystemNavbar -->
</template>

<script>
export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Trails',
          fr: 'Sentiers'
        },
        2: {
          en: 'Points',
          fr: 'Points'
        },
        3: {
          en: 'Updates',
          fr: 'Mises à jour'
        },
        4: {
          en: 'Calendars',
          fr: 'Calendriers'
        },
        5: {
          en: 'Share',
          fr: 'Partager'
        },
        6: {
          en: 'User Settings',
          fr: 'Paramètres de l’utilisateur'
        },
        7: {
          en: 'Help',
          fr: 'Aide'
        },
        8: {
          en: 'Trail System Details',
          fr: 'Détails du réseau de sentiers'
        },
        9: {
          en: 'Weather',
          fr: 'Météo'
        },
        10: {
          en: 'All Trail Systems',
          fr: 'Systèmes de sentiers'
        },
        11: {
          en: 'All Trail Systems',
          fr: 'Systèmes de sentiers'
        },
        12: {
          en: 'Trail Systems',
          fr: 'Systèmes de sentiers'
        }
      }
    }
  },
  methods: {
    toggleUpdates () {
      this.$store.commit('hideAllDrawers')
      setTimeout(() => {
        this.$store.dispatch('fetchUpdates').then(() => {
          this.$store.commit('showDrawer', 'updates')
        })
      }, 300)
    },
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'english'
      return this.phrases[id][lang]
    },
    toggleDrawer (drawer) {
      this.$store.commit('hideAllDrawers')
      setTimeout(() => {
        this.$store.commit('showDrawer', drawer)
      }, 300)
    },
    getClass () {
      const iconCode = this.$store.state.trailSystem.weather.weather[0].icon
      let iconClass
      if (iconCode === '01d') { iconClass = 'fal fa-sun' }
      if (iconCode === '01n') { iconClass = 'fas fa-moon-stars' }
      if (iconCode === '02d') { iconClass = 'fal fa-cloud-sun' }
      if (iconCode === '02n') { iconClass = 'fas fa-cloud-moon' }
      if (iconCode === '03d' || iconCode === '03n') { iconClass = 'fal fa-cloud' }
      if (iconCode === '04d' || iconCode === '04n') { iconClass = 'fal fa-clouds' }
      if (iconCode === '09d' || iconCode === '09n') { iconClass = 'fas fa-cloud-showers-heavy' }
      if (iconCode === '10d') { iconClass = 'fas fa-cloud-sun-rain' }
      if (iconCode === '10n') { iconClass = 'fas fa-cloud-moon-rain' }
      if (iconCode === '11d' || iconCode === '11n') { iconClass = 'fas fa-thunderstorm' }
      if (iconCode === '13d' || iconCode === '13n') { iconClass = 'fal fa-snowflake' }
      if (iconCode === '50d' || iconCode === '50n') { iconClass = 'far fa-fog' }
      return iconClass
    }
  },
  filters: {
    formatTemp (val, unit) {
      let output
      if (unit === 'fahrenheit') {
        output = ((val * 1.8) + 32).toFixed(0) + '°F'
      } else {
        output = val.toFixed(0) + '°C'
      }
      return output
    }
  }
}
</script>

<style scoped>
#trailSystemNavbar {
  position: relative;
  z-index: 12;
}

#trailSystemNavbarTitle:hover {
  color: #4BB0DC;
}

#tsIcon {
  height: 38px;
  border-radius: 50px;
  margin-top: -15px;
  margin-right: 8px;
  margin-bottom: -11px;
}

/* #currentConditions {
  color: #4BB0DC;
} */

#currentConditions:hover {
  color: #4BB0DC;
}

#currentConditions img {
  height: 28px;
}

#mobileNav {
  color: white;
  display: none;
  /* height: 40px; */
  z-index: 9;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.mobilenav-title {
  text-align: center;
  background-color: #F5F5F5;
  padding: 2px;
  color: #333;
}

.mobilenav-title img {
  height: 21px;
}

.mobilenav-wrapper {
  position: relative;
  font-weight: bold;
  height: 100%;
  z-index: 1;
  height: 40px;
  background-color: #8E7D69;
}
.mobilenav-items {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  list-style: none;
  padding-left: 0;
}

.mobilenav-items::-webkit-scrollbar {
  display: none;
}

.mobilenav-items li:first-child {
  margin-left: 10px;
}

.mobilenav-items li {
  display: inline-block;
  padding: 8px 20px;
  border-radius: 100px;
  cursor: pointer;
}

.mobilenav-items li a {
  color: white;
}

.mobilenav-items li.active {
  color: white;
  background-color: #0D4858;
}

@media(max-width: 800px) {
  #desktopNav {
    display: none;
  }

  #mobileNav {
    display: block;
  }

  #rightIndicator {
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    background: linear-gradient(90deg, rgba(142,125,105,0) 0%, rgba(142,125,105,0.8) 40%, rgba(142,125,105,1) 100%);
    z-index: 9;
    color: #eee;
    padding: 8px 10px 9px 15px;
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase'
import TrailSystem from '@/components/trailSystem/TrailSystem'
import Signage from '@/components/signage/Signage'
import Login from '@/components/user/Login'
import PublicLogin from '@/components/user/PublicLogin'
import Script from '@/components/Script'
import CustomerImport from '@/components/CustomerImport'
import Print from '@/components/Print'
// import Docs from '@/components/Docs'
import Admin from '@/components/admin/Admin'
import Audit from '@/components/admin/Audit'
import Home from '@/components/home/Home'
import AllSystems from '@/components/allSystems/AllSystems'
import LegacyWidget from '@/components/LegacyWidget'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login/:mode?/:redirect?',
    name: 'Login',
    component: Login
  },
  {
    path: '/public-login/:mode?/:redirect?',
    name: 'PublicLogin',
    component: PublicLogin
  },
  {
    path: '/all',
    name: 'AllSystems',
    component: AllSystems
  },
  {
    path: '/script',
    name: 'Script',
    component: Script
  },
  {
    path: '/customer-import',
    name: 'CustomerImport',
    component: CustomerImport
  },
  {
    path: '/print/:id?',
    name: 'Print',
    component: Print
  },
  {
    path: '/ts/:id?/:mode?/:embedMode?',
    name: 'TrailSystem',
    component: TrailSystem
  },
  {
    path: '/signage/:id?',
    name: 'Signage',
    component: Signage
  },
  // {
  //   path: '/docs/:id?',
  //   name: 'Docs',
  //   component: Docs
  // },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/audit',
    name: 'Audit',
    component: Audit
  },
  {
    path: '/trail/embedtraillistwidget',
    name: 'LegacyWidget',
    component: LegacyWidget
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const currentUser = firebase.auth().currentUser

  if (requiresAuth && !currentUser) {
    next('/login')
  } else if (requiresAuth && currentUser) {
    next()
  } else {
    next()
  }
})

export default router

<template>
  <div id="updates">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.updates.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="$store.commit('hideDrawer', 'updates')"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title">{{ language(1) }}</h4>
        <b-btn
          @click="createUpdate()"
          class="mb-2 mr-1"
          v-if="$store.state.currentUser"
          variant="success"
        >
          <i class="far fa-plus"></i> {{ language(2) }}
        </b-btn>
      </template>

      <div>
        <div v-if="updates.length < 1">
          <div class="alert alert-success text-center">
            <h5>{{ language(3) }}</h5>
            <b-btn
              @click="createUpdate()"
              variant="success"
            >{{ language(4) }}</b-btn>
          </div><!-- /.alert -->
        </div>
        <div v-if="updates.length > 0">
          <h4 class="mb-3">{{ language(6) }}</h4>
          <div
            v-for="(update, i) in $store.state.updates"
            v-bind:key="i"
            class="card mb-3 p-3"
          >
            <div class="d-flex w-100 justify-content-between">
              <p class="mb-1">
                <strong>
                  <span><i :class="update.iconClass"></i> {{ language(update.label) }}</span>
                </strong>
              </p>
              <p class="text-success" v-if="update.expirationDate">{{ language(8) }} {{ formatDate(update.expirationDate) }}</p>
            </div>
            <Notice :update="update" v-if="update.type === 'notice'" />
            <SnowReport :update="update" v-if="update.type === 'snowReport'" />
            <SurfaceConditions :update="update" v-if="update.type === 'surfaceConditions'" />
            <div v-if="update.attachToTrails">
              <p>Attached to:<br>
                <b-badge
                  v-for="(trail, i) in update.relevantTrails"
                  v-bind:key="i"
                  class="mr-1"
                  pill
                >
                  {{ trail.name }}
                </b-badge>
              </p>
            </div>
            <b-btn
              @click="editUpdate(update)"
              class="float-right mt-2"
              size="sm"
            >
              <i class="fas fa-edit"></i> {{ language(9) }}
            </b-btn>
          </div>
        </div><!-- /v-if more than 0 updates -->
      </div><!--  -->
      <!-- EXPIRED UPDATES -->
      <div v-if="expiredUpdates.length >= 1">
        <h4 class="mb-3">{{ language(12) }} <small><i class="fas fa-times-circle clickable text-danger" @click="$store.commit('setStateProperty', { property: 'expiredUpdates', value: [] })"></i></small></h4>
        <div
          v-for="(update, i) in $store.state.expiredUpdates"
          v-bind:key="i"
          class="card mb-3 p-3"
        >
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-1">
              <strong>
                <span><i :class="update.iconClass"></i> {{ language(update.label) }}</span>
              </strong>
            </p>
            <p class="text-danger" v-if="update.expirationDate">{{ language(13) }} {{ formatDate(update.expirationDate) }}</p>
          </div>
          <Notice :update="update" v-if="update.type === 'notice'" />
          <SnowReport :update="update" v-if="update.type === 'snowReport'" />
          <SurfaceConditions :update="update" v-if="update.type === 'surfaceConditions'" />
          <div v-if="update.relevantTrails.length > 0">
            <p>Attached to:<br>
              <b-badge
                v-for="(trail, i) in update.relevantTrails"
                v-bind:key="i"
                class="mr-1"
                pill
              >
                {{ trail.name }}
              </b-badge>
            </p>
          </div>
          <b-btn
            @click="editUpdate(update)"
            class="float-right mt-2"
            size="sm"
          >
            <i class="fas fa-edit"></i> {{ language(9) }}
          </b-btn>
        </div>
      </div><!-- /.list-group -->
      <b-btn
        @click="showExpiredUpdates()"
        class="w-100"
      ><i class="fas fa-plus"></i> {{ language(5) }}</b-btn>
    </b-card>
  </div><!-- /#updates -->
</template>

<script>
import Notice from './templates/Notice'
import SnowReport from './templates/SnowReport'
import SurfaceConditions from './templates/SurfaceConditions'
import moment from 'moment'
import 'moment/locale/es'

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Updates',
          fr: 'Mises à jour'
        },
        2: {
          en: 'Post Update',
          fr: 'Créer une mise à jour'
        },
        3: {
          en: 'No Active Updates',
          fr: 'Aucune mise à jour active'
        },
        4: {
          en: 'Create Your First Update',
          fr: 'Créer votre première mise à jour'
        },
        5: {
          en: 'More Expired Updates',
          fr: 'Autres mises à jour expirées'
        },
        6: {
          en: 'Active Updates',
          fr: 'Mises à jour actives'
        },
        Notice: {
          en: 'Notice',
          fr: 'Avis'
        },
        8: {
          en: 'Expires',
          fr: 'Expire'
        },
        9: {
          en: 'Edit Update',
          fr: 'Modifier la mise à jour'
        },
        'Snow Report': {
          en: 'Snow Report',
          fr: 'Bulletin d’enneigement'
        },
        'Surface Conditions': {
          en: 'Surface Conditions',
          fr: 'État de la surface'
        },
        12: {
          en: 'Expired Updates',
          fr: 'Mises à jour expirées'
        },
        13: {
          en: 'Expired',
          fr: 'Expiré il y a 3 jours'
        }
      },
      expiredUpdatesLimit: 10
    }
  },
  components: {
    Notice,
    SnowReport,
    SurfaceConditions
  },
  computed: {
    updates: {
      get () {
        return this.$store.state.updates
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'updates', value: value })
      }
    },
    expiredUpdates: {
      get () {
        return this.$store.state.expiredUpdates
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'expiredUpdates', value: value })
      }
    }
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    showExpiredUpdates () {
      this.$store.dispatch('fetchExpiredUpdates', this.expiredUpdatesLimit)
      this.expiredUpdatesLimit = this.expiredUpdatesLimit + 10
    },
    editUpdate (update) {
      this.$store.commit('hideDrawer', 'updates')
      this.$store.commit('setStateProperty', { property: 'editMode', value: true })
      this.$store.commit('setStateProperty', { property: 'update', value: update })
      setTimeout(() => {
        this.$store.commit('showDrawer', 'createUpdate')
      }, 300)
    },
    createUpdate () {
      this.$store.commit('hideDrawer', 'updates')
      setTimeout(() => {
        this.$store.commit('setStateProperty', { property: 'editMode', value: false })
        this.$store.commit('showDrawer', 'createUpdate')
      }, 300)
    },
    formatDate (val) {
      moment.locale(this.$store.state.trailSystem.language)
      if (!val || !val.seconds) {
        return '-'
      } else {
        const date = val.toDate()
        return moment(date).fromNow()
      }
    }
  }
}
</script>

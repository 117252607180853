<template>
  <div id="createTrailSystem">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.createTrailSystem.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="$store.commit('hideDrawer', 'createTrailSystem')"
          v-if="$store.state.trailSystems.length > 0"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 v-if="$store.state.editMode">{{ language(25) }}</h4>
        <h4 v-if="!$store.state.editMode">{{ language(24) }}</h4>
      </template>
      <div v-if="$store.state.trailSystems.length < 1">
        <p class="alert alert-info">{{ language(26) }}</p>
      </div>
      <b-form>
        <div class="card p-2 mb-2 bg-light" v-if="$store.state.userProfile.superAdmin">
          <p class="text-center"><strong>Admin Only</strong></p>
          <!-- OVERRIDE BILLING AND KEEP ACTIVE -->
          <b-form-checkbox
            v-model="tempTrailSystem.override"
            name="check-button"
            switch
            class="mb-1 text-success"
          >
            <strong>OVERRIDE BILLING AND KEEP ACTIVE</strong>
          </b-form-checkbox>
          <!-- BLOCK TRAIL SYSTEM -->
          <b-form-checkbox
            v-model="tempTrailSystem.blocked"
            name="check-button"
            switch
            class="mb-1 text-danger"
          >
            <strong>BLOCK TRAIL SYSTEM</strong>
          </b-form-checkbox>
        </div><!-- /ADMIN SETTINGS -->
        <label>{{ language(1) }}</label>
        <b-form-input v-model="tempTrailSystem.name"></b-form-input>
        <label class="mt-2">{{ language(27) }}</label>
        <b-form-textarea v-model="tempTrailSystem.description"></b-form-textarea>
        <label class="mt-2">{{ language(2) }}</label>
        <b-form-input v-model="tempTrailSystem.phoneNumber"></b-form-input>
        <label class="mt-2">{{ language(3) }}</label>
        <b-form-input v-model="tempTrailSystem.website"></b-form-input>

        <label class="mt-2">{{ language(4) }}</label>
        <ImageUpload
          v-on:fileDelivery="deliverIconPath($event)"
        />
        <!-- Hide TS Name -->
        <b-form-checkbox v-model="tempTrailSystem.hideName" name="check-button" switch class="mt-3">
          {{ language(5) }}<br><small>{{ language(6) }}</small>
        </b-form-checkbox>
        <b-form-group :label="language(7)" class="mt-3">
          <b-form-radio v-model="tempTrailSystem.depthMeasurement" value="centimeters">{{ language(8) }}</b-form-radio>
          <b-form-radio v-model="tempTrailSystem.depthMeasurement" value="inches">{{ language(9) }}</b-form-radio>
        </b-form-group>
        <b-form-group :label="language(10)" class="mt-3">
          <b-form-radio v-model="tempTrailSystem.lengthMeasurement" value="kilometers">{{ language(11) }}</b-form-radio>
          <b-form-radio v-model="tempTrailSystem.lengthMeasurement" value="miles">{{ language(12) }}</b-form-radio>
        </b-form-group>
        <b-form-group :label="language(13)" class="mt-3">
          <b-form-radio v-model="tempTrailSystem.tempMeasurement" value="celsius">{{ language(14) }}</b-form-radio>
          <b-form-radio v-model="tempTrailSystem.tempMeasurement" value="fahrenheit">{{ language(15) }}</b-form-radio>
        </b-form-group>
        <b-form-group :label="language(28)" class="mt-3">
          <b-form-radio v-model="tempTrailSystem.language" value="en">English</b-form-radio>
          <b-form-radio v-model="tempTrailSystem.language" value="fr">Français</b-form-radio>
        </b-form-group>

        <!-- HIDE ACTIVITIES -->
        <div v-if="$store.state.editMode && hiddenActivities && tempTrailSystem.activities">
          <label>{{ language(33) }}<br><small>{{ language(34) }}</small></label>
          <Multiselect
            v-model="hiddenActivities"
            :options="tempTrailSystem.activities"
            :multiple="true"
            :close-on-select="false"
            :preserve-search="false"
            :placeholder="language(33)"
            :label="tempTrailSystem.language"
            track-by="value"
          />
        </div><!-- /v-if -->

        <!-- DEFAULT ACTIVITY -->
        <div v-if="$store.state.editMode && tempTrailSystem.activities" class="mt-2">
          <label>{{ language(35) }}<br><small>{{ language(36) }}</small></label>
          <Multiselect
            v-model="defaultActivity"
            :options="tempTrailSystem.activities"
            :multiple="false"
            :close-on-select="true"
            :preserve-search="false"
            :placeholder="language(35)"
            :label="tempTrailSystem.language"
            track-by="value"
          />
          <b-button @click="defaultActivity = false" class="btn btn-success btn-sm">Clear Default Activity</b-button>
        </div><!-- /v-if -->

        <!-- SELECT RELEVANT TRAIL SYSTEMS -->
        <label class="mt-2">{{ language(16) }}</label>
        <Multiselect
          v-model="tempTrailSystem.relatedTrailSystems"
          :options="allTrailSystems"
          :multiple="true"
          :close-on-select="false"
          :preserve-search="false"
          :placeholder="language(17)"
          label="name"
          track-by="tsId"
        />

        <!-- PROMOTE TRAILHUB-->
        <b-form-checkbox v-model="tempTrailSystem.promoteTrailHUB" name="check-button" switch class="mt-3">
          {{ language(32) }}
        </b-form-checkbox>

        <!-- TRAIL CATEGORIES -->
        <label class="mt-2">{{ language(30) }}</label>
        <Multiselect
          v-model="categories"
          :options="categories"
          :tag-placeholder="language(31)"
          :placeholder="language(31)"
          :multiple="true"
          :taggable="true"
          @tag="addCategory"
        />
        <!-- HIDE FROM PUBLIC VIEW -->
        <b-form-checkbox v-model="tempTrailSystem.inactive" name="check-button" switch class="mt-3">
          {{ language(18) }}<br><small>{{ language(19) }}</small>
        </b-form-checkbox>
        <b-button variant="primary" class="mt-3 mr-1" @click="saveTrailSystem()">{{ language(20) }}</b-button>
        <b-button
          variant="danger"
          class="mt-3"
          v-if="$store.state.editMode"
          @click="confirmTrash = true"
        >{{ language(22) }}</b-button>
      </b-form>
      <br>
      <b-button
        size="sm"
        variant="secondary"
        class="mt-3 mr-1"
        @click="logout()"
        v-if="$store.state.trailSystems.length < 1"
      >{{ language(29) }}</b-button>
    </b-card>

    <Confirm
      v-if="confirmTrash"
      :text="language(23)"
      v-on:confirmed="trashTrailSystem($event)"
    />

  </div><!-- /#createTrailSystem -->
</template>

<script>
import Confirm from '../Confirm'
import ImageUpload from './ImageUpload'
import firebase from 'firebase'
import Multiselect from 'vue-multiselect'
import axios from 'axios'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      categories: [],
      hiddenActivities: [],
      defaultActivity: false,
      phrases: {
        1: {
          en: 'Trail System Name',
          fr: 'Nom du réseau de sentiers'
        },
        2: {
          en: 'Public Phone Number',
          fr: 'Numéro de téléphone public'
        },
        3: {
          en: 'Website',
          fr: 'Site Web'
        },
        4: {
          en: 'Trail System Icon',
          fr: 'Icône du réseau de sentiers'
        },
        5: {
          en: 'Hide Trail System Title',
          fr: 'Masquer le titre du réseau de sentiers'
        },
        6: {
          en: 'Only display your icon.',
          fr: 'Afficher votre icône seulement.'
        },
        7: {
          en: 'Depth Measurements',
          fr: 'Mesures de la profondeur'
        },
        8: {
          en: 'Centimeters',
          fr: 'Centimètres'
        },
        9: {
          en: 'Inches',
          fr: 'Pouces'
        },
        10: {
          en: 'Length Measurements',
          fr: 'Mesures de la longueur'
        },
        11: {
          en: 'Kilometers',
          fr: 'Kilomètres'
        },
        12: {
          en: 'Miles',
          fr: 'Milles'
        },
        13: {
          en: 'Temperature Measurements',
          fr: 'Mesures de la température'
        },
        14: {
          en: 'Celsius',
          fr: 'Celsius'
        },
        15: {
          en: 'Fahrenheit',
          fr: 'Fahrenheit'
        },
        16: {
          en: 'Related Trail Systems',
          fr: 'Réseaux de sentiers connexes'
        },
        17: {
          en: 'Select Trail Systems',
          fr: 'Sélectionner les réseaux de sentiers'
        },
        18: {
          en: 'Trail System Inactive',
          fr: 'Réseau de sentiers inactif'
        },
        19: {
          en: 'Turn this off when ready to share your trails with the world!',
          fr: 'Désactivez cette fonction lorsque vous êtes prêt à partager vos sentiers avec le monde entier!'
        },
        20: {
          en: 'Save',
          fr: 'Enregistrer'
        },
        21: {
          en: 'Delete Icon',
          fr: 'Supprimer l\'icône'
        },
        22: {
          en: 'Trash Trail System',
          fr: 'Supprimer le sentiers'
        },
        23: {
          en: 'DANGER! Are you sure you want to trash this trail system? All trails within this trail system will also be trashed.',
          fr: 'DANGER! Êtes-vous sûr de vouloir détruire ce système de sentiers ? Tous les sentiers de ce réseau de sentiers seront également détruits.'
        },
        24: {
          en: 'Create Trail System',
          fr: 'Créer un système de sentiers'
        },
        25: {
          en: 'Edit Trail System',
          fr: 'Modifier le système de sentiers'
        },
        26: {
          en: 'Please fill out this form to set up your trail system. Remember to select a default location by clicking on the map!',
          fr: 'Veuillez remplir ce formulaire pour configurer votre réseau de sentiers. N\'oubliez pas de sélectionner un emplacement par défaut en cliquant sur la carte !'
        },
        27: {
          en: 'Description',
          fr: 'Description'
        },
        28: {
          en: 'Language',
          fr: 'Langue'
        },
        29: {
          en: 'Log out',
          fr: 'Se déconnecter'
        },
        30: {
          en: 'Categories',
          fr: 'Catégories'
        },
        31: {
          en: 'Select categories',
          fr: 'Sélectionnez les catégories'
        },
        32: {
          en: 'Link to browse all of TrailHUB in navbar?',
          fr: 'Lien pour parcourir les systèmes de sentiers dans la barre de navigation ?'
        },
        33: {
          en: 'Hide Activities',
          fr: 'Masquer les activités'
        },
        34: {
          en: 'Use this to temporarily hide activities from your trail system.',
          fr: 'Utilisez-le pour masquer temporairement les activités de votre système de suivi.'
        },
        35: {
          en: 'Default Activity',
          fr: 'Activité par défaut'
        },
        36: {
          en: 'Use this to set a default view for your trail system. Leave blank to show all trail activities.',
          fr: 'Utilisez ceci pour définir une vue par défaut pour votre système de sentiers. Laissez vide pour afficher toutes les activités de sentier.'
        }
      },
      allTrailSystems: [],
      confirmTrash: false,
      activityOptions: [],
      tempTrailSystem: {
        name: '',
        description: '',
        categories: [],
        geolocation: false,
        depthMeasurement: 'inches',
        lengthMeasurement: 'miles',
        tempMeasurement: 'fahrenheit',
        language: 'en',
        phoneNumber: '',
        website: '',
        inactive: true,
        blocked: false,
        override: false,
        hideName: false,
        promoteTrailHUB: true,
        hiddenActivities: [],
        defaultActivity: false
      }
    }
  },
  components: {
    Confirm,
    ImageUpload,
    Multiselect
  },
  computed: {
    trailSystem: {
      get () {
        return this.$store.state.trailSystem
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trailSystem', value: value })
      }
    },
    geocoderControl: {
      get () {
        return this.$store.state.geocoderControl
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'geocoderControl', value: value })
      }
    },
    selectMarker: {
      get () {
        return this.$store.state.selectMarker
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'selectMarker', value: value })
      }
    }
  },
  mounted () {
    if (this.$store.state.editMode) {
      this.tempTrailSystem = this.trailSystem
    }
    if (this.trailSystem.categories) {
      this.categories = this.trailSystem.categories
    }
    if (this.trailSystem.hiddenActivities) {
      this.hiddenActivities = this.trailSystem.hiddenActivities
    } else {
      this.hiddenActivities = []
    }
    if (!this.tempTrailSystem.defaultActivity) {
      this.tempTrailSystem.defaultActivity = ''
      this.defaultActivity = ''
    } else {
      this.defaultActivity = this.tempTrailSystem.defaultActivity
    }
    this.initMapControls()
    this.fetchAllSystems()
  },
  destroyed () {
    if (this.selectMarker) {
      this.selectMarker.remove()
      this.$store.commit('setStateProperty', { property: 'selectMarker', value: false })
    }
    this.$store.commit('setStateProperty', { property: 'editMode', value: false })
    this.$store.state.mapObject.removeControl(this.$store.state.geocoderControl)
    this.$store.state.mapObject.off('click', this.addMarker)
  },
  methods: {
    addCategory (newCat) {
      this.categories.push(newCat)
      console.log(this.categories)
    },
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    async fetchAllSystems () {
      const geoJsonStorageRef = firebase.storage().ref('allSystems/allSystems.geojson')
      const geoJsonUrl = await geoJsonStorageRef.getDownloadURL()
      const fileData = await axios.get(geoJsonUrl)
      fileData.data.features.forEach((item, i) => {
        const trailSystemItem = {}
        trailSystemItem.name = item.properties.name
        trailSystemItem.tsId = item.properties.tsId
        this.allTrailSystems.push(trailSystemItem)
      })
    },
    deliverIconPath (path) {
      this.tempTrailSystem.iconPath = path
    },
    logout () {
      fb.auth.signOut().then(() => {
        this.$router.push('/login')
        location.reload()
      }).catch(err => {
        this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 3000 })
      })
    },
    trashTrailSystem (confirmed) {
      const self = this
      async function trashTrail (trail) {
        await fb.trailsCollection.doc(trail.properties.trailId).get().then(doc => {
          const trailDoc = doc.data()
          trailDoc.trashedBy = self.$store.state.currentUser.uid
          return fb.trashedTrailsCollection.doc(doc.id).set(trailDoc)
        }).then(() => {
          return fb.trailsCollection.doc(trail.properties.trailId).delete()
        }).catch(err => {
          console.log(err.message)
        })
      }
      async function trashPoint (point) {
        await fb.pointsCollection.doc(point.properties.pointId).get().then(doc => {
          const pointDoc = doc.data()
          pointDoc.trashedBy = self.$store.state.currentUser.uid
          return fb.trashedPointsCollection.doc(doc.id).set(pointDoc)
        }).then(() => {
          return fb.pointsCollection.doc(point.properties.pointId).delete()
        }).catch(err => {
          console.log(err.message)
        })
      }
      const trailSystemId = self.$store.state.trailSystem.id
      if (confirmed) {
        self.confirmTrash = false
        if (trailSystemId === self.$store.state.userProfile.defaultTrailSystem) {
          fb.usersCollection.doc(self.$store.state.currentUser.uid).update({
            defaultTrailSystem: false
          })
        }
        self.trailSystem.trashedBy = self.$store.state.currentUser.uid
        fb.trashedTrailSystemsCollection.doc(self.trailSystem.id).set(self.trailSystem).then(() => {
          const promises = []
          // Trash any trails in this trailSystem
          self.$store.state.trails.forEach(trail => {
            const prom = trashTrail(trail)
            promises.push(prom)
          })
          return Promise.all(promises)
        }).then(() => {
          const promises = []
          // Trash any points in this trailSystem
          self.$store.state.points.forEach(point => {
            const prom = trashPoint(point)
            promises.push(prom)
          })
          return Promise.all(promises)
        }).then(() => {
          return fb.trailSystemsCollection.doc(trailSystemId).delete()
        }).then(() => {
          window.location.href = '/'
        }).catch(err => {
          console.log(err.message)
        })
      } else {
        self.confirmTrash = false
        return false
      }
    },
    addMarker (e) {
      if (this.selectMarker) {
        this.selectMarker.remove()
      }
      const markerOptions = e
      markerOptions.color = '#333'
      // markerOptions.draggable = true
      this.selectMarker = new maplibregl.Marker(markerOptions) // eslint-disable-line
        .setLngLat(e.lngLat)
        .addTo(this.$store.state.mapObject)
      this.tempTrailSystem.geolocation = new firebase.firestore.GeoPoint(e.lngLat.lat, e.lngLat.lng)
    },
    initMapControls () {
      const map = this.$store.state.mapObject
      // Check for map ready
      if (map.boxZoom) {
        // Map is ready
        map.removeControl(this.$store.state.zoomControl)
        map.removeControl(this.$store.state.locateControl)
        this.geocoderControl = new MapboxGeocoder({ // eslint-disable-line
          accessToken: 'pk.eyJ1IjoidHJhaWxodWIiLCJhIjoiY2toamZ3Z3lsMDVpdjJycGVibm93b2Y2NSJ9.t9zR8pPj3btbMXqUdIB3dg',
          maplibregl: maplibregl, // eslint-disable-line
          marker: false,
          zoom: 16,
          flyTo: {
            animate: false
          }
        })
        map.addControl(this.geocoderControl)
        map.addControl(this.$store.state.zoomControl)
        map.addControl(this.$store.state.locateControl)
        // Setup click marker controls
        map.on('click', this.addMarker)
      } else {
        const self = this
        setTimeout(() => {
          self.initMapControls()
        }, 500)
      }
    },
    saveTrailSystem () {
      if (!this.tempTrailSystem.geolocation) {
        this.$store.commit('setAlert', { active: true, message: 'You must select a default location for your trail system by searching/clicking on the map!', level: 'alert-warning', timeout: 6000 })
        return false
      }
      if (this.tempTrailSystem.website.length > 1 && this.tempTrailSystem.website.substr(0, 4) !== 'http') {
        this.tempTrailSystem.website = 'http://' + this.tempTrailSystem.website
      }
      if (this.$store.state.editMode) {
        fb.trailSystemsCollection.doc(this.trailSystem.id).update({
          blocked: this.tempTrailSystem.blocked || false,
          override: this.tempTrailSystem.override || false,
          name: this.tempTrailSystem.name,
          description: this.tempTrailSystem.description,
          categories: this.categories,
          geolocation: this.tempTrailSystem.geolocation,
          iconPath: this.tempTrailSystem.iconPath || false,
          depthMeasurement: this.tempTrailSystem.depthMeasurement,
          lengthMeasurement: this.tempTrailSystem.lengthMeasurement,
          tempMeasurement: this.tempTrailSystem.tempMeasurement || 'fahrenheit',
          language: this.tempTrailSystem.language || 'en',
          phoneNumber: this.tempTrailSystem.phoneNumber,
          website: this.tempTrailSystem.website,
          updatedOn: new Date(),
          updatedBy: this.$store.state.currentUser.uid,
          users: this.tempTrailSystem.users,
          permissions: this.tempTrailSystem.permissions,
          inactive: this.tempTrailSystem.inactive || false,
          promoteTrailHUB: this.tempTrailSystem.promoteTrailHUB || false,
          relatedTrailSystems: this.tempTrailSystem.relatedTrailSystems || false,
          hideName: this.tempTrailSystem.hideName || false,
          hiddenActivities: this.hiddenActivities || [],
          defaultActivity: this.defaultActivity || false
        }).then(ref => {
          this.$store.commit('setAlert', { active: true, message: 'TrailSystem updated!', level: 'alert-success', timeout: 4000 })
          this.$store.dispatch('fetchTrailSystems')
          this.$store.commit('hideDrawer', 'createTrailSystem')
          setTimeout(() => {
            this.$store.commit('showDrawer', 'trails')
          }, 300)
        }).catch(err => {
          this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 6000 })
          console.log(err)
        })
      } else {
        fb.trailSystemsCollection.add({
          blocked: this.tempTrailSystem.blocked || false,
          override: this.tempTrailSystem.override || false,
          name: this.tempTrailSystem.name,
          description: this.tempTrailSystem.description,
          categories: this.categories,
          geolocation: this.tempTrailSystem.geolocation,
          iconPath: this.tempTrailSystem.iconPath || false,
          depthMeasurement: this.tempTrailSystem.depthMeasurement,
          lengthMeasurement: this.tempTrailSystem.lengthMeasurement,
          tempMeasurement: this.tempTrailSystem.tempMeasurement || 'fahrenheit',
          language: this.tempTrailSystem.language || 'en',
          phoneNumber: this.tempTrailSystem.phoneNumber,
          website: this.tempTrailSystem.website,
          createdOn: new Date(),
          updatedOn: new Date(),
          updatedBy: this.$store.state.currentUser.uid,
          ownerId: this.$store.state.currentUser.uid,
          createdBy: this.$store.state.currentUser.uid,
          users: [],
          permissions: {},
          geoJsonPath: false,
          inactive: this.tempTrailSystem.inactive || true,
          promoteTrailHUB: this.tempTrailSystem.promoteTrailHUB || true,
          relatedTrailSystems: this.tempTrailSystem.relatedTrailSystems || false,
          hideName: this.tempTrailSystem.hideName || false,
          hiddenActivities: [],
          defaultActivity: false
        }).then(ref => {
          window.location.href = '/ts/' + ref.id
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }
}
</script>

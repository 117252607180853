<template>
  <div id="weather">
    <b-card
      class="blockDrawer large fullMobile"
      :class="$store.state.drawer.weather.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="$store.commit('hideDrawer', 'weather')"
        >
          <i class="far fa-times"></i>
        </a>
        <h4>{{ language(1) }}</h4>
      </template>
      <div v-if="showWeather">
        <p class="text-right" id="tempSelect">
          <strong>
            <span
              @click="trailSystem.tempMeasurement = 'fahrenheit'"
              :class="{ active: trailSystem.tempMeasurement === 'fahrenheit' }"
              class="clickable"
            >°F</span>
          </strong>
          |
          <strong>
            <span
              @click="trailSystem.tempMeasurement = 'celsius'"
              :class="{ active: trailSystem.tempMeasurement === 'celsius' }"
              class="clickable"
            >°C</span>
          </strong>
        </p>

        <div class="row weather-card">
          <div class="col-4 left text-center">
            <h1 class="mt-3 text-dark">{{ weather.current.temp | formatTemp(trailSystem.tempMeasurement, false)}}°</h1>
            <p>{{ language(2) }} {{ weather.current.feels_like | formatTemp(trailSystem.tempMeasurement)}}<br>
            {{ weather.daily[0].temp.min | formatTemp(trailSystem.tempMeasurement, false)}}° H | {{ weather.daily[0].temp.max | formatTemp(trailSystem.tempMeasurement, false)}}° L</p>
          </div><!-- /.col-4 -->
          <div class="col-8 right text-light">
            <i :class="getClass(trailSystem.weather.weather[0].icon)" class="main-icon mt-3"></i>
            <p>
              <strong class="weather-description">{{ weather.current.weather[0].description }}</strong><br>
              {{ language(3) }}: {{ weather.current.wind_speed | formatWindSpeed(trailSystem.lengthMeasurement) }} {{ weather.current.wind_deg | formatWindDirection }}<br>
              {{ language(4) }}: {{ weather.current.humidity }}%<br>
              {{ language(5) }}: {{ weather.current.visibility | formatVisibility(trailSystem.lengthMeasurement) }}<br>
              {{ language(6) }}: {{ weather.current.sunrise | formatTime }} | {{ language(7) }}: {{ weather.current.sunset | formatTime }}
            </p>
          </div><!-- /.col-8 -->
        </div><!-- /.row -->

        <p class="mt-3 text-dark"><strong>{{ language(8) }}</strong></p>
        <div id="forecast">
          <b-card
            v-for="(day, index) in weather.daily"
            v-bind:key="index"
            no-body
            class="mb-2"
          >
            <b-card-header
              header-tag="header"
              class="pt-2 pb-2 pl-3 pr-3 forecast-header"
              role="tab"
            >
              <div
                class='d-flex justify-content-between align-items-center'
                v-b-toggle="'accordion-' + index"
              >
                <span>{{ day.dt | formatDate}} | {{ day.temp.max | formatTemp(trailSystem.tempMeasurement, false)}}° / {{ day.temp.min | formatTemp(trailSystem.tempMeasurement)}}</span>
                <span><i :class="getClass(day.weather[0].icon)" class="collapse-icon text-dark"></i> <i class="far fa-chevron-down"></i></span>
              </div>
            </b-card-header>
            <b-collapse
              :id="'accordion-' + index"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="pb-1">
                <p><strong class="text-dark weather-description">{{ day.weather[0].description }}</strong><br>
                  {{ language(3) }}: {{ day.wind_speed | formatWindSpeed(trailSystem.lengthMeasurement) }} {{ day.wind_deg | formatWindDirection }}<br>
                  {{ language(4) }}: {{ day.humidity }}%<br>
                  {{ language(6) }}: {{ day.sunrise | formatTime }} | {{ language(7) }}: {{ day.sunset | formatTime }}
                </p>
                <hr>
                <p class="mb-0"><strong class="text-dark">{{ language(9) }}</strong></p>
                <div class="row">
                  <div class="col-3">
                    <p><strong>{{ language(10) }}</strong><br>{{ day.temp.morn | formatTemp(trailSystem.tempMeasurement, false)}}°</p>
                  </div>
                  <div class="col-3">
                    <p><strong>{{ language(11) }}</strong><br>{{ day.temp.day | formatTemp(trailSystem.tempMeasurement, false)}}°</p>
                  </div>
                  <div class="col-3">
                    <p><strong>{{ language(12) }}</strong><br>{{ day.temp.eve | formatTemp(trailSystem.tempMeasurement, false)}}°</p>
                  </div>
                  <div class="col-3">
                    <p><strong>{{ language(13) }}</strong><br>{{ day.temp.night | formatTemp(trailSystem.tempMeasurement, false)}}°</p>
                  </div>
                </div><!-- /.row -->
                <hr>
                <p class="mb-0"><strong class="text-dark">{{ language(2) }}</strong></p>
                <div class="row">
                  <div class="col-3">
                    <p><strong>{{ language(10) }}</strong><br>{{ day.feels_like.morn | formatTemp(trailSystem.tempMeasurement, false)}}°</p>
                  </div>
                  <div class="col-3">
                    <p><strong>{{ language(11) }}</strong><br>{{ day.feels_like.day | formatTemp(trailSystem.tempMeasurement, false)}}°</p>
                  </div>
                  <div class="col-3">
                    <p><strong>{{ language(12) }}</strong><br>{{ day.feels_like.eve | formatTemp(trailSystem.tempMeasurement, false)}}°</p>
                  </div>
                  <div class="col-3">
                    <p><strong>{{ language(13) }}</strong><br>{{ day.feels_like.night | formatTemp(trailSystem.tempMeasurement, false)}}°</p>
                  </div>
                </div><!-- /.row -->
                <!-- SNOW -->
                <hr v-if="day.snow">
                <p  v-if="day.snow" class="mb-0"><strong class="text-dark">{{ language(14) }}</strong><br>{{ day.snow | formatDepth(trailSystem.depthMeasurement) }}</p>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div><!-- /days -->
        <!-- SNOW REPORT -->
        <hr v-if="trailSystem.updates.snowReport">
        <p  v-if="trailSystem.updates.snowReport"><strong class="text-dark">{{ language(15) }}</strong></p>
        <SnowReport
          v-if="trailSystem.updates.snowReport"
          :update="trailSystem.updates.snowReport[0]"
          class="mb-3"
        />
      </div><!-- /if showWeather -->
      <div v-else>
        <p class="text-center lead mt-5"><i class="fas fa-sync fa-spin"></i> Loading weather...</p>
      </div>
    </b-card>
  </div><!-- /#weather -->
</template>

<script>
import axios from 'axios'
import SnowReport from '../update/templates/SnowReport'

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Weather',
          fr: 'Météo'
        },
        2: {
          en: 'Feels like',
          fr: 'Température ressentie'
        },
        3: {
          en: 'Wind',
          fr: 'Vent'
        },
        4: {
          en: 'Humidity',
          fr: 'Humidité'
        },
        5: {
          en: 'Visibility',
          fr: 'Visibilité'
        },
        6: {
          en: 'Sunrise',
          fr: 'Lever du soleil'
        },
        7: {
          en: 'Sunset',
          fr: 'Coucher de soleil'
        },
        8: {
          en: '7-day Forecast',
          fr: 'Prévisions 7 jours'
        },
        9: {
          en: 'Temperature',
          fr: 'Température'
        },
        10: {
          en: 'Morning',
          fr: 'Matinée'
        },
        11: {
          en: 'Day',
          fr: 'Journée'
        },
        12: {
          en: 'Evening',
          fr: 'Soirée'
        },
        13: {
          en: 'Night',
          fr: 'Nuit'
        },
        14: {
          en: 'Snow',
          fr: 'Neiger'
        },
        15: {
          en: 'Snow Report',
          fr: 'Bulletin de neige'
        }
      },
      weather: false,
      showWeather: false
    }
  },
  computed: {
    trailSystem: {
      get () {
        return this.$store.state.trailSystem
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trailSystem', value: value })
      }
    }
  },
  components: {
    SnowReport
  },
  created () {
    axios.get('https://trailhub.org/api/weather/' + this.trailSystem.id).then(data => {
      this.weather = data.data[0]
      this.trailSystem.weather.main.temp = this.weather.current.temp
      console.log(this.weather)
      this.showWeather = true
    })
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    getClass (iconCode) {
      // const iconCode = this.$store.state.trailSystem.weather.weather[0].icon
      let iconClass
      if (iconCode === '01d') { iconClass = 'fal fa-sun' }
      if (iconCode === '01n') { iconClass = 'fas fa-moon-stars' }
      if (iconCode === '02d') { iconClass = 'fal fa-cloud-sun' }
      if (iconCode === '02n') { iconClass = 'fas fa-cloud-moon' }
      if (iconCode === '03d' || iconCode === '03n') { iconClass = 'fal fa-cloud' }
      if (iconCode === '04d' || iconCode === '04n') { iconClass = 'fal fa-clouds' }
      if (iconCode === '09d' || iconCode === '09n') { iconClass = 'fas fa-cloud-showers-heavy' }
      if (iconCode === '10d') { iconClass = 'fas fa-cloud-sun-rain' }
      if (iconCode === '10n') { iconClass = 'fas fa-cloud-moon-rain' }
      if (iconCode === '11d' || iconCode === '11n') { iconClass = 'fas fa-thunderstorm' }
      if (iconCode === '13d' || iconCode === '13n') { iconClass = 'fal fa-snowflake' }
      if (iconCode === '50d' || iconCode === '50n') { iconClass = 'far fa-fog' }
      return iconClass
    }
  },
  filters: {
    formatDepth (val, unit) {
      let depth
      if (unit === 'inches') {
        depth = (val / 25.4).toFixed(0) + ' in'
      } else {
        depth = (val / 10).toFixed(0) + ' cm'
      }
      return depth
    },
    formatDate (val) {
      var d = new Date(val * 1000)
      var weekday = []
      weekday[0] = 'Sun'
      weekday[1] = 'Mon'
      weekday[2] = 'Tue'
      weekday[3] = 'Wed'
      weekday[4] = 'Thu'
      weekday[5] = 'Fri'
      weekday[6] = 'Sat'
      var month = []
      month[0] = 'Jan'
      month[1] = 'Feb'
      month[2] = 'Mar'
      month[3] = 'Apr'
      month[4] = 'May'
      month[5] = 'Jun'
      month[6] = 'Jul'
      month[7] = 'Aug'
      month[8] = 'Sep'
      month[9] = 'Oct'
      month[10] = 'Nov'
      month[11] = 'Dec'
      return weekday[d.getUTCDay()] + ', ' + month[d.getMonth()] + ' ' + d.getDate()
    },
    formatTime (val) {
      var date = new Date(val * 1000)
      var hours = date.getHours()
      let hoursFormatted
      let amPm
      if (hours < 13) {
        hoursFormatted = hours
        amPm = ' a.m.'
      } else {
        hoursFormatted = hours - 12
        amPm = ' p.m.'
      }
      var minutes = '0' + date.getMinutes()
      var formattedTime = hoursFormatted + ':' + minutes.substr(-2) + amPm
      return formattedTime
    },
    formatVisibility (val, unit) {
      let distance
      if (unit === 'miles') {
        distance = (val / 1609).toFixed(1) + ' mi'
      } else {
        distance = (val / 1000).toFixed(1) + ' km'
      }
      return distance
    },
    formatWindDirection (val) {
      let direction
      if (val >= 337.5 && val < 22.5) { direction = 'N' }
      if (val >= 22.5 && val < 67.5) { direction = 'NE' }
      if (val >= 67.5 && val < 112.5) { direction = 'E' }
      if (val >= 112.5 && val < 157.5) { direction = 'SE' }
      if (val >= 157.5 && val < 202.5) { direction = 'S' }
      if (val >= 202.5 && val < 247.5) { direction = 'SW' }
      if (val >= 247.5 && val < 292.5) { direction = 'W' }
      if (val >= 292.5 && val < 337.5) { direction = 'NW' }
      return direction
    },
    formatWindSpeed (val, unit) {
      // val is meters per second
      let speed
      if (unit === 'miles') {
        speed = (val * 2.237).toFixed(0) + ' mph'
      } else {
        speed = val.toFixed(1) + ' m/s'
      }
      return speed
    },
    formatTemp (val, unit, label) {
      let output
      if (unit === 'fahrenheit') {
        if (label === false) {
          output = ((val * 1.8) + 32).toFixed(0)
        } else {
          output = ((val * 1.8) + 32).toFixed(0) + '°F'
        }
      } else {
        if (label === false) {
          output = val.toFixed(0)
        } else {
          output = val.toFixed(0) + '°C'
        }
      }
      return output
    }
  }
}
</script>

<style scoped>
.forecast-header {
  border-radius: 30px;
}

.collapse-icon {
  font-size: 30px;
}

#tempSelect .active {
  color: #0A5C72;
}

.weather-card {
  margin-left: -20px;
  margin-right: -20px;
}

.weather-card .left {
  background-color: #F5F5F5;
}

.weather-card .right {
  background-color: #0A5C72;
}

.weather-card h1 {
  font-size: 60px;
}

.weather-card p {
  font-size: 14px;
  font-weight: bold;
}

.weather-description {
  text-transform: uppercase;
}

.weather-card .main-icon {
  font-size: 34px;
}
</style>

<template>
  <div id="adminTrailSystem">
    <div class="o-modal mt-5">
      <div class="o-modal-content full mt-4">
        <div class="o-modal-header">
          <p class="lead">
            <a target="_blank" :href="'/ts/' + adminData.trailSystem.id">{{ adminData.trailSystem.name }}</a><i class="fas fa-times float-right clickable mt-1" @click="adminData.showTrailSystem = false"></i>
            <span v-if="adminData.trailSystem.blocked"><i class="fas fa-ban ml-1 text-danger" v-b-tooltip.hover.bottom="'Blocked'"></i></span>
            <span v-if="adminData.trailSystem.override"><i class="fas fa-lock-open ml-1 text-success" v-b-tooltip.hover.bottom="'Overridden'"></i></span>
          </p>
        </div><!-- /.o-moddal-header -->
        <div class="o-modal-body pb-5">
          <div class="alert-danger p-1 mb-2" v-if="adminData.trailSystem.failComment">{{ adminData.trailSystem.failComment }}</div>
          <p>
            {{ adminData.trailSystem.ownerProfile.nameFirst }} {{ adminData.trailSystem.ownerProfile.nameLast }} |
            {{ adminData.trailSystem.ownerProfile.organization }} |
            {{ adminData.trailSystem.ownerProfile.email }} |
            {{ adminData.trailSystem.ownerProfile.phoneNumber | formatPhoneNumber }}
            <b-badge
              pill
              class="clickable ml-2 text-light"
              @click="adminData.changeOwner = true"
            >
              Change Owner
            </b-badge>
            <br>
            Customer since: {{ adminData.trailSystem.createdOn._seconds | formatDate }}<br>
            <span v-if="adminData.trailSystem.subscription === 'circle'">Circle (NFP)</span>
            <span v-if="adminData.trailSystem.subscription === 'triangle'">Triangle (NFP)</span>
            <span v-if="adminData.trailSystem.subscription === 'star'">Star (NFP)</span>
            <span v-if="adminData.trailSystem.subscription === 'square'">Square</span>
            <span v-if="adminData.trailSystem.subscription === 'diamond'">Diamond</span>
            <span v-if="adminData.trailSystem.subscription === 'doubleDiamond'">Double Diamond</span>
            <b-badge
              v-if="adminData.trailSystem.activeSub"
              pill
              class="clickable ml-2 text-light"
              :href="adminData.trailSystem.activeSub.stripeLink"
              target="_blank"
            >
              Payment Details
            </b-badge>
          </p>
          <p>{{ adminData.trailSystem.ownerTSs.length }} Trail System<span v-if="adminData.trailSystem.ownerTSs.length > 1">s</span>:</p>
          <b-table
            hover
            :items="adminData.trailSystem.ownerTSs"
            :fields="fields"
          >
            <template #cell(name)="data">
              <a target="_blank" :href="'/ts/' + data.item.id">{{ data.item.name }}</a>
            </template>
            <template #cell(createdOn._seconds)="data">
              {{ data.item.createdOn._seconds | formatDate }}
            </template>
            <template #cell(trustees)="data">
              <ul v-if="showTrustees" style="padding-inline-start:0px;">
                <li
                  v-for="(trustee, i) in data.item.trustees"
                  v-bind:key="i"
                >
                  {{ adminData.trailSystem.permissions[trustee.id] }} |
                  {{ trustees[trustee.id].nameFirst }} {{ trustees[trustee.id].nameLast }} |
                  {{ trustees[trustee.id].email }}
                </li>
              </ul>
            </template>
          </b-table>
        </div><!-- /.o-modal-body -->
      </div><!-- /.o-modal-content -->
    </div><!-- /.o-modal -->
  </div><!-- /#adminTrailSystem -->
</template>

<script>
import moment from 'moment'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'createdOn._seconds',
          sortable: true,
          label: 'Created'
        },
        {
          key: 'trustees',
          label: 'Trustees'
        }
      ],
      showTrustees: false,
      trustees: {}
    }
  },
  created () {
    console.log(this.adminData.trailSystem)
    this.gatherTrustees()
  },
  computed: {
    adminData: {
      get () {
        return this.$store.state.adminData
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'adminData', value: value })
      }
    }
  },
  methods: {
    gatherTrustees () {
      this.adminData.trailSystem.ownerTSs.forEach(ts => {
        ts.trustees.forEach(trustee => {
          this.trustees[trustee.id] = 'No Data'
        })
      })
      const self = this
      async function getTrustee (trusteeId) {
        await fb.usersCollection.doc(trusteeId).get().then(trusteeDoc => {
          const trusteeData = trusteeDoc.data()
          trusteeData.id = trusteeDoc.id
          self.trustees[trusteeDoc.id] = trusteeData
          return true
        })
      }
      const promises = []
      Object.keys(this.trustees).forEach(trustee => {
        const promise = getTrustee(trustee)
        promises.push(promise)
      })
      Promise.all(promises).then(() => {
        this.showTrustees = true
      })
    }
  },
  filters: {
    formatPhoneNumber (phoneNumberString) {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return null
    },
    formatDate (val) {
      if (!val) { return '-' }
      var t = new Date(1970, 0, 1)
      t.setSeconds(val)
      return moment(t).format('MM/DD/YY')
    }
  }
}
</script>

<style scoped>
a {
  color: #333;
  text-decoration: underline;
}
</style>

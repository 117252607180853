<template>
  <div id="diningOptions">
    <label>{{ language(1) }}</label>
    <b-button-group class="w-100 mb-2" v-if="tempPoint.diningOptions">
      <b-button
        v-for="(day, i) in days"
        v-bind:key="i"
        :class="{ active: currentDay === i }"
        @click="currentDay = i"
      >{{ day[$store.state.trailSystem.language].shortName }}</b-button>
    </b-button-group>
    <h5 class="text-center">{{ days[currentDay][$store.state.trailSystem.language].name }}</h5>
    <div
      v-for="(menu, i) in tempPoint.diningOptions.days[currentDay].menus"
      v-bind:key="i"
      class="card p-2 mt-2"
    >
      <b-form-group :label="language(3)">
        <b-form-input
          v-model="menu.title"
          required
        ></b-form-input>
      </b-form-group>
      <div class="form-group">
        <label>{{ language(4) }}</label>
        <quill-editor
          v-model="menu.body"
          :options="editorOptions"
        ></quill-editor>
      </div><!-- /.form-group -->
      <label class="text-center">{{ language(5) }}</label>
      <b-time v-model="menu.startTime" locale="en" hide-header></b-time>
      <label class="text-center mt-2">{{ language(6) }}</label>
      <b-time v-model="menu.endTime" locale="en" hide-header></b-time>
      <p class="text-danger text-right clickable" @click="removeMenu(i)"><small>Remove</small></p>
      <p
        class="text-right clickable"
        @click="moveMenu(i, 'up')"
        v-if="i > 0 && tempPoint.diningOptions.days[currentDay].menus.length > 1"
      ><small>Move up</small></p>
      <p
        class="text-right clickable"
        @click="moveMenu(i, 'down')"
        v-if="i < tempPoint.diningOptions.days[currentDay].menus.length - 1 && tempPoint.diningOptions.days[currentDay].menus.length > 1"
      ><small>Move down</small></p>
    </div>
    <b-btn @click="addMenu()" class="mt-2">{{ language(2) }}</b-btn>
  </div><!-- /#diningOptions -->
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  data () {
    return {
      currentDay: 0,
      phrases: {
        1: {
          en: 'Dining Options',
          fr: 'Choix de restaurants'
        },
        2: {
          en: 'Add Menu',
          fr: 'Ajouter un menu'
        },
        3: {
          en: 'Menu Title',
          fr: 'Titre du menu'
        },
        4: {
          en: 'Menu Body',
          fr: 'Descriptif des menus'
        },
        5: {
          en: 'Start Time',
          fr: 'Heure de début'
        },
        6: {
          en: 'End Time',
          fr: 'Heure de fin'
        }
      },
      days: [
        {
          shortName: 'Su',
          name: 'Sunday',
          en: {
            shortName: 'Su',
            name: 'Sunday'
          },
          fr: {
            shortName: 'Di',
            name: 'Dimanche'
          },
          menus: []
        },
        {
          shortName: 'Mo',
          name: 'Monday',
          en: {
            shortName: 'Mo',
            name: 'Monday'
          },
          fr: {
            shortName: 'Lu',
            name: 'Lundi'
          },
          menus: []
        },
        {
          shortName: 'Tu',
          name: 'Tuesday',
          en: {
            shortName: 'Tu',
            name: 'Tuesday'
          },
          fr: {
            shortName: 'Ma',
            name: 'Mardi'
          },
          menus: []
        },
        {
          shortName: 'We',
          name: 'Wednesday',
          en: {
            shortName: 'We',
            name: 'Wednesday'
          },
          fr: {
            shortName: 'Me',
            name: 'Mercredi'
          },
          menus: []
        },
        {
          shortName: 'Th',
          name: 'Thursday',
          en: {
            shortName: 'Th',
            name: 'Thursday'
          },
          fr: {
            shortName: 'Je',
            name: 'Jeudi'
          },
          menus: []
        },
        {
          shortName: 'Fr',
          name: 'Friday',
          en: {
            shortName: 'Fr',
            name: 'Friday'
          },
          fr: {
            shortName: 'Ve',
            name: 'Vendredi'
          },
          menus: []
        },
        {
          shortName: 'Sa',
          name: 'Saturday',
          en: {
            shortName: 'Sa',
            name: 'Saturday'
          },
          fr: {
            shortName: 'Sa',
            name: 'Samedi'
          },
          menus: []
        }
      ],
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, false] }],
            [{ align: [] }],
            ['clean']
          ]
        }
      }
    }
  },
  components: {
    quillEditor
  },
  computed: {
    tempPoint: {
      get () {
        return this.$store.state.tempPoint
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'tempPoint', value: value })
      }
    }
  },
  created () {
    if (!this.tempPoint.diningOptions) {
      this.tempPoint.diningOptions = {
        days: this.days
      }
    }
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    moveMenu (i, direction) {
      function arraymove (arr, fromIndex, toIndex) {
        var element = arr[fromIndex]
        arr.splice(fromIndex, 1)
        arr.splice(toIndex, 0, element)
      }
      if (direction === 'up') {
        arraymove(this.tempPoint.diningOptions.days[this.currentDay].menus, i, i - 1)
      } else if (direction === 'down') {
        arraymove(this.tempPoint.diningOptions.days[this.currentDay].menus, i, i + 1)
      }
    },
    removeMenu (i) {
      this.tempPoint.diningOptions.days[this.currentDay].menus.splice(i, 1)
    },
    addMenu () {
      this.tempPoint.diningOptions.days[this.currentDay].menus.push({
        title: 'Breakfast',
        body: '<h1 class="ql-align-center">Appetizers</h1><p class="ql-align-center"><br></p><p class="ql-align-center"><strong>Breadsticks</strong></p><p class="ql-align-center">Describe your item here</p><h2 class="ql-align-center">$8</h2><p class="ql-align-center"><br></p><p class="ql-align-center"><strong>Cup of soup</strong></p><p class="ql-align-center">Describe this soup here</p><h2 class="ql-align-center">$5</h2><p class="ql-align-center"><br></p><h1 class="ql-align-center">Main Course</h1><p class="ql-align-center"><br></p><p class="ql-align-center"><strong>Pasta</strong></p><p class="ql-align-center">This is a description of this item</p><h2 class="ql-align-center">$16</h2><p class="ql-align-center"><br></p><p class="ql-align-center"><strong>Steak</strong></p><p class="ql-align-center">This is a description of this item</p><h2 class="ql-align-center">$25</h2><p class="ql-align-center"><br></p>',
        startTime: '08:00:00',
        endTime: '11:30:00'
      })
    }
  }
}
</script>

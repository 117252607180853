<template>
  <div id="points">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.points.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="$store.commit('hideDrawer', 'points')"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title">{{ language(1) }}</h4>
        <b-btn
          @click="createPoint()"
          class="mb-2 mr-1"
          v-if="$store.state.currentUser"
        >
          <i class="far fa-plus"></i> {{ language(2) }}
        </b-btn>
      </template>

      <div class="list-group">
        <div v-if="points.length < 1">
          <div class="alert alert-success text-center">
            <h5>No Points Yet</h5>
            <b-btn
              @click="createPoint()"
              variant="success"
            >Create Your First Point</b-btn>
          </div><!-- /.alert -->
        </div>
        <div
          v-for="(point, i) in $store.state.points"
          v-bind:key="i"
          class="list-group-item list-group-item-action flex-column align-items-start clickable"
          :class="{ hidden: point.properties.hidden }"
          @click="launchPoint(point)"
          @mouseover="$store.dispatch('highlightPoint', point)"
          @mouseleave="$store.dispatch('removePointHighlight')"
        >
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-1">
              <strong>
                <span v-if="point.properties.status" class="mr-1">
                  <i style="color:#28a745;" v-if="point.properties.status == 'Open'" class="fas fa-check-circle"></i>
                  <i style="color:#dc3545;" v-if="point.properties.status == 'Closed'" class="fas fa-times-circle"></i>
                  <i style="color:#ffc107;" v-if="point.properties.status == 'Caution'" class="fas fa-exclamation-circle"></i>
                </span>
                <span>{{ point.properties.name }}<span v-if="point.properties.hidden"><small> ({{ language(3) }})</small></span></span>
              </strong>
            </p>
            <div class="marker" :class="point.properties.markerClass"></div>
          </div>
        </div>
      </div><!-- /.list-group -->
    </b-card>
  </div><!-- /#points -->
</template>

<script>
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Points of Interest',
          fr: 'Points d\'interêts'
        },
        2: {
          en: 'New Point',
          fr: 'Nouveau point'
        },
        3: {
          en: 'Hidden',
          fr: 'Sentier caché'
        }
      }
    }
  },
  computed: {
    points: {
      get () {
        return this.$store.state.points
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'points', value: value })
      }
    }
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    launchPoint (point) {
      this.$store.commit('hideDrawer', 'points')
      fb.pointsCollection.doc(point.properties.pointId).get().then(doc => {
        const pointDoc = doc.data()
        pointDoc.id = doc.id
        this.$store.commit('setStateProperty', { property: 'point', value: pointDoc })
        setTimeout(() => {
          this.$store.commit('showDrawer', 'pointDetails')
          this.$store.dispatch('highlightPoint', point)
        }, 300)
        setTimeout(() => {
          const map = this.$store.state.mapObject
          const lat = this.$store.state.point.geolocation.latitude
          const lng = this.$store.state.point.geolocation.longitude
          map.flyTo({
            center: [lng, lat],
            zoom: 15,
            padding: 20
          })
        }, 600)
      })
    },
    createPoint () {
      this.$store.commit('hideDrawer', 'points')
      setTimeout(() => {
        this.$store.commit('setStateProperty', { property: 'editMode', value: false })
        this.$store.commit('showDrawer', 'createPoint')
      }, 300)
    }
  }
}
</script>

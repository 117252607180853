<template>
  <div id="surfaceConditions">
    <p class="lead">
      <b-badge
        v-for="(condition, i) in update.conditions"
        v-bind:key="i"
        class="mr-1"
        pill
        variant="dark"
      >
        <i :class="condition.iconClass"></i> {{ condition[$store.state.trailSystem.language] || condition.text }} ({{ updateDate }})
      </b-badge>
    </p>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: ['update'],
  computed: {
    updateDate () {
      const val = this.update.updatedOn
      moment.locale(this.$store.state.trailSystem.language)
      if (!val || !val.seconds) {
        return '-'
      } else {
        const date = val.toDate()
        return moment(date).fromNow()
      }
    }
  }
}
</script>

<template>
  <div id="allSystemsMap">
    <div class="map" id="map"></div>
    <!-- User Settings -->
    <b-btn
      id="settingsToggle"
      class="map-toggle"
      @click="$store.commit('showDrawer', 'userSettings')"
      v-b-tooltip.hover.right="'User Settings'"
      size="sm"
    >
      <i class="fas fa-user"></i>
    </b-btn>
    <b-btn
      id="trailsToggle"
      class="map-toggle"
      @click="$store.commit('showDrawer', 'allSystems')"
      v-b-tooltip.hover.right="'View Trail Systems'"
      size="sm"
    >
       <i class="fas fa-map-signs"></i>
    </b-btn>
    <b-btn
      id="trailsToggleMobile"
      class="map-toggle-long"
      @click="$store.commit('showDrawer', 'allSystems')"
      v-b-tooltip.hover.right="'View Trail Systems'"
    >
      <i class="fas fa-map-signs"></i> Trail Systems <i class="fas fa-caret-up"></i>
    </b-btn>
    <!-- TrailSystems Button -->
    <b-btn
      id="trailHubHome"
      class="map-toggle-long"
      to="/"
    ><i class="fas fa-home"></i> TrailHUB</b-btn>
  </div>
</template>

<script>
import axios from 'axios'
import firebase from 'firebase'

export default {
  mounted () {
    this.$store.commit('initTrailSystemMap')
    this.fetchAllSystems()
  },
  methods: {
    async fetchAllSystems () {
      const mapObject = this.$store.state.mapObject
      const geoJsonStorageRef = firebase.storage().ref('allSystems/allSystems.geojson')
      const geoJsonUrl = await geoJsonStorageRef.getDownloadURL()
      const fileData = await axios.get(geoJsonUrl)
      fileData.data.features.sort(function (a, b) {
        if (a.properties.name < b.properties.name) { return -1 }
        if (a.properties.name > b.properties.name) { return 1 }
        return 0
      })

      // Set open vs closed
      fileData.data.features.forEach(ts => {
        if (ts.properties.openTrails > 0 || ts.properties.cautionTrails > 0) {
          ts.properties.status = 1
        } else {
          ts.properties.status = 0
        }
      })

      this.$store.commit('setStateProperty', { property: 'allSystemsGeoJson', value: fileData.data })
      mapObject.addSource('allSystemsSource', {
        type: 'geojson',
        data: fileData.data
      })
      mapObject.addLayer({
        id: 'allSystemsLayer',
        type: 'circle',
        source: 'allSystemsSource',
        layout: {},
        paint: {
          'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            1, 10,
            12, 18,
            20, 50
          ],
          'circle-color': [
            'step',
            ['to-number', ['get', 'status']],
            '#dc3545',
            1, '#28a745'
          ]
        }
      })
      const popup = new maplibregl.Popup({ // eslint-disable-line
        closeButton: false,
        closeOnClick: false
      })
      mapObject.on('click', 'allSystemsLayer', function (e) {
        console.log('Clicked')
        const tsUrl = '/ts/' + e.features[0].properties.tsId
        window.open(tsUrl, '_blank')
      })
      mapObject.on('mouseenter', 'allSystemsLayer', function (e) {
        mapObject.getCanvas().style.cursor = 'pointer'
        const coordinates = e.features[0].geometry.coordinates.slice()
        const description = e.features[0].properties.name
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }
        popup.setLngLat(coordinates).setHTML(description).addTo(mapObject)
      })
      mapObject.on('mouseleave', 'allSystemsLayer', function (e) {
        mapObject.getCanvas().style.cursor = ''
        popup.remove()
      })
      // Loop through and get all activities
      const allActivities = {}
      fileData.data.features.forEach(trailSystem => {
        if (trailSystem.properties.activities) {
          trailSystem.properties.activities.forEach(activity => {
            if (!allActivities[activity.value]) {
              allActivities[activity.value] = activity
              allActivities[activity.value].count = 1
            } else {
              allActivities[activity.value].count++
            }
          })
        }
      })
      const allActivitiesArray = []
      const allActivityKeys = Object.keys(allActivities)
      allActivityKeys.forEach(key => {
        if (key !== 'undefined') {
          allActivitiesArray.push(allActivities[key])
        }
      })
      allActivitiesArray.sort(function (a, b) {
        if (a.count < b.count) { return 1 }
        if (a.count > b.count) { return -1 }
        return 0
      })
      this.$store.commit('setStateProperty', { property: 'allActivities', value: allActivitiesArray })
      // Get and map subscriptions
      const self = this
      setTimeout(() => {
        const subscriptionsGeoJson = {
          type: 'FeatureCollection',
          features: []
        }
        if (self.$store.state.publicUserProfile.subscriptions) {
          self.$store.state.publicUserProfile.subscriptions.forEach(subscription => {
            self.$store.state.allSystemsGeoJson.features.forEach(system => {
              if (system.properties.tsId === subscription) {
                subscriptionsGeoJson.features.push(system)
              }
            })
          })
        }
        self.$store.commit('setStateProperty', { property: 'subscriptionsGeoJson', value: subscriptionsGeoJson })
        mapObject.addSource('subscriptionsSource', {
          type: 'geojson',
          data: subscriptionsGeoJson
        })
        mapObject.addLayer({
          id: 'subscriptionsLayer',
          type: 'circle',
          source: 'subscriptionsSource',
          layout: {},
          paint: {
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              1, 10,
              12, 18,
              20, 50
            ],
            'circle-color': '#ffc107'
          }
        })
        mapObject.on('click', 'subscriptionsLayer', function (e) {
          console.log('Clicked')
          const tsUrl = '/ts/' + e.features[0].properties.tsId
          window.open(tsUrl, '_blank')
        })
        mapObject.on('mouseenter', 'subscriptionsLayer', function (e) {
          mapObject.getCanvas().style.cursor = 'pointer'
          const coordinates = e.features[0].geometry.coordinates.slice()
          const description = e.features[0].properties.name
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
          }
          popup.setLngLat(coordinates).setHTML(description).addTo(mapObject)
        })
        mapObject.on('mouseleave', 'subscriptionsLayer', function (e) {
          mapObject.getCanvas().style.cursor = ''
          popup.remove()
        })
        const geocoderControl = new MapboxGeocoder({ // eslint-disable-line
          accessToken: 'pk.eyJ1IjoidHJhaWxodWIiLCJhIjoiY2toamZ3Z3lsMDVpdjJycGVibm93b2Y2NSJ9.t9zR8pPj3btbMXqUdIB3dg',
          maplibregl: maplibregl, // eslint-disable-line
          marker: false,
          zoom: 12,
          flyTo: {
            animate: false
          }
        })
        mapObject.removeControl(self.$store.state.zoomControl)
        mapObject.removeControl(self.$store.state.locateControl)
        if (screen.width > 800) {
          mapObject.addControl(geocoderControl)
        }
        mapObject.addControl(self.$store.state.zoomControl)
        mapObject.addControl(self.$store.state.locateControl)
      }, 3000)
      mapObject.fitBounds([
        [-76.86, 40],
        [-67.36, 48]
      ])
      this.$store.commit('showDrawer', 'allSystems')
      console.log(fileData.data.features)
    }
  }
}
</script>

<style scoped>
#map {
  position: absolute;
  left: 0px;
  top: 0;
  width: 100%;
  height: 100%;
}
.map-toggle, .map-toggle-long {
  position: fixed;
  z-index: 1;
}

.map-toggle-long {
  max-width: 80vw;
}

#trailHubHome {
  top: 10px;
  left: 10px;
}

#settingsToggle {
  bottom: 34px;
  left: 10px;
}

#trailsToggle {
  top: 60px;
  left: 10px;
}

#trailsToggleMobile {
  display: none;
}

@media (max-width: 800px) {
  #trailsToggle {
    display: none;
  }
  #trailsToggleMobile {
    display: block;
    bottom: 34px;
    left: calc(50% - 80px);
  }

  #pointsToggle {
    top: 60px;
    left: 10px;
  }

  #updatesToggle {
    top: 116px;
    left: 10px;
  }
}
</style>

export default {
  setAlert (state, value) {
    state.alert = value
    if (value.timeout > 0) {
      setTimeout(function () {
        state.alert.active = false
      }, value.timeout)
    }
  },
  initTrailSystemMap (state) {
    // maplibregl.accessToken = 'pk.eyJ1IjoidHJhaWxodWIiLCJhIjoiY2toamZ3Z3lsMDVpdjJycGVibm93b2Y2NSJ9.t9zR8pPj3btbMXqUdIB3dg' // eslint-disable-line
    state.mapObject = new maplibregl.Map({ // eslint-disable-line
      container: 'map',
      style: 'https://orbit.ist/trailhub.json',
      center: [-72, 43],
      zoom: 6
    })
    state.zoomControl = new maplibregl.NavigationControl() // eslint-disable-line
    state.mapObject.addControl(state.zoomControl) // eslint-disable-line
    if (state.embedMode !== 'embed_noui') {
      state.locateControl = new maplibregl.GeolocateControl({ // eslint-disable-line
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      })
      state.mapObject.addControl(state.locateControl)
    }
    if (state.embedMode === 'embed' || state.embedMode === 'embed_noui') {
      state.mapObject.scrollZoom.disable()
    }
    setTimeout(() => {
      state.mapObject.resize()
    }, 1000)
  },
  setStateProperty (state, options) {
    state[options.property] = options.value
  },
  showDrawer (state, drawer) {
    state.drawer[drawer].show = true
    setTimeout(() => {
      state.drawer[drawer].position = 'in'
    }, 10)
    setTimeout(() => {
      const map = state.mapObject
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      if (vw >= 800) {
        map.easeTo({
          padding: { left: 500 },
          duration: 300
        })
      } else {
        const mapAdjustment = vh * 0.6
        map.easeTo({
          padding: { bottom: mapAdjustment },
          duration: 300
        })
      }
    }, 10)
  },
  hideDrawer (state, drawer) {
    state.drawer[drawer].position = 'out'
    const map = state.mapObject
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    if (vw >= 800) {
      map.easeTo({
        padding: { left: 0 },
        duration: 300
      })
    } else {
      map.easeTo({
        padding: { bottom: 0 },
        duration: 300
      })
    }
    setTimeout(() => {
      state.drawer[drawer].show = false
    }, 300)
  },
  hideAllDrawers (state) {
    const allDrawers = Object.keys(state.drawer)
    allDrawers.forEach(drawer => {
      state.drawer[drawer].position = 'out'
      setTimeout(() => {
        state.drawer[drawer].show = false
      }, 300)
    })
  }
}

<template>
  <div id="createTrail">
    <!-- User Details -->
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.createTrail.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="closeCreateTrail()"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 v-if="$store.state.editMode">{{ language(2) }}</h4>
        <h4 v-if="!$store.state.editMode">{{ language(1) }}</h4>
      </template>
      <b-form>
        <!-- NAME -->
        <label for="trail-name">{{ language(3) }} <span class="text-danger">*</span></label>
        <b-form-input
          id="trail-name"
          v-model="tempTrail.name"
        ></b-form-input>
        <!-- TYPE -->
        <label class="mt-2">{{ language(4) }}</label>
        <div>
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="tempTrail.type !== 'Standard'"
            @click="tempTrail.type = 'Standard'"
          ><i class="fas fa-map-signs"></i> {{ types.Standard[$store.state.trailSystem.language] }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="tempTrail.type === 'Standard'"
            @click="tempTrail.type = 'Standard'"
          ><i class="fas fa-map-signs"></i> {{ types.Standard[$store.state.trailSystem.language] }}</b-button>
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="tempTrail.type !== 'Lift'"
            @click="tempTrail.type = 'Lift'"
          ><i class="far fa-ski-lift"></i> {{ types.Lift[$store.state.trailSystem.language] }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="tempTrail.type === 'Lift'"
            @click="tempTrail.type = 'Lift'"
          ><i class="far fa-ski-lift"></i> {{ types.Lift[$store.state.trailSystem.language] }}</b-button>
        </div>
        <!-- STATUS -->
        <label class="mt-2">{{ language(5) }}<br><small>{{ language(32) }}</small></label>
        <div>
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="tempTrail.status !== 'Open'"
            @click="tempTrail.status = 'Open'"
          ><i class="fas fa-check-circle text-success"></i> {{ statuses.Open[$store.state.trailSystem.language] }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="tempTrail.status === 'Open'"
            @click="tempTrail.status = 'Open'"
          ><i class="fas fa-check-circle text-success"></i> {{ statuses.Open[$store.state.trailSystem.language] }}</b-button>
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="tempTrail.status !== 'Caution'"
            @click="tempTrail.status = 'Caution'"
          ><i class="fas fa-exclamation-circle text-warning"></i> {{ statuses.Caution[$store.state.trailSystem.language] }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="tempTrail.status === 'Caution'"
            @click="tempTrail.status = 'Caution'"
          ><i class="fas fa-exclamation-circle text-warning"></i> {{ statuses.Caution[$store.state.trailSystem.language] }}</b-button>
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="tempTrail.status !== 'Closed'"
            @click="tempTrail.status = 'Closed'"
          ><i class="fas fa-times-circle text-danger"></i> {{ statuses.Closed[$store.state.trailSystem.language] }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="tempTrail.status === 'Closed'"
            @click="tempTrail.status = 'Closed'"
          ><i class="fas fa-times-circle text-danger"></i> {{ statuses.Closed[$store.state.trailSystem.language] }}</b-button>
          <b-button
            variant="outline-secondary"
            v-if="tempTrail.status !== 'None'"
            @click="tempTrail.status = 'None'"
          >{{ statuses.None[$store.state.trailSystem.language] }}</b-button>
          <b-button
            variant="secondary"
            v-if="tempTrail.status === 'None'"
            @click="tempTrail.status = 'None'"
          >{{ statuses.None[$store.state.trailSystem.language] }}</b-button>
        </div>

        <!-- DESCRIPTION -->
        <label for="trail-notes" class="mt-2">{{ language(6) }}</label>
        <b-form-textarea
          id="trail-description"
          v-model="tempTrail.description"
        ></b-form-textarea>

        <!-- CATEGORIES -->
        <div v-if="trailSystem.categories && trailSystem.categories.length > 0">
          <label class="mt-2">{{ language(25) }}</label>
          <Multiselect
            v-model="tempTrail.categories"
            :options="trailSystem.categories"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="true"
            :preserve-search="false"
            :placeholder="language(26)"
          />
        </div><!-- /v-if categories lenth > 0 -->

        <!-- Difficulty -->
        <div v-if="tempTrail.type !== 'Lift'">
          <label class="mt-2">{{ language(7) }}</label>
          <div>
            <b-button
              class="mr-1 mb-1"
              variant="outline-secondary"
              v-if="tempTrail.difficulty !== 'circle'"
              @click="tempTrail.difficulty = 'circle'"
            ><span class="difficulty-marker circle"></span> {{ difficulties.circle[$store.state.trailSystem.language] }}</b-button>
            <b-button
              class="mr-1 mb-1"
              variant="secondary"
              v-if="tempTrail.difficulty === 'circle'"
              @click="tempTrail.difficulty = 'circle'"
            ><span class="difficulty-marker circle"></span> {{ difficulties.circle[$store.state.trailSystem.language] }}</b-button>
            <b-button
              class="mr-1 mb-1"
              variant="outline-secondary"
              v-if="tempTrail.difficulty !== 'square'"
              @click="tempTrail.difficulty = 'square'"
            ><span class="difficulty-marker square"></span> {{ difficulties.square[$store.state.trailSystem.language] }}</b-button>
            <b-button
              class="mr-1 mb-1"
              variant="secondary"
              v-if="tempTrail.difficulty === 'square'"
              @click="tempTrail.difficulty = 'square'"
            ><span class="difficulty-marker square"></span> {{ difficulties.square[$store.state.trailSystem.language] }}</b-button>
            <b-button
              class="mr-1 mb-1"
              variant="outline-secondary"
              v-if="tempTrail.difficulty !== 'diamond'"
              @click="tempTrail.difficulty = 'diamond'"
            ><span class="difficulty-marker diamond"></span> {{ difficulties.diamond[$store.state.trailSystem.language] }}</b-button>
            <b-button
              class="mr-1 mb-1"
              variant="secondary"
              v-if="tempTrail.difficulty === 'diamond'"
              @click="tempTrail.difficulty = 'diamond'"
            ><span class="difficulty-marker diamond"></span> {{ difficulties.diamond[$store.state.trailSystem.language] }}</b-button>
            <b-button
              class="mr-1 mb-1"
              variant="outline-secondary"
              v-if="tempTrail.difficulty !== 'doubleDiamond'"
              @click="tempTrail.difficulty = 'doubleDiamond'"
            ><span class="difficulty-marker diamond"></span><span class="difficulty-marker diamond"></span> {{ difficulties.doubleDiamond[$store.state.trailSystem.language] }}</b-button>
            <b-button
              class="mr-1 mb-1"
              variant="secondary"
              v-if="tempTrail.difficulty === 'doubleDiamond'"
              @click="tempTrail.difficulty = 'doubleDiamond'"
            ><span class="difficulty-marker diamond"></span><span class="difficulty-marker diamond"></span> {{ difficulties.doubleDiamond[$store.state.trailSystem.language] }}</b-button>
            <b-button
              class="mr-1 mb-1"
              variant="outline-secondary"
              v-if="tempTrail.difficulty !== 'terrainPark'"
              @click="tempTrail.difficulty = 'terrainPark'"
            ><span class="difficulty-marker terrainPark"></span> {{ difficulties.terrainPark[$store.state.trailSystem.language] }}</b-button>
            <b-button
              class="mr-1 mb-1"
              variant="secondary"
              v-if="tempTrail.difficulty === 'terrainPark'"
              @click="tempTrail.difficulty = 'terrainPark'"
            ><span class="difficulty-marker terrainPark"></span> {{ difficulties.terrainPark[$store.state.trailSystem.language] }}</b-button>
            <b-button
              class="mr-1 mb-1"
              variant="outline-secondary"
              v-if="tempTrail.difficulty !== 'notRated'"
              @click="tempTrail.difficulty = 'notRated'"
            >{{ difficulties.notRated[$store.state.trailSystem.language] }}</b-button>
            <b-button
              class="mr-1 mb-1"
              variant="secondary"
              v-if="tempTrail.difficulty === 'notRated'"
              @click="tempTrail.difficulty = 'notRated'"
            >{{ difficulties.notRated[$store.state.trailSystem.language] }}</b-button>
          </div>
        </div><!-- /difficulty -->
        <!-- Activities -->
        <div v-if="tempTrail.type !== 'Lift'">
          <label class="mt-2">{{ language(8) }}<br><small>*{{ language(9) }}</small></label>
          <div v-if="tempTrail.activities" class="form-group mt-2">
            <div class="container">
              <div
                v-for="(activity, i) in tempTrail.activities"
                v-bind:key="i"
                class="mb-2 card p-1"
              >
                <div class="row mb-1">
                  <div class="col-12">
                    <i :class="activity.iconClass"></i> {{ activity[$store.state.trailSystem.language] || activity.label }}
                  </div><!-- /.col-md-8 -->
                </div><!-- /.row -->

                <div class="row mb-1">
                  <div class="col-10">
                    <!-- <b-form-checkbox
                      v-model="tempTrail.activities[i].open"
                      switch
                    ></b-form-checkbox> -->
                    <!-- STATUS -->
                    <div class="mb-1">
                      <b-button
                        class="mr-1"
                        variant="outline-secondary"
                        v-if="tempTrail.activities[i].status !== 'Open'"
                        @click="tempTrail.activities[i].status = 'Open'"
                        size="sm"
                      ><i class="fas fa-check-circle text-success"></i></b-button>
                      <b-button
                        class="mr-1"
                        variant="secondary"
                        v-if="tempTrail.activities[i].status === 'Open'"
                        @click="tempTrail.activities[i].status = 'Open'"
                        size="sm"
                      ><i class="fas fa-check-circle text-success"></i></b-button>
                      <b-button
                        class="mr-1"
                        variant="outline-secondary"
                        v-if="tempTrail.activities[i].status !== 'Caution'"
                        @click="tempTrail.activities[i].status = 'Caution'"
                        size="sm"
                      ><i class="fas fa-exclamation-circle text-warning"></i></b-button>
                      <b-button
                        class="mr-1"
                        variant="secondary"
                        v-if="tempTrail.activities[i].status === 'Caution'"
                        @click="tempTrail.activities[i].status = 'Caution'"
                        size="sm"
                      ><i class="fas fa-exclamation-circle text-warning"></i></b-button>
                      <b-button
                        variant="outline-secondary"
                        v-if="tempTrail.activities[i].status !== 'Closed'"
                        @click="tempTrail.activities[i].status = 'Closed'"
                        size="sm"
                      ><i class="fas fa-times-circle text-danger"></i></b-button>
                      <b-button
                        variant="secondary"
                        v-if="tempTrail.activities[i].status === 'Closed'"
                        @click="tempTrail.activities[i].status = 'Closed'"
                        size="sm"
                      ><i class="fas fa-times-circle text-danger"></i></b-button>
                    </div>

                    <!-- DIFFICULTY -->
                    <div v-if="tempTrail.activities[i].difficulty">
                      <b-button
                        class="mr-1"
                        variant="outline-secondary"
                        v-if="tempTrail.activities[i].difficulty !== 'circle'"
                        @click="tempTrail.activities[i].difficulty = 'circle'"
                        size="sm"
                      ><span class="difficulty-marker circle"></span></b-button>
                      <b-button
                        class="mr-1"
                        variant="secondary"
                        v-if="tempTrail.activities[i].difficulty === 'circle'"
                        @click="tempTrail.activities[i].difficulty = 'circle'"
                        size="sm"
                      ><span class="difficulty-marker circle"></span></b-button>

                      <b-button
                        class="mr-1"
                        variant="outline-secondary"
                        v-if="tempTrail.activities[i].difficulty !== 'square'"
                        @click="tempTrail.activities[i].difficulty = 'square'"
                        size="sm"
                      ><span class="difficulty-marker square"></span></b-button>
                      <b-button
                        class="mr-1"
                        variant="secondary"
                        v-if="tempTrail.activities[i].difficulty === 'square'"
                        @click="tempTrail.activities[i].difficulty = 'square'"
                        size="sm"
                      ><span class="difficulty-marker square"></span></b-button>

                      <b-button
                        class="mr-1"
                        variant="outline-secondary"
                        v-if="tempTrail.activities[i].difficulty !== 'diamond'"
                        @click="tempTrail.activities[i].difficulty = 'diamond'"
                        size="sm"
                      ><span class="difficulty-marker diamond"></span></b-button>
                      <b-button
                        class="mr-1"
                        variant="secondary"
                        v-if="tempTrail.activities[i].difficulty === 'diamond'"
                        @click="tempTrail.activities[i].difficulty = 'diamond'"
                        size="sm"
                      ><span class="difficulty-marker diamond"></span></b-button>

                      <b-button
                        class="mr-1"
                        variant="outline-secondary"
                        v-if="tempTrail.activities[i].difficulty !== 'doubleDiamond'"
                        @click="tempTrail.activities[i].difficulty = 'doubleDiamond'"
                        size="sm"
                      ><span class="difficulty-marker diamond"></span><span class="difficulty-marker diamond"></span></b-button>
                      <b-button
                        class="mr-1"
                        variant="secondary"
                        v-if="tempTrail.activities[i].difficulty === 'doubleDiamond'"
                        @click="tempTrail.activities[i].difficulty = 'doubleDiamond'"
                        size="sm"
                      ><span class="difficulty-marker diamond"></span><span class="difficulty-marker diamond"></span></b-button>

                      <b-button
                        class="mr-1"
                        variant="outline-secondary"
                        v-if="tempTrail.activities[i].difficulty !== 'terrainPark'"
                        @click="tempTrail.activities[i].difficulty = 'terrainPark'"
                        size="sm"
                      ><span class="difficulty-marker terrainPark"></span></b-button>
                      <b-button
                        class="mr-1"
                        variant="secondary"
                        v-if="tempTrail.activities[i].difficulty === 'terrainPark'"
                        @click="tempTrail.activities[i].difficulty = 'terrainPark'"
                        size="sm"
                      ><span class="difficulty-marker terrainPark"></span></b-button>

                      <b-button
                        class="mr-1"
                        variant="outline-secondary"
                        v-if="tempTrail.activities[i].difficulty !== 'notRated'"
                        @click="tempTrail.activities[i].difficulty = 'notRated'"
                        size="sm"
                      >{{ difficulties.notRated[$store.state.trailSystem.language] }}</b-button>
                      <b-button
                        class="mr-1"
                        variant="secondary"
                        v-if="tempTrail.activities[i].difficulty === 'notRated'"
                        @click="tempTrail.activities[i].difficulty = 'notRated'"
                        size="sm"
                      >{{ difficulties.notRated[$store.state.trailSystem.language] }}</b-button>
                    </div>
                  </div><!-- /.col-md-4 -->
                  <div class="col-2 text-center">
                    <i @click="tempTrail.activities.splice(i, 1)" class="fas fa-minus-circle text-danger clickable"></i>
                  </div><!-- /.col-md-2 -->
                </div><!-- /.row -->
              </div><!-- /.card -->
            </div><!-- /.container -->
          </div>
          <b-input-group :prepend="language(12)" class="mt-2">
            <b-form-select
              v-model="newActivity"
              :options="activityOptions"
            ></b-form-select>
            <b-input-group-append>
              <b-button @click="addActivity()" variant="success">{{ language(13) }}</b-button>
            </b-input-group-append>
          </b-input-group>
        </div><!-- /activities -->
        <div class="form-group mt-2">
          <label>{{ language(14) }}</label>
          <p><small class="form-text text-muted mb-2">{{ language(15) }}</small></p>
          <input
            type="file"
            id="inputTrailFile"
            name="inputTrailFile"
            value="Import"
            class="mb-2"
            accept=".geojson,.zip,.kmz,.kml,.gpx"
          />
        </div><!-- /.form-group -->
        <div class="form-group mt-2" v-if="!$store.state.editMode && fileSelected">
          <b-form-checkbox
            id="multiTrailsCheckbox"
            v-model="multiTrails"
            name="multiTrailsCheckbox"
            :value="true"
            :unchecked-value="false"
          >
            {{ language(21) }}
          </b-form-checkbox>
          <p><small class="form-text text-muted mb-2">{{ language(22) }}</small></p>
        </div><!-- /.form-group -->

        <!-- LINE STYLE -->
        <label>{{ language(31) }}</label>
        <div>
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="tempTrail.lineStyle !== 'Solid'"
            @click="tempTrail.lineStyle = 'Solid'"
          ><span class="solid-line">–</span> {{ language(29) }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="tempTrail.lineStyle === 'Solid'"
            @click="tempTrail.lineStyle = 'Solid'"
          ><span class="solid-line">–</span> {{ language(29) }}</b-button>
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="tempTrail.lineStyle !== 'Dashed'"
            @click="tempTrail.lineStyle = 'Dashed'"
          ><span class="dashed-line">- -</span> {{ language(30) }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="tempTrail.lineStyle === 'Dashed'"
            @click="tempTrail.lineStyle = 'Dashed'"
          ><span class="dashed-line">- -</span> {{ language(30) }}</b-button>
        </div>
        <!-- ONE WAY SETTINGS -->
        <b-form-checkbox v-model="tempTrail.oneWay" name="check-button" switch class="mt-3">
          {{ language(27) }}
        </b-form-checkbox>
        <b-form-checkbox v-if="tempTrail.oneWay" v-model="tempTrail.oneWayReversed" name="check-button" switch class="mt-0">
          {{ language(28) }}
        </b-form-checkbox>

        <!-- HIDDEN-->
        <b-form-checkbox v-model="tempTrail.hidden" name="check-button" switch class="mt-3">
          {{ language(23) }}<br><small>{{ language(24) }}</small>
        </b-form-checkbox>

        <b-button variant="primary" class="mt-3 mr-1 w-100" @click="saveTrail()">{{ language(18) }}</b-button>
        <b-button
          variant="danger"
          class="mt-3"
          v-if="$store.state.editMode"
          @click="confirmTrash = true"
        >{{ language(19) }}</b-button>
      </b-form>
    </b-card>

    <Confirm
      v-if="confirmTrash"
      :text="language(20)"
      v-on:confirmed="trashTrail($event)"
    />

    <!-- Undo/redo interface elements -->
    <b-btn
      id="undoDraw"
      class="map-toggle"
      @click="undoDraw()"
      v-b-tooltip.hover.right="'Undo'"
      size="sm"
      v-if="drawHistoryIndex > 0"
    >
      <i class="fas fa-undo"></i>
    </b-btn>
    <b-btn
      id="redoDraw"
      class="map-toggle"
      @click="redoDraw()"
      v-b-tooltip.hover.right="'Redo'"
      size="sm"
      v-if="drawHistoryIndex < drawHistory.length - 1"
    >
      <i class="fas fa-redo"></i>
    </b-btn>

  </div><!-- /#createTrail -->
</template>

<script>
import firebase from 'firebase'
import Confirm from '../Confirm'
import Multiselect from 'vue-multiselect'
import shp from 'shpjs'
import activityOptions from '../../assets/lib/activityOptions.js'
const JSZip = require('jszip')
const toGeoJSON = require('@tmcw/togeojson')
const DOMParser = require('xmldom').DOMParser
const fb = require('../../firebaseConfig.js')
const turf = require('@turf/turf')
const gpxParser = require('gpxparser')

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Create Trail',
          fr: 'Créer un sentier'
        },
        2: {
          en: 'Edit Trail',
          fr: 'Modifier le sentier'
        },
        3: {
          en: 'Name',
          fr: 'Nom'
        },
        4: {
          en: 'Type',
          fr: 'Type'
        },
        5: {
          en: 'Status',
          fr: 'État'
        },
        6: {
          en: 'Description',
          fr: 'Description'
        },
        7: {
          en: 'Difficulty',
          fr: 'Difficulté'
        },
        8: {
          en: 'Activities',
          fr: 'Activités'
        },
        9: {
          en: 'Automatically ordered by status on save.',
          fr: 'Classé automatiquement par état au moment de l’enregistrement.'
        },
        10: {
          en: 'Activity',
          fr: 'Activité'
        },
        11: {
          en: 'Remove',
          fr: 'Retirer'
        },
        12: {
          en: 'Choose',
          fr: 'Choisir'
        },
        13: {
          en: 'Add activity',
          fr: 'Ajouter une activité'
        },
        14: {
          en: 'Import Trail File',
          fr: 'Importer un fichier de sentiers'
        },
        15: {
          en: 'Supported files include .gpx, .kmz, .kml, .geojson, and compressed shape files. You may also simply draw your trail on the map by first clicking on the yellow draw button on the right side of the map.',
          fr: 'Fichiers pris en charge : .gpx, .kmz, .kml, .geojson et les fichiers compressés. Vous pouvez aussi simplement dessiner votre sentier sur la carte; il vous suffit de cliquer sur le bouton jaune de dessin situé dans la partie droite de la carte.'
        },
        16: {
          en: 'Choose File',
          fr: 'Choisir le fichier'
        },
        17: {
          en: 'No file chosen',
          fr: 'Aucun fichier choisi'
        },
        18: {
          en: 'Save',
          fr: 'Enregistrer'
        },
        19: {
          en: 'Trash Trail',
          fr: 'Supprimer le sentier'
        },
        20: {
          en: 'DANGER! Are you sure you want to trash this Trail?',
          fr: 'DANGER! Êtes-vous sûr de vouloir supprimer ce sentier?'
        },
        21: {
          en: 'File contains multiple trails.',
          fr: 'Le fichier contient plusieurs pistes.'
        },
        22: {
          en: 'If checked, the system will try to generate multiple trails from this file. Use with caution. 100 trails maximum.',
          fr: 'Si coché, le système essaiera de générer plusieurs pistes à partir de ce fichier. Utiliser avec précaution. 100 pistes maximum.'
        },
        23: {
          en: 'Trail Hidden',
          fr: 'Sentier caché'
        },
        24: {
          en: 'This will hide this trail from public view.',
          fr: 'Cela cachera ce sentier à la vue du public.'
        },
        25: {
          en: 'Categories',
          fr: 'Catégories'
        },
        26: {
          en: 'Select categories',
          fr: 'Sélectionnez les catégories'
        },
        27: {
          en: 'One Way',
          fr: 'Sens unique'
        },
        28: {
          en: 'Reverse Direction',
          fr: 'Direction inverse'
        },
        29: {
          en: 'Solid',
          fr: 'Solide'
        },
        30: {
          en: 'Dashed',
          fr: 'Pointillé'
        },
        31: {
          en: 'Line Style',
          fr: 'Style de ligne'
        },
        32: {
          en: 'If set to \'None\', trail status will be inferred from activity statuses.',
          fr: 'S\'il est défini sur "Aucun", l\'état du parcours sera déduit des statuts d\'activité.'
        }
      },
      multiTrails: false,
      fileSelected: false,
      confirmTrash: false,
      drawHistory: [],
      drawHistoryIndex: 0,
      tempTrail: {
        name: '',
        categories: [],
        description: '',
        elevations: [],
        geoJson: false,
        hidden: false,
        lineStyle: 'Solid',
        distance: 0,
        oneWay: false,
        oneWayReversed: false,
        order: 0,
        status: 'Open',
        type: 'Standard',
        difficulty: 'circle',
        activities: []
      },
      newActivity: {},
      difficulties: {
        circle: {
          en: 'Beginner',
          fr: 'Débutant'
        },
        square: {
          en: 'Intermediate',
          fr: 'Intermédiaire'
        },
        diamond: {
          en: 'Expert',
          fr: 'Expert'
        },
        doubleDiamond: {
          en: 'Expert Only',
          fr: 'Expert seulement'
        },
        terrainPark: {
          en: 'Terrain Park',
          fr: 'Parc à neige'
        },
        notRated: {
          en: 'Not Rated',
          fr: 'Sans classement'
        }
      },
      types: {
        Standard: {
          en: 'Standard',
          fr: 'Standard'
        },
        Lift: {
          en: 'Lift',
          fr: 'Remonte-pente'
        }
      },
      statuses: {
        Open: {
          en: 'Open',
          fr: 'Ouvert'
        },
        Caution: {
          en: 'Caution',
          fr: 'Attention'
        },
        Closed: {
          en: 'Closed',
          fr: 'Fermé'
        },
        None: {
          en: 'None',
          fr: 'Aucune'
        }
      },
      activityOptions: activityOptions
    }
  },
  components: {
    Confirm,
    Multiselect
  },
  computed: {
    trail: {
      get () {
        return this.$store.state.trail
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trail', value: value })
      }
    },
    trailSystem: {
      get () {
        return this.$store.state.trailSystem
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trailSystem', value: value })
      }
    },
    drawControlsObjects: {
      get () {
        return this.$store.state.drawControlsObjects
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'drawControlsObjects', value: value })
      }
    }
  },
  created () {
    if (this.$store.state.editMode) {
      this.tempTrail = this.trail
      if (!this.tempTrail.type) {
        this.tempTrail.type = 'Standard'
      }
      this.tempTrail.activities.forEach(activity => {
        if (!activity.difficulty) {
          activity.difficulty = 'notRated'
        }
      })
    }
    // Deal with proper language of labels
    if (this.$store.state.trailSystem.language !== 'en') {
      this.activityOptions.forEach(option => {
        option.text = option.value[this.$store.state.trailSystem.language]
      })
    }
    setTimeout(() => {
      this.initMapControls()
    }, 300)
  },
  destroyed () {
    if (this.$store.state.mapObject.getLayer('trailsMutedLayer')) {
      this.$store.state.mapObject.removeLayer('trailsMutedLayer')
      this.$store.state.mapObject.removeSource('trailsMutedSource')
    }
    this.$store.commit('setStateProperty', { property: 'editMode', value: false })
    this.$store.state.mapObject.removeControl(this.drawControlsObjects[this.drawControlsObjects.length - 1])
    this.$store.dispatch('mapTrails')
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    undoDraw () {
      const lastIndex = this.drawHistoryIndex - 1
      if (lastIndex >= 0) {
        this.drawHistoryIndex = lastIndex
        const lastGeoJson = this.drawHistory[lastIndex]
        const objString = JSON.stringify(lastGeoJson)
        this.tempTrail.geoJson = objString
        this.drawControlsObjects[this.drawControlsObjects.length - 1].add(lastGeoJson)
      }
    },
    redoDraw () {
      const lastIndex = this.drawHistoryIndex + 1
      if (lastIndex < 20) { // because we're keeping 20 logs of history
        this.drawHistoryIndex = lastIndex
        const lastGeoJson = this.drawHistory[lastIndex]
        const objString = JSON.stringify(lastGeoJson)
        this.tempTrail.geoJson = objString
        this.drawControlsObjects[this.drawControlsObjects.length - 1].add(lastGeoJson)
      }
    },
    logDrawHistory (geoJson) {
      for (let i = this.drawHistory.length - 1; i > this.drawHistoryIndex; i--) {
        this.drawHistory.splice(i, 1)
      }
      this.drawHistory.push(geoJson)
      if (this.drawHistory.length > 20) {
        this.drawHistory.splice(0, 1)
      }
      this.drawHistoryIndex = this.drawHistory.length - 1
    },
    addActivity () {
      let addThis = true
      if (!this.tempTrail.activities) {
        this.tempTrail.activities = []
      }
      if (!this.newActivity.value) {
        this.$store.commit('setAlert', { active: true, message: 'You must first select an activity from the dropdown!', level: 'alert-warning', timeout: 6000 })
        addThis = false
      }
      this.tempTrail.activities.forEach(activity => {
        if (activity.value === this.newActivity.value) {
          this.$store.commit('setAlert', { active: true, message: 'You already added that activity to this trail!', level: 'alert-warning', timeout: 6000 })
          addThis = false
        }
      })
      if (addThis) {
        this.tempTrail.activities.push(this.newActivity)
      }
      this.newActivity = {}
    },
    closeCreateTrail () {
      this.$store.state.mapObject.setStyle('https://orbit.ist/trailhub.json')
      this.$store.commit('hideDrawer', 'createTrail')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'trails')
      }, 300)
    },
    trashTrail (confirmed) {
      const trailId = this.$store.state.trail.id
      const trailDoc = this.$store.state.trail
      if (confirmed) {
        this.confirmTrash = false
        fb.trashedTrailsCollection.doc(trailId).set(trailDoc).then(() => {
          return fb.trailsCollection.doc(trailId).delete()
        }).then(() => {
          fb.compileRequestsCollection.add({
            createdOn: new Date(),
            createdBy: this.$store.state.currentUser.uid,
            trailSystemId: this.$store.state.trailSystem.id,
            oldGeoJsonPath: this.$store.state.trailSystem.geoJsonPath
          })
          this.closeCreateTrail()
          this.$store.commit('setAlert', { active: true, message: 'Trashing trail!', level: 'alert-success', timeout: 4000 })
        }).catch(err => {
          console.log(err.message)
        })
      } else {
        this.confirmTrash = false
        return false
      }
    },
    initMapControls () {
      const map = this.$store.state.mapObject
      const self = this
      if (map.getLayer('trailHighlightLayer')) {
        map.removeLayer('trailHighlightBorderLayer')
        map.removeLayer('trailHighlightLayer')
      }
      if (map.getLayer('trailHighlightDirLayer')) {
        map.removeLayer('trailHighlightDirLayer')
        map.removeLayer('trailHighlightDirRevLayer')
      }
      if (map.getLayer('trailsLayer')) {
        map.removeLayer('trailsLayer')
        map.removeLayer('trailsDashedLayer')
        map.removeLayer('liftsLayer')
        map.removeLayer('trailsLabelsLayer')
      }
      if (map.getLayer('trailsDirLayer')) {
        map.removeLayer('trailsDirLayer')
        map.removeLayer('trailsDirRevLayer')
      }
      if (map.getSource('trailsSource')) {
        map.removeSource('trailsSource')
      }
      map.setStyle('https://orbit.ist/dark.json')

      // map.on('style.load', function () {
      setTimeout(() => {
        console.log('Style loaded')
        map.addSource('trailsMutedSource', {
          type: 'geojson',
          data: self.$store.state.trailsGeoJson
        })
        map.addLayer({
          id: 'trailsMutedLayer',
          type: 'line',
          source: 'trailsMutedSource',
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': 'rgba(255,255,255,0.3)',
            'line-width': 2
          }
        })
      }, 2000)
      // })
      // DRAW SETTINGS
      const newDrawControl = new MapboxDraw({ // eslint-disable-line
        displayControlsDefault: true,
        controls: {
          point: false,
          polygon: false,
          combine_features: false,
          uncombine_features: false
        }
      })
      this.drawControlsObjects.push(newDrawControl)

      map.addControl(this.drawControlsObjects[this.drawControlsObjects.length - 1])
      // If in edit mode, add the existing geoJson to draw
      if (this.tempTrail.geoJson) {
        const trailLine = JSON.parse(this.tempTrail.geoJson)
        if (trailLine.features[0].geometry.coordinates.length > 1) {
          this.drawControlsObjects[this.drawControlsObjects.length - 1].add(trailLine)
          map.fitBounds(turf.bbox(trailLine), {
            padding: 20,
            animate: false
          })
        }
      }
      // When A Feature is created, write it to this.block.footprint
      map.on('draw.create', function (e) {
        self.tempTrail.geoJson = JSON.stringify(self.drawControlsObjects[self.drawControlsObjects.length - 1].getAll())
        self.logDrawHistory(self.drawControlsObjects[self.drawControlsObjects.length - 1].getAll())
      })
      map.on('draw.delete', function (e) {
        self.tempTrail.geoJson = JSON.stringify(self.drawControlsObjects[self.drawControlsObjects.length - 1].getAll())
        self.logDrawHistory(self.drawControlsObjects[self.drawControlsObjects.length - 1].getAll())
      })
      map.on('draw.update', function (e) {
        self.tempTrail.geoJson = JSON.stringify(self.drawControlsObjects[self.drawControlsObjects.length - 1].getAll())
        self.logDrawHistory(self.drawControlsObjects[self.drawControlsObjects.length - 1].getAll())
      })
      // GEOJSON NORMALIZATION AND CLEANING
      function normalizeGeojson (geojson) {
        const origGJ = JSON.parse(JSON.stringify(geojson))
        if (geojson.type === 'FeatureCollection') {
          if (geojson.properties) {
            delete geojson.properties
          }
          console.log(geojson)
          // REMOVE ANY NULL / BAD APPLE COORDINATES OR FEATURES
          for (let i = geojson.features.length - 1; i >= 0; i--) {
            if (!geojson.features[i].geometry) {
              geojson.features.splice(i, 1)
            } else if (geojson.features[i].geometry.type === 'GeometryCollection' || geojson.features[i].geometry.type === 'MultiLineString') {
              console.log('GeometryCollection or MultiLineString detected')
            } else if (geojson.features[i].geometry.type !== 'LineString') {
              geojson.features.splice(i, 1)
            } else {
              for (let me = geojson.features[i].geometry.coordinates.length - 1; me >= 0; me--) {
                for (let we = 0; we < geojson.features[i].geometry.coordinates[me].length; we++) {
                  if (typeof geojson.features[i].geometry.coordinates[me][we] !== 'number') {
                    if (we < 2) {
                      geojson.features[i].geometry.coordinates.splice(me, 1)
                    } else {
                      geojson.features[i].geometry.coordinates[me][we] = 0
                    }
                  }
                }
              }
            }
          }
          // CHECK TO SEE IF ANYTHING IS LEFT. IF SO, TRY CREATING A LINE USING POINTS
          if (geojson.features.length === 0) {
            console.log('No lines found! Trying to build a line out of points!')
            const constructedLineFeature = {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'LineString',
                coordinates: []
              }
            }
            origGJ.features.forEach(feat => {
              if (feat.geometry.type === 'Point') {
                constructedLineFeature.geometry.coordinates.push(feat.geometry.coordinates)
              }
            })
            geojson.features.push(constructedLineFeature)
          }
          return Object.assign(
            {},
            geojson,
            {
              features: geojson.features.reduce(
                (result, each) => result.concat(normalizeGeojson(each)),
                []
              )
            }
          )
        }
        if (geojson.type === 'Feature' && geojson.geometry.type === 'GeometryCollection') {
          return geojson.geometry.geometries.map((geometry) => Object.assign(
            {},
            geojson,
            { geometry }
          ))
        }
        return geojson
      }

      // Setup listeners for file upload
      function onGeoJsonLoad (event) {
        const obj = JSON.parse(event.target.result)
        if (obj.crs) {
          delete obj.crs
        }
        const normalizedGeojson = normalizeGeojson(obj)
        var objString = JSON.stringify(normalizedGeojson)
        self.tempTrail.geoJson = objString
        self.drawControlsObjects[self.drawControlsObjects.length - 1].add(normalizedGeojson)
        map.fitBounds(turf.bbox(normalizedGeojson), {
          padding: 20,
          animate: false
        })
      }

      function onShpLoad (event) {
        shp(event.target.result).then(shpgeojson => {
          const normalizedGeojson = normalizeGeojson(shpgeojson)
          var objString = JSON.stringify(normalizedGeojson)
          self.tempTrail.geoJson = objString
          self.drawControlsObjects[self.drawControlsObjects.length - 1].add(normalizedGeojson)
          map.fitBounds(turf.bbox(shpgeojson), {
            padding: 20,
            animate: false
          })
        })
      }

      function onFileSelect (event) {
        self.fileSelected = true
        if (event.target.files.length === 0) {
          console.log('No file selected.')
          return
        }

        // What is file's extension?
        const fileName = event.target.files[0].name
        const lastDot = fileName.lastIndexOf('.')
        const fileExt = fileName.substring(lastDot + 1)
        const reader = new FileReader()

        if (fileExt === 'kmz') {
          const getDom = xml => (new DOMParser()).parseFromString(xml, 'text/xml')
          const getExtension = fileName => fileName.split('.').pop()
          const getKmlDom = (kmzFile) => {
            var zip = new JSZip()
            return zip.loadAsync(kmzFile).then(zip => {
              let kmlDom = null
              zip.forEach((relPath, file) => {
                if (getExtension(relPath) === 'kml' && kmlDom === null) {
                  kmlDom = file.async('string').then(getDom)
                }
              })
              return kmlDom
            })
          }
          const geoJson = getKmlDom(event.target.files[0]).then(kmlDom => {
            const geoJsonObject = toGeoJSON.kml(kmlDom)
            return geoJsonObject
          })
          geoJson.then(gj => {
            const normalizedGeojson = normalizeGeojson(gj)
            const objString = JSON.stringify(normalizedGeojson)
            self.tempTrail.geoJson = objString
            self.drawControlsObjects[self.drawControlsObjects.length - 1].add(normalizedGeojson)
            map.fitBounds(turf.bbox(normalizedGeojson), {
              padding: 20,
              animate: false
            })
          })
        }

        if (fileExt === 'kml') {
          const kmlFile = event.target.files[0]
          const reader = new FileReader()
          reader.onload = function () {
            const readerResult = reader.result
            const parsedKML = new DOMParser().parseFromString(readerResult, 'utf8')
            const geoJsonObject = toGeoJSON.kml(parsedKML)
            const normalizedGeojson = normalizeGeojson(geoJsonObject)
            const objString = JSON.stringify(normalizedGeojson)
            self.tempTrail.geoJson = objString
            self.drawControlsObjects[self.drawControlsObjects.length - 1].add(normalizedGeojson)
            map.fitBounds(turf.bbox(normalizedGeojson), {
              padding: 20,
              animate: false
            })
          }
          reader.readAsText(kmlFile)
        }

        if (fileExt === 'gpx') {
          const gpxFile = event.target.files[0]
          const reader = new FileReader()
          reader.onload = function () {
            const readerResult = reader.result
            const gpx = new gpxParser() // eslint-disable-line
            gpx.parse(readerResult)
            const gpxGeoJSON = gpx.toGeoJSON()
            const normalizedGeojson = normalizeGeojson(gpxGeoJSON)
            const objString = JSON.stringify(normalizedGeojson)
            self.tempTrail.geoJson = objString
            self.drawControlsObjects[self.drawControlsObjects.length - 1].add(normalizedGeojson)
            map.fitBounds(turf.bbox(normalizedGeojson), {
              padding: 20,
              animate: false
            })
          }
          reader.readAsText(gpxFile)
        }

        if (fileExt === 'geojson') {
          reader.onload = onGeoJsonLoad
          reader.readAsText(event.target.files[0])
        }

        if (fileExt === 'zip' || fileExt === 'shp') {
          reader.onload = onShpLoad
          reader.readAsArrayBuffer(event.target.files[0])
        }
      }
      document.getElementById('inputTrailFile').addEventListener('change', onFileSelect)
    },
    saveTrail () {
      console.log(this.tempTrail)
      const self = this
      async function addTrail (trailObj) {
        trailObj.geoJson.features[0].properties = {}
        console.log(trailObj)
        const thisTrailLength = turf.length(trailObj.geoJson, { units: 'kilometers' })
        let thisTrailCoords
        if (Array.isArray(trailObj.geoJson.features[0].geometry.coordinates[0][0])) {
          thisTrailCoords = new firebase.firestore.GeoPoint(trailObj.geoJson.features[0].geometry.coordinates[0][0][1], trailObj.geoJson.features[0].geometry.coordinates[0][0][0])
        } else {
          thisTrailCoords = new firebase.firestore.GeoPoint(trailObj.geoJson.features[0].geometry.coordinates[0][1], trailObj.geoJson.features[0].geometry.coordinates[0][0])
        }
        await fb.trailsCollection.add({
          activities: self.tempTrail.activities || [],
          categories: self.tempTrail.categories || [],
          createdBy: self.$store.state.currentUser.uid,
          createdOn: new Date(),
          difficulty: trailObj.difficulty,
          elevations: false,
          geoJson: JSON.stringify(trailObj.geoJson) || {},
          hidden: false,
          lineStyle: self.tempTrail.lineStyle || 'Solid',
          distance: thisTrailLength,
          description: self.tempTrail.description || '',
          name: trailObj.name,
          order: trailObj.order,
          trailSystemId: self.$store.state.trailSystem.id,
          geolocation: thisTrailCoords,
          status: self.tempTrail.status,
          type: self.tempTrail.type,
          updatedBy: self.$store.state.currentUser.uid,
          updatedOn: new Date()
        }).then(() => {
          return true
        }).catch(err => {
          console.log(err)
          return false
        })
      }
      // Trail Length
      let trailGeoJson = false
      let trailLength = false
      let trailCoords = false
      if (this.tempTrail.geoJson) {
        trailGeoJson = JSON.parse(this.tempTrail.geoJson)
        trailLength = turf.length(trailGeoJson, { units: 'kilometers' })
        if (trailGeoJson.features.length < 1) {
          trailGeoJson.features.push({"type":"Feature","properties":{},"geometry":{"type": "LineString","coordinates": []}}) // eslint-disable-line
          this.tempTrail.geoJson = '{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{"type": "LineString","coordinates": []}}]}'
        }
        if (trailGeoJson.features[0].geometry.coordinates.length > 0) {
          if (Array.isArray(trailGeoJson.features[0].geometry.coordinates[0][0])) {
            // Handle multiline strings
            trailCoords = new firebase.firestore.GeoPoint(trailGeoJson.features[0].geometry.coordinates[0][0][1], trailGeoJson.features[0].geometry.coordinates[0][0][0])
          } else {
            // Handle standard lines
            trailCoords = new firebase.firestore.GeoPoint(trailGeoJson.features[0].geometry.coordinates[0][1], trailGeoJson.features[0].geometry.coordinates[0][0])
          }
        }
      } else {
        this.tempTrail.geoJson = '{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{"type": "LineString","coordinates": []}}]}'
      }
      if (!this.tempTrail.name) {
        this.$store.commit('setAlert', { active: true, message: 'You must name your trail!', level: 'alert-warning', timeout: 6000 })
        return false
      }
      if (this.$store.state.editMode) {
        let geoLocationData
        if (!trailGeoJson || trailGeoJson.features[0].geometry.coordinates.length < 1) {
          geoLocationData = false
        } else if (Array.isArray(trailGeoJson.features[0].geometry.coordinates[0][0])) {
          geoLocationData = new firebase.firestore.GeoPoint(trailGeoJson.features[0].geometry.coordinates[0][0][1], trailGeoJson.features[0].geometry.coordinates[0][0][0])
        } else {
          geoLocationData = new firebase.firestore.GeoPoint(trailGeoJson.features[0].geometry.coordinates[0][1], trailGeoJson.features[0].geometry.coordinates[0][0])
        }

        // Close all activities if closing the trail
        if (this.tempTrail.status === 'Closed') {
          this.tempTrail.activities.forEach((activity, i) => {
            activity.status = 'Closed'
          })
        }

        // if changes made to trail footprint then make elevations false
        let elevationsArray = this.tempTrail.elevations
        console.log(this.drawHistory.length)
        if (this.drawHistory.length > 0) {
          console.log('New footprint detected. Recalculate elevations.')
          elevationsArray = false
        }
        fb.trailsCollection.doc(this.trail.id).update({
          activities: this.tempTrail.activities || [],
          categories: this.tempTrail.categories || [],
          difficulty: this.tempTrail.difficulty,
          elevations: elevationsArray,
          geoJson: this.tempTrail.geoJson,
          hidden: this.tempTrail.hidden || false,
          lineStyle: this.tempTrail.lineStyle || 'Solid',
          distance: trailLength,
          description: this.tempTrail.description || '',
          name: this.tempTrail.name,
          oneWay: this.tempTrail.oneWay || false,
          oneWayReversed: this.tempTrail.oneWayReversed || false,
          order: this.tempTrail.order,
          geolocation: geoLocationData,
          status: this.tempTrail.status,
          type: this.tempTrail.type,
          updatedBy: this.$store.state.currentUser.uid,
          updatedOn: new Date()
        }).then(ref => {
          fb.compileRequestsCollection.add({
            createdOn: new Date(),
            createdBy: this.$store.state.currentUser.uid,
            trailSystemId: this.$store.state.trailSystem.id,
            oldGeoJsonPath: this.$store.state.trailSystem.geoJsonPath
          })
          // this.closeCreateTrail()
          this.$store.commit('setAlert', { active: true, message: 'Updating trail!', level: 'alert-success', timeout: 4000 })
          this.$store.commit('hideDrawer', 'createTrail')
          this.$store.state.mapObject.setStyle('https://orbit.ist/trailhub.json')
          setTimeout(() => {
            this.$store.commit('showDrawer', 'trail')
          }, 300)
        }).catch(err => {
          this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 6000 })
          console.log(err)
        })
      } else {
        // If multiTrails is true, look through all the features and save individual trails
        if (!this.multiTrails) {
          fb.trailsCollection.add({
            activities: this.tempTrail.activities || [],
            categories: this.tempTrail.categories || [],
            createdBy: this.$store.state.currentUser.uid,
            createdOn: new Date(),
            difficulty: this.tempTrail.difficulty,
            elevations: false,
            geoJson: this.tempTrail.geoJson,
            hidden: this.tempTrail.hidden || false,
            lineStyle: this.tempTrail.lineStyle || 'Solid',
            distance: trailLength,
            description: this.tempTrail.description || '',
            name: this.tempTrail.name,
            oneWay: this.tempTrail.oneWay || false,
            oneWayReversed: this.tempTrail.oneWayReversed || false,
            order: -Math.abs(this.$store.state.trails.length),
            trailSystemId: this.$store.state.trailSystem.id,
            geolocation: trailCoords,
            status: this.tempTrail.status,
            type: this.tempTrail.type,
            updatedBy: this.$store.state.currentUser.uid,
            updatedOn: new Date()
          }).then(ref => {
            fb.compileRequestsCollection.add({
              createdOn: new Date(),
              createdBy: this.$store.state.currentUser.uid,
              trailSystemId: this.$store.state.trailSystem.id,
              oldGeoJsonPath: this.$store.state.trailSystem.geoJsonPath
            })
            this.closeCreateTrail()
            this.$store.commit('setAlert', { active: true, message: 'Creating trail!', level: 'alert-success', timeout: 4000 })
          }).catch(err => {
            this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 6000 })
            console.log(err)
          })
        } else {
          const trailsGeoJson = JSON.parse(this.tempTrail.geoJson)
          const trails = []
          trailsGeoJson.features.forEach(trailFeature => {
            const trail = {
              type: 'FeatureCollection',
              features: [trailFeature]
            }
            trails.push(trail)
          })
          const promises = []
          trails.forEach((trail, i) => {
            if (i < 200) {
              // Look for trail properties to snag
              let trailObjectDifficulty
              if (trail.features[0].properties.difficulty) {
                const diffVal = trail.features[0].properties.difficulty
                if (diffVal === 'circle' || diffVal === 'square' || diffVal === 'diamond' || diffVal === 'doubleDiamond' || diffVal === 'terrainPark' || diffVal === 'notRated') {
                  trailObjectDifficulty = diffVal
                } else {
                  trailObjectDifficulty = 'notRated'
                }
              } else {
                trailObjectDifficulty = 'notRated'
              }
              const trailObject = {
                name: trail.features[0].properties.name || self.tempTrail.name + '_' + i,
                difficulty: trailObjectDifficulty,
                geoJson: trail,
                order: i
              }
              const prom = addTrail(trailObject)
              promises.push(prom)
            }
          })
          Promise.all(promises).then(() => {
            fb.compileRequestsCollection.add({
              createdOn: new Date(),
              createdBy: this.$store.state.currentUser.uid,
              trailSystemId: this.$store.state.trailSystem.id,
              oldGeoJsonPath: this.$store.state.trailSystem.geoJsonPath
            })
            this.closeCreateTrail()
            this.$store.commit('setAlert', { active: true, message: 'Creating trails!', level: 'alert-success', timeout: 4000 })
          }).catch(err => {
            this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 6000 })
            console.log(err)
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.map-toggle {
  position: fixed;
  z-index: 3;
}

#undoDraw {
  top: 300px;
  right: 10px;
}

#redoDraw {
  top: 355px;
  right: 10px;
}

.solid-line, .dashed-line {
  font-weight: bold;
  color: #28a745;
}
</style>

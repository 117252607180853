import axios from 'axios'
import firebase from 'firebase'
const fb = require('../firebaseConfig.js')
const pfb = require('../publicFirebaseConfig.js')
const turf = require('@turf/turf')

export default {
  fetchTrustees ({ commit, state }) {
    // Get trailSystem Trustee details if we are logged in
    commit('setStateProperty', { property: 'ownerData', value: false })
    commit('setStateProperty', { property: 'trusteeData', value: false })
    commit('setStateProperty', { property: 'currentRole', value: false })
    if (state.currentUser) {
      fb.usersCollection.doc(state.trailSystem.ownerId).get().then(user => {
        const ownerData = user.data()
        ownerData.id = user.id
        commit('setStateProperty', { property: 'ownerData', value: ownerData })
      })
      // Get trustees' details and push to the farm object
      const trusteeData = []
      for (let i = 0; i < state.trailSystem.users.length; i++) {
        const user = state.trailSystem.users[i].id
        fb.usersCollection.doc(user).get().then(user => {
          const userData = user.data()
          userData.id = user.id
          trusteeData.push(userData)
        })
      }
      commit('setStateProperty', { property: 'trusteeData', value: trusteeData })
      if (state.trailSystem.ownerId === state.currentUser.uid) {
        commit('setStateProperty', { property: 'currentRole', value: 'Administrator' })
      } else if (state.trailSystem.permissions[state.currentUser.uid]) {
        commit('setStateProperty', { property: 'currentRole', value: state.trailSystem.permissions[state.currentUser.uid] })
      } else if (state.userProfile.superAdmin) {
        console.log('Welcome superAdmin!!')
        commit('setStateProperty', { property: 'currentRole', value: 'Administrator' })
      }
    }
    // END GET TRUSTEES
  },
  fetchExpiredUpdates ({ commit, state }, limit) {
    commit('setStateProperty', { property: 'expiredUpdates', value: [] })
    return new Promise((resolve, reject) => {
      fb.updatesCollection.where('trailSystemId', '==', state.trailSystem.id).where('expirationDate', '<=', new Date()).orderBy('expirationDate', 'desc').limit(limit).onSnapshot(snapshots => {
        const updateItems = []
        snapshots.forEach(doc => {
          const item = doc.data()
          item.id = doc.id
          updateItems.push(item)
        })
        commit('setStateProperty', { property: 'expiredUpdates', value: updateItems })
        resolve()
      })
    })
  },
  fetchUpdates ({ commit, state }) {
    return new Promise((resolve, reject) => {
      fb.updatesCollection.where('trailSystemId', '==', state.trailSystem.id).where('expirationDate', '>=', new Date()).orderBy('expirationDate', 'asc').limit(50).onSnapshot(snapshots => {
        const updateItems = []
        // commit('removeAttachedUpdates')
        snapshots.forEach(doc => {
          const item = doc.data()
          item.id = doc.id
          updateItems.push(item)
          // commit('attachUpdate', item)
        })
        commit('setStateProperty', { property: 'updates', value: updateItems })
        resolve()
      })
    })
  },
  removePointHighlight ({ commit, state }) {
    const map = state.mapObject
    if (map.getLayer('highlightPointLayer')) {
      map.removeLayer('highlightPointLayer')
    }
    if (map.getSource('highlightPointSource')) {
      map.removeSource('highlightPointSource')
    }
  },
  highlightPoint ({ commit, state }, point) {
    const map = state.mapObject
    // Map the point
    if (map.getLayer('highlightPointLayer')) {
      map.removeLayer('highlightPointLayer')
    }
    if (map.getSource('highlightPointSource')) {
      map.removeSource('highlightPointSource')
    }
    map.addSource('highlightPointSource', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [point]
      }
    })
    map.addLayer({
      id: 'highlightPointLayer',
      type: 'circle',
      source: 'highlightPointSource',
      paint: {
        'circle-color': '#333',
        'circle-radius': 20
      }
    })
  },
  removeTrailHighlight ({ commit, state }) {
    const map = state.mapObject
    if (map.getLayer('trailHighlightLayer')) {
      map.removeLayer('trailHighlightBorderLayer')
      map.removeLayer('trailHighlightLayer')
    }
    if (map.getLayer('trailHighlightDirLayer')) {
      map.removeLayer('trailHighlightDirLayer')
      map.removeLayer('trailHighlightDirRevLayer')
    }
  },
  highlightTrail ({ commit, state }, trailId) {
    const map = state.mapObject
    if (map.getLayer('trailHighlightLayer')) {
      map.removeLayer('trailHighlightBorderLayer')
      map.removeLayer('trailHighlightLayer')
    }
    if (map.getLayer('trailHighlightDirLayer')) {
      map.removeLayer('trailHighlightDirLayer')
      map.removeLayer('trailHighlightDirRevLayer')
    }
    map.addLayer({
      id: 'trailHighlightBorderLayer',
      type: 'line',
      source: 'trailsSource',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#6c757d',
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          16, 16,
          20, 50
        ]
      }
    })
    map.addLayer({
      id: 'trailHighlightLayer',
      type: 'line',
      source: 'trailsSource',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': [
          'match',
          ['get', 'status'],
          'Open',
          '#28a745',
          'Caution',
          '#ffc107',
          'Closed',
          '#dc3545',
          '#ccc'
        ],
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          16, 8,
          20, 25
        ]
      }
    })
    map.addLayer({
      id: 'trailHighlightDirLayer',
      type: 'line',
      source: 'trailsSource',
      paint: {
        'line-pattern': 'pattern',
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          16, 8,
          20, 25
        ]
      },
      filter: ['all', ['==', 'oneWay', true], ['==', 'oneWayReversed', false], ['==', 'trailId', trailId]]
    })
    map.addLayer({
      id: 'trailHighlightDirRevLayer',
      type: 'line',
      source: 'trailsSource',
      paint: {
        'line-pattern': 'patternRev',
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          16, 8,
          20, 25
        ]
      },
      filter: ['all', ['==', 'oneWay', true], ['==', 'oneWayReversed', true], ['==', 'trailId', trailId]]
    })
    map.setFilter('trailHighlightBorderLayer', ['==', 'trailId', trailId])
    map.setFilter('trailHighlightLayer', ['==', 'trailId', trailId])
    // map.setFilter('trailHighlightDirLayer', ['all', ['==', 'oneWay', true], ['==', 'oneWayReversed', false], ['==', 'trailId', trailId]])
    // map.setFilter('trailHighlightDirRevLayer', ['all', ['==', 'oneWay', true], ['==', 'oneWayReversed', true], ['==', 'trailId', trailId]])
  },
  updateTrailOrders ({ commit, state }) {
    async function updateTrail (id, index) {
      await fb.trailsCollection.doc(id).update({
        order: index
      }).then(() => {
        return false
      })
    }
    const promises = []
    state.trails.forEach((trail, i) => {
      if (trail.properties.order !== i) {
        const prom = updateTrail(trail.properties.trailId, i)
        promises.push(prom)
      }
    })
    return Promise.all(promises)
  },
  zoomToOrg ({ commit, state }) {
    const map = state.mapObject
    let bounds
    if (state.trailsGeoJson.features) {
      console.log(state.trailsGeoJson)
      bounds = turf.bbox(state.trailsGeoJson)
    }
    if ((state.trails.length < 1 && !state.trailsGeoJson.features) || bounds[0] === Infinity) {
      const lat = state.trailSystem.geolocation.latitude
      const lng = state.trailSystem.geolocation.longitude
      map.flyTo({
        center: [lng, lat],
        zoom: 15,
        animate: false,
        padding: 20,
        pitch: 0,
        bearing: 0
      })
    } else {
      map.fitBounds(bounds, {
        animate: false,
        padding: 20,
        pitch: 0,
        bearing: 0
      })
    }
  },
  mapTrails ({ commit, dispatch, state }) {
    // Check for special query strings
    // const queryStrings = new Proxy(new URLSearchParams(window.location.search), {
    //   get: (searchParams, prop) => searchParams.get(prop)
    // })
    // const hideTrails = queryStrings.hideTrails || false
    // console.log(queryStrings)
    // console.log(hideTrails)

    if (state.trailsGeoJson.type) {
      const map = state.mapObject
      const trailsGeoJson = state.trailsGeoJson
      // Map the layer
      if (map.getLayer('trailHighlightLayer')) {
        map.removeLayer('trailHighlightBorderLayer')
        map.removeLayer('trailHighlightLayer')
      }
      if (map.getLayer('trailHighlightDirLayer')) {
        map.removeLayer('trailHighlightDirLayer')
        map.removeLayer('trailHighlightDirRevLayer')
      }
      if (map.getLayer('trailsLayer')) {
        map.removeLayer('trailsLayer')
        map.removeLayer('trailsDashedLayer')
        map.removeLayer('trailsLabelsLayer')
        map.removeLayer('liftsLayer')
      }
      if (map.getLayer('trailsDirLayer')) {
        map.removeLayer('trailsDirLayer')
        map.removeLayer('trailsDirRevLayer')
      }

      if (map.getSource('trailsSource')) {
        map.removeSource('trailsSource')
      }
      // Loop through and remove markers
      state.mapMarkers.forEach(marker => {
        marker.remove()
      })

      // Order the geojson features by 'Caution', 'Open', 'Closed'
      const orderedTrailsGeoJson = {
        type: 'FeatureCollection',
        features: []
      }
      const trailSatusTypes = ['Caution', 'Open', 'Closed', 'None']
      trailSatusTypes.forEach(trailStatusType => {
        trailsGeoJson.features.forEach(trailFeature => {
          if (trailFeature.properties.status === trailStatusType) {
            orderedTrailsGeoJson.features.push(trailFeature)
          }
        })
      })
      console.log(orderedTrailsGeoJson)
      map.addSource('trailsSource', {
        type: 'geojson',
        data: orderedTrailsGeoJson
      })
      // Map Trail Layer
      map.addLayer({
        id: 'trailsLayer',
        type: 'line',
        source: 'trailsSource',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': [
            'match',
            ['get', 'status'],
            'Open',
            '#28a745',
            'Caution',
            '#ffc107',
            'Closed',
            '#dc3545',
            '#aaa'
          ],
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            12, 2,
            18, 8,
            20, 25
          ]
        },
        filter: ['==', '$type', 'LineString']
      })
      map.addLayer({
        id: 'trailsDashedLayer',
        type: 'line',
        source: 'trailsSource',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': [
            'match',
            ['get', 'status'],
            'Open',
            '#28a745',
            'Caution',
            '#ffc107',
            'Closed',
            '#dc3545',
            '#ccc'
          ],
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            12, 2,
            18, 8,
            20, 25
          ],
          'line-dasharray': [2, 2]
        },
        filter: ['==', '$type', 'LineString']
      })

      // MAP TRAIL DIRECTION
      let directionals = false
      trailsGeoJson.features.forEach(trailFeature => {
        if (trailFeature.properties.oneWay) {
          directionals = true
        }
      })
      if (directionals) {
        map.loadImage(
          '/static/oneWay.png',
          (err, image) => {
            // Throw an error if something goes wrong.
            if (err) throw err

            // Add the image to the map style.
            map.addImage('pattern', image)

            // Create a new layer and style it using `fill-pattern`.
            map.addLayer({
              id: 'trailsDirLayer',
              type: 'line',
              source: 'trailsSource',
              paint: {
                'line-pattern': 'pattern',
                'line-width': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  12, 2,
                  18, 18,
                  20, 25
                ]
              },
              filter: ['==', '$type', 'LineString']
            })
            map.setFilter('trailsDirLayer', ['all', ['==', 'oneWay', true], ['==', 'oneWayReversed', false], ['==', 'hidden', false], ['==', 'filtered', false]])
          }
        )
        map.loadImage(
          '/static/oneWayRev.png',
          (err, image) => {
            // Throw an error if something goes wrong.
            if (err) throw err

            // Add the image to the map style.
            map.addImage('patternRev', image)

            // Create a new layer and style it using `fill-pattern`.
            map.addLayer({
              id: 'trailsDirRevLayer',
              type: 'line',
              source: 'trailsSource',
              paint: {
                'line-pattern': 'patternRev',
                'line-width': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  12, 2,
                  18, 18,
                  20, 25
                ]
              },
              filter: ['==', '$type', 'LineString']
            })
            map.setFilter('trailsDirRevLayer', ['all', ['==', 'oneWay', true], ['==', 'oneWayReversed', true], ['==', 'hidden', false], ['==', 'filtered', false]])
          }
        )
      }

      // Map Text Labels
      map.addLayer({
        id: 'trailsLabelsLayer',
        type: 'symbol',
        source: 'trailsSource',
        layout: {
          'symbol-placement': 'line-center',
          'text-font': ['Open Sans Regular'],
          'text-field': '{name}',
          'text-size': 16,
          'text-rotate': -4,
          'text-letter-spacing': 0.2,
          'symbol-spacing': 1
        },
        paint: {
          'text-translate': [0, -20],
          'text-halo-width': 2,
          'text-halo-color': '#fff'
        }
      })
      // Map Lifts
      map.addLayer({
        id: 'liftsLayer',
        type: 'line',
        source: 'trailsSource',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-dasharray': [2, 2],
          'line-color': [
            'match',
            ['get', 'status'],
            'Open',
            '#28a745',
            'Caution',
            '#ffc107',
            'Closed',
            '#dc3545',
            '#ccc'
          ],
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            12, 2,
            18, 8,
            20, 25
          ]
        },
        filter: ['==', '$type', 'LineString']
      })
      // Filter for lifts
      map.setFilter('liftsLayer', ['==', 'type', 'Lift'])
      // Loop through and add point markers
      trailsGeoJson.features.forEach(feature => {
        if (feature.geometry && feature.geometry.type === 'Point' && !feature.properties.hidden) {
          const el = document.createElement('div')
          let waitTimeElement = ''
          let waitTimeLevel = ''
          if (feature.properties.markerClass === 'wait-time') {
            if (feature.properties.waitTime <= 5) { waitTimeLevel = ' text-success' }
            if (feature.properties.waitTime > 5 && feature.properties.waitTime <= 15) { waitTimeLevel = ' text-warning' }
            if (feature.properties.waitTime > 15) { waitTimeLevel = ' text-danger' }
            waitTimeElement = '<span class="marker-wait-time' + waitTimeLevel + '">' + feature.properties.waitTime + '</span><br><span class="marker-wait-time-label">min</span>'
          }
          let statusBadge = ''
          if (feature.properties.status) {
            if (feature.properties.status === 'Closed') {
              statusBadge = '<i style="color:#dc3545;" class="fas fa-times-circle status-badge"></i>'
            }
            if (feature.properties.status === 'Caution') {
              statusBadge = '<i style="color:#ffc107;" class="fas fa-exclamation-circle status-badge"></i>'
            }
          }
          el.className = 'marker ' + feature.properties.markerClass
          el.innerHTML = waitTimeElement + '<span class="marker-label">' + feature.properties.name + '</span>' + statusBadge
          el.addEventListener('click', () => {
            if (!state.drawer.createPoint.show && !state.drawer.createTrail.show) {
              dispatch('highlightPoint', feature)
              const pointId = feature.properties.pointId
              commit('hideAllDrawers')
              fb.pointsCollection.doc(pointId).get().then(doc => {
                const pointDoc = doc.data()
                pointDoc.id = doc.id
                commit('setStateProperty', { property: 'point', value: pointDoc })
                setTimeout(() => {
                  commit('showDrawer', 'pointDetails')
                }, 300)
              })
            }
          })
          const marker = new maplibregl.Marker(el).setLngLat(feature.geometry.coordinates).addTo(map) // eslint-disable-line
          state.mapMarkers.push(marker)
        }
      })

      // Show/hide markers
      state.mapObject.on('zoomend', function (e) {
        if (state.mapObject.getZoom() > 16) {
          document.getElementById('trailMap').classList.add('show-marker-labels')
        } else {
          document.getElementById('trailMap').classList.remove('show-marker-labels')
        }
      })

      // MOUSE ENTER AND LEAVE POINTERS
      state.mapObject.on('mouseenter', 'trailsLayer', function () {
        state.mapObject.getCanvas().style.cursor = 'pointer'
      })
      state.mapObject.on('mouseleave', 'trailsLayer', function () {
        state.mapObject.getCanvas().style.cursor = ''
      })
      state.mapObject.on('mouseenter', 'trailsDashedLayer', function () {
        state.mapObject.getCanvas().style.cursor = 'pointer'
      })
      state.mapObject.on('mouseleave', 'trailsDashedLayer', function () {
        state.mapObject.getCanvas().style.cursor = ''
      })
      state.mapObject.on('mouseenter', 'liftsLayer', function () {
        state.mapObject.getCanvas().style.cursor = 'pointer'
      })
      state.mapObject.on('mouseleave', 'liftsLayer', function () {
        state.mapObject.getCanvas().style.cursor = ''
      })
      // CLICK EVENTS
      state.mapObject.on('click', 'trailsLayer', function (e) {
        if (!state.drawer.createPoint.show) {
          const trailId = e.features[0].properties.trailId
          commit('hideAllDrawers')
          setTimeout(() => {
            commit('showDrawer', 'trail')
          }, 300)
          fb.trailsCollection.doc(trailId).get().then(doc => {
            const trailDoc = doc.data()
            trailDoc.id = doc.id
            commit('setStateProperty', { property: 'trail', value: trailDoc })
          })
        }
      })
      state.mapObject.on('click', 'trailsDashedLayer', function (e) {
        if (!state.drawer.createPoint.show) {
          const trailId = e.features[0].properties.trailId
          commit('hideAllDrawers')
          setTimeout(() => {
            commit('showDrawer', 'trail')
          }, 300)
          fb.trailsCollection.doc(trailId).get().then(doc => {
            const trailDoc = doc.data()
            trailDoc.id = doc.id
            commit('setStateProperty', { property: 'trail', value: trailDoc })
          })
        }
      })
      state.mapObject.on('click', 'liftsLayer', function (e) {
        if (!state.drawer.createPoint.show) {
          const trailId = e.features[0].properties.trailId
          commit('hideAllDrawers')
          setTimeout(() => {
            commit('showDrawer', 'trail')
          }, 300)
          fb.trailsCollection.doc(trailId).get().then(doc => {
            const trailDoc = doc.data()
            trailDoc.id = doc.id
            commit('setStateProperty', { property: 'trail', value: trailDoc })
          })
        }
      })

      // FILTERS
      state.mapObject.setFilter('trailsLayer', ['all', ['==', 'type', 'Standard'], ['==', 'lineStyle', 'Solid'], ['==', 'hidden', false], ['==', 'filtered', false]])
      state.mapObject.setFilter('trailsDashedLayer', ['all', ['==', 'type', 'Standard'], ['==', 'lineStyle', 'Dashed'], ['==', 'hidden', false], ['==', 'filtered', false]])
      state.mapObject.setFilter('trailsLabelsLayer', ['all', ['==', 'type', 'Standard'], ['==', 'hidden', false], ['==', 'filtered', false]])
      // state.mapObject.setFilter('trailsLayer', ['==', 'hidden', false])
      // Filter out lifts
      // map.setFilter('trailsLayer', ['==', 'type', 'Standard'])
      // if (state.trailSystem.ownerId === state.currentUser.uid || state.trailSystem.permissions[state.currentUser.uid]) {
      //
      // } else {
      //   state.mapObject.setFilter('trailsLayer', ['==', 'hidden', false])
      // }
    }
  },
  fetchTrails ({ commit, state }) {
    const geoJsonPath = state.trailSystem.geoJsonPath
    if (!geoJsonPath) {
      return
    }
    const geoJsonStorageRef = firebase.storage().ref(geoJsonPath)
    let trailsGeoJson
    const trails = []
    const points = []
    return new Promise((resolve, reject) => {
      // Get geoJson File
      geoJsonStorageRef.getDownloadURL().then(geoJsonUrl => {
        return axios.get(geoJsonUrl)
      }).then(response => {
        trailsGeoJson = response.data
        commit('setStateProperty', { property: 'trailsGeoJson', value: trailsGeoJson })
        trailsGeoJson.features.forEach(feature => {
          feature.properties.filtered = false
          if (feature.properties.showInLists && feature.properties.type !== 'Lift') {
            if (state.trailSystem.ownerId === state.currentUser.uid || state.trailSystem.permissions[state.currentUser.uid]) {
              trails.push(feature)
            } else if (!feature.properties.hidden) {
              trails.push(feature)
            }
          } else if (feature.geometry.type === 'Point') {
            points.push(feature)
          }
        })
        const trailsGeoJsonDefault = JSON.parse(JSON.stringify(trailsGeoJson))
        commit('setStateProperty', { property: 'trailsGeoJsonDefault', value: trailsGeoJsonDefault })
        commit('setStateProperty', { property: 'trails', value: trails })
        commit('setStateProperty', { property: 'points', value: points })
        resolve()
      }).catch(err => {
        console.log(err)
      })
    })
  },
  async fetchTrailSystems ({ commit, state }) {
    console.log('Fetch Trail Systems')
    const userId = state.currentUser.uid
    const trailSystems = []

    await fb.trailSystemsCollection.where('ownerId', '==', userId).orderBy('createdOn', 'desc').limit(300).get().then(snapshots => {
      snapshots.forEach(doc => {
        const item = doc.data()
        item.id = doc.id
        item.role = 'Owner'
        trailSystems.push(item)
      })
      return fb.trailSystemsCollection.where('users', 'array-contains', { id: userId }).orderBy('createdOn', 'desc').limit(300).get()
    }).then(snapshots => {
      console.log('Got users')
      snapshots.forEach(doc => {
        const item = doc.data()
        item.id = doc.id
        item.role = item.permissions[userId]
        trailSystems.push(item)
      })
      commit('setStateProperty', { property: 'trailSystems', value: trailSystems })
      // resolve()
      console.log('Resolved')
      return true
    }).catch(err => {
      console.log(err)
      return false
    })

    // return new Promise((resolve, reject) => {
    //   console.log('Promise')
    //   return fb.trailSystemsCollection.where('ownerId', '==', userId).orderBy('createdOn', 'desc').limit(300).get().then(snapshots => {
    //     snapshots.forEach(doc => {
    //       const item = doc.data()
    //       item.id = doc.id
    //       item.role = 'Owner'
    //       trailSystems.push(item)
    //     })
    //     return fb.trailSystemsCollection.where('users', 'array-contains', { id: userId }).orderBy('createdOn', 'desc').limit(300).get()
    //   }).then(snapshots => {
    //     console.log('Got users')
    //     snapshots.forEach(doc => {
    //       const item = doc.data()
    //       item.id = doc.id
    //       item.role = item.permissions[userId]
    //       trailSystems.push(item)
    //     })
    //     commit('setStateProperty', { property: 'trailSystems', value: trailSystems })
    //     resolve()
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // })
  },
  initTrailSystemMap ({ commit, state }) {
    commit('initTrailSystemMap')
  },
  clearUserData ({ commit }) {
    commit('setStateProperty', { property: 'currentUser', value: {} })
    commit('setStateProperty', { property: 'userProfile', value: {} })
  },
  fetchUserProfile ({ commit, state }) {
    fb.usersCollection.doc(state.currentUser.uid).onSnapshot(res => {
      commit('setStateProperty', { property: 'userProfile', value: res.data() })
    })
  },
  fetchPublicUserProfile ({ commit, state }) {
    pfb.usersCollection.doc(state.currentPublicUser.uid).onSnapshot(res => {
      commit('setStateProperty', { property: 'publicUserProfile', value: res.data() })
    })
  }
}

export default {
  active: true,
  changeOwner: false,
  displayList: false,
  filteredResults: [],
  inactive: false,
  initialLoad: true,
  showTrailSystem: false,
  trailSystem: {},
  trailSystems: [],
  trial: true
}

<template>
  <div id="trail">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.trail.position"
      :style="cardStyles"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="closeTrail()"
        >
          <i class="far fa-times"></i>
        </a>
        <a
          class="tuck-button clickable"
          @click="tuckTrail()"
          v-if="!cardTucked"
        >
          <i class="fas fa-chevron-down"></i>
        </a>
        <a
          class="tuck-button clickable"
          @click="tuckTrail()"
          v-if="cardTucked"
        >
          <i class="fas fa-chevron-up"></i>
        </a>
        <h4 class="card-title">
          <span v-if="trail.type !== 'Lift'">
            <span v-if="trail.difficulty != 'doubleDiamond'" :class="trail.difficulty" class="difficulty-marker ml-1"></span>
            <span v-if="trail.difficulty == 'doubleDiamond'" class="difficulty-marker diamond ml-1"></span><span v-if="trail.difficulty == 'doubleDiamond'" class="difficulty-marker diamond"></span>
          </span>
          <span v-else><i class="far fa-ski-lift"></i></span>
          {{ trail.name }} <small><span v-if="trail.hidden">({{ language(2) }})</span>
          <b-badge
            v-if="trail.oneWay"
            pill
            variant="warning"
            class="mb-2"
          ><i class="fas fa-long-arrow-alt-right"></i> {{ language(3) }}</b-badge>
          </small>
        </h4>
        <b-btn
          @click="editTrail()"
          class="mb-2"
          v-if="$store.state.currentUser && $store.state.currentRole"
        >
          {{ language(1) }}
        </b-btn>
      </template>
      <p class="text-center mb-1"><em><small>Last Updated: {{ trail.updatedOn | formatDate }}</small></em></p>
      <Notice
        v-for="(notice, i) in notices"
        v-bind:key="i"
        :update="notice"
      />
      <SnowReport
        v-if="snowReport"
        :update="snowReport"
      />
      <h5 v-if="trail.status !== 'None'">
        <i style="color:#28a745;" v-if="trail.status == 'Open'" class="fas fa-check-circle"></i>
        <i style="color:#dc3545;" v-if="trail.status == 'Closed'" class="fas fa-times-circle"></i>
        <i style="color:#ffc107;" v-if="trail.status == 'Caution'" class="fas fa-exclamation-circle"></i>
        {{ this.statuses[trail.status][$store.state.trailSystem.language] }}
      </h5>
      <SurfaceConditions
        v-if="surfaceConditions"
        :update="surfaceConditions"
      />
      <div v-if="trail.activities && trail.type !== 'Lift'" class="mb-2">
        <div
          v-for="(activity, i) in trail.activities"
          v-bind:key="i"
          class="activity-badge"
        >
          <b-badge
            v-if="!hiddenActivities[activity.value]"
            class="mr-1 activity mb-1"
            pill
            :variant="setActivityVariant(activity.status)"
          >
            <!-- <i v-if="activity.open" class="fas fa-check-circle mr-1"></i>
            <i v-if="!activity.open" class="fas fa-times-circle mr-1"></i> -->
            <i v-if="activity.status == 'Open'" class="fas fa-check-circle mr-1"></i>
            <i v-if="activity.status == 'Closed'" class="fas fa-times-circle mr-1"></i>
            <i v-if="activity.status == 'Caution'" class="fas fa-exclamation-circle mr-1"></i>
            <i :class="activity.iconClass" class="activity-icon"></i> {{ activity[$store.state.trailSystem.language] || activity.label }}
            <span v-if="activity.difficulty && activity.difficulty === 'circle'" class="difficulty-marker circle ml-1"></span>
            <span v-if="activity.difficulty && activity.difficulty === 'square'" class="difficulty-marker square ml-1"></span>
            <span v-if="activity.difficulty && activity.difficulty === 'diamond'" class="difficulty-marker diamond ml-1"></span>
            <span v-if="activity.difficulty && activity.difficulty === 'doubleDiamond'" class="difficulty-marker diamond ml-1"></span><span v-if="activity.difficulty && activity.difficulty === 'doubleDiamond'" class="difficulty-marker diamond"></span>
            <span v-if="activity.difficulty && activity.difficulty === 'terrainPark'" class="difficulty-marker terrainPark ml-1"></span>
          </b-badge>
        </div><!-- /v-for -->
      </div><!-- /v-if -->
      <p v-if="trail.description">{{ trail.description }}</p>
      <div v-if="trail.distance && trail.type !== 'Lift'" class="card mt-3">
        <div class="card-body pb-0">
          <p class="text-center"><strong>{{ formatLength(trail.distance) }}</strong></p>
          <ElevationProfile
            v-if="trail.elevations"
            class="elevation-profile"
          />
        </div>
      </div>
    </b-card>
  </div><!-- /#trail -->
</template>

<script>
import ElevationProfile from './ElevationProfile'
import Notice from '../update/templates/Notice'
import SnowReport from '../update/templates/SnowReport'
import SurfaceConditions from '../update/templates/SurfaceConditions'
import moment from 'moment'

export default {
  data () {
    return {
      cardStyles: '',
      cardTucked: false,
      notices: [],
      snowReport: false,
      surfaceConditions: false,
      statuses: {
        Open: {
          en: 'Open',
          fr: 'Ouvert'
        },
        Caution: {
          en: 'Caution',
          fr: 'Attention'
        },
        Closed: {
          en: 'Closed',
          fr: 'Fermé'
        },
        None: {
          en: 'None',
          fr: 'Aucune'
        }
      },
      phrases: {
        1: {
          en: 'Edit Trail',
          fr: 'Modifier le sentier'
        },
        2: {
          en: 'Hidden',
          fr: 'Sentier caché'
        },
        3: {
          en: 'One Way',
          fr: 'Sens unique'
        }
      }
    }
  },
  components: {
    ElevationProfile,
    Notice,
    SnowReport,
    SurfaceConditions
  },
  computed: {
    hiddenActivities: {
      get () {
        return this.$store.state.hiddenActivities
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'hiddenActivities', value: value })
      }
    },
    trail: {
      get () {
        return this.$store.state.trail
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trail', value: value })
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    tuckTrail () {
      if (this.cardTucked) {
        this.cardStyles = ''
        this.cardTucked = false
      } else {
        this.cardStyles = 'top:80vh;'
        this.cardTucked = true
      }
    },
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    setActivityVariant (status) {
      if (status === 'Open') {
        return 'success'
      } else if (status === 'Caution') {
        return 'warning'
      } else if (status === 'Closed') {
        return 'danger'
      }
    },
    init () {
      this.$store.dispatch('highlightTrail', this.trail.id)
      const self = this
      function gatherUpdates () {
        self.$store.state.updates.forEach(update => {
          if (update.attachToTrails) {
            update.relevantTrails.forEach(relevantTrail => {
              if (relevantTrail.trailId === self.trail.id) {
                if (update.type === 'notice') {
                  self.notices.push(update)
                }
                if (update.type === 'snowReport') {
                  self.snowReport = update
                }
                if (update.type === 'surfaceConditions') {
                  self.surfaceConditions = update
                }
              }
            })
          }
        })
      }
      if (this.$store.state.updatesGathered) {
        gatherUpdates()
      } else {
        this.$store.dispatch('fetchUpdates').then(() => {
          this.$store.commit('setStateProperty', { property: 'updatesGathered', value: true })
          gatherUpdates()
        })
      }
    },
    editTrail () {
      this.$store.commit('setStateProperty', { property: 'editMode', value: true })
      this.$store.commit('hideDrawer', 'trail')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'createTrail')
      }, 300)
    },
    closeTrail () {
      this.$store.dispatch('removeTrailHighlight')
      this.$store.commit('hideDrawer', 'trail')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'trails')
      }, 300)
    },
    formatLength (length) {
      if (length) {
        if (this.$store.state.trailSystem.lengthMeasurement === 'miles') {
          let miles = length * 0.621371
          miles = miles.toFixed(2)
          return miles + ' miles'
        } else {
          return length.toFixed(2) + ' kilometers'
        }
      } else {
        return 'Cannot get length'
      }
    }
  },
  filters: {
    formatDate (val) {
      if (!val) { return '-' }
      const date = val.toDate()
      return moment(date).format('MM/DD/YY - h:mm a')
    }
  }
}
</script>

<style scoped>
.elevation-profile {
  height: 300px;
}

.tuck-button {
  position: absolute;
  top: 4px;
  right: 50px;
  color: #777;
  background-color: white;
  border-radius: 4px;
  padding: 5px;
  font-size: 22px;
}

@media (max-width: 600px) {
  .elevation-profile {
    height: 100px;
  }
}

@media (min-width: 600px) {
  .tuck-button {
    display: none;
  }
}
</style>

<template>
  <div id="sideBar">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.allSystems.position"
      style="top:0;height:100%;"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="$store.commit('hideDrawer', 'allSystems')"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title"><i class="fas fa-heart text-danger"></i> {{ language(1) }}
          <span
            class="mt-2 mb-0 clickable pick-lang"
            @click="toggleLanguage('fr')"
            v-if="trailSystem.language !== 'fr'"
          >
            <small>Voir en français</small>
          </span>
          <span
            class="mt-2 mb-0 clickable pick-lang"
            @click="toggleLanguage('en')"
            v-if="trailSystem.language !== 'en'"
          >
            <small>View in English</small>
          </span>
        </h4>
      </template>
      <b-btn
        @click="showMySubscriptions()"
        v-if="onlySubscriptions === false"
        class="mb-2"
      ><span class="status-dot caution"></span> {{ language(2) }}</b-btn>
      <b-btn
        @click="showAll()"
        v-if="onlySubscriptions"
        class="mb-2"
      >Show All</b-btn>
      <p
        v-if="!onlySubscriptions"
        class="lead"
      >
        <span
          v-for="(activity, i) in $store.state.allActivities"
          v-bind:key="i"
        >
          <b-badge
            v-if="!filteredActivity.value || filteredActivity.value === activity.value"
            class="mr-1 clickable"
            pill
            variant="dark"
            @click="activityFilter(activity)"
          >
            <i :class="activity.iconClass"></i> {{ activity[$store.state.trailSystem.language] || activity.label }} ({{ activity.count }})
          </b-badge>
        </span>
        <b-badge
          v-if="trailSystemsFiltered"
          class="mr-1 clickable"
          pill
          variant="warning"
          @click="showAll()"
        >
          <i class="fas fa-sync-alt"></i>
        </b-badge>
      </p>
      <VueFuse
        v-if="$store.state.allSystemsGeoJson"
        :fuseOpts="fuseOptions"
        :list="$store.state.allSystemsGeoJson.features"
        :placeholder="language(3)"
        class="text-center border rounded-lg center mb-2 w-100"
        @fuse-results="handleResults"
      />
      <div class="list-group">
        <div
          v-for="(trailSystem, i) in trailSystemResults"
          v-bind:key="i"
          @mouseover="$store.dispatch('highlightPoint', trailSystem.item)"
          @mouseleave="$store.dispatch('removePointHighlight')"
          class="trail-container"
        >
          <div
            class="list-group-item list-group-item-action flex-column align-items-start clickable p-0 trail-item handle"
            v-if="trailSystem.item.properties.name"
            @click="launchTrailSystem(trailSystem.item)"
          >
            <div v-if="trailSystem.item.properties.status === 1" class="status-bar h-100 Open">
              <i class="fas fa-check-circle"></i>
            </div>
            <div v-if="trailSystem.item.properties.status === 0" class="status-bar h-100 Closed">
              <i class="fas fa-times-circle"></i>
            </div>
            <div class="trail-card-body">
              <p class="lead mb-0">
                <strong>{{ trailSystem.item.properties.name }}</strong>
              </p>
              <p class="m-0 mb-1"><i class="fas fa-check-circle text-success mr-1"></i>{{ trailSystem.item.properties.openTrails }} <i class="fas fa-exclamation-circle text-warning ml-1 mr-1"></i>{{ trailSystem.item.properties.cautionTrails }} <i class="fas fa-times-circle text-danger ml-1 mr-1"></i>{{ trailSystem.item.properties.closedTrails }}</p>
              <div v-if="trailSystem.item.properties.activities">
                <b-badge
                  v-for="(activity, i) in trailSystem.item.properties.activities"
                  v-bind:key="i"
                  class="mr-1 activity"
                  pill
                  :variant="setActivityVariant(activity.status)"
                >
                  <i :class="activity.iconClass" class="activity-icon"></i>
                </b-badge>
              </div>
            </div><!-- /.trail-card-body -->
          </div><!-- /.list-group-item -->
        </div><!-- render logic -->
      </div><!-- /.list-group -->
    </b-card>
    <UserSettings v-if="$store.state.drawer.userSettings.show" />
  </div><!-- /#sideBar -->
</template>

<script>
import VueFuse from 'vue-fuse'
import UserSettings from '../user/UserSettings'

export default {
  data () {
    return {
      filteredGeoJson: {
        type: 'FeatureCollection',
        features: []
      },
      allActivities: [],
      filteredActivity: false,
      trailSystemsFiltered: false,
      onlySubscriptions: false,
      trailSystemResults: [],
      fuseOptions: {
        keys: ['properties.name']
      },
      phrases: {
        1: {
          en: 'TrailHUB Customers',
          fr: 'Clients de TrailHUB'
        },
        2: {
          en: 'My Subscriptions',
          fr: 'Mes abonnements'
        },
        3: {
          en: 'Search',
          fr: 'Rechercher'
        }
      }
    }
  },
  components: {
    VueFuse,
    UserSettings
  },
  computed: {
    trailSystem: {
      get () {
        return this.$store.state.trailSystem
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trailSystem', value: value })
      }
    }
  },
  methods: {
    language (id) {
      const lang = this.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    toggleLanguage (lang) {
      this.trailSystem.language = lang
    },
    activityFilter (activity) {
      this.trailSystemsFiltered = true
      this.onlySubscriptions = false
      this.trailSystemResults = []
      this.filteredGeoJson.features = []
      this.filteredActivity = activity
      this.$store.state.allSystemsGeoJson.features.forEach(feature => {
        if (feature.properties.activities) {
          feature.properties.activities.forEach(activityItem => {
            if (activityItem.value === activity.value) {
              const object = {
                item: {}
              }
              object.item = feature
              this.trailSystemResults.push(object)
              this.filteredGeoJson.features.push(feature)
            }
          })
        }
      })
      this.$store.state.mapObject.setLayoutProperty('allSystemsLayer', 'visibility', 'none')
      this.$store.state.mapObject.setLayoutProperty('subscriptionsLayer', 'visibility', 'none')
      this.$store.state.mapObject.addSource('filteredActivitySource', {
        type: 'geojson',
        data: this.filteredGeoJson
      })
      this.$store.state.mapObject.addLayer({
        id: 'filteredActivityLayer',
        type: 'circle',
        source: 'filteredActivitySource',
        layout: {},
        paint: {
          'circle-radius': 10,
          'circle-color': [
            'step',
            ['to-number', ['get', 'openTrails']],
            '#dc3545',
            1, '#28a745'
          ]
        }
      })
    },
    showAll () {
      if (this.$store.state.mapObject.getLayer('filteredActivityLayer')) {
        this.$store.state.mapObject.removeLayer('filteredActivityLayer')
        this.$store.state.mapObject.removeSource('filteredActivitySource')
      }
      this.trailSystemResults = []
      this.$store.state.mapObject.setLayoutProperty('allSystemsLayer', 'visibility', 'visible')
      this.$store.state.mapObject.setLayoutProperty('subscriptionsLayer', 'visibility', 'visible')
      this.$store.state.allSystemsGeoJson.features.forEach(feature => {
        const object = {
          item: {}
        }
        object.item = feature
        this.trailSystemResults.push(object)
      })
      this.onlySubscriptions = false
      this.trailSystemsFiltered = false
      this.filteredActivity = false
    },
    showMySubscriptions () {
      if (this.$store.state.mapObject.getLayer('filteredActivityLayer')) {
        this.$store.state.mapObject.removeLayer('filteredActivityLayer')
        this.$store.state.mapObject.removeSource('filteredActivitySource')
      }
      this.$store.state.mapObject.setLayoutProperty('subscriptionsLayer', 'visibility', 'visible')
      this.onlySubscriptions = true
      this.trailSystemResults = []
      this.$store.state.subscriptionsGeoJson.features.forEach(feature => {
        const object = {
          item: {}
        }
        object.item = feature
        this.trailSystemResults.push(object)
      })
      this.$store.state.mapObject.setLayoutProperty('allSystemsLayer', 'visibility', 'none')
    },
    launchTrailSystem (ts) {
      const tsUrl = '/ts/' + ts.properties.tsId
      window.open(tsUrl, '_blank')
    },
    handleResults (r) {
      this.trailSystemResults = r
    },
    setActivityVariant (status) {
      if (status === 'Open') {
        return 'success'
      } else if (status === 'Caution') {
        return 'warning'
      } else if (status === 'Closed') {
        return 'danger'
      }
    }
  }
}
</script>

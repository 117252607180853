export default [
  {
    text: 'ATV Off Road',
    value: {
      label: 'ATV Off Road',
      en: 'ATV Off Road',
      fr: 'VTT hors route',
      value: 'atv',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1379661131'
    }
  },
  {
    text: 'Biking',
    value: {
      label: 'Biking',
      en: 'Biking',
      fr: 'Piste cyclable',
      value: 'biking',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'fas fa-bicycle'
    }
  },
  {
    text: 'Climbing',
    value: {
      label: 'Climbing',
      en: 'Climbing',
      fr: 'Escalade',
      value: 'climbing',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1377155288'
    }
  },
  {
    text: 'Disc Golf',
    value: {
      label: 'Disc Golf',
      en: 'Disc Golf',
      fr: 'Disque-golf',
      value: 'discGolf',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1486981687'
    }
  },
  {
    text: 'E-Bike',
    value: {
      label: 'E-Bike',
      en: 'E-Bike',
      fr: 'Vélo électrique',
      value: 'eBike',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1570037751'
    }
  },
  {
    text: 'Fat Biking',
    value: {
      label: 'Fat Biking',
      en: 'Fat Biking',
      fr: 'Vélo à pneus surdimensionnés',
      value: 'fatBiking',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1487062241'
    }
  },
  {
    text: 'Fishing',
    value: {
      label: 'Fishing',
      en: 'Fishing',
      fr: 'Pêche',
      value: 'fishing',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1486981452'
    }
  },
  {
    text: 'Fly Fishing',
    value: {
      label: 'FLy Fishing',
      en: 'Fly Fishing',
      fr: 'Pêche à la mouche',
      value: 'flyFishing',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1486981452'
    }
  },
  {
    text: 'Hiking',
    value: {
      label: 'Hiking',
      en: 'Hiking',
      fr: 'Randonnée pédestre',
      value: 'hiking',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1379658986'
    }
  },
  {
    text: 'Horseback Riding',
    value: {
      label: 'Horseback Riding',
      en: 'Horseback Riding',
      fr: 'Équitation',
      value: 'horsebackRiding',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1379661220'
    }
  },
  {
    text: 'Lift Access Biking',
    value: {
      label: 'Lift Access Biking',
      en: 'Lift Access Biking',
      fr: 'Remonte-pente vélo',
      value: 'liftAccessBiking',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1486980997'
    }
  },
  {
    text: 'Mountain Biking',
    value: {
      label: 'Mountain Biking',
      en: 'Mountain Biking',
      fr: 'Vélo de montagne',
      value: 'mountainBiking',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'fas fa-biking-mountain'
    }
  },
  {
    text: 'Mountain Biking - Adaptive',
    value: {
      label: 'Mountain Biking - Adaptive',
      en: 'Mountain Biking - Adaptive',
      fr: 'Vélo de montagne - Adaptive',
      value: 'mountainBikingAdaptive',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'fab fa-accessible-icon'
    }
  },
  {
    text: 'Nordic Skiing',
    value: {
      label: 'Nordic Skiing',
      en: 'Nordic Skiing',
      fr: 'Ski de fond classique',
      value: 'nordicSkiing',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'fas fa-skiing-nordic'
    }
  },
  {
    text: 'Nordic Skiing - Skating',
    value: {
      label: 'Nordic Skiing - Skating',
      en: 'Nordic Skiing - Skating',
      fr: 'Ski de fond pas de patin',
      value: 'nordicSkiingSkating',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'fas fa-skiing-nordic'
    }
  },
  {
    text: 'Nordic Skiing - Alpine Touring',
    value: {
      label: 'Nordic Skiing - Alpine Touring',
      en: 'Nordic Skiing - Alpine Touring',
      fr: 'Ski de montagne',
      value: 'nordicSkiingAlpine',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'fas fa-skiing-nordic'
    }
  },
  {
    text: 'Nordic Skiing - Backcountry',
    value: {
      label: 'Nordic Skiing - Backcountry',
      en: 'Nordic Skiing - Backcountry',
      fr: 'Ski nordique - hors-piste',
      value: 'nordicSkiingBackcountry',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'fas fa-skiing-nordic'
    }
  },
  {
    text: 'Nordic Skiing - Single Track',
    value: {
      label: 'Nordic Skiing - Single Track',
      en: 'Nordic Skiing - Single Track',
      fr: 'Ski de fond classique (single track)',
      value: 'nordicSkiingSingleTrack',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'fas fa-skiing-nordic'
    }
  },
  {
    text: 'Paddling',
    value: {
      label: 'Paddling',
      en: 'Paddling',
      fr: 'Sports de pagaie',
      value: 'paddling',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1379661860'
    }
  },
  {
    text: 'Recreational Paths',
    value: {
      label: 'Recreational Paths',
      en: 'Recreational Paths',
      fr: 'Sentiers récréatifs',
      value: 'recreationalPaths',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1379659289'
    }
  },
  {
    text: 'Rollerskiing',
    value: {
      label: 'Rollerskiing',
      en: 'Rollerskiing',
      fr: 'Ski à roulettes',
      value: 'rollerSkiing',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1379661411'
    }
  },
  {
    text: 'Skiing/Snowboarding',
    value: {
      label: 'Skiing/Snowboarding',
      en: 'Skiing/Snowboarding',
      fr: 'Ski/planche à neige',
      value: 'skiingSnowboarding',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1380797509'
    }
  },
  {
    text: 'Skating',
    value: {
      label: 'Skating',
      en: 'Skating',
      fr: 'Patinage',
      value: 'skating',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1377155480'
    }
  },
  {
    text: 'Snowmobiling',
    value: {
      label: 'Snowmobiling',
      en: 'Snowmobiling',
      fr: 'Motoneige',
      value: 'snowmobiling',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1379659150'
    }
  },
  {
    text: 'Snowshoeing',
    value: {
      label: 'Snowshoeing',
      en: 'Snowshoeing',
      fr: 'Raquette',
      value: 'snowshoeing',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1380797480'
    }
  },
  {
    text: 'Trail Running',
    value: {
      label: 'Trail Running',
      en: 'Trail Running',
      fr: 'Course en sentier',
      value: 'trailRunning',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1486981427'
    }
  },
  {
    text: 'Winter Walk',
    value: {
      label: 'Winter Walk',
      en: 'Winter Walk',
      fr: 'Marche Hivernale',
      value: 'winterWalk',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'fas fa-boot'
    }
  },
  {
    text: 'Zipline',
    value: {
      label: 'Zipline',
      en: 'Zipline',
      fr: 'Tyrolienne',
      value: 'zipline',
      status: 'Open',
      difficulty: 'notRated',
      iconClass: 'icon-Large_1486981412'
    }
  }
]

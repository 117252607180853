<template>
  <div id="blockedMessage" v-if="$store.state.trailSystem.blocked && !$store.state.trailSystem.override && showMessage">
    <div class="container bg-warning text-center p-2">
      <p class="m-0"><strong>Paused Trail System</strong><br>This is most likely because your free trial has expired. You may activate this trail system by <a href="https://www.support.trailhub.org/billing-preferences" target="_blank">purchasing a TrailHUB subscription</a>. Please <a href="https://www.support.trailhub.org/contact" target="_blank">contact TrailHUB</a> if you have any questions. Thank you!</p>
      <!-- <b-btn
        v-if="$store.state.userProfile.superAdmin"
        @click="showMessage = false"
      >Admin Hide Message</b-btn> -->
    </div>
  </div><!-- /#blockedMessage -->
</template>

<script>
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      showMessage: true
    }
  },
  mounted () {
    this.checkStatus()
  },
  methods: {
    checkStatus () {
      async function getCustomClaimRole () {
        await fb.auth.currentUser.getIdToken(true)
        const decodedToken = await fb.auth.currentUser.getIdTokenResult()
        return decodedToken.claims.stripeRole
      }
      if (this.$store.state.trailSystem.ownerId) {
        if (this.$store.state.trailSystem.blocked && !this.$store.state.trailSystem.override) {
          if (this.$store.state.currentUser) {
            if (this.$store.state.currentUser.uid === this.$store.state.trailSystem.ownerId) {
              getCustomClaimRole().then(role => {
                if (role) {
                  return fb.trailSystemsCollection.doc(this.$store.state.trailSystem.id).update({
                    blocked: false
                  }).then(() => {
                    console.log('Trail System Updated!')
                  }).catch(err => {
                    console.log(err)
                  })
                } else {
                  console.log('No Role Detected')
                }
              })
            } else {
              console.log('Not the current owner')
            }
          } else {
            console.log('Not Logged In')
          }
        }
      } else {
        setTimeout(() => {
          this.checkStatus()
        }, 3000)
      }
    }
  }
}
</script>

<style scoped>
#blockedMessage {
  position: fixed;
  top: 40%;
  left: 0;
  width: 100%;
  z-index: 10;
}

#blockedMessage .container {
  border-radius: 4px;
}
</style>

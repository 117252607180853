<template>
  <div id="notice" class="alert mb-2" :class="noticeClass">
    <p class="mb-0"><i v-if="update.severity === 'info'" class="fas fa-info-circle"></i><i v-if="update.severity === 'warning' || update.severity === 'danger'" class="fas fa-exclamation-circle"></i> {{ update.description }} <small><em>~{{ update.updatedOn | formatDate}}</em></small></p>
    <b-btn
      v-if="update.linkUrl"
      :href="update.linkUrl"
      :class="buttonVariant"
      class="mt-2"
      target="_blank"
      size="sm"
    >
      {{ language(1) }}
    </b-btn>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Learn More',
          fr: 'Plus de détails'
        }
      },
      noticeClass: 'alert-info',
      buttonVariant: 'btn-info'
    }
  },
  props: ['update'],
  created () {
    this.init()
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    init () {
      this.noticeClass = 'alert-' + this.update.severity
      this.buttonVariant = 'btn-' + this.update.severity
    }
  },
  filters: {
    formatDate (val) {
      if (!val) { return '-' }
      const date = val.toDate()
      return moment(date).format('MM/DD/YY hh:mm a')
    }
  }
}
</script>

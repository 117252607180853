<template>
  <div class="trailSystem" :class="$route.params.mode + ' ' + $route.params.embedMode">
    <Billing v-if="$store.state.drawer.billing.show" />
    <BlockedMessage />
    <BulkEdit v-if="$store.state.drawer.bulkEdit.show" />
    <Calendars v-if="$store.state.drawer.calendars.show" />
    <CalendarsSettings v-if="$store.state.drawer.calendarsSettings.show" />
    <CreatePoint v-if="$store.state.drawer.createPoint.show" />
    <CreateTrail v-if="$store.state.drawer.createTrail.show" />
    <CreateTrailSystem v-if="$store.state.drawer.createTrailSystem.show" />
    <CreateUpdate v-if="$store.state.drawer.createUpdate.show" />
    <!-- <IosInstallPrompt v-if="$store.state.iosInstallPrompt && !$store.state.mapLoader" /> -->
    <Menu />
    <Points v-if="$store.state.drawer.points.show" />
    <PointDetails v-if="$store.state.drawer.pointDetails.show" />
    <Share v-if="$store.state.drawer.share.show" />
    <TrailSystemMap />
    <TrailSystemNavbar v-if="$store.state.trailSystem.id && !$store.state.embedMode || $store.state.drawer.createTrailSystem.show" />
    <TrailSystemDetails v-if="$store.state.drawer.trailSystemDetails.show" />
    <TrailSystems v-if="$store.state.drawer.trailSystems.show" />
    <Trails v-if="$store.state.drawer.trails.show" />
    <Trail v-if="$store.state.drawer.trail.show" />
    <Trustees v-if="$store.state.drawer.trustees.show" />
    <UserSettings v-if="$store.state.drawer.userSettings.show" />
    <Updates v-if="$store.state.drawer.updates.show" />
    <Update v-if="$store.state.drawer.update.show" />
    <Weather v-if="$store.state.drawer.weather.show" />
  </div>
</template>

<script>
import Billing from '../user/Billing'
import BlockedMessage from './BlockedMessage'
import BulkEdit from '../trail/BulkEdit'
import Calendars from './Calendars'
import CalendarsSettings from './CalendarsSettings'
import CreatePoint from '../point/CreatePoint'
import CreateTrail from '../trail/CreateTrail'
import CreateTrailSystem from './CreateTrailSystem'
import CreateUpdate from '../update/CreateUpdate'
// import IosInstallPrompt from './IosInstallPrompt'
import Menu from './Menu'
import Points from '../point/Points'
import PointDetails from '../point/PointDetails'
import Share from './Share'
import TrailSystemMap from './Map'
import TrailSystemNavbar from './TrailSystemNavbar'
import TrailSystemDetails from './TrailSystemDetails'
import TrailSystems from './TrailSystems'
import Trustees from './Trustees'
import Trails from '../trail/Trails'
import Trail from '../trail/Trail'
import UserSettings from '../user/UserSettings'
import Updates from '../update/Updates'
import Update from '../update/Update'
import Weather from './Weather'
import firebase from 'firebase'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      dynamicManifest: {}
    }
  },
  components: {
    Billing,
    BlockedMessage,
    BulkEdit,
    Calendars,
    CalendarsSettings,
    CreatePoint,
    CreateTrail,
    CreateTrailSystem,
    CreateUpdate,
    // IosInstallPrompt,
    Menu,
    Points,
    PointDetails,
    Share,
    TrailSystemMap,
    TrailSystemNavbar,
    TrailSystemDetails,
    TrailSystems,
    Trustees,
    Trails,
    Trail,
    UserSettings,
    Updates,
    Update,
    Weather
  },
  mounted () {
    this.$store.commit('setStateProperty', { property: 'mapLoader', value: true })
    setTimeout(() => {
      this.initTrailSystem()
    }, 1000)
  },
  computed: {
    trailSystem: {
      get () {
        return this.$store.state.trailSystem
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trailSystem', value: value })
      }
    }
  },
  methods: {
    initPWA () {
      const stringManifest = JSON.stringify(this.dynamicManifest)
      const blob = new Blob([stringManifest], { type: 'application/json' })
      const manifestURL = URL.createObjectURL(blob)
      document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL)
      if (document.querySelectorAll('[rel="manifest"]')[1]) {
        document.querySelectorAll('[rel="manifest"]')[1].remove()
      }
      if (document.querySelector('[rel="apple-touch-icon"]')) {
        document.querySelector('[rel="apple-touch-icon"]').setAttribute('href', this.trailSystem.iconUrl)
      }
      // Detects if device is on iOS
      // const isIos = () => {
      //   const userAgent = window.navigator.userAgent.toLowerCase()
      //   return /iphone|ipad|ipod/.test(userAgent)
      // }
      // Detects if device is in standalone mode
      // const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone)
      // Check if we are in embed mode
      // const embedMode = this.$route.params.embedMode || false
      // Checks if should display install popup notification:
      // if (isIos() && !isInStandaloneMode() && !embedMode) {
      //   this.$store.commit('setStateProperty', { property: 'iosInstallPrompt', value: true })
      // }
    },
    startTrailSystem () {
      console.log('Start Trail System')
      // const map = this.$store.state.mapObject
      let orgId
      // Check for map ready
      const self = this
      // map.on('load', function () {
      console.log('Map Loaded')
      if (!self.$route.params.id) {
        if (self.$store.state.userProfile.defaultTrailSystem) {
          orgId = self.$store.state.userProfile.defaultTrailSystem
        } else {
          orgId = self.$store.state.trailSystems[0].id
        }
        self.$router.push('/ts/' + orgId)
      } else {
        orgId = self.$route.params.id
      }
      fb.trailSystemsCollection.doc(orgId).onSnapshot(org => {
        self.trailSystem = org.data()
        self.trailSystem.id = org.id
        // Check to see if the system has a default activity
        console.log('TRAIL SYSTEM')
        console.log(self.trailSystem)
        const params = new URLSearchParams(window.location.search)
        if (self.trailSystem.defaultActivity && !params.has('a')) {
          const defaultActivity = self.trailSystem.defaultActivity
          // If so, push that to the URL
          // Construct a new URL object using the current window location
          const url = new URL(window.location)

          // Set or update the query parameter
          url.searchParams.set('a', defaultActivity.value)

          // Push the new URL to the browser history
          history.pushState(null, '', url)
        }
        self.$store.dispatch('fetchTrails').then(() => {
          self.$store.dispatch('mapTrails')
          if (self.$store.state.firstLoad && self.$route.params.mode !== 'map') {
            setTimeout(() => {
              if (self.$route.params.mode === 'points') {
                self.$store.commit('showDrawer', 'points')
              } else {
                self.$store.commit('showDrawer', 'trails')
              }
            }, 500)
          }
          setTimeout(() => {
            if (self.$store.state.firstLoad) {
              self.$store.dispatch('zoomToOrg')
            }
            self.$store.commit('setStateProperty', { property: 'mapLoader', value: false })
            self.$store.commit('setStateProperty', { property: 'firstLoad', value: false })
            // self.$store.dispatch('fetchUpdates')
            if (self.trailSystem.iconPath) {
              const iconPath = self.trailSystem.iconPath
              const iconPathSm = iconPath.split('.').slice(0, -1).join('.') + '_512x512.' + iconPath.substr(iconPath.lastIndexOf('.') + 1)
              const storageRef = firebase.storage().ref(iconPathSm)
              storageRef.getDownloadURL().then(url => {
                self.$store.commit('setStateProperty', { property: 'showTSIcon', value: false })
                if (url) {
                  self.trailSystem.iconUrl = url
                } else {
                  self.trailSystem.iconUrl = '/img/icons/android-chrome-512x512.png'
                }
                self.$store.commit('setStateProperty', { property: 'showTSIcon', value: true })
                // SETUP OUR MANIFTST FOR PWA
                self.dynamicManifest = {
                  name: self.trailSystem.name,
                  short_name: self.trailSystem.name,
                  description: self.trailSystem.name,
                  start_url: 'https://trailhub.org/ts/' + self.trailSystem.id,
                  background_color: '#333',
                  theme_color: '#eee',
                  display: 'standalone',
                  icons: [{
                    src: self.trailSystem.iconUrl,
                    sizes: '512x512'
                  }]
                }
                self.initPWA()
                // END PWA SETTINGS
              }).catch(err => {
                console.log(err.message)
              })
            } else {
              self.trailSystem.iconUrl = '/img/icons/android-chrome-512x512.png'
              // SETUP OUR MANIFTST FOR PWA
              self.dynamicManifest = {
                name: self.trailSystem.name,
                short_name: self.trailSystem.name,
                description: self.trailSystem.name,
                start_url: 'https://trailhub.org/ts/' + self.trailSystem.id,
                background_color: '#333',
                theme_color: '#eee',
                display: 'standalone',
                icons: [{
                  src: '/img/icons/android-chrome-512x512.png',
                  sizes: '512x512'
                }]
              }
              self.initPWA()
              // END PWA SETTINGS
            }
            async function getCustomClaimRole () {
              await fb.auth.currentUser.getIdToken(true)
              const decodedToken = await fb.auth.currentUser.getIdTokenResult()
              return decodedToken.claims.stripeRole
            }
            if (self.$store.state.currentUser) {
              getCustomClaimRole().then(role => {
                if (role) {
                  self.$store.commit('setStateProperty', { property: 'planRole', value: role })
                }
              })
              self.$store.dispatch('fetchTrustees')
            }
          }, 400)
        })
      })
      // })
    },
    setTrailSystem () {
      console.log('Set Trail System')
      // const map = this.$store.state.mapObject
      // Check for map ready
      const self = this
      // map.on('load', function () {
      self.$store.commit('showDrawer', 'createTrailSystem')
      self.$store.commit('setStateProperty', { property: 'mapLoader', value: false })
      // })
    },
    initTrailSystem () {
      if (this.$route.params.mode) {
        this.$store.commit('setStateProperty', { property: 'trailSystemMode', value: this.$route.params.mode })
        if (this.$route.params.mode === 'native') {
          document.body.classList.add('native')
        }
      }
      if (this.$store.state.currentUser) {
        this.$store.dispatch('fetchTrailSystems').then(trailSystems => {
          console.log('Got Trail Systems')
          console.log(this.$store.state.trailSystems.length)
          if (this.$store.state.trailSystems.length < 1 && !this.$route.params.id) {
            if (window.location.pathname !== '/ts/') {
              this.$router.push('/ts/')
            }
            this.setTrailSystem()
          } else {
            this.startTrailSystem()
          }
        })
      } else {
        this.startTrailSystem()
      }
    }
  }
}
</script>

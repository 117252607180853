<template>
  <div id="trails">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.trails.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="$store.commit('hideDrawer', 'trails')"
          v-if="$store.state.trailSystemMode != 'list'"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title">{{ language(1) }}
          <!-- <span
            class="mt-2 mb-0 clickable pick-lang"
            @click="toggleLanguage('fr')"
            v-if="trailSystem.language !== 'fr'"
          >
            <small>Voir en français</small>
          </span>
          <span
            class="mt-2 mb-0 clickable pick-lang"
            @click="toggleLanguage('en')"
            v-if="trailSystem.language !== 'en'"
          >
            <small>View in English</small>
          </span> -->
          <b-badge
            class="clickable"
            pill
            size="sm"
            v-if="$store.state.trailSystemMode == 'list'"
            @click="$store.commit('hideDrawer', 'trails')"
            variant="dark"
          ><i class="fas fa-map"></i> {{ language(5) }}</b-badge>
          <b-badge
            pill
            class="ml-1 clickable"
            size="sm"
            v-if="$store.state.trailSystemMode == 'list' && $store.state.embedMode"
            :href="'https://trailhub.org/ts/' + $store.state.trailSystem.id"
            target="_blank"
            variant="dark"
          ><i class="fas fa-external-link-alt"></i> {{ language(6) }}</b-badge>
        </h4>
        <b-btn
          @click="createTrail()"
          class="mb-2 mr-1"
          id="createTrailButton"
          v-if="$store.state.currentUser && $store.state.currentRole && !$store.state.embedMode"
        >
          <i class="far fa-plus"></i> {{ language(2) }}
        </b-btn>
        <b-btn
          @click="toggleReorder()"
          class="mb-2 mr-1"
          v-if="$store.state.currentUser && $store.state.currentRole && !$store.state.embedMode"
        >
          <i class="fas fa-bars"></i> {{ language(3) }}
        </b-btn>
        <b-btn
          @click="bulkEdit()"
          class="mb-2"
          v-if="$store.state.currentUser && $store.state.currentRole && !$store.state.embedMode"
        >
          <i class="fas fa-pencil"></i> {{ language(4) }}
        </b-btn>
      </template>

      <div v-if="$store.state.trailSystem.updates">
        <div v-if="$store.state.trailSystem.updates.notice">
          <Notice
            v-for="(notice, i) in $store.state.trailSystem.updates.notice"
            v-bind:key="i"
            :update="notice"
          />
        </div>

        <SnowReport
          v-if="$store.state.trailSystem.updates.snowReport"
          :update="$store.state.trailSystem.updates.snowReport[0]"
          class="mb-3"
        />

        <SurfaceConditions
          v-if="$store.state.trailSystem.updates.surfaceConditions"
          :update="$store.state.trailSystem.updates.surfaceConditions[0]"
          class="mb-3"
        />
      </div>

      <!-- Trails Actions -->
      <div class="mb-3">
        <div
          class="alert alert-success text-center"
          id="activityFilterLabel"
          v-if="filteredActivity"
        >
          <strong>
            <i :class="filteredActivity.iconClass"></i> {{ filteredActivity[$store.state.trailSystem.language] }}
          </strong>
        </div><!-- /.alert -->
        <b-dropdown v-if="$store.state.trailSystem.activities" variant="dark" :text="language(22)" class="mr-1" size="sm">
          <b-dropdown-item
            v-for="(activity, i) in $store.state.trailSystem.activities"
            v-bind:key="i"
            @click="activityFilter(activity)"
          ><i :class="activity.iconClass"></i> {{ activity[$store.state.trailSystem.language] || activity.label }}</b-dropdown-item>
        </b-dropdown>
        <b-dropdown v-if="$store.state.trailSystem.activities" variant="dark" :text="language(23)" class="mr-1" size="sm">
          <b-dropdown-item
            @click="toggleLanguage('fr')"
            v-if="trailSystem.language !== 'fr'"
          >
            <small>Voir en français</small>
          </b-dropdown-item>
          <b-dropdown-item
            @click="toggleLanguage('en')"
            v-if="trailSystem.language !== 'en'"
          >
            <small>View in English</small>
          </b-dropdown-item>
        </b-dropdown>
        <b-button
          pill
          variant="dark"
          @click="confirmDownload = true"
          size="sm"
          class="mr-1"
        >
          <i class="fas fa-file-download"></i> {{ language(24) }}
        </b-button>
        <b-button
          v-if="trailsFiltered"
          pill
          variant="warning"
          @click="activityFilter('reset')"
          size="sm"
        >
          <i class="fas fa-sync-alt"></i> {{ language(7) }}
        </b-button>
      </div>

      <div v-if="$store.state.trailSystem.totalTrails">
        <b-progress height="24px" class="mb-3" :max="$store.state.trailSystem.totalTrails" show-value>
          <b-progress-bar :value="$store.state.trailSystem.openTrails" variant="success"><span class="clickable" @click="statusFilter('Open')"><i class="fas fa-check-circle"></i> {{ $store.state.trailSystem.openTrails }}</span></b-progress-bar>
          <b-progress-bar :value="$store.state.trailSystem.cautionTrails" variant="warning"><span class="clickable" @click="statusFilter('Caution')"><i class="fas fa-exclamation-circle"></i> {{ $store.state.trailSystem.cautionTrails }}</span></b-progress-bar>
          <b-progress-bar :value="$store.state.trailSystem.closedTrails" variant="danger"><span class="clickable" @click="statusFilter('Closed')"><i class="fas fa-times-circle"></i> {{ $store.state.trailSystem.closedTrails }}</span></b-progress-bar>
        </b-progress>
      </div>

      <p v-if="$store.state.trailSystem.activities && $store.state.currentUser && reorder" class="lead">
        <span
          v-for="(activity, i) in $store.state.trailSystem.activities"
          v-bind:key="i"
        >
          <b-badge
            v-if="!filteredActivity.value || filteredActivity.value === activity.value"
            class="mr-1 clickable"
            pill
            variant="dark"
            @click="activityFilter(activity)"
          >
            <i :class="activity.iconClass"></i> {{ activity[$store.state.trailSystem.language] || activity.label }}
          </b-badge>
          <small v-if="$store.state.currentUser && reorder" class="mr-3">
            <span class="mr-2 clickable" @click="moveActivity('bottom', activity)"><i class="fas fa-arrow-to-bottom"></i></span>
            <span class="clickable" @click="moveActivity('top', activity)"><i class="fas fa-arrow-to-top"></i></span>
          </small>
        </span>
      </p>

      <!-- Search -->
      <VueFuse
        v-if="trails.length > 0"
        :fuseOpts="fuseOptions"
        :list="trails"
        :placeholder="language(8)"
        class="text-center border rounded-lg center mb-2 w-100"
        @fuse-results="handleResults"
      />

      <b-btn
        class="w-100 mb-2"
        variant="success"
        v-if="orderChanged"
        @click="saveOrder()"
      >Save Order</b-btn>

      <b-btn
        class="mb-2 mr-1"
        @click="alphabetize()"
        v-if="$store.state.currentUser && reorder"
      ><i class="fas fa-sort-alpha-up"></i></b-btn>

      <b-btn
        class="mb-2 mr-1"
        @click="sortbyDifficulty('easy')"
        v-if="$store.state.currentUser && reorder"
      ><i class="fas fa-sort-amount-up-alt"></i> {{ language(9) }}</b-btn>
      <b-btn
        class="mb-2"
        @click="sortbyDifficulty('hard')"
        v-if="$store.state.currentUser && reorder"
      ><i class="fas fa-sort-amount-up"></i> {{ language(10) }}</b-btn>

      <div class="list-group">
        <div v-if="trails.length < 1 && !$store.state.trailSystem.geoJsonPath">
          <div class="alert alert-success text-center">
            <h5>No Trails Yet</h5>
            <b-btn
              @click="createTrail()"
              variant="success"
              v-if="$store.state.currentUser"
            >Create Your First Trail</b-btn>
          </div><!-- /.alert -->
        </div>
        <!-- TRAILS LIST GROUP -->
        <draggable
          @change="changeOrder()"
          v-model="trails"
          handle=".handle"
          :disabled="!reorder"
        >
          <div
            v-for="(trail, i) in trailResults"
            v-bind:key="i"
            @mouseover="$store.dispatch('highlightTrail', trail.item.properties.trailId)"
            @mouseleave="$store.dispatch('removeTrailHighlight')"
            class="trail-container"
          >
            <!-- REORDER CONTROLS -->
            <p class="text-right mb-0" v-if="$store.state.currentUser && reorder">
              <small>
                <span><i class="fa fa-bars handle mr-2"></i></span>
                <span class="mr-2 clickable" @click="moveTrail('bottom', i)"><i class="fas fa-arrow-to-bottom"></i></span>
                <span class="clickable" @click="moveTrail('top', i)"><i class="fas fa-arrow-to-top"></i></span>
              </small>
            </p>
            <!-- TRAIL OPTIONS -->
            <b-dropdown v-if="$store.state.currentUser && !reorder && $store.state.currentRole && !$store.state.embedMode" class="options" size="sm"  variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i style="color:#333;" class="fas fa-caret-down clickable"></i>
              </template>
              <b-dropdown-item @click="launchTsSelector(trail, 'copy')"><i class="fas fa-copy"></i> {{ language(11) }}</b-dropdown-item>
            </b-dropdown>
            <div
              class="list-group-item list-group-item-action flex-column align-items-start clickable p-0 trail-item handle"
              v-if="!trail.filtered"
              @click="launchTrail(trail.item)"
              :class="{ hidden: trail.item.properties.hidden }"
            >
              <!-- STATUS LEFT BAR -->
              <div class="status-bar h-100" :class="trail.item.properties.status">
                <i v-if="trail.item.properties.status == 'Open'" class="fas fa-check-circle"></i>
                <i v-if="trail.item.properties.status == 'Closed'" class="fas fa-times-circle"></i>
                <i v-if="trail.item.properties.status == 'Caution'" class="fas fa-exclamation-circle"></i>
              </div>
              <div class="trail-card-body">
                <!-- TITLE LINE -->
                <div class="d-flex w-100 justify-content-between">
                  <p class="lead mb-1">
                      <span v-if="trail.item.properties.difficulty != 'doubleDiamond'" :class="trail.item.properties.difficulty" class="difficulty-marker mr-1"></span>
                      <span v-if="trail.item.properties.difficulty == 'doubleDiamond'" class="difficulty-marker diamond mr-1"></span><span v-if="trail.item.properties.difficulty == 'doubleDiamond'" class="difficulty-marker diamond mr-1"></span>
                      <strong>{{ trail.item.properties.name }} <span v-if="trail.item.properties.hidden"><small>({{ language(16) }})</small></span></strong>
                      <b-badge
                        v-if="trail.item.properties.oneWay"
                        pill
                        variant="warning"
                        class="mb-2"
                      ><i class="fas fa-long-arrow-alt-right"></i></b-badge>
                  </p>
                  <small v-if="trail.item.properties.distance" class="pt-1"><strong>{{ formatLength(trail.item.properties.distance) }}</strong></small>
                </div><!-- /.d-flex -->
                <div v-if="trail.item.properties.activities">
                  <div
                    v-for="(activity, i) in trail.item.properties.activities"
                    v-bind:key="i"
                    class="activity-badge"
                  >
                    <b-badge
                      class="mr-1 activity mb-1"
                      pill
                      :variant="setActivityVariant(activity.status)"
                      v-if="activity.value === filteredActivity.value || !filteredActivity && !hiddenActivities[activity.value]"
                    >
                      <i :class="activity.iconClass" class="activity-icon"></i>
                      <span class="ml-1">{{ activity[$store.state.trailSystem.language] }}</span>
                      <!-- <span class="ml-1" v-if="activity.value === 'nordicSkiingSkating'">{{ language(17) }}</span>
                      <span class="ml-1" v-if="activity.value === 'nordicSkiingBackcountry'">{{ language(18) }}</span>
                      <span class="ml-1" v-if="activity.value === 'nordicSkiingAlpine'">{{ language(19) }}</span>
                      <span class="ml-1" v-if="activity.value === 'nordicSkiingSingleTrack'">{{ language(20) }}</span>
                      <span class="ml-1" v-if="activity.value === 'nordicSkiing'">{{ language(21) }}</span>
                      <span class="ml-1" v-if="activity.value === 'flyFishing'">{{ language(26) }}</span> -->
                      <span v-if="activity.difficulty && activity.difficulty === 'circle'" class="difficulty-marker circle ml-1"></span>
                      <span v-if="activity.difficulty && activity.difficulty === 'square'" class="difficulty-marker square ml-1"></span>
                      <span v-if="activity.difficulty && activity.difficulty === 'diamond'" class="difficulty-marker diamond ml-1"></span>
                      <span v-if="activity.difficulty && activity.difficulty === 'doubleDiamond'" class="difficulty-marker diamond ml-1"></span><span v-if="activity.difficulty && activity.difficulty === 'doubleDiamond'" class="difficulty-marker diamond"></span>
                      <span v-if="activity.difficulty && activity.difficulty === 'terrainPark'" class="difficulty-marker terrainPark ml-1"></span>
                    </b-badge>
                  </div><!-- /v-for activities -->
                </div><!-- /v-if activities -->
                <div v-if="trail.item.properties.updates">
                  <div v-if="trail.item.properties.updates.notice">
                    <p
                      v-for="(notice, i) in trail.item.properties.updates.notice"
                      v-bind:key="i"
                      class="mb-0"
                    >
                      <i v-if="notice.severity === 'info'" class="fas fa-info-circle"></i><i v-if="notice.severity === 'warning'" class="fas fa-exclamation-circle text-warning"></i><i v-if="notice.severity === 'danger'" class="fas fa-exclamation-circle text-danger"></i> {{ notice.description }}
                    </p>
                  </div>
                  <p class="mb-0">
                    <span
                      v-if="trail.item.properties.updates.snowReport"
                      class="mr-2"
                    >
                      <i class="fas fa-snowflake"></i> {{ trail.item.properties.updates.snowReport[0].baseDepth }} <span v-if="$store.state.trailSystem.depthMeasurement == 'inches'">in</span><span v-if="$store.state.trailSystem.depthMeasurement == 'centimeters'">cm</span>
                    </span>
                    <span class="lead mb-1"
                      v-if="trail.item.properties.updates.surfaceConditions"
                    >
                      <!-- <span
                        v-for="(condition, index) in trail.item.properties.updates.surfaceConditions[0].conditions"
                        v-bind:key="index"
                        class="mr-2"
                      ><i :class="condition.iconClass"></i> {{ condition[$store.state.trailSystem.language] || condition.text }}</span>
                      </span> -->
                      <span
                        v-for="(surfaceCondition, me) in trail.item.properties.updates.surfaceConditions"
                        v-bind:key="me"
                      >
                        <b-badge
                        v-for="(condition, index) in surfaceCondition.conditions"
                        v-bind:key="index"
                        class="mr-1"
                        pill
                        variant="dark"
                      >
                        <i :class="condition.iconClass"></i> {{ condition[$store.state.trailSystem.language] || condition.text }}
                      </b-badge>
                      </span>
                    </span>
                  </p>
                </div>
                <!-- If Updates -->
              </div><!-- /.trail-card-body -->
            </div><!-- /.list-group -->
          </div><!-- render logic -->
        </draggable>
      </div><!-- /.list-group -->
      <p class="text-center mt-2" v-if="$store.state.trailSystemMode !== 'native'"><a class="btn btn-secondary" :href="'/print/' + $store.state.trailSystem.id + '?status=' + printQuery.status + '&activity=' + printQuery.activity" target="_blank"><i class="fas fa-print"></i> {{ language(12) }}</a></p>
      <p class="text-center" v-if="$store.state.trailSystemMode !== 'native'">{{ language(13) }} <a href="https://trailhub.org" target="_blank">trailhub.org</a></p>
    </b-card>
    <div v-if="tsSelector" class="ts-selector">
      <p class="mb-4"><strong>{{ language(14) }}</strong></p>
      <p v-for="(ts, i) in $store.state.trailSystems" v-bind:key="i" @click="copyTrail(ts)" class="clickable lead underline">{{ ts.name }} <i class="fas fa-arrow-right"></i></p>
      <b-btn class="mt-4" @click="tsSelector = false">{{ language(15) }}</b-btn>
    </div>

    <Confirm
      v-if="confirmDownload"
      :text="language(25)"
      v-on:confirmed="download($event)"
    />

  </div><!-- /#trails -->
</template>

<script>
import Notice from '../update/templates/Notice'
import SnowReport from '../update/templates/SnowReport'
import SurfaceConditions from '../update/templates/SurfaceConditions'
import VueFuse from 'vue-fuse'
import draggable from 'vuedraggable'
import firebase from 'firebase'
import Confirm from '../Confirm'
import tokml from 'tokml'
import axios from 'axios'
const turf = require('@turf/turf')
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      confirmDownload: false,
      printQuery: {
        status: 'all',
        activity: 'all'
      },
      tsSelector: false,
      tsToCopyTo: false,
      trailToCopy: false,
      orderChanged: false,
      reorder: false,
      trailsFiltered: false,
      trailResults: [],
      fuseOptions: {
        keys: ['properties.name']
      },
      phrases: {
        1: {
          en: 'Trails',
          fr: 'Sentiers'
        },
        2: {
          en: 'New Trail',
          fr: 'Nouveau sentier'
        },
        3: {
          en: 'Reorder Trails',
          fr: 'Réorganiser les sentiers'
        },
        4: {
          en: 'Bulk Edit',
          fr: 'Modification en bloc'
        },
        5: {
          en: 'Map',
          fr: 'Carte géographique'
        },
        6: {
          en: 'Fullscreen',
          fr: 'Plein écran'
        },
        7: {
          en: 'Show All Activities',
          fr: 'Réinitialiser les filtres'
        },
        8: {
          en: 'Search',
          fr: 'Rechercher'
        },
        9: {
          en: 'Easy / Hard',
          fr: 'Facile / Difficile'
        },
        10: {
          en: 'Hard / Easy',
          fr: 'Difficile / Facile'
        },
        11: {
          en: 'Copy to Trail System',
          fr: 'Copier dans le réseau de sentiers'
        },
        12: {
          en: 'Print Trail Report',
          fr: 'Imprimer le rapport sur les sentiers'
        },
        13: {
          en: 'Powered by',
          fr: 'Propulsé par'
        },
        14: {
          en: 'Choose Trail System',
          fr: 'Choisir le réseau de sentiers'
        },
        15: {
          en: 'Cancel',
          fr: 'Annuler'
        },
        16: {
          en: 'Hidden',
          fr: 'Sentier caché'
        },
        17: {
          en: 'Skating',
          fr: 'Pas de patin'
        },
        18: {
          en: 'Backcountry',
          fr: 'Hors-piste'
        },
        19: {
          en: 'Alpine Touring',
          fr: 'Ski de montagne'
        },
        20: {
          en: 'Single Track',
          fr: 'Single track'
        },
        21: {
          en: '',
          fr: 'Classique'
        },
        22: {
          en: 'Activities',
          fr: 'Activités'
        },
        23: {
          en: 'Language',
          fr: 'Langue'
        },
        24: {
          en: 'Download',
          fr: 'Télécharger'
        },
        25: {
          en: 'You are about to download a mapping file to your device that can be opened by other mapping apps for offline use. We recommend the Gaia GPS app for mobile devices. Would you like to continue?',
          fr: 'Vous êtes sur le point de télécharger un fichier de cartographie sur votre appareil qui peut être ouvert par d\'autres applications de cartographie pour une utilisation hors ligne. Nous recommandons l\'application Gaia GPS pour les appareils mobiles. Voulez-vous continuer?'
        },
        26: {
          en: 'Fly Fishing',
          fr: 'Pêche à la mouche'
        }
      }
    }
  },
  components: {
    draggable,
    Notice,
    SnowReport,
    SurfaceConditions,
    VueFuse,
    Confirm
  },
  computed: {
    hiddenActivities: {
      get () {
        return this.$store.state.hiddenActivities
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'hiddenActivities', value: value })
      }
    },
    filteredActivity: {
      get () {
        return this.$store.state.filteredActivity
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'filteredActivity', value: value })
      }
    },
    trailSystem: {
      get () {
        return this.$store.state.trailSystem
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trailSystem', value: value })
      }
    },
    trails: {
      get () {
        return this.$store.state.trails
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trails', value: value })
      }
    }
  },
  mounted () {
    const params = new URLSearchParams(window.location.search)

    // Check if the "a" parameter exists
    if (params.has('a')) {
      // Get the value of the "a" parameter
      const aValue = params.get('a')
      // Perform your logic here
      this.activityFilter({ value: aValue })
    }
    if (this.filteredActivity) {
      setTimeout(() => {
        this.activityFilter(this.filteredActivity)
      }, 1000)
    }
    if (this.trailSystem.hiddenActivities) {
      this.trailSystem.hiddenActivities.forEach(hiddenActivity => {
        this.hiddenActivities[hiddenActivity.value] = true
      })
    }
  },
  methods: {
    download (confirm) {
      if (confirm) {
        const path = this.$store.state.trailSystem.geoJsonPath
        const cleanDescription = this.$store.state.trailSystem.name.replace(/\s+/g, '_')
        const fileName = cleanDescription + '.kml'
        const storageRef = firebase.storage().ref(path)
        storageRef.getDownloadURL().then(url => {
          return axios.get(url)
        }).then(response => {
          return tokml(response.data)
        }).then(kml => {
          var element = document.createElement('a')
          element.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(kml))
          element.setAttribute('download', fileName)
          element.style.display = 'none'
          document.body.appendChild(element)
          element.click()
          document.body.removeChild(element)
        }).catch(err => {
          console.log(err)
        })
      }
      this.confirmDownload = false
    },
    toggleLanguage (lang) {
      this.trailSystem.language = lang
    },
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    bulkEdit () {
      this.$store.commit('hideDrawer', 'trails')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'bulkEdit')
      }, 300)
    },
    launchTsSelector (trail) {
      this.trailToCopy = trail
      this.tsSelector = true
    },
    copyTrail (ts) {
      this.tsToCopyTo = ts
      console.log(this.trailToCopy)
      console.log(this.tsToCopyTo)
      fb.trailsCollection.doc(this.trailToCopy.item.properties.trailId).get().then(trailDoc => {
        const trailItem = trailDoc.data()
        trailItem.createdBy = this.$store.state.currentUser.uid
        trailItem.createdOn = new Date()
        trailItem.updatedBy = this.$store.state.currentUser.uid
        trailItem.updatedOn = new Date()
        trailItem.trailSystemId = this.tsToCopyTo.id
        return fb.trailsCollection.add(trailItem)
      }).then(ref => {
        fb.compileRequestsCollection.add({
          createdOn: new Date(),
          createdBy: this.$store.state.currentUser.uid,
          trailSystemId: this.tsToCopyTo.id,
          oldGeoJsonPath: this.tsToCopyTo.geoJsonPath
        })
        this.$store.commit('setAlert', { active: true, message: 'Trail Copied!', level: 'alert-success', timeout: 2000 })
      }).catch(err => {
        this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 6000 })
        console.log(err)
      })
      this.tsSelector = false
    },
    statusFilter (status) {
      this.printQuery.status = status
      this.trailResults.forEach(item => {
        item.filtered = false
      })
      this.trailsFiltered = false
      this.filteredActivity = false
      this.trailResults.forEach(trail => {
        if (trail.item.properties.status !== status) {
          trail.filtered = true
        }
      })
      this.trailsFiltered = true
      // this.$store.state.mapObject.setFilter('trailsLayer', ['==', 'status', status])
      this.$store.state.mapObject.setFilter('trailsLayer', ['all', ['==', 'status', status], ['==', 'hidden', false]])
      this.$store.state.mapObject.setFilter('trailsLabelsLayer', ['all', ['==', 'status', status], ['==', 'hidden', false]])
    },
    moveActivity (position, activity) {
      this.orderChanged = true
      const trailsToMove = []
      for (let i = this.trails.length - 1; i >= 0; i--) {
        if (this.trails[i].properties.activities.length > 0) {
          this.trails[i].properties.activities.forEach(trailActivity => {
            if (trailActivity.value === activity.value) {
              trailsToMove.push(this.trails[i])
              this.trails.splice(i, 1)
            }
          })
        }
      }
      if (position === 'bottom') {
        trailsToMove.forEach((item, i) => {
          this.trails.push(item)
        })
      } else if (position === 'top') {
        this.trails = trailsToMove.concat(this.trails)
      }
    },
    moveTrail (position, index) {
      this.orderChanged = true
      if (position === 'bottom') {
        this.trails.push(this.trails.splice(index, 1)[0])
      } else if (position === 'top') {
        this.trails.unshift(this.trails[index])
        this.trails.splice((index + 1), 1)
      }
    },
    activityFilter (activity) {
      const trailsGeoJsonDefault = JSON.parse(JSON.stringify(this.$store.state.trailsGeoJsonDefault))
      console.log(trailsGeoJsonDefault)
      this.$store.commit('setStateProperty', { property: 'trailsGeoJson', value: trailsGeoJsonDefault })
      // const map = this.$store.state.mapObject
      if (activity === 'reset') {
        // Create a new URL object based on the current location
        const url = new URL(window.location)
        // Clear the search part of the URL to remove all query strings
        url.search = ''
        // Use history.pushState to update the URL in the browser address bar without reloading the page
        history.pushState(null, '', url.toString())
        location.reload()
        // this.printQuery.status = 'all'
        // this.printQuery.activity = 'all'
        // this.trails.forEach(item => {
        //   item.properties.filtered = false
        // })
        // this.trailResults.forEach(item => {
        //   item.filtered = false
        // })
        // this.trailsFiltered = false
        // this.filteredActivity = false
        // FILTERS
        // map.setFilter('trailsLayer', ['all', ['!=', 'status', 'null'], ['==', 'hidden', false]])
        // map.setFilter('trailsLabelsLayer', ['all', ['!=', 'status', 'null'], ['==', 'hidden', false]])
        // map.setFilter('trailsLayer', ['all', ['==', 'type', 'Standard'], ['==', 'lineStyle', 'Solid'], ['==', 'hidden', false]])
        // map.setFilter('trailsDashedLayer', ['all', ['==', 'type', 'Standard'], ['==', 'lineStyle', 'Dashed'], ['==', 'hidden', false]])
        // map.setFilter('trailsLabelsLayer', ['all', ['==', 'type', 'Standard'], ['==', 'hidden', false]])
        // this.$store.dispatch('mapTrails')
      } else {
        // Construct a new URL object using the current window location
        const url = new URL(window.location)

        // Set or update the query parameter
        url.searchParams.set('a', activity.value)

        // Push the new URL to the browser history
        history.pushState(null, '', url)

        this.filteredActivity = activity
        this.printQuery.activity = activity.value
        this.trailResults.forEach(trail => {
          trail.filtered = true
          trail.item.properties.activities.forEach(trailActivity => {
            if (trailActivity.value === activity.value) {
              trail.filtered = false
              // UPDATE STATUS VALUE AND MAP HERE
              trail.item.properties.status = trailActivity.status
            }
          })
        })
        this.trailsFiltered = true
        this.$store.state.trailsGeoJson.features.forEach(trail => {
          trail.properties.filtered = true
          if (trail.properties.activities) {
            console.log(trail.properties)
            console.log(activity)
            trail.properties.activities.forEach(trailActivity => {
              if (trailActivity.value === activity.value) {
                trail.properties.filtered = false
                // UPDATE STATUS VALUE AND MAP HERE
                trail.properties.status = trailActivity.status
              }
            })
          }
        })
        this.$store.dispatch('mapTrails')
        // setTimeout(() => {
        //   map.setFilter('trailsLayer', ['all', ['==', 'filtered', false], ['==', 'hidden', false]])
        //   map.setFilter('trailsLabelsLayer', ['all', ['==', 'filtered', false], ['==', 'hidden', false]])
        //   map.setFilter('trailsDirLayer', ['all', ['==', 'filtered', false], ['==', 'hidden', false]])
        //   map.setFilter('trailsDirRevLayer', ['all', ['==', 'filtered', false], ['==', 'hidden', false]])
        //   map.setFilter('trailsDashedLayer', ['all', ['==', 'filtered', false], ['==', 'hidden', false]])
        // }, 300)
      }
    },
    handleResults (r) {
      this.trailResults = r
    },
    setActivityVariant (status) {
      if (status === 'Open') {
        return 'success'
      } else if (status === 'Caution') {
        return 'warning'
      } else if (status === 'Closed') {
        return 'danger'
      }
    },
    sortbyDifficulty (priority) {
      const newOrder = []
      let order
      if (priority === 'easy') {
        order = ['circle', 'square', 'diamond', 'doubleDiamond', 'terrainPark', 'notRated']
      } else {
        order = ['terrainPark', 'doubleDiamond', 'diamond', 'square', 'circle', 'notRated']
      }
      order.forEach(difficulty => {
        this.trails.forEach(trail => {
          if (trail.properties.difficulty === difficulty) {
            newOrder.push(trail)
          }
        })
      })
      this.trails = newOrder
      this.orderChanged = true
    },
    alphabetize () {
      this.trails.sort((a, b) => (a.properties.name > b.properties.name) ? 1 : -1)
      this.orderChanged = true
    },
    showTrailSystemDetails () {
      this.$store.commit('hideDrawer', 'trails')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'trailSystemDetails')
      }, 300)
    },
    toggleReorder () {
      if (this.reorder) {
        this.handleClass = 'handle'
        this.reorder = false
      } else {
        this.handleClass = ''
        this.reorder = true
      }
    },
    saveOrder () {
      this.orderChanged = false
      this.reorder = false
      this.$store.dispatch('updateTrailOrders').then(() => {
        fb.compileRequestsCollection.add({
          createdOn: new Date(),
          createdBy: this.$store.state.currentUser.uid,
          trailSystemId: this.$store.state.trailSystem.id,
          oldGeoJsonPath: this.$store.state.trailSystem.geoJsonPath
        }).then(() => {
          this.$store.commit('setAlert', { active: true, message: 'Order updated!', level: 'alert-success', timeout: 2000 })
        })
      })
    },
    changeOrder () {
      this.orderChanged = true
    },
    launchTrail (trail) {
      this.$store.commit('hideDrawer', 'trails')
      fb.trailsCollection.doc(trail.properties.trailId).get().then(doc => {
        const trailDoc = doc.data()
        trailDoc.id = doc.id
        const activitiesOrdered = []
        trailDoc.activities.forEach((item, i) => {
          if (item.status === 'Open') {
            activitiesOrdered.push(item)
          }
        })
        trailDoc.activities.forEach((item, i) => {
          if (item.status === 'Caution') {
            activitiesOrdered.push(item)
          }
        })
        trailDoc.activities.forEach((item, i) => {
          if (item.status === 'Closed') {
            activitiesOrdered.push(item)
          }
        })
        trailDoc.activities.forEach((item, i) => {
          if (item.status !== 'Open' && item.status !== 'Caution' && item.status !== 'Closed') {
            activitiesOrdered.push(item)
          }
        })
        trailDoc.activities = activitiesOrdered
        this.$store.commit('setStateProperty', { property: 'trail', value: trailDoc })
        setTimeout(() => {
          this.$store.commit('showDrawer', 'trail')
        }, 300)
        setTimeout(() => {
          const map = this.$store.state.mapObject
          const trailGeoJson = JSON.parse(this.$store.state.trail.geoJson)
          const bounds = turf.bbox(trailGeoJson)
          if (bounds[0] <= 90 && bounds[0] >= -90) {
            map.fitBounds(bounds, {
              padding: 20
            })
          }
        }, 600)
      })
    },
    createTrail () {
      this.$store.commit('hideDrawer', 'trails')
      setTimeout(() => {
        this.$store.commit('setStateProperty', { property: 'editMode', value: false })
        this.$store.commit('showDrawer', 'createTrail')
      }, 300)
    },
    formatLength (length) {
      if (length) {
        if (this.$store.state.trailSystem.lengthMeasurement === 'miles') {
          let miles = length * 0.621371
          miles = miles.toFixed(2)
          return miles + ' mi'
        } else {
          return length.toFixed(2) + ' km'
        }
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.ts-selector {
  position: absolute;
  top: 30%;
  background-color: #eee;
  width: calc(100% - 40px);
  left: 20px;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  z-index: 10;
  box-shadow: 1px 3px 6px rgba(0,0,0,0.3);
}

.trail-container {
  position: relative;
  border-radius: 4px;
}

.options {
  position: absolute;
  top: -8px;
  right: -5px;
  z-index: 9;
}

#activityFilterLabel {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 1px;
}

@media (max-width:800px) {
  #createTrailButton {
    display: none;
  }
}
</style>

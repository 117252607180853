<template>
  <div id="admin" class="container">
    <h1>Customer Audit</h1>
    <!-- BLOCK TOOL -->
    <b-btn
      class="float-right"
      size="sm"
      @click="loadBlockTool()"
    >Block Tool</b-btn>
    <div
      id="blockTool"
      v-if="blockTool"
    >
      <b-btn
        size="sm"
        @click="blockAccounts()"
        variant="danger"
        class="mr-1"
      >Block Accounts</b-btn>
      <b-btn
        size="sm"
        @click="blockTool = false"
      >Cancel</b-btn>
      <ol class="mt-2">
        <div
          v-for="(toBlock, i) in trailSystemsToBlock"
          v-bind:key="i"
        >
          <li>{{ toBlock.name }}<span v-if="toBlock.override" class="text-success"> (Overridden)</span><span v-if="toBlock.blocked" class="text-danger"> (Blocked)</span></li>
        </div>
      </ol>
    </div>
    <!-- END BLOCK TOOL -->
    <!-- TRIAL TOOL -->
    <!-- <b-btn
      class="float-right"
      size="sm"
      @click="loadTrialTool()"
    >Trial Tool</b-btn>
    <div
      id="blockTool"
      v-if="trialTool"
    >
      <b-btn
        size="sm"
        @click="blockTrials()"
        variant="danger"
        class="mr-1"
      >Block Trials</b-btn>
      <b-btn
        size="sm"
        @click="trialTool = false"
      >Cancel</b-btn>
      <ol class="mt-2">
        <li
          v-for="(toTrial, i) in trailSystemsToTrial"
          v-bind:key="i"
        >{{ toTrial.name }}</li>
      </ol>
    </div> -->
    <!-- END BLOCK TOOL -->
    <!-- FILTERS -->
    <p class="lead">
      <b-badge
        class="mr-1 clickable"
        pill
        variant="success"
        @click="filter('passAudit', true)"
      >
        Passed
      </b-badge>
      <b-badge
        class="mr-1 clickable"
        pill
        variant="warning"
        @click="filter('trialPeriod', true)"
      >
        Trial Period
      </b-badge>
      <b-badge
        class="mr-1 clickable"
        pill
        variant="danger"
        @click="filter('passAudit', false)"
      >
        Failed
      </b-badge>
      <b-badge
        class="mr-1 clickable"
        pill
        @click="filter('blocked', true)"
      >
        Blocked
      </b-badge>
      <b-badge
        class="mr-1 clickable"
        pill
        variant="primary"
        @click="filter('override', true)"
      >
        Overridden
      </b-badge>
      <b-badge
        v-if="filter"
        class="mr-1 clickable"
        pill
        variant="default"
        @click="filter('reset', true)"
      >
        <i class="fas fa-sync-alt"></i> Refresh
      </b-badge>
    </p>
    <VueFuse
      v-if="displayList"
      :fuseOpts="fuseOptions"
      :list="trailSystems"
      placeholder="Search"
      class="text-center border rounded-lg center mb-2 w-100"
      @fuse-results="handleSearch"
    />

    <div
      class="list-group"
      v-if="displayList && $store.state.userProfile.superAdmin"
    >
      <div
        v-for="(trailSystem, i) in filteredResults"
        v-bind:key="i"
        class="list-group-item list-group-item-action flex-column align-items-start clickable trail-item handle"
      >
        <p class="p-1 text-center text-white bg-primary" v-if="trailSystem.override"><strong>OVERRIDDEN</strong></p>
        <p class="p-1 text-center text-white bg-secondary" v-if="trailSystem.blocked"><strong>BLOCKED</strong></p>
        <p class="p-1 text-center bg-warning" v-if="trailSystem.trialPeriod"><strong>TRIAL PERIOD</strong></p>
        <p class="p-1 text-center text-white bg-danger" v-if="!trailSystem.passAudit"><strong>FAIL</strong> - {{ trailSystem.failComment }}</p>
        <p class="p-1 text-center text-white bg-success" v-if="trailSystem.passAudit"><strong>PASS</strong></p>
        <p class="lead"><a :href="'/ts/' + trailSystem.id" target="_blank">{{ trailSystem.name }}</a></p>
        <p class="lead text-warning" v-if="!trailSystem.name">No Name... most likely a trustee account</p>
        <p>ID: {{ trailSystem.id }}</p>
        <p>Subscription: <span v-if="trailSystem.activeSub">{{ trailSystem.activeSub.role }}</span><span v-else>No Subscription</span></p>
        <p>Trustees: {{ trailSystem.trusteeCount }}</p>
        <p>Number of Trail Systems Used: {{ trailSystem.ownerTSCount }}</p>
        <div class="card p-1">
          <p><small><strong>Owner Details</strong></small></p>
          <p>Created: {{ trailSystem.ownerProfile.createdOn | formatDate }}</p>
          <p>Email: {{ trailSystem.ownerProfile.email }}</p>
          <p>Subscriptions:</p>
          <ul>
            <li
              v-for="(subscription, i) in trailSystem.ownerSubscriptions"
              v-bind:key="i"
            >{{ subscription.role }} - {{ subscription.status }}</li>
          </ul>
        </div>
      </div><!-- /.list-group-item -->
    </div><!-- /.list-group -->
  </div><!-- /#admin -->
</template>

<script>
import axios from 'axios'
import firebase from 'firebase'
import moment from 'moment'
import VueFuse from 'vue-fuse'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      trialTool: false,
      trailSystemsToTrial: [],
      blockTool: false,
      initialLoad: true,
      trailSystemsToBlock: [],
      displayList: false,
      filtered: false,
      filteredResults: [],
      fuseOptions: {
        keys: ['name']
      },
      plans: {
        circle: {
          systems: 2,
          trustees: 99
        },
        triangle: {
          systems: 4,
          trustees: 99
        },
        star: {
          systems: 8,
          trustees: 99
        },
        square: {
          systems: 2,
          trustees: 99
        },
        diamond: {
          systems: 4,
          trustees: 99
        },
        doubleDiamond: {
          systems: 8,
          trustees: 99
        }
      },
      trailSystems: []
    }
  },
  created () {
    const storageRef = firebase.storage().ref('admin/customerAudit.json')
    storageRef.getDownloadURL().then(url => {
      axios.get(url).then(response => {
        this.trailSystems = response.data
        this.runAudit()
      })
    })
  },
  components: {
    VueFuse
  },
  methods: {
    blockTrials () {
      this.trailSystemsToTrial.forEach((item, i) => {
        console.log(item.id)
        fb.trailSystemsCollection.doc(item.id).update({
          inactive: true,
          updatedOn: new Date()
        })
      })
    },
    loadTrialTool () {
      this.trailSystemsToTrial = []
      this.trailSystems.forEach((ts, i) => {
        if (ts.trialPeriod) {
          this.trailSystemsToTrial.push(ts)
        }
      })
      this.trialTool = true
      console.log(this.trailSystemsToTrial)
    },
    blockAccounts () {
      this.trailSystemsToBlock.forEach((item, i) => {
        console.log(item.id)
        fb.trailSystemsCollection.doc(item.id).update({
          blocked: true,
          updatedOn: new Date()
        })
      })
    },
    loadBlockTool () {
      this.trailSystemsToBlock = []
      this.trailSystems.forEach((ts, i) => {
        if (!ts.passAudit && !ts.trialPeriod && !ts.blocked) {
          this.trailSystemsToBlock.push(ts)
        }
      })
      this.blockTool = true
      console.log(this.trailSystemsToBlock)
    },
    handleSearch (r) {
      if (!this.initialLoad) {
        this.filteredResults = []
        r.forEach(result => {
          this.filteredResults.push(result.item)
        })
      } else {
        this.initialLoad = false
      }
    },
    filter (property, value) {
      this.filteredResults = []
      if (property === 'reset') {
        this.runAudit()
      } else {
        for (let i = 0; i < this.trailSystems.length; i++) {
          if (this.trailSystems[i][property] === value && !this.trailSystems[i].blocked) {
            this.filteredResults.push(this.trailSystems[i])
          } else if (property === 'override' && this.trailSystems[i].override) {
            this.filteredResults.push(this.trailSystems[i])
          } else if (property === 'blocked' && this.trailSystems[i].blocked) {
            this.filteredResults.push(this.trailSystems[i])
          }
        }
        this.filtered = property
      }
    },
    runAudit () {
      const nowSeconds = Date.now() / 1000
      this.trailSystems.forEach((ts, i) => {
        const ownerId = ts.ownerId
        this.trailSystems[i].passAudit = true
        // Look for subscriptions
        if (ts.ownerSubscriptions.length === 0) {
          this.trailSystems[i].passAudit = false
          this.trailSystems[i].failComment = 'No subscription'
        } else {
          let activeSub = false
          ts.ownerSubscriptions.forEach(sub => {
            // Subscription status
            if (sub.status === 'active') {
              activeSub = true
              this.trailSystems[i].activeSub = sub
            }
          })
          if (!activeSub) {
            this.trailSystems[i].activeSub = false
            this.trailSystems[i].passAudit = false
            this.trailSystems[i].failComment = 'No paid/active subscription'
          }
        }
        // Check Owner Number of Trail Systems
        let ownerCount = 0
        this.trailSystems.forEach(ownerCheckSystem => {
          if (ownerCheckSystem.ownerId === ownerId) {
            ownerCount++
          }
        })
        this.trailSystems[i].ownerTSCount = ownerCount
        // Check Number of Trustees & Systems
        if (this.trailSystems[i].activeSub) {
          let role = this.trailSystems[i].activeSub.role
          if (!role) {
            console.log('No role')
            role = 'circle'
          }
          if (this.plans[role].systems < this.trailSystems[i].ownerTSCount || this.plans[role].trustees < this.trailSystems[i].trusteeCount) {
            this.trailSystems[i].passAudit = false
            this.trailSystems[i].failComment = 'Too many trail systems or trustees'
          }
        }
        // Check for 31 day trial
        const startedAccount = ts.ownerProfile.createdOn._seconds
        if (nowSeconds - startedAccount > 2678400) {
          this.trailSystems[i].trialPeriod = false
        } else {
          this.trailSystems[i].trialPeriod = true
        }
        if (!ts.blocked) {
          this.filteredResults.push(ts)
        }
      })
      this.displayList = true
    }
  },
  filters: {
    formatDate (val) {
      if (!val) { return '-' }
      var t = new Date(1970, 0, 1)
      t.setSeconds(val._seconds)
      return moment(t).format('MM/DD/YY')
    }
  }
}
</script>

<style scoped>
#blockTool {
  position: fixed;
  top: 20px;
  left: 50px;
  width: calc(100% - 100px);
  background-color: #eee;
  padding: 20px;
  border-radius: 4px;
  z-index: 10;
  height: 90%;
  overflow-y: scroll;
}
</style>

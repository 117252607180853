<template>
  <div id="trustees">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.trustees.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="hideTrustees()"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title">{{ language(1) }}</h4>
      </template>
      <p
        v-if="$store.state.ownerData"
      ><strong>{{ language(2) }}</strong> {{ $store.state.ownerData.email }}</p>
      <form @submit.prevent class="form-inline">
        <div class="form-group">
          <input
            v-model="invitation.email"
            class="form-control mr-2"
            :placeholder="language(3)"/>
        </div><!-- /.form-group -->
        <button
          @click="invite()"
          type="submit"
          class="btn btn-primary"
        >{{ language(4) }}</button>
      </form>
      <table v-if="$store.state.trusteeData.length > 0" class="table table-striped table-hover mt-3">
        <thead>
          <tr>
            <th scope="col">{{ language(5) }}</th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(trustee, index) in $store.state.trusteeData"
            v-bind:key="index"
          >
            <td>{{ trustee.email }}</td>
            <td>
              <b-form-select
                v-model="trailSystem.permissions[trustee.id]"
                :options="permissionOptions"
                class="mb-3"
                size="sm"
              />
            </td>
            <td>
              <i
                class="fal fa-user-slash clickable"
                v-b-tooltip.hover
                title="Remove Trustee"
                @click="confirmRemove = { confirm: true, index: index, userId: trustee.id }"
              ></i>
            </td>
          </tr>
          <button
            @click="savePermissions()"
            class="btn btn-primary mt-2"
          >Save Permissions</button>
        </tbody>
      </table>
    </b-card>
    <Confirm
      v-if="confirmRemove.confirm"
      text="Are you sure you want to remove this trustee?"
      v-on:confirmed="removeTrustee($event)"
    />
  </div><!-- /#trustees -->
</template>

<script>
import Confirm from '../Confirm'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Trustees',
          fr: 'Administrateurs'
        },
        2: {
          en: 'Owner',
          fr: 'Propriétaire'
        },
        3: {
          en: 'Enter email address',
          fr: 'Entrez votre adresse courriel'
        },
        4: {
          en: 'Invite',
          fr: 'Inviter'
        },
        5: {
          en: 'Email',
          fr: 'Adresse courriel'
        }
      },
      confirmRemove: {
        confirm: false,
        index: 0,
        userId: ''
      },
      permissionOptions: ['Trustee', 'Administrator'],
      invitation: {
        email: ''
      }
    }
  },
  components: {
    Confirm
  },
  computed: {
    trailSystem: {
      get () {
        return this.$store.state.trailSystem
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trailSystem', value: value })
      }
    }
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    savePermissions () {
      const self = this
      fb.trailSystemsCollection.doc(self.trailSystem.id).update({
        permissions: self.trailSystem.permissions,
        updatedOn: new Date(),
        updatedBy: self.$store.state.currentUser.uid
      }).then(e => {
        self.$store.commit('setAlert', { active: true, message: 'Permissions updated!', level: 'alert-success', timeout: 4000 })
      }).catch(err => {
        console.log(err)
      })
    },
    removeTrustee (confirmed) {
      const index = this.confirmRemove.index
      const userId = this.confirmRemove.userId
      const self = this
      if (confirmed) {
        self.confirmRemove.confirm = false
        // Remove user from this farm.users
        self.trailSystem.users.splice(index, 1)
        // Remove user from this farm.permissions
        delete self.trailSystem.permissions[userId]
        fb.trailSystemsCollection.doc(self.trailSystem.id).update({
          users: self.trailSystem.users,
          permissions: self.trailSystem.permissions,
          updatedOn: new Date(),
          updatedBy: self.$store.state.currentUser.uid
        }).then(doc => {
          self.$store.dispatch('fetchTrustees')
        })
      } else {
        self.confirmRemove.confirm = false
        return false
      }
    },
    invite () {
      const self = this
      fb.usersCollection.where('email', '==', self.invitation.email).get().then(querySnapshot => {
        if (querySnapshot.docs.length < 1) {
          self.$store.commit('setAlert', { active: true, message: 'This email address does not exist in our system! This user must first create a manager account via these instructions: https://www.support.trailhub.org/create-new-account', level: 'alert-warning', timeout: 90000 })
        } else {
          const uid = querySnapshot.docs[0].id
          // Add user to trailSystem
          self.trailSystem.users.push({ id: uid })
          // Add permission record to trailSystem's permissions
          self.trailSystem.permissions[uid] = 'Trustee'
          fb.trailSystemsCollection.doc(self.trailSystem.id).update({
            users: self.trailSystem.users,
            permissions: self.trailSystem.permissions,
            updatedOn: new Date(),
            updatedBy: self.$store.state.currentUser.uid
          }).then(item => {
            self.$store.dispatch('fetchTrustees')

            // NOTIFICATION
            const notificationMessage = 'TrailHUB user, ' + self.$store.state.userProfile.email + ', has added you as a trustee on ' + self.$store.state.trailSystem.name + '.'
            const notificationUrl = 'https://trailhub.org/ts/' + self.$store.state.trailSystem.id
            const notificationSubject = 'TrailHUB Notification'
            fb.notificationsCollection.add({
              createdOn: new Date(),
              updatedOn: new Date(),
              createdBy: self.$store.state.currentUser.uid,
              trailSystemId: self.$store.state.trailSystem.id,
              subject: notificationSubject,
              message: notificationMessage,
              recipient: uid,
              url: notificationUrl,
              read: false
            })

            self.$store.commit('setAlert', { active: true, message: 'Successfully invited trustee!', level: 'alert-success', timeout: 4000 })
          })
        }
      })
    },
    hideTrustees () {
      this.$store.commit('hideDrawer', 'trustees')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'trailSystemDetails')
      }, 300)
    }
  }
}
</script>

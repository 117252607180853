<template>
  <div id="signageHeader">
    <div v-if="showWeather" id="headerContainer">
      <img
        v-if="$store.state.showTSIcon"
        :src="$store.state.trailSystem.iconUrl"
        id="signageLogo"
      />
      <span
        @click="toggleDrawer('trailSystemDetails')"
        v-if="!$store.state.trailSystem.hideName"
        class="clickable"
        id="signageNavbarTitle"
      >{{ $store.state.trailSystem.name }}</span>
      <div id="signageHeaderTop">
        <p><strong>
          {{ currentDate }} |
          {{ currentTime }} |
          {{ language(1) }}: {{ weather.current.temp | formatTemp(trailSystem.tempMeasurement)}} |
          {{ language(2) }}: {{ weather.current.wind_speed | formatWindSpeed(trailSystem.tempMeasurement)}} |
          {{ language(3) }}: {{ weather.current.wind_gust | formatWindSpeed(trailSystem.tempMeasurement)}}
        </strong></p>
      </div>
      <div id="signageHeaderBottom">
        <div
          v-for="(hour, i) in weather.hourly.slice(0, 8)"
          v-bind:key="i"
          class="hourlyChip"
        >
          <strong>
            <i :class="getClass(hour.weather[0].icon)" class="collapse-icon text-dark"></i>
            {{ hour.dt | formatTime}} |
            {{ hour.temp | formatTemp(trailSystem.tempMeasurement)}}
          </strong>
        </div><!-- /.hourlyChip -->
      </div><!-- /#signageHeaderBottom -->
    </div><!-- /if showWeather -->
  </div><!-- /#signageHeader -->
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Current Temp',
          fr: 'Température actuelle'
        },
        2: {
          en: 'Wind',
          fr: 'Vent'
        },
        3: {
          en: 'Gusting',
          fr: 'Rafale'
        }
      },
      weather: false,
      showWeather: false,
      currentTime: false,
      currentDate: false
    }
  },
  computed: {
    trailSystem: {
      get () {
        return this.$store.state.trailSystem
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trailSystem', value: value })
      }
    }
  },
  mounted () {
    axios.get('https://trailhub.org/api/weather/' + this.trailSystem.id).then(data => {
      this.weather = data.data[0]
      this.trailSystem.weather.main.temp = this.weather.current.temp
      this.showWeather = true
    })
    this.getFormattedTime()
    this.getFormattedDate()
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    getClass (iconCode) {
      // const iconCode = this.$store.state.trailSystem.weather.weather[0].icon
      let iconClass
      if (iconCode === '01d') { iconClass = 'fal fa-sun' }
      if (iconCode === '01n') { iconClass = 'fas fa-moon-stars' }
      if (iconCode === '02d') { iconClass = 'fal fa-cloud-sun' }
      if (iconCode === '02n') { iconClass = 'fas fa-cloud-moon' }
      if (iconCode === '03d' || iconCode === '03n') { iconClass = 'fal fa-cloud' }
      if (iconCode === '04d' || iconCode === '04n') { iconClass = 'fal fa-clouds' }
      if (iconCode === '09d' || iconCode === '09n') { iconClass = 'fas fa-cloud-showers-heavy' }
      if (iconCode === '10d') { iconClass = 'fas fa-cloud-sun-rain' }
      if (iconCode === '10n') { iconClass = 'fas fa-cloud-moon-rain' }
      if (iconCode === '11d' || iconCode === '11n') { iconClass = 'fas fa-thunderstorm' }
      if (iconCode === '13d' || iconCode === '13n') { iconClass = 'fal fa-snowflake' }
      if (iconCode === '50d' || iconCode === '50n') { iconClass = 'far fa-fog' }
      return iconClass
    },
    getFormattedTime () {
      var date = new Date()
      var hours = date.getHours()
      let hoursFormatted
      let amPm
      if (hours < 13) {
        hoursFormatted = hours
        amPm = ' a.m.'
      } else {
        hoursFormatted = hours - 12
        amPm = ' p.m.'
      }
      var minutes = '0' + date.getMinutes()
      var formattedTime = hoursFormatted + ':' + minutes.substr(-2) + amPm
      this.currentTime = formattedTime
      const self = this
      setTimeout(function () {
        self.getFormattedTime()
      }, 60000)
      // return formattedTime
    },
    getFormattedDate () {
      const d = new Date()
      const weekday = []
      weekday[0] = 'Sunday'
      weekday[1] = 'Monday'
      weekday[2] = 'Tuesday'
      weekday[3] = 'Wednesday'
      weekday[4] = 'Thursday'
      weekday[5] = 'Friday'
      weekday[6] = 'Saturday'
      const month = []
      month[0] = 'Jan'
      month[1] = 'Feb'
      month[2] = 'Mar'
      month[3] = 'Apr'
      month[4] = 'May'
      month[5] = 'Jun'
      month[6] = 'Jul'
      month[7] = 'Aug'
      month[8] = 'Sep'
      month[9] = 'Oct'
      month[10] = 'Nov'
      month[11] = 'Dec'
      this.currentDate = weekday[d.getUTCDay()] + ' | ' + month[d.getMonth()] + ' ' + d.getDate()
      const self = this
      setTimeout(function () {
        self.getFormattedDate()
      }, 60000)
      // return weekday[d.getUTCDay()] + ' | ' + month[d.getMonth()] + ' ' + d.getDate()
    }
  },
  filters: {
    formatTime (val) {
      var date = new Date(val * 1000)
      var hours = date.getHours()
      let hoursFormatted
      let amPm
      if (hours < 13) {
        hoursFormatted = hours
        amPm = ' a.m.'
      } else {
        hoursFormatted = hours - 12
        amPm = ' p.m.'
      }
      var formattedTime = hoursFormatted + amPm
      return formattedTime
    },
    formatWindSpeed (val, unit) {
      // val is meters per second
      let speed
      if (unit === 'miles') {
        speed = (val * 2.237).toFixed(0) + ' mph'
      } else {
        speed = val.toFixed(1) + ' m/s'
      }
      return speed
    },
    formatTemp (val, unit, label) {
      let output
      if (unit === 'fahrenheit') {
        if (label === false) {
          output = ((val * 1.8) + 32).toFixed(0)
        } else {
          output = ((val * 1.8) + 32).toFixed(0) + '°F'
        }
      } else {
        if (label === false) {
          output = val.toFixed(0)
        } else {
          output = val.toFixed(0) + '°C'
        }
      }
      return output
    }
  }
}
</script>

<style scoped>
.hourlyChip {
  display: inline-block;
  padding: 10px 15px;
}

#headerContainer {
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
  background-color: #F5F5F5;
  padding-left: 20px;
  border-bottom: solid 5px #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
}

#signageLogo {
  height: 100%;
  padding: 10px;
}

#signageNavbarTitle {
  font-size: 28px;
  position: absolute;
  top: 30px;
}

#signageHeaderTop {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  font-size: 20px;
  padding-right: 20px;
  padding-top: 14px;
  text-transform: uppercase;
}

#signageHeaderBottom {
  position: absolute;
  right: 0;
  top: 50px;
  height: 50px;
}
</style>

<template>
  <div id="snowReport" class="mb-2">
    <b-card class="standard-card">
      <div class="d-flex w-100 justify-content-between">
        <div v-if="update.baseDepth">
          <p class="mb-0"><small>{{ language(6) }}</small></p>
          <h2>{{ update.baseDepth }} <span v-if="$store.state.trailSystem.depthMeasurement == 'inches'">in</span><span v-if="$store.state.trailSystem.depthMeasurement == 'centimeters'">cm</span></h2>
        </div>
        <h2><i :class="update.iconClass"></i></h2>
      </div>
      <div class="d-flex w-100 justify-content-between text-center">
        <div v-if="update.twentyFourHours">
          <p class="mb-0"><small>24h</small></p>
          <p class="mb-0"><strong>{{ update.twentyFourHours }} <span v-if="$store.state.trailSystem.depthMeasurement == 'inches'">in</span><span v-if="$store.state.trailSystem.depthMeasurement == 'centimeters'">cm</span></strong></p>
        </div>
        <div v-if="update.fourtyEightHours">
          <p class="mb-0"><small>48h</small></p>
          <p class="mb-0"><strong>{{ update.fourtyEightHours }} <span v-if="$store.state.trailSystem.depthMeasurement == 'inches'">in</span><span v-if="$store.state.trailSystem.depthMeasurement == 'centimeters'">cm</span></strong></p>
        </div>
        <div v-if="update.sevenDays">
          <p class="mb-0"><small>7d</small></p>
          <p class="mb-0"><strong>{{ update.sevenDays }} <span v-if="$store.state.trailSystem.depthMeasurement == 'inches'">in</span><span v-if="$store.state.trailSystem.depthMeasurement == 'centimeters'">cm</span></strong></p>
        </div>
        <div v-if="update.seasonTotal">
          <p class="mb-0"><small>{{ language(7) }}</small></p>
          <p class="mb-0"><strong>{{ update.seasonTotal }} <span v-if="$store.state.trailSystem.depthMeasurement == 'inches'">in</span><span v-if="$store.state.trailSystem.depthMeasurement == 'centimeters'">cm</span></strong></p>
        </div>
      </div>
      <p class="mb-0 mt-2 text-center"><small>
        <span v-if="update.lastSnowDate">{{ language(1) }} {{ update.lastSnowDate | formatDate }} {{ language(2) }} {{ update.lastSnowTime | formatTime }} {{ language(3) }} {{ update.lastSnowAmount }} <span v-if="$store.state.trailSystem.depthMeasurement == 'inches'">inches</span><span v-if="$store.state.trailSystem.depthMeasurement == 'centimeters'">centimeters</span>. </span>
        <span v-if="update.upperElevationDepth">{{ language(4) }} {{ update.upperElevationDepth }} <span v-if="$store.state.trailSystem.depthMeasurement == 'inches'">in</span><span v-if="$store.state.trailSystem.depthMeasurement == 'centimeters'">cm</span>. </span>
        <span v-if="update.lastSnowMakingDate">{{ language(5) }} {{ update.lastSnowMakingDate | formatDate }}.</span>
        <em> ~{{ update.updatedOn | formatDateFull }}</em>
      </small></p>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Last snow reported',
          fr: 'Dernière chute de neige annoncée le'
        },
        2: {
          en: 'at',
          fr: 'à'
        },
        3: {
          en: 'dropping',
          fr: ' : '
        },
        4: {
          en: 'Depth at elevation is',
          fr: 'La profondeur en altitude est de'
        },
        5: {
          en: 'Snow was last made',
          fr: 'Dernier bulletin d’enneigement le'
        },
        6: {
          en: 'Base depth',
          fr: 'Profondeur de la base'
        },
        7: {
          en: 'Season',
          fr: 'Saison'
        }
      }
    }
  },
  props: ['update'],
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    }
  },
  filters: {
    formatTime (val) {
      if (!val) { return '-' }
      return moment(val, 'HH:mm:SS').format('hh a')
    },
    formatDate (val) {
      if (!val) { return '-' }
      const date = val.toDate()
      return moment(date).format('MM/DD')
    },
    formatDateFull (val) {
      if (!val) { return '-' }
      const date = val.toDate()
      return moment(date).format('MM/DD/YY hh:mm a')
    }
  }
}
</script>

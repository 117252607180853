<template>
  <div id="signageUpdates">
    <div v-if="$store.state.trailSystem.updates">
      <!-- NOTICE -->
      <div v-if="currentPane === 'notice'">
        <Notice
          v-for="(notice, i) in $store.state.trailSystem.updates.notice"
          v-bind:key="i"
          :update="notice"
        />
      </div>
      <!-- SNOW REPORT -->
      <div v-if="currentPane === 'snowReport'">
        <SnowReport
          v-if="$store.state.trailSystem.updates.snowReport"
          :update="$store.state.trailSystem.updates.snowReport[0]"
          class="mb-3"
        />
      </div>
      <!-- SURFACE CONDITIONS -->
      <div v-if="currentPane === 'surfaceConditions'">
        <SurfaceConditions
          v-if="$store.state.trailSystem.updates.surfaceConditions"
          :update="$store.state.trailSystem.updates.surfaceConditions[0]"
          class="mb-3"
        />
      </div>
      <!-- STATS -->
      <div v-if="currentPane === 'stats'">
        <SignageStats />
      </div><!-- /stats -->
      <!-- WEB CAMS -->
      <div v-if="currentPane === 'webCams'">
        <SignageWebCams />
      </div>
      <!-- CALENDARS -->
      <div v-if="currentPane === 'calendars'">
        <SignageCalendars />
      </div>
      <!-- DINING MENUS -->
      <!-- <div v-if="currentPane === 'diningMenus'">
        <SignageDiningMenus />
      </div> -->

    </div>
  </div><!-- /#signageUpates -->
</template>

<script>
import Notice from '../update/templates/Notice'
import SnowReport from '../update/templates/SnowReport'
import SurfaceConditions from '../update/templates/SurfaceConditions'
import SignageStats from './SignageStats'
import SignageWebCams from './SignageWebCams'
import SignageCalendars from './SignageCalendars'
// import SignageDiningMenus from './SignageDiningMenus'

export default {
  data () {
    return {
      panesIndex: 3,
      currentPane: 'stats',
      updatePanes: [],
      rotateSpeed: 10000
    }
  },
  components: {
    Notice,
    SnowReport,
    SurfaceConditions,
    SignageStats,
    SignageWebCams,
    SignageCalendars
    // SignageDiningMenus
  },
  created () {
    this.runPanes()
  },
  methods: {
    runPanes () {
      // Recalculate panes that exist
      this.updatePanes = []
      this.updatePanes.push({
        title: 'stats',
        display: true
      })
      if (this.$store.state.trailSystem.updates.notice) {
        this.updatePanes.push({
          title: 'notice',
          display: false
        })
      }
      if (this.$store.state.trailSystem.updates.snowReport) {
        this.updatePanes.push({
          title: 'snowReport',
          display: false
        })
      }
      if (this.$store.state.trailSystem.updates.surfaceConditions) {
        this.updatePanes.push({
          title: 'surfaceConditions',
          display: false
        })
      }
      if ((this.$store.state.trailSystem.subscription === 'doubleDiamond' && this.$store.state.trailSystem.calendars.length >= 1) || (this.$store.state.trailSystem.subscription === 'star' && this.$store.state.trailSystem.calendars.length >= 1)) {
        this.updatePanes.push({
          title: 'calendars',
          display: false
        })
      }
      let webCams = false
      // let diningMenus = false
      this.$store.state.points.forEach(pointFeature => {
        if (pointFeature.properties.markerClass === 'web-cam') {
          webCams = true
        }
        // if (pointFeature.properties.markerClass === 'dining') {
        //   diningMenus = true
        // }
      })
      if (webCams) {
        this.updatePanes.push({
          title: 'webCams',
          display: false
        })
      }
      // if (diningMenus) {
      //   this.updatePanes.push({
      //     title: 'diningMenus',
      //     display: false
      //   })
      // }
      // End gathering panes
      // Wait a second, then display the next pane
      // Reset if to start if needed
      setTimeout(() => {
        // Hide all panes
        this.updatePanes.forEach((pane, i) => {
          pane.display = false
        })
        const indexLength = this.updatePanes.length - 1
        if (this.panesIndex >= indexLength) {
          this.panesIndex = 0
        } else {
          this.panesIndex = this.panesIndex + 1
        }
        this.updatePanes[this.panesIndex].display = true
        this.currentPane = this.updatePanes[this.panesIndex].title
        this.runPanes()
      }, this.rotateSpeed)
    }
  }
}
</script>

<style scoped>
#signageUpdates {
  position: absolute;
  background-color: #F5F5F5;
  top: 20px;
  left: 20px;
  width: calc(100% - 40px);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  height: calc(50% - 60px);
}
</style>

<template>
  <div id="createPoint">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.createPoint.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="closeCreatePoint()"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 v-if="$store.state.editMode">{{ language(4) }}</h4>
        <h4 v-if="!$store.state.editMode">{{ language(5) }}</h4>
      </template>
      <b-form>

        <!-- TYPE -->
        <b-form-group
          class="mt-2"
          :label="language(1)"
          label-for="point-type"
        >
          <b-form-select
            id="point-type"
            v-model="tempPoint.markerClass"
            :options="typeOptions"
          ></b-form-select>
        </b-form-group>

        <!-- NAME -->
        <b-form-group
          class="mt-2"
          :label="language(2)"
          label-for="point-name"
          required
        >
          <b-form-input
            id="point-name"
            v-model="tempPoint.name"
          ></b-form-input>
        </b-form-group>

        <!-- WEBCAM URL If web-cam selected, gather link -->
        <div v-if="tempPoint.markerClass === 'web-cam'">
          <b-form-group
            class="mt-2"
            label="Webcam URL"
            label-for="point-webCamUrl"
            description="Currently supports links to Youtube livestreams."
          >
            <b-form-input
              id="point-webCamUrl"
              v-model="tempPoint.webCamUrl"
            ></b-form-input>
          </b-form-group>
        </div><!-- / v-if -->

        <!-- DESCRIPTION -->
        <b-form-group
          class="mt-2"
          :label="language(3)"
          label-for="point-description"
        >
          <b-form-textarea
            id="point-description"
            v-model="tempPoint.description"
          ></b-form-textarea>
        </b-form-group>

        <!-- STATUS -->
        <label class="mt-2">{{ language(9) }}</label>
        <div>
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="tempPoint.status !== 'Open'"
            @click="tempPoint.status = 'Open'"
          ><i class="fas fa-check-circle text-success"></i> {{ this.statuses.Open[$store.state.trailSystem.language] }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="tempPoint.status === 'Open'"
            @click="tempPoint.status = 'Open'"
          ><i class="fas fa-check-circle text-success"></i> {{ this.statuses.Open[$store.state.trailSystem.language] }}</b-button>
          <b-button
            class="mr-1"
            variant="outline-secondary"
            v-if="tempPoint.status !== 'Caution'"
            @click="tempPoint.status = 'Caution'"
          ><i class="fas fa-exclamation-circle text-warning"></i> {{ this.statuses.Caution[$store.state.trailSystem.language] }}</b-button>
          <b-button
            class="mr-1"
            variant="secondary"
            v-if="tempPoint.status === 'Caution'"
            @click="tempPoint.status = 'Caution'"
          ><i class="fas fa-exclamation-circle text-warning"></i> {{ this.statuses.Caution[$store.state.trailSystem.language] }}</b-button>
          <b-button
            variant="outline-secondary"
            v-if="tempPoint.status !== 'Closed'"
            @click="tempPoint.status = 'Closed'"
          ><i class="fas fa-times-circle text-danger"></i> {{ this.statuses.Closed[$store.state.trailSystem.language] }}</b-button>
          <b-button
            variant="secondary"
            v-if="tempPoint.status === 'Closed'"
            @click="tempPoint.status = 'Closed'"
          ><i class="fas fa-times-circle text-danger"></i> {{ this.statuses.Closed[$store.state.trailSystem.language] }}</b-button>
        </div>

        <!-- HIDDEN-->
        <b-form-checkbox v-model="tempPoint.hidden" name="check-button" switch class="mt-3">
          {{ language(10) }}<br><small>{{ language(11) }}</small>
        </b-form-checkbox>

        <!-- DINING OPTIONS IF dining selected -->
        <DiningOptions v-if="tempPoint.markerClass === 'dining' && $store.state.trailSystem.subscription && $store.state.trailSystem.subscription === 'doubleDiamond' || tempPoint.markerClass === 'dining' && $store.state.trailSystem.subscription && $store.state.trailSystem.subscription === 'star'" />
        <!-- WAIT TIME OPTIONS -->
        <div v-if="tempPoint.markerClass === 'wait-time' && $store.state.trailSystem.subscription && $store.state.trailSystem.subscription === 'doubleDiamond' || tempPoint.markerClass === 'wait-time' && $store.state.trailSystem.subscription && $store.state.trailSystem.subscription === 'star'">
          <b-form-group
            class="mt-2"
            :label="language(8)"
            label-for="point-wait-time"
            required
          >
            <b-form-input
              id="point-wait-time"
              v-model="tempPoint.waitTime"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div><!-- /if wait-time -->
        <b-alert
          v-if="tempPoint.markerClass === 'dining' && !$store.state.trailSystem.subscription ||
          tempPoint.markerClass === 'dining' && ($store.state.trailSystem.subscription !== 'doubleDiamond' && $store.state.trailSystem.subscription !== 'star') ||
          tempPoint.markerClass === 'wait-time' && !$store.state.trailSystem.subscription ||
          tempPoint.markerClass === 'wait-time' && ($store.state.trailSystem.subscription !== 'doubleDiamond' && $store.state.trailSystem.subscription !== 'star')"
          variant="danger"
          show
        >Only 'Double Diamond' and 'Star' customers have access dining menus, wait times, and other guest experience features.</b-alert>

        <b-button variant="primary" class="mt-5 mr-1 w-100" @click="savePoint()">{{ language(7) }}</b-button>
        <b-button
          variant="danger"
          class="mt-3"
          v-if="$store.state.editMode"
          @click="confirmTrash = true"
        >{{ language(6) }}</b-button>
      </b-form>
    </b-card>

    <Confirm
      v-if="confirmTrash"
      text="DANGER! Are you sure you want to trash this Point?"
      v-on:confirmed="trashPoint($event)"
    />

  </div><!-- /#createPoint -->
</template>

<script>
import Confirm from '../Confirm'
import DiningOptions from './DiningOptions'
import firebase from 'firebase'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Type',
          fr: 'Type'
        },
        2: {
          en: 'Name',
          fr: 'Nom'
        },
        3: {
          en: 'Description',
          fr: 'Description'
        },
        4: {
          en: 'Edit Point',
          fr: 'Modifier le point'
        },
        5: {
          en: 'Create Point',
          fr: 'Créer un point'
        },
        6: {
          en: 'Trash Point',
          fr: 'Supprimer le point'
        },
        7: {
          en: 'Save',
          fr: 'Enregistrer'
        },
        8: {
          en: 'Estimated Wait Time in Minutes',
          fr: 'Temps d’attente estimé en minutes'
        },
        9: {
          en: 'Status',
          fr: 'État'
        },
        10: {
          en: 'Point Hidden',
          fr: 'Point caché'
        },
        11: {
          en: 'This will hide this point from public view.',
          fr: 'Cela cachera ce point à la vue du public.'
        }
      },
      confirmTrash: false,
      statuses: {
        Open: {
          en: 'Open',
          fr: 'Ouvert'
        },
        Caution: {
          en: 'Caution',
          fr: 'Attention'
        },
        Closed: {
          en: 'Closed',
          fr: 'Fermé'
        }
      },
      typeOptions: [
        { value: 'bar', text: 'Bar', en: 'Bar', fr: 'Bar' },
        { value: 'cabin', text: 'Cabin', en: 'Cabin', fr: 'Cabine' },
        { value: 'dining', text: 'Dining *', en: 'Dining *', fr: 'À manger *' },
        { value: 'dog-park', text: 'Dog Park', en: 'Dog Park', fr: 'Parc à chien' },
        { value: 'fallen-tree', text: 'Fallen Tree', en: 'Fallen Tree', fr: 'Arbre tombé' },
        { value: 'first-aid', text: 'First Aid', en: 'First Aid', fr: 'Premiers secours' },
        { value: 'fishing', text: 'Fishing', en: 'Fishing', fr: 'Pêche à gué' },
        { value: 'flyFishing', text: 'Fly Fishing', en: 'Fishing', fr: 'Pêche à la mouche' },
        { value: 'generalInfo', text: 'General Information', en: 'General Information', fr: 'Informations générales' },
        { value: 'generalWarning', text: 'General Warning', en: 'General Warning', fr: 'Avertissement général' },
        { value: 'generalDanger', text: 'General Danger', en: 'General Danger', fr: 'Danger général' },
        { value: 'generic', text: 'Generic', en: 'Generic', fr: 'Générique' },
        { value: 'hazard', text: 'Hazard', en: 'Hazard', fr: 'Risquer' },
        { value: 'ice', text: 'Ice', en: 'Ice', fr: 'Glace' },
        { value: 'ice-rink', text: 'Ice Rink', en: 'Ice Rink', fr: 'Patinoire' },
        { value: 'lodging', text: 'Lodging', en: 'Lodging', fr: 'Hébergement' },
        { value: 'lodge', text: 'Lodge', en: 'Lodge', fr: 'Loge' },
        { value: 'mud', text: 'Mud', en: 'Mud', fr: 'Boue' },
        { value: 'parking', text: 'Parking', en: 'Parking', fr: 'Stationnement' },
        { value: 'parking-busy', text: 'Busy Parking', en: 'Busy Parking', fr: 'Stationnement fréquenté' },
        { value: 'parking-very-busy', text: 'Very Busy Parking', en: 'Very Busy Parking', fr: 'Stationnement très fréquenté' },
        { value: 'playground', text: 'Playground', en: 'Playground', fr: 'Terrain de jeux' },
        { value: 'restaurant', text: 'Restaurant', en: 'Restaurant', fr: 'Restaurant' },
        { value: 'restroom', text: 'Restroom', en: 'Restroom', fr: 'Toilettes' },
        { value: 'scenic', text: 'Scenic', en: 'Scenic', fr: 'Pittoresque' },
        { value: 'trailhead', text: 'Trailhead', en: 'Trailhead', fr: 'Départ de sentier' },
        { value: 'wait-time', text: 'Wait Time *', en: 'Wait Time *', fr: 'Temps d’attente *' },
        { value: 'water-access', text: 'Water Access', en: 'Water Access', fr: 'Accès par voie navigable' },
        { value: 'web-cam', text: 'Web Cam', en: 'Web Cam', fr: 'Caméra Web' },
        { value: 'yurt', text: 'Yurt', en: 'Yurt', fr: 'Yourte' }
      ]
    }
  },
  components: {
    Confirm,
    DiningOptions
  },
  computed: {
    point: {
      get () {
        return this.$store.state.point
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'point', value: value })
      }
    },
    tempPoint: {
      get () {
        return this.$store.state.tempPoint
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'tempPoint', value: value })
      }
    },
    geocoderControl: {
      get () {
        return this.$store.state.geocoderControl
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'geocoderControl', value: value })
      }
    },
    selectMarker: {
      get () {
        return this.$store.state.selectMarker
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'selectMarker', value: value })
      }
    }
  },
  mounted () {
    // Deal with proper language of labels
    if (this.$store.state.trailSystem.language !== 'en') {
      this.typeOptions.forEach(option => {
        option.text = option[this.$store.state.trailSystem.language]
      })
    }
    // Remove highlight point and markers
    this.$store.dispatch('removePointHighlight')
    this.$store.state.mapMarkers.forEach(marker => {
      marker.remove()
    })
    if (this.$store.state.editMode) {
      this.tempPoint = this.point
      // Add this point's marker
      const markerCoords = {
        lngLat: {
          lat: this.point.geolocation.latitude,
          lng: this.point.geolocation.longitude
        }
      }
      this.addMarker(markerCoords)
    } else {
      this.tempPoint = {
        description: '',
        geolocation: false,
        hidden: false,
        markerClass: 'generic',
        name: '',
        status: 'Open',
        webCamUrl: ''
      }
    }
    this.initMapControls()
  },
  destroyed () {
    if (this.selectMarker) {
      this.selectMarker.remove()
      this.$store.commit('setStateProperty', { property: 'selectMarker', value: false })
    }
    this.$store.commit('setStateProperty', { property: 'editMode', value: false })
    this.$store.state.mapObject.removeControl(this.$store.state.geocoderControl)
    this.$store.state.mapObject.off('click', this.addMarker)
    this.$store.dispatch('mapTrails')
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    closeCreatePoint () {
      this.$store.commit('hideDrawer', 'createPoint')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'points')
      }, 300)
    },
    trashPoint (confirmed) {
      const pointId = this.$store.state.point.id
      const pointDoc = this.$store.state.point
      if (confirmed) {
        this.confirmTrash = false
        fb.trashedPointsCollection.doc(pointId).set(pointDoc).then(() => {
          return fb.pointsCollection.doc(pointId).delete()
        }).then(() => {
          fb.compileRequestsCollection.add({
            createdOn: new Date(),
            createdBy: this.$store.state.currentUser.uid,
            trailSystemId: this.$store.state.trailSystem.id,
            oldGeoJsonPath: this.$store.state.trailSystem.geoJsonPath
          })
          this.closeCreatePoint()
          this.$store.commit('setAlert', { active: true, message: 'Trashing point!', level: 'alert-success', timeout: 4000 })
        }).catch(err => {
          console.log(err.message)
        })
      } else {
        this.confirmTrash = false
        return false
      }
    },
    addMarker (e) {
      if (this.selectMarker) {
        this.selectMarker.remove()
      }
      const el = document.createElement('div')
      el.className = 'marker ' + this.tempPoint.markerClass
      this.selectMarker = new maplibregl.Marker(el).setLngLat(e.lngLat).addTo(this.$store.state.mapObject) // eslint-disable-line
      this.tempPoint.geolocation = new firebase.firestore.GeoPoint(e.lngLat.lat, e.lngLat.lng)
    },
    initMapControls () {
      const map = this.$store.state.mapObject
      map.removeControl(this.$store.state.zoomControl)
      map.removeControl(this.$store.state.locateControl)
      this.geocoderControl = new MapboxGeocoder({ // eslint-disable-line
        accessToken: 'pk.eyJ1IjoidHJhaWxodWIiLCJhIjoiY2toamZ3Z3lsMDVpdjJycGVibm93b2Y2NSJ9.t9zR8pPj3btbMXqUdIB3dg', // eslint-disable-line
        maplibregl: maplibregl, // eslint-disable-line
        marker: false,
        zoom: 16,
        flyTo: {
          animate: false
        }
      })
      map.addControl(this.geocoderControl)
      map.addControl(this.$store.state.zoomControl)
      map.addControl(this.$store.state.locateControl)
      // Setup click marker controls
      map.on('click', this.addMarker)
    },
    savePoint () {
      if (!this.tempPoint.geolocation) {
        this.$store.commit('setAlert', { active: true, message: 'You must select a location for your point by searching and/or clicking on the map!', level: 'alert-warning', timeout: 6000 })
        return false
      }
      if (!this.tempPoint.name) {
        this.$store.commit('setAlert', { active: true, message: 'You must name your point!', level: 'alert-warning', timeout: 6000 })
        return false
      }
      if (this.$store.state.editMode) {
        fb.pointsCollection.doc(this.point.id).update({
          description: this.tempPoint.description,
          diningOptions: this.tempPoint.diningOptions || false,
          geolocation: this.tempPoint.geolocation,
          hidden: this.tempPoint.hidden || false,
          markerClass: this.tempPoint.markerClass,
          name: this.tempPoint.name,
          status: this.tempPoint.status || 'Open',
          updatedBy: this.$store.state.currentUser.uid,
          updatedOn: new Date(),
          waitTime: parseInt(this.tempPoint.waitTime, 10) || 0,
          webCamUrl: this.tempPoint.webCamUrl || ''
        }).then(ref => {
          fb.compileRequestsCollection.add({
            createdOn: new Date(),
            createdBy: this.$store.state.currentUser.uid,
            trailSystemId: this.$store.state.trailSystem.id,
            oldGeoJsonPath: this.$store.state.trailSystem.geoJsonPath
          })
          this.closeCreatePoint()
          this.$store.commit('setAlert', { active: true, message: 'Updating point!', level: 'alert-success', timeout: 4000 })
        }).catch(err => {
          this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 6000 })
          console.log(err)
        })
      } else {
        fb.pointsCollection.add({
          createdBy: this.$store.state.currentUser.uid,
          createdOn: new Date(),
          description: this.tempPoint.description,
          diningOptions: this.tempPoint.diningOptions || false,
          geolocation: this.tempPoint.geolocation,
          hidden: this.tempPoint.hidden || false,
          markerClass: this.tempPoint.markerClass,
          name: this.tempPoint.name,
          status: this.tempPoint.status || 'Open',
          trailSystemId: this.$store.state.trailSystem.id,
          updatedBy: this.$store.state.currentUser.uid,
          updatedOn: new Date(),
          waitTime: parseInt(this.tempPoint.waitTime, 10) || 0,
          webCamUrl: this.tempPoint.webCamUrl || ''
        }).then(ref => {
          fb.compileRequestsCollection.add({
            createdOn: new Date(),
            createdBy: this.$store.state.currentUser.uid,
            trailSystemId: this.$store.state.trailSystem.id,
            oldGeoJsonPath: this.$store.state.trailSystem.geoJsonPath
          })
          this.closeCreatePoint()
          this.$store.commit('setAlert', { active: true, message: 'Creating point!', level: 'alert-success', timeout: 4000 })
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }
}
</script>

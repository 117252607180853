<template>
  <div id="app">
    <Alert
      v-if="$store.state.alert.active == true"
    />
    <router-view/>
  </div>
</template>

<script>
import Alert from './components/Alert'

export default {
  components: {
    Alert
  }
}
</script>

<template>
  <div id="signage">
    <SignageHeader v-if="$store.state.trailSystem.id" />
    <div
      class="container-fluid"
      style="margin-top:100px"
      v-if="$store.state.trailSystem.subscription === 'doubleDiamond' || $store.state.trailSystem.subscription === 'star' || $store.state.trailSystem.subscription === 'diamond'"
    >
      <div class="row">
        <div class="col-4" id="signageLeft">
          <SignageUpdates v-if="$store.state.trailSystem.id" />
          <iframe id="signageMap" :src="'/ts/' + $store.state.trailSystem.id + '/map/embed_noui'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen scrolling="no"></iframe>
        </div><!-- /.col-4 -->
        <div class="col-8" id="signageRight">
          <SignageTrails v-if="showTrails" />
        </div><!-- /.col-8 -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div><!-- /#signage -->
</template>

<script>
import SignageHeader from './SignageHeader'
import SignageUpdates from './SignageUpdates'
import SignageTrails from './SignageTrails'
import firebase from 'firebase'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      showTrails: false
    }
  },
  components: {
    SignageHeader,
    SignageUpdates,
    SignageTrails
  },
  computed: {
    hiddenActivities: {
      get () {
        return this.$store.state.hiddenActivities
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'hiddenActivities', value: value })
      }
    },
    trailSystem: {
      get () {
        return this.$store.state.trailSystem
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trailSystem', value: value })
      }
    }
  },
  created () {
    const tsId = this.$route.params.id
    fb.trailSystemsCollection.doc(tsId).onSnapshot(doc => {
      this.showTrails = false
      const tsData = doc.data()
      tsData.id = doc.id
      this.$store.commit('setStateProperty', { property: 'trailSystem', value: tsData })
      this.$store.dispatch('fetchTrails').then(() => {
        if (this.trailSystem.hiddenActivities) {
          this.trailSystem.hiddenActivities.forEach(hiddenActivity => {
            this.hiddenActivities[hiddenActivity.value] = true
            this.trailSystem.activities.forEach((activity, index) => {
              if (activity.value === hiddenActivity.value) {
                this.trailSystem.activities.splice(index, 1)
              }
            })
          })
        }
        this.showTrails = true
      })
      // GET ICON
      if (this.trailSystem.iconPath) {
        const iconPath = this.trailSystem.iconPath
        const iconPathSm = iconPath.split('.').slice(0, -1).join('.') + '_512x512.' + iconPath.substr(iconPath.lastIndexOf('.') + 1)
        const storageRef = firebase.storage().ref(iconPathSm)
        storageRef.getDownloadURL().then(url => {
          this.$store.commit('setStateProperty', { property: 'showTSIcon', value: false })
          if (url) {
            this.trailSystem.iconUrl = url
          } else {
            this.trailSystem.iconUrl = '/img/icons/android-chrome-512x512.png'
          }
          this.$store.commit('setStateProperty', { property: 'showTSIcon', value: true })
        }).catch(err => {
          console.log(err.message)
        })
      } else {
        this.trailSystem.iconUrl = '/img/icons/android-chrome-512x512.png'
      }
    })
  }
}
</script>

<style scoped>
#signage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#signageMap {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  height: 50%;
  border-radius: 20px;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  overflow: hidden;
}

#signageLeft {
  position: relative;
  height: calc(100vh - 100px);
  background-color: #4BB0DC;
}

#signageRight {
  background-color: #0A5C72;
}
</style>

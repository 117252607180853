<template>
  <div id="signageCalendars">
    <!-- <p>
      <span
        v-for="(calendar, i) in $store.state.trailSystem.calendars"
        v-bind:key="i"
        class="calendar-pill"
        :style="'background-color: ' + calendar.color.hex + ';'"
      >
        {{ calendar.title }}
      </span>
    </p> -->
    <div id="calendar"></div>
  </div><!-- /#signageWebCams -->
</template>

<script>
export default {
  mounted () {
    const self = this
    setTimeout(function () {
      self.initCalendar()
    }, 500)
  },
  methods: {
    initCalendar () {
      const self = this
      var calendarEl = document.getElementById('calendar')
      const calendarSources = []
      for (let i = 0; i < self.$store.state.trailSystem.calendars.length; i++) {
        const thisCal = self.$store.state.trailSystem.calendars[i]
        calendarSources.push({
          googleCalendarId: thisCal.id,
          color: thisCal.color.hex
        })
      }

      var calendar = new FullCalendar.Calendar(calendarEl, { // eslint-disable-line
        plugins: ['dayGrid', 'list', 'timeGrid', 'googleCalendar', 'bootstrap'],
        themeSystem: 'bootstrap',
        // header: {
        //   left: 'prev,next today',
        //   center: 'title',
        //   right: 'dayGridMonth,weekList' // timeGridWeek
        // },
        defaultView: 'weekList',
        views: {
          weekList: {
            type: 'list',
            duration: { days: 7 },
            buttonText: 'list'
          }
        },
        // aspectRatio: 2,
        eventLimit: true,
        displayEventTime: true,
        visibleRange: function (currentDate) {
          // Generate a new date for manipulating in the next step
          var startDate = new Date(currentDate.valueOf())
          var endDate = new Date(currentDate.valueOf())

          // Adjust the start & end dates, respectively
          startDate.setDate(startDate.getDate()) // One day in the past
          endDate.setDate(endDate.getDate() + 7) // Two days into the future

          return { start: startDate, end: endDate }
        },
        // API Key
        googleCalendarApiKey: self.$store.state.trailSystem.calendarApiKey,
        // Calendar Sources
        eventSources: calendarSources
      })
      calendar.render()
    }
  }
}
</script>

<style>
.fc-toolbar {
  display: none;
}

.fc-view {
  background-color: #F5F5F5;
}

.fc-scroller {
  max-height: 390px;
  border-radius: 4px;
}
</style>

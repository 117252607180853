<script>
import { Line } from 'vue-chartjs'
const turf = require('@turf/turf')

export default {
  extends: Line,
  mounted () {
    const elevations = []
    if (this.$store.state.trail.elevations) {
      this.$store.state.trail.elevations.forEach((elevation, i) => {
        if (elevation > 0) {
          const inFeet = elevation * 3.28084
          elevations.push(inFeet.toFixed(0))
        } else {
          elevations.push(0)
        }
      })
    }
    const highestElevation = Math.max(...elevations)
    const lowestElevation = Math.min(...elevations)
    const elevationDiff = highestElevation - lowestElevation
    const elevationMean = (highestElevation + lowestElevation) / 2
    let suggestedMin = lowestElevation
    let suggestedMax = highestElevation
    if (elevationDiff < 1000) {
      suggestedMin = elevationMean - 100
      suggestedMax = elevationMean + 100
    }
    if (elevationDiff < 500) {
      suggestedMin = elevationMean - 500
      suggestedMax = elevationMean + 500
    }
    if (elevationDiff < 100) {
      suggestedMin = elevationMean - 900
      suggestedMax = elevationMean + 900
    }
    const self = this
    let highlightedElevationPoint
    this.renderChart(
      {
        labels: elevations,
        datasets: [
          {
            label: 'Elevation',
            data: elevations,
            backgroundColor: 'transparent',
            borderColor: '#333',
            pointBackgroundColor: 'rgba(171, 71, 188, 0)',
            pointRadius: 4,
            pointHitRadius: 10
          }
        ]
      },
      {
        hover: {
          mode: 'point',
          intersect: true,
          onHover: function (e, item) {
            const map = self.$store.state.mapObject
            if (item.length) {
              e.target.style.cursor = 'pointer'
              const index = item[0]._index
              if (highlightedElevationPoint !== index) {
                const stopLength = self.$store.state.trail.distance / 50
                const trailGeoJson = JSON.parse(self.$store.state.trail.geoJson)
                const stopDistance = stopLength * index
                const stopPoint = turf.along(trailGeoJson.features[0], stopDistance, { units: 'kilometers' })
                // Map the point
                if (map.getLayer('highlightElevationLayer')) {
                  map.removeLayer('highlightElevationLayer')
                }
                if (map.getSource('highlightElevationSource')) {
                  map.removeSource('highlightElevationSource')
                }
                map.addSource('highlightElevationSource', {
                  type: 'geojson',
                  data: {
                    type: 'FeatureCollection',
                    features: [
                      stopPoint
                    ]
                  }
                })
                map.addLayer({
                  id: 'highlightElevationLayer',
                  type: 'circle',
                  source: 'highlightElevationSource',
                  paint: {
                    'circle-color': '#333',
                    'circle-radius': 20
                  }
                })
                highlightedElevationPoint = index
              }
            } else {
              e.target.style.cursor = 'default'
              if (map.getLayer('highlightElevationLayer')) {
                map.removeLayer('highlightElevationLayer')
              }
              if (map.getSource('highlightElevationSource')) {
                map.removeSource('highlightElevationSource')
              }
            }
          }
        },
        tooltips: {
          displayColors: false,
          callbacks: {
            title: function () {},
            label: function (tooltipItems, data) {
              return tooltipItems.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' Feet'
            }
          }
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              display: false
            }
          }],
          yAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              display: false,
              suggestedMin: suggestedMin,
              suggestedMax: suggestedMax
            }
          }]
        },
        layout: {
          padding: {
            right: 10
          }
        }
      }
    )
  }
}
</script>

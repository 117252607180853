import Vue from 'vue'
import App from './App'
import './registerServiceWorker'
import router from './router'
import { store } from './store/store.js'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/scss/app.scss'
import VueClipboard from 'vue-clipboard2'
import VueGtag from 'vue-gtag'

const fb = require('./firebaseConfig.js')

Vue.use(BootstrapVue)
Vue.use(VueClipboard)
Vue.use(VueGtag, {
  config: { id: 'G-7X47PW0KQD' }
}, router)

Vue.config.productionTip = false

// handle page reloads
let app
fb.auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      el: '#app',
      router,
      store,
      render: h => h(App)
    })
  }
})

<template>
  <div id="banner">
    <div class="orbitist-banner">
      <div class="orbitist-banner-overlay"></div>
      <div class="container">
        <div class="orbitist-banner-content">
          <h1>TrailHUB</h1>
          <p>{{ language(1) }}</p>
          <router-link :to="{ name: 'AllSystems' }" class="outlined clickable">{{ language(2) }}</router-link>
          <p class="mt-4">
            <span
              class="mt-2 mb-0 clickable pick-lang"
              @click="toggleLanguage('fr')"
              v-if="trailSystem.language !== 'fr'"
            >
              <small>Voir en français</small>
            </span>
            <span
              class="mt-2 mb-0 clickable pick-lang"
              @click="toggleLanguage('en')"
              v-if="trailSystem.language !== 'en'"
            >
              <small>View in English</small>
            </span>
          </p>
        </div><!-- /.orbitist-banner-content -->
      </div><!-- /.container -->
    </div><!-- /.orbitist-banner -->
  </div><!-- /#banner -->
</template>

<script>
export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Maximize Outdoor Guest Experiences with our Trail Management System.',
          fr: 'Aidez les utilisateurs à exploiter au maximum vos sentiers grâce à notre nouveau système de gestion de sentiers!'
        },
        2: {
          en: 'Explore Active Trail Systems',
          fr: 'Explorez les réseaux de sentiers!'
        }
      }
    }
  },
  computed: {
    trailSystem: {
      get () {
        return this.$store.state.trailSystem
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trailSystem', value: value })
      }
    }
  },
  methods: {
    language (id) {
      const lang = this.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    toggleLanguage (lang) {
      this.trailSystem.language = lang
    }
  }
}
</script>

<style scoped>
a.outlined:hover {
  background-color: #eee;
  color: black;
  border: solid 1px #eee;
  text-decoration: none;
}
a.outlined {
  margin: 0 10px;
  color: #eee;
  transition: all 0.2s ease-in-out;
  padding: 10px 14px;
  border: solid 1px;
  border-radius: 50px;
}

/* .orbitist-banner {
  position: relative;
  width: 100%;
  height: 95vh;
  margin-bottom: 80px;
  background: url("https://images.unsplash.com/photo-1516611187196-8686751913bd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2551&q=80") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
} */
.orbitist-banner {
  position: relative;
  width: 100%;
  height: 95vh;
  margin-bottom: 80px;
  background: url("https://unsplash.com/photos/_5RMM-xLZGA/download?ixid=MnwxMjA3fDB8MXxhbGx8Mjd8fHx8fHwyfHwxNjQyNjk5NDAz&force=true&w=1920") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.orbitist-banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
  /* background-color: rgba(255,255,255,0.5); */
}

.orbitist-banner-content {
  position: absolute;
  left: 0;
  width: 100%;
  top: 30vh;
  color: #eee;
  padding: 20px;
  text-align: center;
}

.orbitist-banner-content h1 {
  font-size: 50px;
  margin-bottom: 15px;
}

.orbitist-banner-content p {
  font-size: 24px;
  font-weight: 500;
  margin: 0 auto 50px;
  max-width: 500px;
}

@media (max-width:800px) {
  .orbitist-banner-content {
    text-align: center;
    left: 0;
  }
  .orbitist-banner {
    margin-bottom: 40px;
  }
}
</style>

<template>
  <div id="#allSystems">
    <SideBar v-if="$store.state.drawer.allSystems" />
    <AllSystemsMap />
  </div><!-- /#allSystems -->
</template>

<script>
import SideBar from './SideBar'
import AllSystemsMap from './AllSystemsMap'

export default {
  components: {
    SideBar,
    AllSystemsMap
  }
}
</script>

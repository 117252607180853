<template>
  <div class="home">
    <Navbar />
    <Banner />
    <!-- <HeroText /> -->
    <!-- <HeroImageLeft
      :image="'https://firebasestorage.googleapis.com/v0/b/trail-hub.appspot.com/o/assets%2Fimages%2Fmarketing%2Fstandard.jpg?alt=media&token=baa1d005-48e3-495a-888a-2ac48ca13f9c'"
      :title="'Realtime Mapping'"
      :description="'Provide updates to your audiences in real time. Trail status, snow reports, surface conditions, notices, and more.'"
    />
    <HeroImageRight
      :image="'https://firebasestorage.googleapis.com/v0/b/trail-hub.appspot.com/o/assets%2Fimages%2Fmarketing%2Felevation_profile.jpg?alt=media&token=c092d604-bf2f-4269-82fc-871f4688ff74'"
      :title="'Elevation Profiles'"
      :description="'Help trail users understand what they are getting into with elevation profiles.'"
    /> -->
    <!-- <HeroImageLeft
      :image="'https://firebasestorage.googleapis.com/v0/b/trail-hub.appspot.com/o/assets%2Fimages%2Fmarketing%2Fgreat_glen_mountain.jpg?alt=media&token=2de8fba8-5d5d-42df-9c82-ed03ced72cba'"
      :title="'3D Maps'"
      :description="'Travelers can now explore your trails like never before. With 3D mode, they can understand your trail systems before hitting the road.'"
    /> -->
    <!-- <HeroImageRight
      :image="'https://firebasestorage.googleapis.com/v0/b/trail-hub.appspot.com/o/assets%2Fimages%2Fmarketing%2Fbethel.jpg?alt=media&token=7413ed1e-5fbd-4612-81d5-ad6fac115f5e'"
      :title="'More Ways to Share'"
      :description="'Spread the word about your trails by embedding lists, maps, or a mix of the two on your website. Use QR codes to make it easy for users to access your trail system. Users can save your trail system to their phone, subscribe to updates, and navigate using their gps location.'"
    /> -->
    <!-- <MapBlock /> -->

    <div class="container mb-5">
      <!-- <b-embed
        type="iframe"
        aspect="16by9"
        src="https://youtu.be/nYri3HbI_-k"
        allowfullscreen
      ></b-embed> -->
      <iframe width="100%" height="550" src="https://www.youtube.com/embed/nYri3HbI_-k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <Offerings />

    <p class="text-center icons">
      <i class="icon-Large_1380797509"></i><i class="icon-Large_1379659150"></i><i class="icon-Large_1379658986"></i><i class="icon-Large_1379661860"></i><i class="icon-Large_1380797480"></i><i class="icon-Large_1487062241"></i><i class="icon-Large_1379661220"></i><br>
      <i class="icon-Large_1379661131"></i><i class="icon-Large_1377155288"></i><i class="icon-Large_1486981687"></i><i class="icon-Large_1486981452"></i><i class="icon-Large_1486981412"></i>
    </p>
    <p class="text-center"><a class="outlined platform-support" href="https://support.trailhub.org" target="_blank"><i class="fas fa-question-circle"></i> {{ language(1) }}</a></p>
    <p class="text-center">© Orbitist LLC ~</p>
  </div>
</template>

<script>
import Navbar from './Navbar.vue'
import Banner from './Banner.vue'
import Offerings from './Offerings.vue'
// import HeroText from './HeroText.vue'
// import HeroImageLeft from './HeroImageLeft.vue'
// import HeroImageRight from './HeroImageRight.vue'
// import MapBlock from './MapBlock.vue'

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Platform Support',
          fr: 'Aide sur la plateforme'
        }
      }
    }
  },
  components: {
    Navbar,
    Banner,
    Offerings
    // HeroText,
    // HeroImageLeft,
    // HeroImageRight
    // MapBlock
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    }
  }
}
</script>

<style scoped>
a.outlined:hover {
  background-color: #53c2ef;
  color: white;
  border: solid 1px #eee;
  text-decoration: none;
}
a.outlined {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  color: #eee;
  transition: all 0.2s ease-in-out;
  padding: 10px 14px;
  border: solid 1px #4bb0dc;
  border-radius: 50px;
  background-color: #4bb0dc;
}
.icons {
  font-size: 24px;
  margin: 80px 20px;
  letter-spacing: 10px;
}
</style>

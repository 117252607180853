<template>
  <div id="login" class="pb-5">
    <p class="photo-credit mb-0"><small><span>Photo by <a href="https://unsplash.com/@jamked?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Jamshed Khedri</a> on <a href="https://unsplash.com/s/photos/trail?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span></small></p>

    <!-- SPINNER -->
    <transition name="fade">
      <div id="map-spinner" v-if="performingRequest">
        <RingLoader
          :color="'#333'"
        ></RingLoader>
      </div><!-- /#map-spinner -->
    </transition>

    <div class="login-form">
      <div class="login-form-header p-1 mb-2 bg-warning">
        <p class="mb-0"><strong>{{ language(1) }}</strong><br>{{ language(2) }}<br><a href="/public-login">{{ language(3) }} <i class="fas fa-arrow-right"></i></a></p>
      </div>
      <h1>TrailHUB</h1>
      <p class="lead">{{ language(4) }}</p>

      <div :class="{ 'signup-form': !showLoginForm && !showForgotPassword }">

        <!-- Login Form -->
        <form v-if="showLoginForm" @submit.prevent>
          <div class="form-group">
            <label for="email1">{{ language(5) }}</label>
            <input v-model.trim="loginForm.email" type="text" placeholder="you@email.com" id="email1" class="form-control" />
          </div><!-- /.form-group -->
          <div class="form-group">
            <label for="password1">{{ language(6) }}</label>
            <input v-model.trim="loginForm.password" type="password" placeholder="******" id="password1" class="form-control" />
          </div><!-- /.form-group -->
          <button @click="login" class="btn btn-primary">{{ language(7) }}</button>
          <div class="extras">
            <button class="btn btn-sm btn-secondary mr-1" @click="togglePasswordReset">{{ language(8) }}</button>
            <button class="btn btn-sm btn-secondary" @click="toggleForm">{{ language(9) }}</button>
          </div><!-- /.extras -->
        </form>

        <!-- Signup Form -->
        <form v-if="!showLoginForm && !showForgotPassword" @submit.prevent>
          <h2>{{ language(9) }}</h2>
          <div class="form-group">
            <label for="nameFirst2">{{ language(14) }}</label>
            <input v-model.trim="signupForm.nameFirst" type="text" placeholder="First Name" id="nameFirst2" class="form-control" />
          </div><!-- /.form-group -->
          <div class="form-group">
            <label for="nameLast2">{{ language(15) }}</label>
            <input v-model.trim="signupForm.nameLast" type="text" placeholder="Last Name" id="nameLast2" class="form-control" />
          </div><!-- /.form-group -->
          <div class="form-group">
            <label for="phone2">{{ language(16) }}</label>
            <input v-model.trim="signupForm.phoneNumber" type="text" placeholder="555-555-5555" id="phone2" class="form-control" />
          </div><!-- /.form-group -->
          <div class="form-group">
            <label for="nameLast2">{{ language(17) }}</label>
            <input v-model.trim="signupForm.organization" type="text" placeholder="Organization" id="nameLast2" class="form-control" />
          </div><!-- /.form-group -->
          <div class="form-group">
            <label for="email2">{{ language(5) }}</label>
            <input v-model.trim="signupForm.email" type="text" placeholder="you@email.com" id="email2" class="form-control" />
          </div><!-- /.form-group -->
          <div class="form-group">
            <label for="password2">{{ language(6) }}</label>
            <input v-model.trim="signupForm.password" type="password" placeholder="min 6 characters" id="password2" class="form-control" />
          </div><!-- /.form-group -->
          <button @click="signup" class="btn btn-primary">{{ language(20) }}</button>
          <div class="extras">
            <button class="btn btn-sm btn-secondary" @click="toggleForm">{{ language(13) }}</button>
          </div><!-- /.extras -->
        </form>

        <!-- Forgot Password -->
        <form v-if="showForgotPassword" @submit.prevent class="password-reset">
          <div v-if="!passwordResetSuccess">
            <h2>{{ language(10) }}</h2>
            <p>{{ language(11) }}</p>
            <div class="form-group">
              <label for="email3">Email</label>
              <input v-model.trim="passwordForm.email" type="text" placeholder="you@email.com" id="email3" class="form-control" />
            </div><!-- /.form-control -->
            <button @click="resetPassword" class="btn btn-primary">{{ language(12) }}</button>
            <div class="extras">
              <button @click="togglePasswordReset" class="btn btn-sm btn-secondary">{{ language(13) }}</button>
            </div><!-- /.extras -->
          </div><!-- /v-if=!passwordResetSuccess -->
          <div v-else>
            <h2>{{ language(21) }}</h2>
            <p>{{ language(22) }}</p>
            <button @click="togglePasswordReset" class="btn btn-primary">{{ language(13) }}</button>
          </div><!-- /v-else -->
        </form>
        <p
          class="mt-2 mb-0 clickable"
          @click="toggleLanguage('fr')"
          v-if="trailSystem.language !== 'fr'"
        >
          <small>Voir en français</small>
        </p>
        <p
          class="mt-2 mb-0 clickable"
          @click="toggleLanguage('en')"
          v-if="trailSystem.language !== 'en'"
        >
          <small>View in English</small>
        </p>
      </div><!-- /.signup-form -->
    </div><!-- /.container -->
  </div><!-- /#login -->
</template>

<script>
import RingLoader from 'vue-spinner/src/GridLoader'
const fb = require('../../firebaseConfig.js')

export default {
  components: { RingLoader },
  data () {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      signupForm: {
        nameFirst: '',
        nameLast: '',
        phoneNumber: '',
        organization: '',
        email: '',
        password: ''
      },
      passwordForm: {
        email: ''
      },
      showLoginForm: true,
      showForgotPassword: false,
      passwordResetSuccess: false,
      performingRequest: false,
      spinnerColor: '#61539b',
      spinnerSize: '50px',
      phrases: {
        1: {
          en: 'This form is for Trail Managers.',
          fr: 'Ce formulaire est destiné aux gestionnaires de sentiers.'
        },
        2: {
          en: 'Are you a Public User?',
          fr: 'Êtes-vous un utilisateur public?'
        },
        3: {
          en: 'Login Here',
          fr: 'Connectez-vous ici'
        },
        4: {
          en: 'Outdoor Command Center',
          fr: 'Centre de commandement extérieur'
        },
        5: {
          en: 'Email',
          fr: 'Adresse courriel'
        },
        6: {
          en: 'Password',
          fr: 'Mot de passe'
        },
        7: {
          en: 'Log In',
          fr: 'Connectez-vous'
        },
        8: {
          en: 'Forgot Password',
          fr: 'Mot de passe oublié'
        },
        9: {
          en: 'Create an Account',
          fr: 'Créer un compte'
        },
        10: {
          en: 'Reset Password',
          fr: 'Réinitialiser le mot de passe'
        },
        11: {
          en: 'We will send you an email to reset your password',
          fr: 'Un courriel vous sera envoyé pour réinitialiser votre mot de passe'
        },
        12: {
          en: 'Submit',
          fr: 'Soumettre'
        },
        13: {
          en: 'Back to Log In',
          fr: 'Retour à la page de connexion'
        },
        14: {
          en: 'First Name',
          fr: 'Prénom'
        },
        15: {
          en: 'Last Name',
          fr: 'Nom de famille'
        },
        16: {
          en: 'Phone Number',
          fr: 'Numéro de téléphone'
        },
        17: {
          en: 'Organization',
          fr: 'Organisation'
        },
        20: {
          en: 'Sign Up',
          fr: 'S\'inscrire'
        },
        21: {
          en: 'Email Sent',
          fr: 'Courriel envoyé'
        },
        22: {
          en: 'Check your email for a link to reset your password',
          fr: 'Vérifiez vos courriels pour obtenir le lien de réinitialisation de votre mot de passe'
        }
      }
    }
  },
  created () {
    if (this.$store.state.currentUser) {
      this.$router.push('/ts/')
    }
    if (this.$route.params.mode === 'create') {
      this.showLoginForm = false
    }
    if (this.$route.params.mode === 'reset') {
      this.showLoginForm = false
      this.showForgotPassword = true
    }
  },
  computed: {
    trailSystem: {
      get () {
        return this.$store.state.trailSystem
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trailSystem', value: value })
      }
    }
  },
  methods: {
    toggleLanguage (lang) {
      this.trailSystem.language = lang
    },
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    toggleForm () {
      this.showLoginForm = !this.showLoginForm
    },
    togglePasswordReset () {
      if (this.showForgotPassword) {
        this.showLoginForm = true
        this.showForgotPassword = false
        this.passwordResetSuccess = false
      } else {
        this.showLoginForm = false
        this.showForgotPassword = true
      }
    },
    login () {
      this.performingRequest = true
      fb.auth.signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password).then(user => {
        this.$store.commit('setStateProperty', { property: 'currentUser', value: user.user })
        this.$store.dispatch('fetchUserProfile')
        this.performingRequest = false
        if (this.$route.params.redirect) {
          window.location.href = '/ts/' + this.$route.params.redirect
        } else {
          this.$router.push('/ts/')
        }
      }).catch(err => {
        console.log(err)
        this.performingRequest = false
        this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 6000 })
      })
    },
    signup () {
      this.performingRequest = true
      fb.auth.createUserWithEmailAndPassword(this.signupForm.email, this.signupForm.password).then(user => {
        this.$store.commit('setStateProperty', { property: 'currentUser', value: user.user })
        fb.usersCollection.doc(user.user.uid).set({
          createdOn: new Date(),
          email: this.signupForm.email,
          nameFirst: this.signupForm.nameFirst,
          nameLast: this.signupForm.nameLast,
          organization: this.signupForm.organization,
          phoneNumber: this.signupForm.phoneNumber
        }).then(() => {
          this.$store.dispatch('fetchUserProfile')
          this.performingRequest = false
          if (this.$route.params.redirect) {
            window.location.href = '/ts/' + this.$route.params.redirect
          } else {
            this.$router.push('/ts/')
          }
        }).catch(err => {
          console.log(err)
          this.performingRequest = false
          this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 6000 })
        })
      }).catch(err => {
        console.log(err)
        this.performingRequest = false
        this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 6000 })
      })
    },
    resetPassword () {
      this.performingRequest = true
      fb.auth.sendPasswordResetEmail(this.passwordForm.email).then(() => {
        this.performingRequest = false
        this.passwordResetSuccess = true
        this.passwordForm.email = ''
      }).catch(err => {
        console.log(err)
        this.performingRequest = false
        this.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-warning', timeout: 6000 })
      })
    }
  }
}
</script>

<style scoped>
#map-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(255,255,255,0.5);
}

.v-spinner {
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50vh - 50px);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.photo-credit {
  position: fixed;
  bottom: 0;
  right: 10px;
  color: white;
}
.photo-credit a {
  color: #eee;
}
#login {
  padding: 20px;
  background-color: #eee;
  min-height: 100vh;
  background: url('/static/login-background.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login-form {
  background-color: rgba(255,255,255,0.8);
  color: #333;
  max-width: 350px;
  margin: auto;
  padding: 0px 20px 30px 20px;
  border-radius: 20px;
}

.login-form-header {
  margin-left: -20px;
  margin-right: -20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.login-form .logo {
  width: 150px;
  margin-left: calc(50% - 75px);
  margin-bottom: 20px;
}

.login-form h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}

.login-form h2 {
  text-align: center;
  font-size: 24px;
}

.login-form p {
  text-align: center;
}

.login-form button.btn-primary {
  width: 100%;
  border-radius: 50px;
}

.extras {
  margin-top: 10px;
  text-align: center;
}

</style>

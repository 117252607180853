<template>
  <div id="admin">
    <AdminNav />
    <AdminTable />
    <AdminTrailSystem v-if="$store.state.adminData.showTrailSystem" />
    <AdminChangeOwner v-if="$store.state.adminData.changeOwner" />
  </div><!-- /#admin -->
</template>

<script>
import AdminNav from './AdminNav'
import AdminTable from './AdminTable'
import AdminTrailSystem from './AdminTrailSystem'
import AdminChangeOwner from './AdminChangeOwner'

export default {
  components: {
    AdminNav,
    AdminTable,
    AdminTrailSystem,
    AdminChangeOwner
  }
}
</script>

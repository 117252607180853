<template>
  <div id="calendars">
    <b-card
      class="blockDrawer large fullMobile"
      :class="$store.state.drawer.calendars.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="$store.commit('hideDrawer', 'calendars')"
        >
          <i class="far fa-times"></i>
        </a>
        <h4>{{ language(1) }}</h4>
      </template>
      <p>
        <span
          v-for="(calendar, i) in $store.state.trailSystem.calendars"
          v-bind:key="i"
          class="calendar-pill"
          :style="'background-color: ' + calendar.color.hex + ';'"
        >
          {{ calendar.title }}
        </span>
      </p>

      <div id="calendar"></div>

      <!-- Calendar Popup -->
      <div
        v-if="popup.active == true"
        class="calendar-popup"
      >
        <div class="calendar-popup-content">
          <div class="clickable calendar-popup-close"
            @click="popup.active = false"
          ><i class="fas fa-times"></i></div>
          <h2>{{ popup.title }}</h2>
          <p
            v-if="popup.allDay == false"
            class="lead"
          >{{ language(2) }} {{ popup.startTime | shortTime }}<br>{{ language(3) }} {{ popup.endTime | shortTime }}</p>
          <p
            v-if="popup.allDay == true"
            class="lead"
          >{{ language(4) }}</p>
          <p
            v-if="popup.location.length > 1"
          ><strong>{{ popup.location }}</strong></p>
          <p
            v-if="popup.description.length > 1"
            v-html="popup.description"
          ></p>
        </div><!-- /.calendar-popup-content -->
      </div><!-- /.calendar-popup -->
    </b-card>
  </div><!-- /#calendars -->
</template>

<script>
export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Calendars',
          fr: 'Calendriers'
        },
        2: {
          en: 'Start:',
          fr: 'Début:'
        },
        3: {
          en: 'End:',
          fr: 'Finir:'
        },
        4: {
          en: 'All Day',
          fr: 'Toute la journée'
        }
      },
      popup: {
        active: false,
        title: '',
        description: '',
        startTime: '',
        endTime: '',
        location: '',
        allDay: false
      }
    }
  },
  mounted () {
    const self = this
    setTimeout(function () {
      self.initCalendar()
    }, 500)
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    initCalendar () {
      const self = this
      var calendarEl = document.getElementById('calendar')
      const calendarSources = []
      for (let i = 0; i < self.$store.state.trailSystem.calendars.length; i++) {
        const thisCal = self.$store.state.trailSystem.calendars[i]
        calendarSources.push({
          googleCalendarId: thisCal.id,
          color: thisCal.color.hex
        })
      }

      var calendar = new FullCalendar.Calendar(calendarEl, { // eslint-disable-line
        locale: this.$store.state.trailSystem.language,
        plugins: ['dayGrid', 'list', 'timeGrid', 'googleCalendar', 'bootstrap'],
        themeSystem: 'bootstrap',
        header: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,weekList' // timeGridWeek
        },
        defaultView: 'weekList',
        views: {
          weekList: {
            type: 'list',
            duration: { days: 7 },
            buttonText: 'list'
          }
        },
        // aspectRatio: 2,
        eventLimit: true,
        displayEventTime: true,
        visibleRange: function (currentDate) {
          // Generate a new date for manipulating in the next step
          var startDate = new Date(currentDate.valueOf())
          var endDate = new Date(currentDate.valueOf())

          // Adjust the start & end dates, respectively
          startDate.setDate(startDate.getDate()) // One day in the past
          endDate.setDate(endDate.getDate() + 7) // Two days into the future

          return { start: startDate, end: endDate }
        },
        // API Key
        googleCalendarApiKey: self.$store.state.trailSystem.calendarApiKey,
        // Calendar Sources
        eventSources: calendarSources,
        eventClick: function (arg) {
          arg.jsEvent.preventDefault()
          console.log(arg)
          console.log(self.popup)
          self.popup.title = arg.event.title
          self.popup.startTime = arg.event.start
          self.popup.endTime = arg.event.end
          self.popup.allDay = arg.event.allDay
          if (arg.event.extendedProps.location) {
            self.popup.location = arg.event.extendedProps.location
          } else {
            self.popup.location = ''
          }
          if (arg.event.extendedProps.description) {
            self.popup.description = arg.event.extendedProps.description
          } else {
            self.popup.description = ''
          }
          self.popup.active = true
        }
      })
      calendar.render()
    }
  },
  filters: {
    shortTime (time) {
      var a = new Date(time)
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      var year = a.getFullYear()
      var month = months[a.getMonth()]
      var date = a.getDate()
      var timeString = a.toLocaleTimeString('en-US')
      var newTime = month + ', ' + date + ', ' + year + ' - ' + timeString
      return newTime
    }
  }
}
</script>

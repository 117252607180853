<template>
  <div id="update">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.update.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="closeUpdate()"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title">
          Update
        </h4>
        <b-btn
          @click="editUpdate()"
          class="mb-2"
          v-if="$store.state.currentUser"
        >
          Edit Update
        </b-btn>
      </template>
    </b-card>
  </div><!-- /#update -->
</template>

<script>
export default {
  computed: {
    update: {
      get () {
        return this.$store.state.update
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'update', value: value })
      }
    }
  },
  methods: {
    editUpdate () {
      this.$store.commit('setStateProperty', { property: 'editMode', value: true })
      this.$store.commit('hideDrawer', 'update')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'createUpdate')
      }, 300)
    },
    closeUpdate () {
      this.$store.commit('hideDrawer', 'update')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'updates')
      }, 300)
    }
  }
}
</script>

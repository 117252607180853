<template>
  <div id="imageUpload">
    <input
      v-if="!iconPath"
      type="file"
      class="form-control-file"
      accept=".jpg,.png"
      @change="onFileSelected"
      id="imageFileInput"
    />
    <div class="progress" style="height: 2px;">
      <div class="progress-bar" id="progressBar" role="progressbar" style="width: 0%;" aria-valuemin="0" aria-valuemax="100"></div>
    </div><!-- /.progress -->
    <img
      v-if="iconUrl"
      :src="iconUrl"
      class="w-100"
    />
    <b-btn
      v-if="iconPath"
      @click="deleteIcon()"
      variant="danger"
      class="mt-1"
    >{{ language(1) }}</b-btn>
  </div><!-- /.dataFiles-input -->
</template>

<script>
import firebase from 'firebase'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Delete Icon',
          fr: 'Supprimer l\'icône'
        }
      },
      iconUrl: false,
      iconPath: false
    }
  },
  computed: {
    trailSystem: {
      get () {
        return this.$store.state.trailSystem
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'trailSystem', value: value })
      }
    }
  },
  created () {
    if (this.$store.state.editMode === true && this.trailSystem.iconPath) {
      this.iconPath = this.trailSystem.iconPath
      this.renderIcon()
    }
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    },
    deleteIcon () {
      const iconPath = this.iconPath
      const iconPathSm = iconPath.split('.').slice(0, -1).join('.') + '_512x512.' + iconPath.substr(iconPath.lastIndexOf('.') + 1)
      const iconPathMd = iconPath.split('.').slice(0, -1).join('.') + '_1500x1500.' + iconPath.substr(iconPath.lastIndexOf('.') + 1)
      console.log(iconPath)
      console.log(iconPathSm)
      console.log(iconPathMd)
      firebase.storage().ref(iconPath).delete()
      firebase.storage().ref(iconPathSm).delete()
      firebase.storage().ref(iconPathMd).delete()
      const self = this
      fb.trailSystemsCollection.doc(this.trailSystem.id).update({
        iconPath: false,
        updatedOn: new Date(),
        updatedBy: self.$store.state.currentUser.uid
      })
      this.iconUrl = false
      this.iconPath = false
      this.$emit('fileDelivery', false)
    },
    renderIcon () {
      const iconPath = this.iconPath
      const storageRef = firebase.storage().ref(iconPath)
      storageRef.getDownloadURL().then(url => {
        this.iconUrl = url
      })
    },
    onFileSelected (e) {
      const self = this
      // Setup file name
      function getFormattedTime () {
        var today = new Date()
        var y = today.getFullYear()
        var m = today.getMonth() + 1
        var d = today.getDate()
        return y + '-' + m + '-' + d + '-'
      }
      function s4 () {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1)
      }
      const fileId = getFormattedTime() + s4()
      // Get the file
      const file = e.target.files[0]
      // Cunstruct the path and storage reference using the farm id
      const filePath = 'trailSystems/' + self.trailSystem.id + '/' + fileId + '-' + file.name
      const storageRef = firebase.storage().ref(filePath)
      // Upload the raw file
      const task = storageRef.put(file)
      // Update the progressBar
      task.on('state_changed', function progress (snapshot) {
        const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100 + '%'
        document.getElementById('progressBar').style.width = percentage
      },
      function error (err) {
        console.log('errors: ' + err)
      },
      function complete () {
        self.$emit('fileDelivery', filePath)
        self.iconPath = filePath
        document.getElementById('progressBar').style.width = 0
        self.renderIcon()
      }
      )
    }
  }
}
</script>

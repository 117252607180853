<template>
  <div id="billing">
    <b-card
      id="billingCard"
      class="blockDrawer full"
      :class="$store.state.drawer.billing.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="hideBilling()"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 id="blockTitle">Billing</h4>
      </template>
      <h1 class="text-center">Choose a Plan</h1>
      <div class="text-center mb-3">
        <b-form-checkbox
          v-model="pricingInterval"
          switch
          size="lg"
          value="year"
          unchecked-value="month"
        >Annual Pricing</b-form-checkbox>
        <b-form-checkbox
          v-model="priceMode"
          switch
          size="lg"
          value="nonProfit"
          unchecked-value="forProfit"
        >Nonprofit Prices</b-form-checkbox>
      </div><!-- /.col-md-4 -->
      <div class="container">
        <div v-if="priceMode === 'forProfit'" class="mb-3 text-center row">
          <div v-for="(planValue, i) in planOrder" v-bind:key="i" class="col-md-4">
            <div
              v-for="(plan, i) in $store.state.plans"
              v-bind:key="i"
            >
              <div class="card mb-4 box-shadow" v-if="plan.name == planValue">
                <div class="card-header">
                  <span v-if="plan.name == 'Square'" class="difficulty-marker square mb-1"></span>
                  <span v-if="plan.name == 'Diamond'" class="difficulty-marker diamond mb-1"></span>
                  <span v-if="plan.name == 'Double Diamond'" class="difficulty-marker diamond ml-1 mb-1"></span><span v-if="plan.name == 'Double Diamond'" class="difficulty-marker diamond mb-1"></span>
                  <p class="my-0 font-weight-normal"><strong>{{ plan.name }}</strong></p>
                  <p class="mt-3 mb-4">{{ plan.description }}</p>
                </div>
                <div
                  v-for="(price, i) in plan.prices"
                  v-bind:key="i"
                >
                  <div class="card-body pb-2" v-if="price.interval == pricingInterval">
                    <h2 class="card-title pricing-card-title">${{ price.unit_amount | toMoney }}</h2>
                    <p>Per {{ price.interval }}</p>
                    <button
                      type="button"
                      class="btn btn-lg btn-block btn-success"
                      @click="choosePlan(price)"
                      v-if="!loading"
                    >Choose Plan</button>
                    <b-btn
                      v-if="loading"
                      class="mb-4"
                      variant="default"
                    >
                      <i class="fas fa-sync fa-spin"></i> Please wait...
                    </b-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div><!-- forProfit Plans -->
        <div v-if="priceMode === 'nonProfit'" class="mb-3 text-center row">
          <div v-for="(planValue, i) in nonProfitPlanOrder" v-bind:key="i" class="col-md-4">
            <div
              v-for="(plan, i) in $store.state.plans"
              v-bind:key="i"
            >
              <div class="card mb-4 box-shadow" v-if="plan.name == planValue">
                <div class="card-header">
                  <span v-if="plan.name == 'Circle'" class="difficulty-marker circle mb-1"></span>
                  <span v-if="plan.name == 'Triangle'" class="difficulty-marker triangle mb-1"></span>
                  <span v-if="plan.name == 'Star'" class="difficulty-marker star mb-1"></span>
                  <p class="my-0 font-weight-normal"><strong>{{ plan.name }}</strong></p>
                  <p class="mt-3 mb-4">{{ plan.description }}</p>
                </div>
                <div
                  v-for="(price, i) in plan.prices"
                  v-bind:key="i"
                >
                  <div class="card-body pb-2" v-if="price.interval == pricingInterval">
                    <h2 class="card-title pricing-card-title">${{ price.unit_amount | toMoney }}</h2>
                    <p>Per {{ price.interval }}</p>
                    <button
                      type="button"
                      class="btn btn-lg btn-block btn-success"
                      @click="choosePlan(price)"
                      v-if="!loading"
                    >Choose Plan</button>
                    <b-btn
                      v-if="loading"
                      class="mb-4"
                      variant="default"
                    >
                      <i class="fas fa-sync fa-spin"></i> Please wait...
                    </b-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div><!-- forProfit Plans -->
      </div><!-- container -->
    </b-card>
  </div><!-- /#billing -->
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      priceMode: 'forProfit',
      planOrder: ['Square', 'Diamond', 'Double Diamond'],
      nonProfitPlanOrder: ['Circle', 'Triangle', 'Star'],
      loading: false,
      pricingInterval: 'month'
    }
  },
  created () {
    console.log('Created Billing Template')
  },
  methods: {
    async choosePlan (price) {
      this.loading = true
      const docRef = await fb.customersCollection.doc(this.$store.state.currentUser.uid).collection('checkout_sessions').add({
        price: price.id,
        success_url: window.location.href,
        cancel_url: window.location.href
      })
      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data()
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          alert(`An error occured: ${error.message}`)
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe
          const stripe = await loadStripe('pk_live_51Hr1cgHcq2wTm0QWsnWStqvPNNyZqNsUl5zGkRghkCIj9GLP12i5xBQIGSs5KA0ccZQCCUkyMdePFCO5UbHVKBRH00hMhgfKMP')
          stripe.redirectToCheckout({ sessionId })
        }
      })
    },
    hideBilling () {
      this.$store.commit('hideDrawer', 'billing')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'userSettings')
      }, 300)
    }
  },
  filters: {
    toMoney (val) {
      return val / 100
    }
  }
}
</script>

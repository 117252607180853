<template>
  <div id="signageStats">
    <div v-if="$store.state.trailSystem.totalTrails">
      <p class="lead text-center signage-header">{{ language(1) }}</p>
      <p class="text-center"><strong>{{ language(3) }}:</strong> {{ $store.state.trailSystem.openTrails }} | <strong>{{ language(4) }}:</strong> {{ $store.state.trailSystem.cautionTrails }} | <strong>{{ language(5) }}:</strong> {{ $store.state.trailSystem.closedTrails }}</p>
      <b-progress height="24px" class="mb-3" :max="$store.state.trailSystem.totalTrails" show-value>
        <b-progress-bar :value="$store.state.trailSystem.openTrails" variant="success"><span class="clickable" @click="statusFilter('Open')"><i class="fas fa-check-circle"></i> {{ $store.state.trailSystem.openTrails }}</span></b-progress-bar>
        <b-progress-bar :value="$store.state.trailSystem.cautionTrails" variant="warning"><span class="clickable" @click="statusFilter('Caution')"><i class="fas fa-exclamation-circle"></i> {{ $store.state.trailSystem.cautionTrails }}</span></b-progress-bar>
        <b-progress-bar :value="$store.state.trailSystem.closedTrails" variant="danger"><span class="clickable" @click="statusFilter('Closed')"><i class="fas fa-times-circle"></i> {{ $store.state.trailSystem.closedTrails }}</span></b-progress-bar>
      </b-progress>
    </div>
    <p class="lead text-center signage-header mt-5">{{ language(2) }}</p>
    <p v-if="$store.state.trailSystem.activities" class="lead text-center">
      <span
        v-for="(activity, i) in $store.state.trailSystem.activities"
        v-bind:key="i"
      >
        <b-badge
          v-if="!$store.state.hiddenActivities[activity.value]"
          class="mr-1 clickable"
          pill
          variant="dark"
          @click="activityFilter(activity)"
        >
          <i :class="activity.iconClass"></i> {{ activity[$store.state.trailSystem.language] || activity.label }}
        </b-badge>
      </span>
    </p>
  </div><!-- /#signageStats -->
</template>

<script>
export default {
  data () {
    return {
      phrases: {
        1: {
          en: 'Trail Totals',
          fr: 'Nombre total de sentiers'
        },
        2: {
          en: 'Available Activities',
          fr: 'Activités offertes'
        },
        3: {
          en: 'Open',
          fr: 'Ouvert'
        },
        4: {
          en: 'Caution',
          fr: 'Attention'
        },
        5: {
          en: 'Closed',
          fr: 'Fermé'
        }
      }
    }
  },
  methods: {
    language (id) {
      const lang = this.$store.state.trailSystem.language || 'en'
      return this.phrases[id][lang]
    }
  }
}
</script>
